import { resolvable }                               from '@mathquis/modelx-resolvables';
import ContractIterationItemModel                   from 'Models/sales/ContractIterationItemModel';
import ContractIterationItemScheduleTypeModel       from 'Models/sales/ContractIterationItemScheduleTypeModel';
import ContractIterationItemScheduleTypeOptionModel from 'Models/sales/ContractIterationItemScheduleTypeOptionModel';
import { urn }                                      from 'helpers/ModelDictionary';
import { computed }                                 from 'mobx';
import moment                                       from 'moment';
import { getWeekdayName }                           from 'tools/Weekdays';
import SalesPrivateApiModel                         from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'contract_iteration_item_schedule')
export default class ContractIterationItemScheduleModel extends SalesPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'contractIterationItem': id;
		'contractIterationItem.contractIteration': id;
	}> = {};

	static get path(): string {
		return '/contract_iteration_item_schedules/{?id}';
	}

	@resolvable(ContractIterationItemModel, { attributeName: 'contractIterationItem' })
	public declare contractIterationItem: ContractIterationItemModel;

	@resolvable(ContractIterationItemScheduleTypeModel, { attributeName: 'contractIterationItemScheduleType' })
	public declare contractIterationItemScheduleType: ContractIterationItemScheduleTypeModel;

	@resolvable(ContractIterationItemScheduleTypeOptionModel, { attributeName: 'contractIterationItemScheduleTypeOption' })
	public declare contractIterationItemScheduleTypeOption: ContractIterationItemScheduleTypeOptionModel;

	public get dayOfWeek(): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
		return this.get('dayOfWeek', 0);
	}

	@computed
	public get endTime(): Moment {
		return moment(this.get('endTime', moment()), 'HH:mm:ss');
	}

	@computed
	public get startTime(): Moment {
		return moment(this.get('startTime', moment()), 'HH:mm:ss');
	}

	@computed
	public get label() {
		switch (this.contractIterationItemScheduleType.reference) {
			case 'every_weeks':
				return `Tous les ${getWeekdayName(this.dayOfWeek)}s, de ${this.startTime.format('HH[h]mm')} à ${this.endTime.format('HH[h]mm')}`;
			case 'only_weeks':
				return `Toutes les ${this.contractIterationItemScheduleTypeOption.label.toLowerCase()}, les ${getWeekdayName(this.dayOfWeek)}s, de ${this.startTime.format('HH[h]mm')} à ${this.endTime.format('HH[h]mm')}`;
			case 'the_nths':
				return `Tous ${this.contractIterationItemScheduleTypeOption.label.toLowerCase()} ${getWeekdayName(this.dayOfWeek)}s du mois, de ${this.startTime.format('HH[h]mm')} à ${this.endTime.format('HH[h]mm')}`;
			default:
				return '';
		}
	}

	@computed
	public get duration() {
		return moment.duration(this.endTime.diff(this.startTime));
	}
}