import { resolvable }      from '@mathquis/modelx-resolvables';
import PartitionGroupModel from 'Models/rh/PartitionGroupModel';
import StaffMemberModel    from 'Models/rh/StaffMemberModel';
import {
	Blamable,
	Timestampable,
}                          from 'helpers/traits';
import { computed }        from 'mobx';
import { getIdFromUrn }    from 'tools/UrnTools';
import RhPrivateApiModel   from '../../abstracts/RhPrivateApiModel';

export default class StaffMemberPartitionGroupModel extends Blamable(Timestampable(RhPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'partitionGroup': id;
		'partitionUrn': string;
		'staffMember': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	static get path(): string {
		return '/staff_member_partition_groups/{?id}';
	}

	@resolvable(PartitionGroupModel, { attributeName: 'partitionGroup' })
	public declare partitionGroup: PartitionGroupModel;

	@resolvable(StaffMemberModel, { attributeName: 'staffMember' })
	public declare staffMember: StaffMemberModel;

	@computed
	public get partitionGroupId(): number {
		return this.get('partitionGroup.id', 0);
	}

	@computed
	public get partitionGroupIri(): string {
		return this.get('partitionGroup.@id', '');
	}

	@computed
	public get partitionId(): id {
		return getIdFromUrn(this.get('partitionUrn', ''));
	}

	@computed
	public get partitionUrn(): string {
		return this.get('partitionUrn', '');
	}

	@computed
	public get staffMemberId() {
		return this.staffMember.id;
	}

	@computed
	public get staffMemberUrn() {
		return this.staffMember.urn;
	}

	@computed
	public get staffMemberIri() {
		return this.staffMember.iri;
	}
}
