if (!window.FRONT_CONFIG) {
	window.FRONT_CONFIG = {
		APP_ENV: 'dev',
		google_maps_api_key: 'AIzaSyDbkRyR1pJzK82BdtlkdE0BL7Vsl6yKnuQ',
		SENTRY_DSN: '',
	};
}

const FrontConfig = window.FRONT_CONFIG;

export default FrontConfig;
