import lazyWithRetry from 'tools/lazyWithRetry';

const ContractIterationDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "contractIterationDashboardPage" */
	'../../pages/ContractIteration/ContractIterationDashboardPage'));

export default [
	{
		component: ContractIterationDashboardPage,
		exact: true,
		path: '/partitions/:partitionId/contract_iterations/:contractIterationId',
	},
];