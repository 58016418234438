import { lazy } from 'react';

const NotFoundScreen = lazy(() =>
	import(
		/* webpackChunkName: "notFoundScreen" */
		'./screens/NotFoundScreen'
	),
);

const UnauthorizedScreen = lazy(() =>
	import(
		/* webpackChunkName: "unauthorizedScreen" */
		'./screens/UnauthorizedScreen'
	),
);

export const routes = [
	{
		component: NotFoundScreen,
		exact: true,
		path: '/errors/404',
	},
	{
		component: UnauthorizedScreen,
		exact: true,
		path: '/errors/401',
	},
];
