/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const Service = $root.Service = (() => {

    /**
     * Namespace Service.
     * @exports Service
     * @namespace
     */
    const Service = {};

    Service.Intervention = (function() {

        /**
         * Namespace Intervention.
         * @memberof Service
         * @namespace
         */
        const Intervention = {};

        Intervention.PlanningInterventionVersionCard = (function() {

            /**
             * Properties of a PlanningInterventionVersionCard.
             * @memberof Service.Intervention
             * @interface IPlanningInterventionVersionCard
             * @property {string|null} [urn] PlanningInterventionVersionCard urn
             * @property {number|null} [id] PlanningInterventionVersionCard id
             * @property {google.protobuf.ITimestamp|null} [updatedAt] PlanningInterventionVersionCard updatedAt
             * @property {google.protobuf.ITimestamp|null} [scheduleStartDate] PlanningInterventionVersionCard scheduleStartDate
             * @property {google.protobuf.ITimestamp|null} [scheduleEndDate] PlanningInterventionVersionCard scheduleEndDate
             * @property {Service.Intervention.PlanningInterventionVersionCard.IIntervention|null} [intervention] PlanningInterventionVersionCard intervention
             * @property {Service.Intervention.PlanningInterventionVersionCard.IVersion|null} [version] PlanningInterventionVersionCard version
             * @property {Service.Intervention.PlanningInterventionVersionCard.ILocation|null} [location] PlanningInterventionVersionCard location
             * @property {Service.Intervention.PlanningInterventionVersionCard.IOwner|null} [owner] PlanningInterventionVersionCard owner
             * @property {Array.<Service.Intervention.PlanningInterventionVersionCard.IInterventionVersionResource>|null} [interventionVersionResources] PlanningInterventionVersionCard interventionVersionResources
             * @property {Array.<Service.Intervention.PlanningInterventionVersionCard.IInterventionOperation>|null} [interventionOperations] PlanningInterventionVersionCard interventionOperations
             * @property {Service.Intervention.PlanningInterventionVersionCard.ITask|null} [task] PlanningInterventionVersionCard task
             * @property {number|null} [anomalyFlag] PlanningInterventionVersionCard anomalyFlag
             * @property {string|null} [testString] PlanningInterventionVersionCard testString
             */

            /**
             * Constructs a new PlanningInterventionVersionCard.
             * @memberof Service.Intervention
             * @classdesc Represents a PlanningInterventionVersionCard.
             * @implements IPlanningInterventionVersionCard
             * @constructor
             * @param {Service.Intervention.IPlanningInterventionVersionCard=} [properties] Properties to set
             */
            function PlanningInterventionVersionCard(properties) {
                this.interventionVersionResources = [];
                this.interventionOperations = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PlanningInterventionVersionCard urn.
             * @member {string} urn
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.urn = "";

            /**
             * PlanningInterventionVersionCard id.
             * @member {number} id
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.id = 0;

            /**
             * PlanningInterventionVersionCard updatedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.updatedAt = null;

            /**
             * PlanningInterventionVersionCard scheduleStartDate.
             * @member {google.protobuf.ITimestamp|null|undefined} scheduleStartDate
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.scheduleStartDate = null;

            /**
             * PlanningInterventionVersionCard scheduleEndDate.
             * @member {google.protobuf.ITimestamp|null|undefined} scheduleEndDate
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.scheduleEndDate = null;

            /**
             * PlanningInterventionVersionCard intervention.
             * @member {Service.Intervention.PlanningInterventionVersionCard.IIntervention|null|undefined} intervention
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.intervention = null;

            /**
             * PlanningInterventionVersionCard version.
             * @member {Service.Intervention.PlanningInterventionVersionCard.IVersion|null|undefined} version
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.version = null;

            /**
             * PlanningInterventionVersionCard location.
             * @member {Service.Intervention.PlanningInterventionVersionCard.ILocation|null|undefined} location
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.location = null;

            /**
             * PlanningInterventionVersionCard owner.
             * @member {Service.Intervention.PlanningInterventionVersionCard.IOwner|null|undefined} owner
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.owner = null;

            /**
             * PlanningInterventionVersionCard interventionVersionResources.
             * @member {Array.<Service.Intervention.PlanningInterventionVersionCard.IInterventionVersionResource>} interventionVersionResources
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.interventionVersionResources = $util.emptyArray;

            /**
             * PlanningInterventionVersionCard interventionOperations.
             * @member {Array.<Service.Intervention.PlanningInterventionVersionCard.IInterventionOperation>} interventionOperations
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.interventionOperations = $util.emptyArray;

            /**
             * PlanningInterventionVersionCard task.
             * @member {Service.Intervention.PlanningInterventionVersionCard.ITask|null|undefined} task
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.task = null;

            /**
             * PlanningInterventionVersionCard anomalyFlag.
             * @member {number} anomalyFlag
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.anomalyFlag = 0;

            /**
             * PlanningInterventionVersionCard testString.
             * @member {string} testString
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             */
            PlanningInterventionVersionCard.prototype.testString = "";

            /**
             * Creates a new PlanningInterventionVersionCard instance using the specified properties.
             * @function create
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @static
             * @param {Service.Intervention.IPlanningInterventionVersionCard=} [properties] Properties to set
             * @returns {Service.Intervention.PlanningInterventionVersionCard} PlanningInterventionVersionCard instance
             */
            PlanningInterventionVersionCard.create = function create(properties) {
                return new PlanningInterventionVersionCard(properties);
            };

            /**
             * Encodes the specified PlanningInterventionVersionCard message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.verify|verify} messages.
             * @function encode
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @static
             * @param {Service.Intervention.IPlanningInterventionVersionCard} message PlanningInterventionVersionCard message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlanningInterventionVersionCard.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.urn);
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.id);
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.scheduleStartDate != null && Object.hasOwnProperty.call(message, "scheduleStartDate"))
                    $root.google.protobuf.Timestamp.encode(message.scheduleStartDate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.scheduleEndDate != null && Object.hasOwnProperty.call(message, "scheduleEndDate"))
                    $root.google.protobuf.Timestamp.encode(message.scheduleEndDate, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.intervention != null && Object.hasOwnProperty.call(message, "intervention"))
                    $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.encode(message.intervention, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                    $root.Service.Intervention.PlanningInterventionVersionCard.Version.encode(message.version, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                    $root.Service.Intervention.PlanningInterventionVersionCard.Location.encode(message.location, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                    $root.Service.Intervention.PlanningInterventionVersionCard.Owner.encode(message.owner, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.interventionVersionResources != null && message.interventionVersionResources.length)
                    for (let i = 0; i < message.interventionVersionResources.length; ++i)
                        $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.encode(message.interventionVersionResources[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.interventionOperations != null && message.interventionOperations.length)
                    for (let i = 0; i < message.interventionOperations.length; ++i)
                        $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.encode(message.interventionOperations[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                    $root.Service.Intervention.PlanningInterventionVersionCard.Task.encode(message.task, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.anomalyFlag != null && Object.hasOwnProperty.call(message, "anomalyFlag"))
                    writer.uint32(/* id 13, wireType 0 =*/104).uint32(message.anomalyFlag);
                if (message.testString != null && Object.hasOwnProperty.call(message, "testString"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.testString);
                return writer;
            };

            /**
             * Encodes the specified PlanningInterventionVersionCard message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @static
             * @param {Service.Intervention.IPlanningInterventionVersionCard} message PlanningInterventionVersionCard message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlanningInterventionVersionCard.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PlanningInterventionVersionCard message from the specified reader or buffer.
             * @function decode
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Service.Intervention.PlanningInterventionVersionCard} PlanningInterventionVersionCard
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlanningInterventionVersionCard.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.urn = reader.string();
                            break;
                        }
                    case 2: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.scheduleStartDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.scheduleEndDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.intervention = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.version = $root.Service.Intervention.PlanningInterventionVersionCard.Version.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.location = $root.Service.Intervention.PlanningInterventionVersionCard.Location.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.owner = $root.Service.Intervention.PlanningInterventionVersionCard.Owner.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            if (!(message.interventionVersionResources && message.interventionVersionResources.length))
                                message.interventionVersionResources = [];
                            message.interventionVersionResources.push($root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.decode(reader, reader.uint32()));
                            break;
                        }
                    case 11: {
                            if (!(message.interventionOperations && message.interventionOperations.length))
                                message.interventionOperations = [];
                            message.interventionOperations.push($root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.decode(reader, reader.uint32()));
                            break;
                        }
                    case 12: {
                            message.task = $root.Service.Intervention.PlanningInterventionVersionCard.Task.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.anomalyFlag = reader.uint32();
                            break;
                        }
                    case 14: {
                            message.testString = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PlanningInterventionVersionCard message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Service.Intervention.PlanningInterventionVersionCard} PlanningInterventionVersionCard
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlanningInterventionVersionCard.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PlanningInterventionVersionCard message.
             * @function verify
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PlanningInterventionVersionCard.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.urn != null && message.hasOwnProperty("urn"))
                    if (!$util.isString(message.urn))
                        return "urn: string expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.updatedAt);
                    if (error)
                        return "updatedAt." + error;
                }
                if (message.scheduleStartDate != null && message.hasOwnProperty("scheduleStartDate")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.scheduleStartDate);
                    if (error)
                        return "scheduleStartDate." + error;
                }
                if (message.scheduleEndDate != null && message.hasOwnProperty("scheduleEndDate")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.scheduleEndDate);
                    if (error)
                        return "scheduleEndDate." + error;
                }
                if (message.intervention != null && message.hasOwnProperty("intervention")) {
                    let error = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.verify(message.intervention);
                    if (error)
                        return "intervention." + error;
                }
                if (message.version != null && message.hasOwnProperty("version")) {
                    let error = $root.Service.Intervention.PlanningInterventionVersionCard.Version.verify(message.version);
                    if (error)
                        return "version." + error;
                }
                if (message.location != null && message.hasOwnProperty("location")) {
                    let error = $root.Service.Intervention.PlanningInterventionVersionCard.Location.verify(message.location);
                    if (error)
                        return "location." + error;
                }
                if (message.owner != null && message.hasOwnProperty("owner")) {
                    let error = $root.Service.Intervention.PlanningInterventionVersionCard.Owner.verify(message.owner);
                    if (error)
                        return "owner." + error;
                }
                if (message.interventionVersionResources != null && message.hasOwnProperty("interventionVersionResources")) {
                    if (!Array.isArray(message.interventionVersionResources))
                        return "interventionVersionResources: array expected";
                    for (let i = 0; i < message.interventionVersionResources.length; ++i) {
                        let error = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.verify(message.interventionVersionResources[i]);
                        if (error)
                            return "interventionVersionResources." + error;
                    }
                }
                if (message.interventionOperations != null && message.hasOwnProperty("interventionOperations")) {
                    if (!Array.isArray(message.interventionOperations))
                        return "interventionOperations: array expected";
                    for (let i = 0; i < message.interventionOperations.length; ++i) {
                        let error = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.verify(message.interventionOperations[i]);
                        if (error)
                            return "interventionOperations." + error;
                    }
                }
                if (message.task != null && message.hasOwnProperty("task")) {
                    let error = $root.Service.Intervention.PlanningInterventionVersionCard.Task.verify(message.task);
                    if (error)
                        return "task." + error;
                }
                if (message.anomalyFlag != null && message.hasOwnProperty("anomalyFlag"))
                    if (!$util.isInteger(message.anomalyFlag))
                        return "anomalyFlag: integer expected";
                if (message.testString != null && message.hasOwnProperty("testString"))
                    if (!$util.isString(message.testString))
                        return "testString: string expected";
                return null;
            };

            /**
             * Creates a PlanningInterventionVersionCard message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Service.Intervention.PlanningInterventionVersionCard} PlanningInterventionVersionCard
             */
            PlanningInterventionVersionCard.fromObject = function fromObject(object) {
                if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard)
                    return object;
                let message = new $root.Service.Intervention.PlanningInterventionVersionCard();
                if (object.urn != null)
                    message.urn = String(object.urn);
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.updatedAt != null) {
                    if (typeof object.updatedAt !== "object")
                        throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.updatedAt: object expected");
                    message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
                }
                if (object.scheduleStartDate != null) {
                    if (typeof object.scheduleStartDate !== "object")
                        throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.scheduleStartDate: object expected");
                    message.scheduleStartDate = $root.google.protobuf.Timestamp.fromObject(object.scheduleStartDate);
                }
                if (object.scheduleEndDate != null) {
                    if (typeof object.scheduleEndDate !== "object")
                        throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.scheduleEndDate: object expected");
                    message.scheduleEndDate = $root.google.protobuf.Timestamp.fromObject(object.scheduleEndDate);
                }
                if (object.intervention != null) {
                    if (typeof object.intervention !== "object")
                        throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.intervention: object expected");
                    message.intervention = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.fromObject(object.intervention);
                }
                if (object.version != null) {
                    if (typeof object.version !== "object")
                        throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.version: object expected");
                    message.version = $root.Service.Intervention.PlanningInterventionVersionCard.Version.fromObject(object.version);
                }
                if (object.location != null) {
                    if (typeof object.location !== "object")
                        throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.location: object expected");
                    message.location = $root.Service.Intervention.PlanningInterventionVersionCard.Location.fromObject(object.location);
                }
                if (object.owner != null) {
                    if (typeof object.owner !== "object")
                        throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.owner: object expected");
                    message.owner = $root.Service.Intervention.PlanningInterventionVersionCard.Owner.fromObject(object.owner);
                }
                if (object.interventionVersionResources) {
                    if (!Array.isArray(object.interventionVersionResources))
                        throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.interventionVersionResources: array expected");
                    message.interventionVersionResources = [];
                    for (let i = 0; i < object.interventionVersionResources.length; ++i) {
                        if (typeof object.interventionVersionResources[i] !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.interventionVersionResources: object expected");
                        message.interventionVersionResources[i] = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.fromObject(object.interventionVersionResources[i]);
                    }
                }
                if (object.interventionOperations) {
                    if (!Array.isArray(object.interventionOperations))
                        throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.interventionOperations: array expected");
                    message.interventionOperations = [];
                    for (let i = 0; i < object.interventionOperations.length; ++i) {
                        if (typeof object.interventionOperations[i] !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.interventionOperations: object expected");
                        message.interventionOperations[i] = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.fromObject(object.interventionOperations[i]);
                    }
                }
                if (object.task != null) {
                    if (typeof object.task !== "object")
                        throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.task: object expected");
                    message.task = $root.Service.Intervention.PlanningInterventionVersionCard.Task.fromObject(object.task);
                }
                if (object.anomalyFlag != null)
                    message.anomalyFlag = object.anomalyFlag >>> 0;
                if (object.testString != null)
                    message.testString = String(object.testString);
                return message;
            };

            /**
             * Creates a plain object from a PlanningInterventionVersionCard message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @static
             * @param {Service.Intervention.PlanningInterventionVersionCard} message PlanningInterventionVersionCard
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PlanningInterventionVersionCard.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.interventionVersionResources = [];
                    object.interventionOperations = [];
                }
                if (options.defaults) {
                    object.urn = "";
                    object.id = 0;
                    object.updatedAt = null;
                    object.scheduleStartDate = null;
                    object.scheduleEndDate = null;
                    object.intervention = null;
                    object.version = null;
                    object.location = null;
                    object.owner = null;
                    object.task = null;
                    object.anomalyFlag = 0;
                    object.testString = "";
                }
                if (message.urn != null && message.hasOwnProperty("urn"))
                    object.urn = message.urn;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
                if (message.scheduleStartDate != null && message.hasOwnProperty("scheduleStartDate"))
                    object.scheduleStartDate = $root.google.protobuf.Timestamp.toObject(message.scheduleStartDate, options);
                if (message.scheduleEndDate != null && message.hasOwnProperty("scheduleEndDate"))
                    object.scheduleEndDate = $root.google.protobuf.Timestamp.toObject(message.scheduleEndDate, options);
                if (message.intervention != null && message.hasOwnProperty("intervention"))
                    object.intervention = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.toObject(message.intervention, options);
                if (message.version != null && message.hasOwnProperty("version"))
                    object.version = $root.Service.Intervention.PlanningInterventionVersionCard.Version.toObject(message.version, options);
                if (message.location != null && message.hasOwnProperty("location"))
                    object.location = $root.Service.Intervention.PlanningInterventionVersionCard.Location.toObject(message.location, options);
                if (message.owner != null && message.hasOwnProperty("owner"))
                    object.owner = $root.Service.Intervention.PlanningInterventionVersionCard.Owner.toObject(message.owner, options);
                if (message.interventionVersionResources && message.interventionVersionResources.length) {
                    object.interventionVersionResources = [];
                    for (let j = 0; j < message.interventionVersionResources.length; ++j)
                        object.interventionVersionResources[j] = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.toObject(message.interventionVersionResources[j], options);
                }
                if (message.interventionOperations && message.interventionOperations.length) {
                    object.interventionOperations = [];
                    for (let j = 0; j < message.interventionOperations.length; ++j)
                        object.interventionOperations[j] = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.toObject(message.interventionOperations[j], options);
                }
                if (message.task != null && message.hasOwnProperty("task"))
                    object.task = $root.Service.Intervention.PlanningInterventionVersionCard.Task.toObject(message.task, options);
                if (message.anomalyFlag != null && message.hasOwnProperty("anomalyFlag"))
                    object.anomalyFlag = message.anomalyFlag;
                if (message.testString != null && message.hasOwnProperty("testString"))
                    object.testString = message.testString;
                return object;
            };

            /**
             * Converts this PlanningInterventionVersionCard to JSON.
             * @function toJSON
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PlanningInterventionVersionCard.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PlanningInterventionVersionCard
             * @function getTypeUrl
             * @memberof Service.Intervention.PlanningInterventionVersionCard
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PlanningInterventionVersionCard.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard";
            };

            PlanningInterventionVersionCard.Task = (function() {

                /**
                 * Properties of a Task.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @interface ITask
                 * @property {string|null} [urn] Task urn
                 * @property {Service.Intervention.PlanningInterventionVersionCard.Task.ITaskStatus|null} [taskStatus] Task taskStatus
                 * @property {Array.<Service.Intervention.PlanningInterventionVersionCard.Task.ITaskCancellation>|null} [taskCancellations] Task taskCancellations
                 */

                /**
                 * Constructs a new Task.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @classdesc Represents a Task.
                 * @implements ITask
                 * @constructor
                 * @param {Service.Intervention.PlanningInterventionVersionCard.ITask=} [properties] Properties to set
                 */
                function Task(properties) {
                    this.taskCancellations = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Task urn.
                 * @member {string} urn
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @instance
                 */
                Task.prototype.urn = "";

                /**
                 * Task taskStatus.
                 * @member {Service.Intervention.PlanningInterventionVersionCard.Task.ITaskStatus|null|undefined} taskStatus
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @instance
                 */
                Task.prototype.taskStatus = null;

                /**
                 * Task taskCancellations.
                 * @member {Array.<Service.Intervention.PlanningInterventionVersionCard.Task.ITaskCancellation>} taskCancellations
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @instance
                 */
                Task.prototype.taskCancellations = $util.emptyArray;

                /**
                 * Creates a new Task instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.ITask=} [properties] Properties to set
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Task} Task instance
                 */
                Task.create = function create(properties) {
                    return new Task(properties);
                };

                /**
                 * Encodes the specified Task message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Task.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.ITask} message Task message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Task.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.urn);
                    if (message.taskStatus != null && Object.hasOwnProperty.call(message, "taskStatus"))
                        $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus.encode(message.taskStatus, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.taskCancellations != null && message.taskCancellations.length)
                        for (let i = 0; i < message.taskCancellations.length; ++i)
                            $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation.encode(message.taskCancellations[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Task message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Task.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.ITask} message Task message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Task.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Task message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Task} Task
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Task.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.Task();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.urn = reader.string();
                                break;
                            }
                        case 2: {
                                message.taskStatus = $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.taskCancellations && message.taskCancellations.length))
                                    message.taskCancellations = [];
                                message.taskCancellations.push($root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Task message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Task} Task
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Task.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Task message.
                 * @function verify
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Task.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.taskStatus != null && message.hasOwnProperty("taskStatus")) {
                        let error = $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus.verify(message.taskStatus);
                        if (error)
                            return "taskStatus." + error;
                    }
                    if (message.taskCancellations != null && message.hasOwnProperty("taskCancellations")) {
                        if (!Array.isArray(message.taskCancellations))
                            return "taskCancellations: array expected";
                        for (let i = 0; i < message.taskCancellations.length; ++i) {
                            let error = $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation.verify(message.taskCancellations[i]);
                            if (error)
                                return "taskCancellations." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a Task message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Task} Task
                 */
                Task.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.Task)
                        return object;
                    let message = new $root.Service.Intervention.PlanningInterventionVersionCard.Task();
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.taskStatus != null) {
                        if (typeof object.taskStatus !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.Task.taskStatus: object expected");
                        message.taskStatus = $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus.fromObject(object.taskStatus);
                    }
                    if (object.taskCancellations) {
                        if (!Array.isArray(object.taskCancellations))
                            throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.Task.taskCancellations: array expected");
                        message.taskCancellations = [];
                        for (let i = 0; i < object.taskCancellations.length; ++i) {
                            if (typeof object.taskCancellations[i] !== "object")
                                throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.Task.taskCancellations: object expected");
                            message.taskCancellations[i] = $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation.fromObject(object.taskCancellations[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Task message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.Task} message Task
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Task.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.taskCancellations = [];
                    if (options.defaults) {
                        object.urn = "";
                        object.taskStatus = null;
                    }
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.taskStatus != null && message.hasOwnProperty("taskStatus"))
                        object.taskStatus = $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus.toObject(message.taskStatus, options);
                    if (message.taskCancellations && message.taskCancellations.length) {
                        object.taskCancellations = [];
                        for (let j = 0; j < message.taskCancellations.length; ++j)
                            object.taskCancellations[j] = $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation.toObject(message.taskCancellations[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this Task to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Task.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Task
                 * @function getTypeUrl
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Task.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.Task";
                };

                Task.TaskStatus = (function() {

                    /**
                     * Properties of a TaskStatus.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                     * @interface ITaskStatus
                     * @property {string|null} [reference] TaskStatus reference
                     */

                    /**
                     * Constructs a new TaskStatus.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                     * @classdesc Represents a TaskStatus.
                     * @implements ITaskStatus
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Task.ITaskStatus=} [properties] Properties to set
                     */
                    function TaskStatus(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TaskStatus reference.
                     * @member {string} reference
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus
                     * @instance
                     */
                    TaskStatus.prototype.reference = "";

                    /**
                     * Creates a new TaskStatus instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Task.ITaskStatus=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus} TaskStatus instance
                     */
                    TaskStatus.create = function create(properties) {
                        return new TaskStatus(properties);
                    };

                    /**
                     * Encodes the specified TaskStatus message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Task.ITaskStatus} message TaskStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskStatus.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.reference);
                        return writer;
                    };

                    /**
                     * Encodes the specified TaskStatus message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Task.ITaskStatus} message TaskStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskStatus.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TaskStatus message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus} TaskStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskStatus.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.reference = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TaskStatus message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus} TaskStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskStatus.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TaskStatus message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TaskStatus.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            if (!$util.isString(message.reference))
                                return "reference: string expected";
                        return null;
                    };

                    /**
                     * Creates a TaskStatus message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus} TaskStatus
                     */
                    TaskStatus.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus();
                        if (object.reference != null)
                            message.reference = String(object.reference);
                        return message;
                    };

                    /**
                     * Creates a plain object from a TaskStatus message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus} message TaskStatus
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TaskStatus.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.reference = "";
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            object.reference = message.reference;
                        return object;
                    };

                    /**
                     * Converts this TaskStatus to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TaskStatus.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for TaskStatus
                     * @function getTypeUrl
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TaskStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.Task.TaskStatus";
                    };

                    return TaskStatus;
                })();

                Task.TaskCancellation = (function() {

                    /**
                     * Properties of a TaskCancellation.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                     * @interface ITaskCancellation
                     * @property {number|null} [id] TaskCancellation id
                     * @property {string|null} [sourceUrn] TaskCancellation sourceUrn
                     * @property {string|null} [label] TaskCancellation label
                     * @property {string|null} [note] TaskCancellation note
                     */

                    /**
                     * Constructs a new TaskCancellation.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task
                     * @classdesc Represents a TaskCancellation.
                     * @implements ITaskCancellation
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Task.ITaskCancellation=} [properties] Properties to set
                     */
                    function TaskCancellation(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TaskCancellation id.
                     * @member {number} id
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @instance
                     */
                    TaskCancellation.prototype.id = 0;

                    /**
                     * TaskCancellation sourceUrn.
                     * @member {string} sourceUrn
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @instance
                     */
                    TaskCancellation.prototype.sourceUrn = "";

                    /**
                     * TaskCancellation label.
                     * @member {string} label
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @instance
                     */
                    TaskCancellation.prototype.label = "";

                    /**
                     * TaskCancellation note.
                     * @member {string} note
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @instance
                     */
                    TaskCancellation.prototype.note = "";

                    /**
                     * Creates a new TaskCancellation instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Task.ITaskCancellation=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation} TaskCancellation instance
                     */
                    TaskCancellation.create = function create(properties) {
                        return new TaskCancellation(properties);
                    };

                    /**
                     * Encodes the specified TaskCancellation message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Task.ITaskCancellation} message TaskCancellation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskCancellation.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                        if (message.sourceUrn != null && Object.hasOwnProperty.call(message, "sourceUrn"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceUrn);
                        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.label);
                        if (message.note != null && Object.hasOwnProperty.call(message, "note"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.note);
                        return writer;
                    };

                    /**
                     * Encodes the specified TaskCancellation message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Task.ITaskCancellation} message TaskCancellation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskCancellation.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TaskCancellation message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation} TaskCancellation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskCancellation.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.id = reader.uint32();
                                    break;
                                }
                            case 2: {
                                    message.sourceUrn = reader.string();
                                    break;
                                }
                            case 3: {
                                    message.label = reader.string();
                                    break;
                                }
                            case 4: {
                                    message.note = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TaskCancellation message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation} TaskCancellation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskCancellation.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TaskCancellation message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TaskCancellation.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id))
                                return "id: integer expected";
                        if (message.sourceUrn != null && message.hasOwnProperty("sourceUrn"))
                            if (!$util.isString(message.sourceUrn))
                                return "sourceUrn: string expected";
                        if (message.label != null && message.hasOwnProperty("label"))
                            if (!$util.isString(message.label))
                                return "label: string expected";
                        if (message.note != null && message.hasOwnProperty("note"))
                            if (!$util.isString(message.note))
                                return "note: string expected";
                        return null;
                    };

                    /**
                     * Creates a TaskCancellation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation} TaskCancellation
                     */
                    TaskCancellation.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation();
                        if (object.id != null)
                            message.id = object.id >>> 0;
                        if (object.sourceUrn != null)
                            message.sourceUrn = String(object.sourceUrn);
                        if (object.label != null)
                            message.label = String(object.label);
                        if (object.note != null)
                            message.note = String(object.note);
                        return message;
                    };

                    /**
                     * Creates a plain object from a TaskCancellation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation} message TaskCancellation
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TaskCancellation.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.id = 0;
                            object.sourceUrn = "";
                            object.label = "";
                            object.note = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.sourceUrn != null && message.hasOwnProperty("sourceUrn"))
                            object.sourceUrn = message.sourceUrn;
                        if (message.label != null && message.hasOwnProperty("label"))
                            object.label = message.label;
                        if (message.note != null && message.hasOwnProperty("note"))
                            object.note = message.note;
                        return object;
                    };

                    /**
                     * Converts this TaskCancellation to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TaskCancellation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for TaskCancellation
                     * @function getTypeUrl
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TaskCancellation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.Task.TaskCancellation";
                    };

                    return TaskCancellation;
                })();

                return Task;
            })();

            PlanningInterventionVersionCard.InterventionOperation = (function() {

                /**
                 * Properties of an InterventionOperation.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @interface IInterventionOperation
                 * @property {number|null} [id] InterventionOperation id
                 * @property {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.ITaskOperation|null} [taskOperation] InterventionOperation taskOperation
                 * @property {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.IInterventionOperationStatus|null} [interventionOperationStatus] InterventionOperation interventionOperationStatus
                 */

                /**
                 * Constructs a new InterventionOperation.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @classdesc Represents an InterventionOperation.
                 * @implements IInterventionOperation
                 * @constructor
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IInterventionOperation=} [properties] Properties to set
                 */
                function InterventionOperation(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InterventionOperation id.
                 * @member {number} id
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @instance
                 */
                InterventionOperation.prototype.id = 0;

                /**
                 * InterventionOperation taskOperation.
                 * @member {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.ITaskOperation|null|undefined} taskOperation
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @instance
                 */
                InterventionOperation.prototype.taskOperation = null;

                /**
                 * InterventionOperation interventionOperationStatus.
                 * @member {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.IInterventionOperationStatus|null|undefined} interventionOperationStatus
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @instance
                 */
                InterventionOperation.prototype.interventionOperationStatus = null;

                /**
                 * Creates a new InterventionOperation instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IInterventionOperation=} [properties] Properties to set
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation} InterventionOperation instance
                 */
                InterventionOperation.create = function create(properties) {
                    return new InterventionOperation(properties);
                };

                /**
                 * Encodes the specified InterventionOperation message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IInterventionOperation} message InterventionOperation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InterventionOperation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                    if (message.taskOperation != null && Object.hasOwnProperty.call(message, "taskOperation"))
                        $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.encode(message.taskOperation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.interventionOperationStatus != null && Object.hasOwnProperty.call(message, "interventionOperationStatus"))
                        $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus.encode(message.interventionOperationStatus, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified InterventionOperation message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IInterventionOperation} message InterventionOperation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InterventionOperation.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InterventionOperation message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation} InterventionOperation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InterventionOperation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.uint32();
                                break;
                            }
                        case 2: {
                                message.taskOperation = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.interventionOperationStatus = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InterventionOperation message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation} InterventionOperation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InterventionOperation.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InterventionOperation message.
                 * @function verify
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InterventionOperation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id))
                            return "id: integer expected";
                    if (message.taskOperation != null && message.hasOwnProperty("taskOperation")) {
                        let error = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.verify(message.taskOperation);
                        if (error)
                            return "taskOperation." + error;
                    }
                    if (message.interventionOperationStatus != null && message.hasOwnProperty("interventionOperationStatus")) {
                        let error = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus.verify(message.interventionOperationStatus);
                        if (error)
                            return "interventionOperationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates an InterventionOperation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation} InterventionOperation
                 */
                InterventionOperation.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation)
                        return object;
                    let message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation();
                    if (object.id != null)
                        message.id = object.id >>> 0;
                    if (object.taskOperation != null) {
                        if (typeof object.taskOperation !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.taskOperation: object expected");
                        message.taskOperation = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.fromObject(object.taskOperation);
                    }
                    if (object.interventionOperationStatus != null) {
                        if (typeof object.interventionOperationStatus !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.interventionOperationStatus: object expected");
                        message.interventionOperationStatus = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus.fromObject(object.interventionOperationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an InterventionOperation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation} message InterventionOperation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InterventionOperation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.id = 0;
                        object.taskOperation = null;
                        object.interventionOperationStatus = null;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.taskOperation != null && message.hasOwnProperty("taskOperation"))
                        object.taskOperation = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.toObject(message.taskOperation, options);
                    if (message.interventionOperationStatus != null && message.hasOwnProperty("interventionOperationStatus"))
                        object.interventionOperationStatus = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus.toObject(message.interventionOperationStatus, options);
                    return object;
                };

                /**
                 * Converts this InterventionOperation to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InterventionOperation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InterventionOperation
                 * @function getTypeUrl
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InterventionOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.InterventionOperation";
                };

                InterventionOperation.TaskOperation = (function() {

                    /**
                     * Properties of a TaskOperation.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                     * @interface ITaskOperation
                     * @property {number|null} [id] TaskOperation id
                     * @property {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.IOperation|null} [operation] TaskOperation operation
                     */

                    /**
                     * Constructs a new TaskOperation.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                     * @classdesc Represents a TaskOperation.
                     * @implements ITaskOperation
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.ITaskOperation=} [properties] Properties to set
                     */
                    function TaskOperation(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TaskOperation id.
                     * @member {number} id
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @instance
                     */
                    TaskOperation.prototype.id = 0;

                    /**
                     * TaskOperation operation.
                     * @member {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.IOperation|null|undefined} operation
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @instance
                     */
                    TaskOperation.prototype.operation = null;

                    /**
                     * Creates a new TaskOperation instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.ITaskOperation=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation} TaskOperation instance
                     */
                    TaskOperation.create = function create(properties) {
                        return new TaskOperation(properties);
                    };

                    /**
                     * Encodes the specified TaskOperation message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.ITaskOperation} message TaskOperation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskOperation.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                        if (message.operation != null && Object.hasOwnProperty.call(message, "operation"))
                            $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.encode(message.operation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified TaskOperation message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.ITaskOperation} message TaskOperation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskOperation.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TaskOperation message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation} TaskOperation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskOperation.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.id = reader.uint32();
                                    break;
                                }
                            case 2: {
                                    message.operation = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TaskOperation message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation} TaskOperation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskOperation.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TaskOperation message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TaskOperation.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id))
                                return "id: integer expected";
                        if (message.operation != null && message.hasOwnProperty("operation")) {
                            let error = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.verify(message.operation);
                            if (error)
                                return "operation." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a TaskOperation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation} TaskOperation
                     */
                    TaskOperation.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation();
                        if (object.id != null)
                            message.id = object.id >>> 0;
                        if (object.operation != null) {
                            if (typeof object.operation !== "object")
                                throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.operation: object expected");
                            message.operation = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.fromObject(object.operation);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a TaskOperation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation} message TaskOperation
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TaskOperation.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.id = 0;
                            object.operation = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.operation != null && message.hasOwnProperty("operation"))
                            object.operation = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.toObject(message.operation, options);
                        return object;
                    };

                    /**
                     * Converts this TaskOperation to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TaskOperation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for TaskOperation
                     * @function getTypeUrl
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TaskOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation";
                    };

                    TaskOperation.Operation = (function() {

                        /**
                         * Properties of an Operation.
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                         * @interface IOperation
                         * @property {number|null} [id] Operation id
                         * @property {string|null} [label] Operation label
                         * @property {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.IOperationGroup|null} [operationGroup] Operation operationGroup
                         */

                        /**
                         * Constructs a new Operation.
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation
                         * @classdesc Represents an Operation.
                         * @implements IOperation
                         * @constructor
                         * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.IOperation=} [properties] Properties to set
                         */
                        function Operation(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Operation id.
                         * @member {number} id
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @instance
                         */
                        Operation.prototype.id = 0;

                        /**
                         * Operation label.
                         * @member {string} label
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @instance
                         */
                        Operation.prototype.label = "";

                        /**
                         * Operation operationGroup.
                         * @member {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.IOperationGroup|null|undefined} operationGroup
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @instance
                         */
                        Operation.prototype.operationGroup = null;

                        /**
                         * Creates a new Operation instance using the specified properties.
                         * @function create
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @static
                         * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.IOperation=} [properties] Properties to set
                         * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation} Operation instance
                         */
                        Operation.create = function create(properties) {
                            return new Operation(properties);
                        };

                        /**
                         * Encodes the specified Operation message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.verify|verify} messages.
                         * @function encode
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @static
                         * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.IOperation} message Operation message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Operation.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
                            if (message.operationGroup != null && Object.hasOwnProperty.call(message, "operationGroup"))
                                $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup.encode(message.operationGroup, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Operation message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @static
                         * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.IOperation} message Operation message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Operation.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an Operation message from the specified reader or buffer.
                         * @function decode
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation} Operation
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Operation.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation();
                            while (reader.pos < end) {
                                let tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.id = reader.uint32();
                                        break;
                                    }
                                case 2: {
                                        message.label = reader.string();
                                        break;
                                    }
                                case 3: {
                                        message.operationGroup = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup.decode(reader, reader.uint32());
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an Operation message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation} Operation
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Operation.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an Operation message.
                         * @function verify
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Operation.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isInteger(message.id))
                                    return "id: integer expected";
                            if (message.label != null && message.hasOwnProperty("label"))
                                if (!$util.isString(message.label))
                                    return "label: string expected";
                            if (message.operationGroup != null && message.hasOwnProperty("operationGroup")) {
                                let error = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup.verify(message.operationGroup);
                                if (error)
                                    return "operationGroup." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an Operation message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation} Operation
                         */
                        Operation.fromObject = function fromObject(object) {
                            if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation)
                                return object;
                            let message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation();
                            if (object.id != null)
                                message.id = object.id >>> 0;
                            if (object.label != null)
                                message.label = String(object.label);
                            if (object.operationGroup != null) {
                                if (typeof object.operationGroup !== "object")
                                    throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.operationGroup: object expected");
                                message.operationGroup = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup.fromObject(object.operationGroup);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an Operation message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @static
                         * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation} message Operation
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Operation.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.defaults) {
                                object.id = 0;
                                object.label = "";
                                object.operationGroup = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.label != null && message.hasOwnProperty("label"))
                                object.label = message.label;
                            if (message.operationGroup != null && message.hasOwnProperty("operationGroup"))
                                object.operationGroup = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup.toObject(message.operationGroup, options);
                            return object;
                        };

                        /**
                         * Converts this Operation to JSON.
                         * @function toJSON
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Operation.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for Operation
                         * @function getTypeUrl
                         * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        Operation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation";
                        };

                        Operation.OperationGroup = (function() {

                            /**
                             * Properties of an OperationGroup.
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                             * @interface IOperationGroup
                             * @property {number|null} [id] OperationGroup id
                             */

                            /**
                             * Constructs a new OperationGroup.
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation
                             * @classdesc Represents an OperationGroup.
                             * @implements IOperationGroup
                             * @constructor
                             * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.IOperationGroup=} [properties] Properties to set
                             */
                            function OperationGroup(properties) {
                                if (properties)
                                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                        if (properties[keys[i]] != null)
                                            this[keys[i]] = properties[keys[i]];
                            }

                            /**
                             * OperationGroup id.
                             * @member {number} id
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup
                             * @instance
                             */
                            OperationGroup.prototype.id = 0;

                            /**
                             * Creates a new OperationGroup instance using the specified properties.
                             * @function create
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup
                             * @static
                             * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.IOperationGroup=} [properties] Properties to set
                             * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup} OperationGroup instance
                             */
                            OperationGroup.create = function create(properties) {
                                return new OperationGroup(properties);
                            };

                            /**
                             * Encodes the specified OperationGroup message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup.verify|verify} messages.
                             * @function encode
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup
                             * @static
                             * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.IOperationGroup} message OperationGroup message or plain object to encode
                             * @param {$protobuf.Writer} [writer] Writer to encode to
                             * @returns {$protobuf.Writer} Writer
                             */
                            OperationGroup.encode = function encode(message, writer) {
                                if (!writer)
                                    writer = $Writer.create();
                                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                                return writer;
                            };

                            /**
                             * Encodes the specified OperationGroup message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup.verify|verify} messages.
                             * @function encodeDelimited
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup
                             * @static
                             * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.IOperationGroup} message OperationGroup message or plain object to encode
                             * @param {$protobuf.Writer} [writer] Writer to encode to
                             * @returns {$protobuf.Writer} Writer
                             */
                            OperationGroup.encodeDelimited = function encodeDelimited(message, writer) {
                                return this.encode(message, writer).ldelim();
                            };

                            /**
                             * Decodes an OperationGroup message from the specified reader or buffer.
                             * @function decode
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup
                             * @static
                             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                             * @param {number} [length] Message length if known beforehand
                             * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup} OperationGroup
                             * @throws {Error} If the payload is not a reader or valid buffer
                             * @throws {$protobuf.util.ProtocolError} If required fields are missing
                             */
                            OperationGroup.decode = function decode(reader, length) {
                                if (!(reader instanceof $Reader))
                                    reader = $Reader.create(reader);
                                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup();
                                while (reader.pos < end) {
                                    let tag = reader.uint32();
                                    switch (tag >>> 3) {
                                    case 1: {
                                            message.id = reader.uint32();
                                            break;
                                        }
                                    default:
                                        reader.skipType(tag & 7);
                                        break;
                                    }
                                }
                                return message;
                            };

                            /**
                             * Decodes an OperationGroup message from the specified reader or buffer, length delimited.
                             * @function decodeDelimited
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup
                             * @static
                             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                             * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup} OperationGroup
                             * @throws {Error} If the payload is not a reader or valid buffer
                             * @throws {$protobuf.util.ProtocolError} If required fields are missing
                             */
                            OperationGroup.decodeDelimited = function decodeDelimited(reader) {
                                if (!(reader instanceof $Reader))
                                    reader = new $Reader(reader);
                                return this.decode(reader, reader.uint32());
                            };

                            /**
                             * Verifies an OperationGroup message.
                             * @function verify
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup
                             * @static
                             * @param {Object.<string,*>} message Plain object to verify
                             * @returns {string|null} `null` if valid, otherwise the reason why it is not
                             */
                            OperationGroup.verify = function verify(message) {
                                if (typeof message !== "object" || message === null)
                                    return "object expected";
                                if (message.id != null && message.hasOwnProperty("id"))
                                    if (!$util.isInteger(message.id))
                                        return "id: integer expected";
                                return null;
                            };

                            /**
                             * Creates an OperationGroup message from a plain object. Also converts values to their respective internal types.
                             * @function fromObject
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup
                             * @static
                             * @param {Object.<string,*>} object Plain object
                             * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup} OperationGroup
                             */
                            OperationGroup.fromObject = function fromObject(object) {
                                if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup)
                                    return object;
                                let message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup();
                                if (object.id != null)
                                    message.id = object.id >>> 0;
                                return message;
                            };

                            /**
                             * Creates a plain object from an OperationGroup message. Also converts values to other types if specified.
                             * @function toObject
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup
                             * @static
                             * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup} message OperationGroup
                             * @param {$protobuf.IConversionOptions} [options] Conversion options
                             * @returns {Object.<string,*>} Plain object
                             */
                            OperationGroup.toObject = function toObject(message, options) {
                                if (!options)
                                    options = {};
                                let object = {};
                                if (options.defaults)
                                    object.id = 0;
                                if (message.id != null && message.hasOwnProperty("id"))
                                    object.id = message.id;
                                return object;
                            };

                            /**
                             * Converts this OperationGroup to JSON.
                             * @function toJSON
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup
                             * @instance
                             * @returns {Object.<string,*>} JSON object
                             */
                            OperationGroup.prototype.toJSON = function toJSON() {
                                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                            };

                            /**
                             * Gets the default type url for OperationGroup
                             * @function getTypeUrl
                             * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup
                             * @static
                             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                             * @returns {string} The default type url
                             */
                            OperationGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                                if (typeUrlPrefix === undefined) {
                                    typeUrlPrefix = "type.googleapis.com";
                                }
                                return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.TaskOperation.Operation.OperationGroup";
                            };

                            return OperationGroup;
                        })();

                        return Operation;
                    })();

                    return TaskOperation;
                })();

                InterventionOperation.InterventionOperationStatus = (function() {

                    /**
                     * Properties of an InterventionOperationStatus.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                     * @interface IInterventionOperationStatus
                     * @property {number|null} [id] InterventionOperationStatus id
                     * @property {string|null} [reference] InterventionOperationStatus reference
                     */

                    /**
                     * Constructs a new InterventionOperationStatus.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation
                     * @classdesc Represents an InterventionOperationStatus.
                     * @implements IInterventionOperationStatus
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.IInterventionOperationStatus=} [properties] Properties to set
                     */
                    function InterventionOperationStatus(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * InterventionOperationStatus id.
                     * @member {number} id
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @instance
                     */
                    InterventionOperationStatus.prototype.id = 0;

                    /**
                     * InterventionOperationStatus reference.
                     * @member {string} reference
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @instance
                     */
                    InterventionOperationStatus.prototype.reference = "";

                    /**
                     * Creates a new InterventionOperationStatus instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.IInterventionOperationStatus=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus} InterventionOperationStatus instance
                     */
                    InterventionOperationStatus.create = function create(properties) {
                        return new InterventionOperationStatus(properties);
                    };

                    /**
                     * Encodes the specified InterventionOperationStatus message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.IInterventionOperationStatus} message InterventionOperationStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterventionOperationStatus.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                        if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.reference);
                        return writer;
                    };

                    /**
                     * Encodes the specified InterventionOperationStatus message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.IInterventionOperationStatus} message InterventionOperationStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterventionOperationStatus.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an InterventionOperationStatus message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus} InterventionOperationStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterventionOperationStatus.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.id = reader.uint32();
                                    break;
                                }
                            case 2: {
                                    message.reference = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an InterventionOperationStatus message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus} InterventionOperationStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterventionOperationStatus.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an InterventionOperationStatus message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    InterventionOperationStatus.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id))
                                return "id: integer expected";
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            if (!$util.isString(message.reference))
                                return "reference: string expected";
                        return null;
                    };

                    /**
                     * Creates an InterventionOperationStatus message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus} InterventionOperationStatus
                     */
                    InterventionOperationStatus.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus();
                        if (object.id != null)
                            message.id = object.id >>> 0;
                        if (object.reference != null)
                            message.reference = String(object.reference);
                        return message;
                    };

                    /**
                     * Creates a plain object from an InterventionOperationStatus message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus} message InterventionOperationStatus
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    InterventionOperationStatus.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.id = 0;
                            object.reference = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            object.reference = message.reference;
                        return object;
                    };

                    /**
                     * Converts this InterventionOperationStatus to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    InterventionOperationStatus.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for InterventionOperationStatus
                     * @function getTypeUrl
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    InterventionOperationStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.InterventionOperation.InterventionOperationStatus";
                    };

                    return InterventionOperationStatus;
                })();

                return InterventionOperation;
            })();

            PlanningInterventionVersionCard.Owner = (function() {

                /**
                 * Properties of an Owner.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @interface IOwner
                 * @property {string|null} [contactUrn] Owner contactUrn
                 * @property {string|null} [clientUrn] Owner clientUrn
                 */

                /**
                 * Constructs a new Owner.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @classdesc Represents an Owner.
                 * @implements IOwner
                 * @constructor
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IOwner=} [properties] Properties to set
                 */
                function Owner(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Owner contactUrn.
                 * @member {string} contactUrn
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @instance
                 */
                Owner.prototype.contactUrn = "";

                /**
                 * Owner clientUrn.
                 * @member {string} clientUrn
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @instance
                 */
                Owner.prototype.clientUrn = "";

                /**
                 * Creates a new Owner instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IOwner=} [properties] Properties to set
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Owner} Owner instance
                 */
                Owner.create = function create(properties) {
                    return new Owner(properties);
                };

                /**
                 * Encodes the specified Owner message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Owner.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IOwner} message Owner message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Owner.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.contactUrn != null && Object.hasOwnProperty.call(message, "contactUrn"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.contactUrn);
                    if (message.clientUrn != null && Object.hasOwnProperty.call(message, "clientUrn"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.clientUrn);
                    return writer;
                };

                /**
                 * Encodes the specified Owner message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Owner.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IOwner} message Owner message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Owner.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Owner message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Owner} Owner
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Owner.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.Owner();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.contactUrn = reader.string();
                                break;
                            }
                        case 2: {
                                message.clientUrn = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Owner message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Owner} Owner
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Owner.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Owner message.
                 * @function verify
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Owner.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.contactUrn != null && message.hasOwnProperty("contactUrn"))
                        if (!$util.isString(message.contactUrn))
                            return "contactUrn: string expected";
                    if (message.clientUrn != null && message.hasOwnProperty("clientUrn"))
                        if (!$util.isString(message.clientUrn))
                            return "clientUrn: string expected";
                    return null;
                };

                /**
                 * Creates an Owner message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Owner} Owner
                 */
                Owner.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.Owner)
                        return object;
                    let message = new $root.Service.Intervention.PlanningInterventionVersionCard.Owner();
                    if (object.contactUrn != null)
                        message.contactUrn = String(object.contactUrn);
                    if (object.clientUrn != null)
                        message.clientUrn = String(object.clientUrn);
                    return message;
                };

                /**
                 * Creates a plain object from an Owner message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.Owner} message Owner
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Owner.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.contactUrn = "";
                        object.clientUrn = "";
                    }
                    if (message.contactUrn != null && message.hasOwnProperty("contactUrn"))
                        object.contactUrn = message.contactUrn;
                    if (message.clientUrn != null && message.hasOwnProperty("clientUrn"))
                        object.clientUrn = message.clientUrn;
                    return object;
                };

                /**
                 * Converts this Owner to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Owner.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Owner
                 * @function getTypeUrl
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Owner
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Owner.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.Owner";
                };

                return Owner;
            })();

            PlanningInterventionVersionCard.Intervention = (function() {

                /**
                 * Properties of an Intervention.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @interface IIntervention
                 * @property {string|null} [urn] Intervention urn
                 * @property {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionStatus|null} [interventionStatus] Intervention interventionStatus
                 * @property {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionType|null} [interventionType] Intervention interventionType
                 * @property {string|null} [label] Intervention label
                 */

                /**
                 * Constructs a new Intervention.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @classdesc Represents an Intervention.
                 * @implements IIntervention
                 * @constructor
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IIntervention=} [properties] Properties to set
                 */
                function Intervention(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Intervention urn.
                 * @member {string} urn
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.urn = "";

                /**
                 * Intervention interventionStatus.
                 * @member {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionStatus|null|undefined} interventionStatus
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.interventionStatus = null;

                /**
                 * Intervention interventionType.
                 * @member {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionType|null|undefined} interventionType
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.interventionType = null;

                /**
                 * Intervention label.
                 * @member {string} label
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.label = "";

                /**
                 * Creates a new Intervention instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IIntervention=} [properties] Properties to set
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention} Intervention instance
                 */
                Intervention.create = function create(properties) {
                    return new Intervention(properties);
                };

                /**
                 * Encodes the specified Intervention message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Intervention.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IIntervention} message Intervention message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Intervention.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.urn);
                    if (message.interventionStatus != null && Object.hasOwnProperty.call(message, "interventionStatus"))
                        $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus.encode(message.interventionStatus, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.interventionType != null && Object.hasOwnProperty.call(message, "interventionType"))
                        $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType.encode(message.interventionType, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.label);
                    return writer;
                };

                /**
                 * Encodes the specified Intervention message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Intervention.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IIntervention} message Intervention message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Intervention.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Intervention message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention} Intervention
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Intervention.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.Intervention();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.urn = reader.string();
                                break;
                            }
                        case 2: {
                                message.interventionStatus = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.interventionType = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.label = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Intervention message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention} Intervention
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Intervention.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Intervention message.
                 * @function verify
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Intervention.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.interventionStatus != null && message.hasOwnProperty("interventionStatus")) {
                        let error = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus.verify(message.interventionStatus);
                        if (error)
                            return "interventionStatus." + error;
                    }
                    if (message.interventionType != null && message.hasOwnProperty("interventionType")) {
                        let error = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType.verify(message.interventionType);
                        if (error)
                            return "interventionType." + error;
                    }
                    if (message.label != null && message.hasOwnProperty("label"))
                        if (!$util.isString(message.label))
                            return "label: string expected";
                    return null;
                };

                /**
                 * Creates an Intervention message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention} Intervention
                 */
                Intervention.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.Intervention)
                        return object;
                    let message = new $root.Service.Intervention.PlanningInterventionVersionCard.Intervention();
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.interventionStatus != null) {
                        if (typeof object.interventionStatus !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.Intervention.interventionStatus: object expected");
                        message.interventionStatus = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus.fromObject(object.interventionStatus);
                    }
                    if (object.interventionType != null) {
                        if (typeof object.interventionType !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.Intervention.interventionType: object expected");
                        message.interventionType = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType.fromObject(object.interventionType);
                    }
                    if (object.label != null)
                        message.label = String(object.label);
                    return message;
                };

                /**
                 * Creates a plain object from an Intervention message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.Intervention} message Intervention
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Intervention.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.urn = "";
                        object.interventionStatus = null;
                        object.interventionType = null;
                        object.label = "";
                    }
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.interventionStatus != null && message.hasOwnProperty("interventionStatus"))
                        object.interventionStatus = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus.toObject(message.interventionStatus, options);
                    if (message.interventionType != null && message.hasOwnProperty("interventionType"))
                        object.interventionType = $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType.toObject(message.interventionType, options);
                    if (message.label != null && message.hasOwnProperty("label"))
                        object.label = message.label;
                    return object;
                };

                /**
                 * Converts this Intervention to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Intervention.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Intervention
                 * @function getTypeUrl
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Intervention.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.Intervention";
                };

                Intervention.InterventionStatus = (function() {

                    /**
                     * Properties of an InterventionStatus.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                     * @interface IInterventionStatus
                     * @property {number|null} [id] InterventionStatus id
                     * @property {string|null} [reference] InterventionStatus reference
                     */

                    /**
                     * Constructs a new InterventionStatus.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                     * @classdesc Represents an InterventionStatus.
                     * @implements IInterventionStatus
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionStatus=} [properties] Properties to set
                     */
                    function InterventionStatus(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * InterventionStatus id.
                     * @member {number} id
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @instance
                     */
                    InterventionStatus.prototype.id = 0;

                    /**
                     * InterventionStatus reference.
                     * @member {string} reference
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @instance
                     */
                    InterventionStatus.prototype.reference = "";

                    /**
                     * Creates a new InterventionStatus instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionStatus=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus} InterventionStatus instance
                     */
                    InterventionStatus.create = function create(properties) {
                        return new InterventionStatus(properties);
                    };

                    /**
                     * Encodes the specified InterventionStatus message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionStatus} message InterventionStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterventionStatus.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                        if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.reference);
                        return writer;
                    };

                    /**
                     * Encodes the specified InterventionStatus message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionStatus} message InterventionStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterventionStatus.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an InterventionStatus message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus} InterventionStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterventionStatus.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.id = reader.uint32();
                                    break;
                                }
                            case 2: {
                                    message.reference = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an InterventionStatus message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus} InterventionStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterventionStatus.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an InterventionStatus message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    InterventionStatus.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id))
                                return "id: integer expected";
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            if (!$util.isString(message.reference))
                                return "reference: string expected";
                        return null;
                    };

                    /**
                     * Creates an InterventionStatus message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus} InterventionStatus
                     */
                    InterventionStatus.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus();
                        if (object.id != null)
                            message.id = object.id >>> 0;
                        if (object.reference != null)
                            message.reference = String(object.reference);
                        return message;
                    };

                    /**
                     * Creates a plain object from an InterventionStatus message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus} message InterventionStatus
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    InterventionStatus.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.id = 0;
                            object.reference = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            object.reference = message.reference;
                        return object;
                    };

                    /**
                     * Converts this InterventionStatus to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    InterventionStatus.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for InterventionStatus
                     * @function getTypeUrl
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    InterventionStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionStatus";
                    };

                    return InterventionStatus;
                })();

                Intervention.InterventionType = (function() {

                    /**
                     * Properties of an InterventionType.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                     * @interface IInterventionType
                     * @property {number|null} [id] InterventionType id
                     * @property {string|null} [reference] InterventionType reference
                     */

                    /**
                     * Constructs a new InterventionType.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention
                     * @classdesc Represents an InterventionType.
                     * @implements IInterventionType
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionType=} [properties] Properties to set
                     */
                    function InterventionType(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * InterventionType id.
                     * @member {number} id
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @instance
                     */
                    InterventionType.prototype.id = 0;

                    /**
                     * InterventionType reference.
                     * @member {string} reference
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @instance
                     */
                    InterventionType.prototype.reference = "";

                    /**
                     * Creates a new InterventionType instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionType=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType} InterventionType instance
                     */
                    InterventionType.create = function create(properties) {
                        return new InterventionType(properties);
                    };

                    /**
                     * Encodes the specified InterventionType message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionType} message InterventionType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterventionType.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                        if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.reference);
                        return writer;
                    };

                    /**
                     * Encodes the specified InterventionType message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Intervention.IInterventionType} message InterventionType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterventionType.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an InterventionType message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType} InterventionType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterventionType.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.id = reader.uint32();
                                    break;
                                }
                            case 2: {
                                    message.reference = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an InterventionType message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType} InterventionType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterventionType.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an InterventionType message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    InterventionType.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id))
                                return "id: integer expected";
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            if (!$util.isString(message.reference))
                                return "reference: string expected";
                        return null;
                    };

                    /**
                     * Creates an InterventionType message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType} InterventionType
                     */
                    InterventionType.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType();
                        if (object.id != null)
                            message.id = object.id >>> 0;
                        if (object.reference != null)
                            message.reference = String(object.reference);
                        return message;
                    };

                    /**
                     * Creates a plain object from an InterventionType message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType} message InterventionType
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    InterventionType.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.id = 0;
                            object.reference = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            object.reference = message.reference;
                        return object;
                    };

                    /**
                     * Converts this InterventionType to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    InterventionType.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for InterventionType
                     * @function getTypeUrl
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    InterventionType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.Intervention.InterventionType";
                    };

                    return InterventionType;
                })();

                return Intervention;
            })();

            PlanningInterventionVersionCard.Version = (function() {

                /**
                 * Properties of a Version.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @interface IVersion
                 * @property {string|null} [urn] Version urn
                 */

                /**
                 * Constructs a new Version.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @classdesc Represents a Version.
                 * @implements IVersion
                 * @constructor
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IVersion=} [properties] Properties to set
                 */
                function Version(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Version urn.
                 * @member {string} urn
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Version
                 * @instance
                 */
                Version.prototype.urn = "";

                /**
                 * Creates a new Version instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Version
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IVersion=} [properties] Properties to set
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Version} Version instance
                 */
                Version.create = function create(properties) {
                    return new Version(properties);
                };

                /**
                 * Encodes the specified Version message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Version.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Version
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IVersion} message Version message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Version.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.urn);
                    return writer;
                };

                /**
                 * Encodes the specified Version message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Version.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Version
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IVersion} message Version message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Version.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Version message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Version
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Version} Version
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Version.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.Version();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.urn = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Version message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Version
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Version} Version
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Version.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Version message.
                 * @function verify
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Version
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Version.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    return null;
                };

                /**
                 * Creates a Version message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Version
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Version} Version
                 */
                Version.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.Version)
                        return object;
                    let message = new $root.Service.Intervention.PlanningInterventionVersionCard.Version();
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    return message;
                };

                /**
                 * Creates a plain object from a Version message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Version
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.Version} message Version
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Version.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.urn = "";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    return object;
                };

                /**
                 * Converts this Version to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Version
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Version.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Version
                 * @function getTypeUrl
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Version
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Version.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.Version";
                };

                return Version;
            })();

            PlanningInterventionVersionCard.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @interface ILocation
                 * @property {string|null} [urn] Location urn
                 * @property {string|null} [city] Location city
                 */

                /**
                 * Constructs a new Location.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {Service.Intervention.PlanningInterventionVersionCard.ILocation=} [properties] Properties to set
                 */
                function Location(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Location urn.
                 * @member {string} urn
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @instance
                 */
                Location.prototype.urn = "";

                /**
                 * Location city.
                 * @member {string} city
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @instance
                 */
                Location.prototype.city = "";

                /**
                 * Creates a new Location instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.ILocation=} [properties] Properties to set
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Location} Location instance
                 */
                Location.create = function create(properties) {
                    return new Location(properties);
                };

                /**
                 * Encodes the specified Location message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Location.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.urn);
                    if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.city);
                    return writer;
                };

                /**
                 * Encodes the specified Location message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.Location.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Location message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.Location();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.urn = reader.string();
                                break;
                            }
                        case 2: {
                                message.city = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Location message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Location message.
                 * @function verify
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Location.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.city != null && message.hasOwnProperty("city"))
                        if (!$util.isString(message.city))
                            return "city: string expected";
                    return null;
                };

                /**
                 * Creates a Location message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.Location} Location
                 */
                Location.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.Location)
                        return object;
                    let message = new $root.Service.Intervention.PlanningInterventionVersionCard.Location();
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.city != null)
                        message.city = String(object.city);
                    return message;
                };

                /**
                 * Creates a plain object from a Location message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.Location} message Location
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Location.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.urn = "";
                        object.city = "";
                    }
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.city != null && message.hasOwnProperty("city"))
                        object.city = message.city;
                    return object;
                };

                /**
                 * Converts this Location to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Location.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Location
                 * @function getTypeUrl
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.Location
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Location.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.Location";
                };

                return Location;
            })();

            PlanningInterventionVersionCard.InterventionVersionResource = (function() {

                /**
                 * Properties of an InterventionVersionResource.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @interface IInterventionVersionResource
                 * @property {number|null} [id] InterventionVersionResource id
                 * @property {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IInterventionResourceType|null} [interventionResourceType] InterventionVersionResource interventionResourceType
                 * @property {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResourceType|null} [resourceType] InterventionVersionResource resourceType
                 * @property {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResource|null} [resource] InterventionVersionResource resource
                 * @property {number|null} [duration] InterventionVersionResource duration
                 * @property {number|null} [distance] InterventionVersionResource distance
                 * @property {boolean|null} [durationToUpdate] InterventionVersionResource durationToUpdate
                 * @property {boolean|null} [distanceToUpdate] InterventionVersionResource distanceToUpdate
                 */

                /**
                 * Constructs a new InterventionVersionResource.
                 * @memberof Service.Intervention.PlanningInterventionVersionCard
                 * @classdesc Represents an InterventionVersionResource.
                 * @implements IInterventionVersionResource
                 * @constructor
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IInterventionVersionResource=} [properties] Properties to set
                 */
                function InterventionVersionResource(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InterventionVersionResource id.
                 * @member {number} id
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @instance
                 */
                InterventionVersionResource.prototype.id = 0;

                /**
                 * InterventionVersionResource interventionResourceType.
                 * @member {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IInterventionResourceType|null|undefined} interventionResourceType
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @instance
                 */
                InterventionVersionResource.prototype.interventionResourceType = null;

                /**
                 * InterventionVersionResource resourceType.
                 * @member {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResourceType|null|undefined} resourceType
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @instance
                 */
                InterventionVersionResource.prototype.resourceType = null;

                /**
                 * InterventionVersionResource resource.
                 * @member {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResource|null|undefined} resource
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @instance
                 */
                InterventionVersionResource.prototype.resource = null;

                /**
                 * InterventionVersionResource duration.
                 * @member {number} duration
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @instance
                 */
                InterventionVersionResource.prototype.duration = 0;

                /**
                 * InterventionVersionResource distance.
                 * @member {number} distance
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @instance
                 */
                InterventionVersionResource.prototype.distance = 0;

                /**
                 * InterventionVersionResource durationToUpdate.
                 * @member {boolean} durationToUpdate
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @instance
                 */
                InterventionVersionResource.prototype.durationToUpdate = false;

                /**
                 * InterventionVersionResource distanceToUpdate.
                 * @member {boolean} distanceToUpdate
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @instance
                 */
                InterventionVersionResource.prototype.distanceToUpdate = false;

                /**
                 * Creates a new InterventionVersionResource instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IInterventionVersionResource=} [properties] Properties to set
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource} InterventionVersionResource instance
                 */
                InterventionVersionResource.create = function create(properties) {
                    return new InterventionVersionResource(properties);
                };

                /**
                 * Encodes the specified InterventionVersionResource message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IInterventionVersionResource} message InterventionVersionResource message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InterventionVersionResource.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                    if (message.interventionResourceType != null && Object.hasOwnProperty.call(message, "interventionResourceType"))
                        $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType.encode(message.interventionResourceType, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.resourceType != null && Object.hasOwnProperty.call(message, "resourceType"))
                        $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType.encode(message.resourceType, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.resource != null && Object.hasOwnProperty.call(message, "resource"))
                        $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource.encode(message.resource, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                        writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.duration);
                    if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                        writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.distance);
                    if (message.durationToUpdate != null && Object.hasOwnProperty.call(message, "durationToUpdate"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.durationToUpdate);
                    if (message.distanceToUpdate != null && Object.hasOwnProperty.call(message, "distanceToUpdate"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.distanceToUpdate);
                    return writer;
                };

                /**
                 * Encodes the specified InterventionVersionResource message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.IInterventionVersionResource} message InterventionVersionResource message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InterventionVersionResource.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InterventionVersionResource message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource} InterventionVersionResource
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InterventionVersionResource.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.uint32();
                                break;
                            }
                        case 2: {
                                message.interventionResourceType = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.resourceType = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.resource = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.duration = reader.uint32();
                                break;
                            }
                        case 6: {
                                message.distance = reader.uint32();
                                break;
                            }
                        case 7: {
                                message.durationToUpdate = reader.bool();
                                break;
                            }
                        case 8: {
                                message.distanceToUpdate = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InterventionVersionResource message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource} InterventionVersionResource
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InterventionVersionResource.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InterventionVersionResource message.
                 * @function verify
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InterventionVersionResource.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id))
                            return "id: integer expected";
                    if (message.interventionResourceType != null && message.hasOwnProperty("interventionResourceType")) {
                        let error = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType.verify(message.interventionResourceType);
                        if (error)
                            return "interventionResourceType." + error;
                    }
                    if (message.resourceType != null && message.hasOwnProperty("resourceType")) {
                        let error = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType.verify(message.resourceType);
                        if (error)
                            return "resourceType." + error;
                    }
                    if (message.resource != null && message.hasOwnProperty("resource")) {
                        let error = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource.verify(message.resource);
                        if (error)
                            return "resource." + error;
                    }
                    if (message.duration != null && message.hasOwnProperty("duration"))
                        if (!$util.isInteger(message.duration))
                            return "duration: integer expected";
                    if (message.distance != null && message.hasOwnProperty("distance"))
                        if (!$util.isInteger(message.distance))
                            return "distance: integer expected";
                    if (message.durationToUpdate != null && message.hasOwnProperty("durationToUpdate"))
                        if (typeof message.durationToUpdate !== "boolean")
                            return "durationToUpdate: boolean expected";
                    if (message.distanceToUpdate != null && message.hasOwnProperty("distanceToUpdate"))
                        if (typeof message.distanceToUpdate !== "boolean")
                            return "distanceToUpdate: boolean expected";
                    return null;
                };

                /**
                 * Creates an InterventionVersionResource message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource} InterventionVersionResource
                 */
                InterventionVersionResource.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource)
                        return object;
                    let message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource();
                    if (object.id != null)
                        message.id = object.id >>> 0;
                    if (object.interventionResourceType != null) {
                        if (typeof object.interventionResourceType !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.interventionResourceType: object expected");
                        message.interventionResourceType = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType.fromObject(object.interventionResourceType);
                    }
                    if (object.resourceType != null) {
                        if (typeof object.resourceType !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.resourceType: object expected");
                        message.resourceType = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType.fromObject(object.resourceType);
                    }
                    if (object.resource != null) {
                        if (typeof object.resource !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.resource: object expected");
                        message.resource = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource.fromObject(object.resource);
                    }
                    if (object.duration != null)
                        message.duration = object.duration >>> 0;
                    if (object.distance != null)
                        message.distance = object.distance >>> 0;
                    if (object.durationToUpdate != null)
                        message.durationToUpdate = Boolean(object.durationToUpdate);
                    if (object.distanceToUpdate != null)
                        message.distanceToUpdate = Boolean(object.distanceToUpdate);
                    return message;
                };

                /**
                 * Creates a plain object from an InterventionVersionResource message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @static
                 * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource} message InterventionVersionResource
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InterventionVersionResource.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.id = 0;
                        object.interventionResourceType = null;
                        object.resourceType = null;
                        object.resource = null;
                        object.duration = 0;
                        object.distance = 0;
                        object.durationToUpdate = false;
                        object.distanceToUpdate = false;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.interventionResourceType != null && message.hasOwnProperty("interventionResourceType"))
                        object.interventionResourceType = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType.toObject(message.interventionResourceType, options);
                    if (message.resourceType != null && message.hasOwnProperty("resourceType"))
                        object.resourceType = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType.toObject(message.resourceType, options);
                    if (message.resource != null && message.hasOwnProperty("resource"))
                        object.resource = $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource.toObject(message.resource, options);
                    if (message.duration != null && message.hasOwnProperty("duration"))
                        object.duration = message.duration;
                    if (message.distance != null && message.hasOwnProperty("distance"))
                        object.distance = message.distance;
                    if (message.durationToUpdate != null && message.hasOwnProperty("durationToUpdate"))
                        object.durationToUpdate = message.durationToUpdate;
                    if (message.distanceToUpdate != null && message.hasOwnProperty("distanceToUpdate"))
                        object.distanceToUpdate = message.distanceToUpdate;
                    return object;
                };

                /**
                 * Converts this InterventionVersionResource to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InterventionVersionResource.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InterventionVersionResource
                 * @function getTypeUrl
                 * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InterventionVersionResource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource";
                };

                InterventionVersionResource.InterventionResourceType = (function() {

                    /**
                     * Properties of an InterventionResourceType.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                     * @interface IInterventionResourceType
                     * @property {number|null} [id] InterventionResourceType id
                     */

                    /**
                     * Constructs a new InterventionResourceType.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                     * @classdesc Represents an InterventionResourceType.
                     * @implements IInterventionResourceType
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IInterventionResourceType=} [properties] Properties to set
                     */
                    function InterventionResourceType(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * InterventionResourceType id.
                     * @member {number} id
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType
                     * @instance
                     */
                    InterventionResourceType.prototype.id = 0;

                    /**
                     * Creates a new InterventionResourceType instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IInterventionResourceType=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType} InterventionResourceType instance
                     */
                    InterventionResourceType.create = function create(properties) {
                        return new InterventionResourceType(properties);
                    };

                    /**
                     * Encodes the specified InterventionResourceType message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IInterventionResourceType} message InterventionResourceType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterventionResourceType.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                        return writer;
                    };

                    /**
                     * Encodes the specified InterventionResourceType message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IInterventionResourceType} message InterventionResourceType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterventionResourceType.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an InterventionResourceType message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType} InterventionResourceType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterventionResourceType.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.id = reader.uint32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an InterventionResourceType message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType} InterventionResourceType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterventionResourceType.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an InterventionResourceType message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    InterventionResourceType.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id))
                                return "id: integer expected";
                        return null;
                    };

                    /**
                     * Creates an InterventionResourceType message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType} InterventionResourceType
                     */
                    InterventionResourceType.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType();
                        if (object.id != null)
                            message.id = object.id >>> 0;
                        return message;
                    };

                    /**
                     * Creates a plain object from an InterventionResourceType message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType} message InterventionResourceType
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    InterventionResourceType.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.id = 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        return object;
                    };

                    /**
                     * Converts this InterventionResourceType to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    InterventionResourceType.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for InterventionResourceType
                     * @function getTypeUrl
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    InterventionResourceType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.InterventionResourceType";
                    };

                    return InterventionResourceType;
                })();

                InterventionVersionResource.ResourceType = (function() {

                    /**
                     * Properties of a ResourceType.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                     * @interface IResourceType
                     * @property {string|null} [reference] ResourceType reference
                     */

                    /**
                     * Constructs a new ResourceType.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                     * @classdesc Represents a ResourceType.
                     * @implements IResourceType
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResourceType=} [properties] Properties to set
                     */
                    function ResourceType(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ResourceType reference.
                     * @member {string} reference
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType
                     * @instance
                     */
                    ResourceType.prototype.reference = "";

                    /**
                     * Creates a new ResourceType instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResourceType=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType} ResourceType instance
                     */
                    ResourceType.create = function create(properties) {
                        return new ResourceType(properties);
                    };

                    /**
                     * Encodes the specified ResourceType message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResourceType} message ResourceType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResourceType.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.reference);
                        return writer;
                    };

                    /**
                     * Encodes the specified ResourceType message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResourceType} message ResourceType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResourceType.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ResourceType message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType} ResourceType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResourceType.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.reference = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ResourceType message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType} ResourceType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResourceType.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ResourceType message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ResourceType.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            if (!$util.isString(message.reference))
                                return "reference: string expected";
                        return null;
                    };

                    /**
                     * Creates a ResourceType message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType} ResourceType
                     */
                    ResourceType.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType();
                        if (object.reference != null)
                            message.reference = String(object.reference);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ResourceType message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType} message ResourceType
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ResourceType.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.reference = "";
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            object.reference = message.reference;
                        return object;
                    };

                    /**
                     * Converts this ResourceType to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ResourceType.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for ResourceType
                     * @function getTypeUrl
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    ResourceType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.ResourceType";
                    };

                    return ResourceType;
                })();

                InterventionVersionResource.Resource = (function() {

                    /**
                     * Properties of a Resource.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                     * @interface IResource
                     * @property {string|null} [resourceUrn] Resource resourceUrn
                     */

                    /**
                     * Constructs a new Resource.
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource
                     * @classdesc Represents a Resource.
                     * @implements IResource
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResource=} [properties] Properties to set
                     */
                    function Resource(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Resource resourceUrn.
                     * @member {string} resourceUrn
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource
                     * @instance
                     */
                    Resource.prototype.resourceUrn = "";

                    /**
                     * Creates a new Resource instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResource=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource} Resource instance
                     */
                    Resource.create = function create(properties) {
                        return new Resource(properties);
                    };

                    /**
                     * Encodes the specified Resource message. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResource} message Resource message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Resource.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.resourceUrn != null && Object.hasOwnProperty.call(message, "resourceUrn"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.resourceUrn);
                        return writer;
                    };

                    /**
                     * Encodes the specified Resource message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.IResource} message Resource message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Resource.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Resource message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource} Resource
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Resource.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.resourceUrn = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Resource message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource} Resource
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Resource.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Resource message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Resource.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.resourceUrn != null && message.hasOwnProperty("resourceUrn"))
                            if (!$util.isString(message.resourceUrn))
                                return "resourceUrn: string expected";
                        return null;
                    };

                    /**
                     * Creates a Resource message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource} Resource
                     */
                    Resource.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource();
                        if (object.resourceUrn != null)
                            message.resourceUrn = String(object.resourceUrn);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Resource message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource
                     * @static
                     * @param {Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource} message Resource
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Resource.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.resourceUrn = "";
                        if (message.resourceUrn != null && message.hasOwnProperty("resourceUrn"))
                            object.resourceUrn = message.resourceUrn;
                        return object;
                    };

                    /**
                     * Converts this Resource to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Resource.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Resource
                     * @function getTypeUrl
                     * @memberof Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Resource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/Service.Intervention.PlanningInterventionVersionCard.InterventionVersionResource.Resource";
                    };

                    return Resource;
                })();

                return InterventionVersionResource;
            })();

            return PlanningInterventionVersionCard;
        })();

        Intervention.Version = (function() {

            /**
             * Properties of a Version.
             * @memberof Service.Intervention
             * @interface IVersion
             * @property {number|null} [id] Version id
             * @property {google.protobuf.ITimestamp|null} [updatedAt] Version updatedAt
             * @property {string|null} [partitionUrn] Version partitionUrn
             * @property {google.protobuf.ITimestamp|null} [date] Version date
             * @property {string|null} [label] Version label
             * @property {Service.Intervention.Version.IVersionType|null} [versionType] Version versionType
             */

            /**
             * Constructs a new Version.
             * @memberof Service.Intervention
             * @classdesc Represents a Version.
             * @implements IVersion
             * @constructor
             * @param {Service.Intervention.IVersion=} [properties] Properties to set
             */
            function Version(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Version id.
             * @member {number} id
             * @memberof Service.Intervention.Version
             * @instance
             */
            Version.prototype.id = 0;

            /**
             * Version updatedAt.
             * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
             * @memberof Service.Intervention.Version
             * @instance
             */
            Version.prototype.updatedAt = null;

            /**
             * Version partitionUrn.
             * @member {string} partitionUrn
             * @memberof Service.Intervention.Version
             * @instance
             */
            Version.prototype.partitionUrn = "";

            /**
             * Version date.
             * @member {google.protobuf.ITimestamp|null|undefined} date
             * @memberof Service.Intervention.Version
             * @instance
             */
            Version.prototype.date = null;

            /**
             * Version label.
             * @member {string} label
             * @memberof Service.Intervention.Version
             * @instance
             */
            Version.prototype.label = "";

            /**
             * Version versionType.
             * @member {Service.Intervention.Version.IVersionType|null|undefined} versionType
             * @memberof Service.Intervention.Version
             * @instance
             */
            Version.prototype.versionType = null;

            /**
             * Creates a new Version instance using the specified properties.
             * @function create
             * @memberof Service.Intervention.Version
             * @static
             * @param {Service.Intervention.IVersion=} [properties] Properties to set
             * @returns {Service.Intervention.Version} Version instance
             */
            Version.create = function create(properties) {
                return new Version(properties);
            };

            /**
             * Encodes the specified Version message. Does not implicitly {@link Service.Intervention.Version.verify|verify} messages.
             * @function encode
             * @memberof Service.Intervention.Version
             * @static
             * @param {Service.Intervention.IVersion} message Version message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Version.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                    $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.partitionUrn != null && Object.hasOwnProperty.call(message, "partitionUrn"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.partitionUrn);
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    $root.google.protobuf.Timestamp.encode(message.date, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.label);
                if (message.versionType != null && Object.hasOwnProperty.call(message, "versionType"))
                    $root.Service.Intervention.Version.VersionType.encode(message.versionType, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Version message, length delimited. Does not implicitly {@link Service.Intervention.Version.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Service.Intervention.Version
             * @static
             * @param {Service.Intervention.IVersion} message Version message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Version.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Version message from the specified reader or buffer.
             * @function decode
             * @memberof Service.Intervention.Version
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Service.Intervention.Version} Version
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Version.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.Version();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.partitionUrn = reader.string();
                            break;
                        }
                    case 4: {
                            message.date = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.label = reader.string();
                            break;
                        }
                    case 6: {
                            message.versionType = $root.Service.Intervention.Version.VersionType.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Version message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Service.Intervention.Version
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Service.Intervention.Version} Version
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Version.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Version message.
             * @function verify
             * @memberof Service.Intervention.Version
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Version.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.updatedAt);
                    if (error)
                        return "updatedAt." + error;
                }
                if (message.partitionUrn != null && message.hasOwnProperty("partitionUrn"))
                    if (!$util.isString(message.partitionUrn))
                        return "partitionUrn: string expected";
                if (message.date != null && message.hasOwnProperty("date")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.date);
                    if (error)
                        return "date." + error;
                }
                if (message.label != null && message.hasOwnProperty("label"))
                    if (!$util.isString(message.label))
                        return "label: string expected";
                if (message.versionType != null && message.hasOwnProperty("versionType")) {
                    let error = $root.Service.Intervention.Version.VersionType.verify(message.versionType);
                    if (error)
                        return "versionType." + error;
                }
                return null;
            };

            /**
             * Creates a Version message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Service.Intervention.Version
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Service.Intervention.Version} Version
             */
            Version.fromObject = function fromObject(object) {
                if (object instanceof $root.Service.Intervention.Version)
                    return object;
                let message = new $root.Service.Intervention.Version();
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.updatedAt != null) {
                    if (typeof object.updatedAt !== "object")
                        throw TypeError(".Service.Intervention.Version.updatedAt: object expected");
                    message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
                }
                if (object.partitionUrn != null)
                    message.partitionUrn = String(object.partitionUrn);
                if (object.date != null) {
                    if (typeof object.date !== "object")
                        throw TypeError(".Service.Intervention.Version.date: object expected");
                    message.date = $root.google.protobuf.Timestamp.fromObject(object.date);
                }
                if (object.label != null)
                    message.label = String(object.label);
                if (object.versionType != null) {
                    if (typeof object.versionType !== "object")
                        throw TypeError(".Service.Intervention.Version.versionType: object expected");
                    message.versionType = $root.Service.Intervention.Version.VersionType.fromObject(object.versionType);
                }
                return message;
            };

            /**
             * Creates a plain object from a Version message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Service.Intervention.Version
             * @static
             * @param {Service.Intervention.Version} message Version
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Version.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.updatedAt = null;
                    object.partitionUrn = "";
                    object.date = null;
                    object.label = "";
                    object.versionType = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                    object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
                if (message.partitionUrn != null && message.hasOwnProperty("partitionUrn"))
                    object.partitionUrn = message.partitionUrn;
                if (message.date != null && message.hasOwnProperty("date"))
                    object.date = $root.google.protobuf.Timestamp.toObject(message.date, options);
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = message.label;
                if (message.versionType != null && message.hasOwnProperty("versionType"))
                    object.versionType = $root.Service.Intervention.Version.VersionType.toObject(message.versionType, options);
                return object;
            };

            /**
             * Converts this Version to JSON.
             * @function toJSON
             * @memberof Service.Intervention.Version
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Version.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Version
             * @function getTypeUrl
             * @memberof Service.Intervention.Version
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Version.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Service.Intervention.Version";
            };

            Version.VersionType = (function() {

                /**
                 * Properties of a VersionType.
                 * @memberof Service.Intervention.Version
                 * @interface IVersionType
                 * @property {string|null} [reference] VersionType reference
                 */

                /**
                 * Constructs a new VersionType.
                 * @memberof Service.Intervention.Version
                 * @classdesc Represents a VersionType.
                 * @implements IVersionType
                 * @constructor
                 * @param {Service.Intervention.Version.IVersionType=} [properties] Properties to set
                 */
                function VersionType(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VersionType reference.
                 * @member {string} reference
                 * @memberof Service.Intervention.Version.VersionType
                 * @instance
                 */
                VersionType.prototype.reference = "";

                /**
                 * Creates a new VersionType instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.Version.VersionType
                 * @static
                 * @param {Service.Intervention.Version.IVersionType=} [properties] Properties to set
                 * @returns {Service.Intervention.Version.VersionType} VersionType instance
                 */
                VersionType.create = function create(properties) {
                    return new VersionType(properties);
                };

                /**
                 * Encodes the specified VersionType message. Does not implicitly {@link Service.Intervention.Version.VersionType.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.Version.VersionType
                 * @static
                 * @param {Service.Intervention.Version.IVersionType} message VersionType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VersionType.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.reference);
                    return writer;
                };

                /**
                 * Encodes the specified VersionType message, length delimited. Does not implicitly {@link Service.Intervention.Version.VersionType.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.Version.VersionType
                 * @static
                 * @param {Service.Intervention.Version.IVersionType} message VersionType message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VersionType.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a VersionType message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.Version.VersionType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.Version.VersionType} VersionType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VersionType.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.Version.VersionType();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.reference = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a VersionType message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.Version.VersionType
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.Version.VersionType} VersionType
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VersionType.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a VersionType message.
                 * @function verify
                 * @memberof Service.Intervention.Version.VersionType
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VersionType.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.reference != null && message.hasOwnProperty("reference"))
                        if (!$util.isString(message.reference))
                            return "reference: string expected";
                    return null;
                };

                /**
                 * Creates a VersionType message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.Version.VersionType
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.Version.VersionType} VersionType
                 */
                VersionType.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.Version.VersionType)
                        return object;
                    let message = new $root.Service.Intervention.Version.VersionType();
                    if (object.reference != null)
                        message.reference = String(object.reference);
                    return message;
                };

                /**
                 * Creates a plain object from a VersionType message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.Version.VersionType
                 * @static
                 * @param {Service.Intervention.Version.VersionType} message VersionType
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VersionType.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.reference = "";
                    if (message.reference != null && message.hasOwnProperty("reference"))
                        object.reference = message.reference;
                    return object;
                };

                /**
                 * Converts this VersionType to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.Version.VersionType
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VersionType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VersionType
                 * @function getTypeUrl
                 * @memberof Service.Intervention.Version.VersionType
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VersionType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/Service.Intervention.Version.VersionType";
                };

                return VersionType;
            })();

            return Version;
        })();

        Intervention.AnomalyRequest = (function() {

            /**
             * Properties of an AnomalyRequest.
             * @memberof Service.Intervention
             * @interface IAnomalyRequest
             * @property {string|null} [urn] AnomalyRequest urn
             * @property {number|null} [id] AnomalyRequest id
             * @property {number|null} [total] AnomalyRequest total
             * @property {number|null} [done] AnomalyRequest done
             * @property {string|null} [status] AnomalyRequest status
             * @property {string|null} [uniqueIdentifier] AnomalyRequest uniqueIdentifier
             */

            /**
             * Constructs a new AnomalyRequest.
             * @memberof Service.Intervention
             * @classdesc Represents an AnomalyRequest.
             * @implements IAnomalyRequest
             * @constructor
             * @param {Service.Intervention.IAnomalyRequest=} [properties] Properties to set
             */
            function AnomalyRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AnomalyRequest urn.
             * @member {string} urn
             * @memberof Service.Intervention.AnomalyRequest
             * @instance
             */
            AnomalyRequest.prototype.urn = "";

            /**
             * AnomalyRequest id.
             * @member {number} id
             * @memberof Service.Intervention.AnomalyRequest
             * @instance
             */
            AnomalyRequest.prototype.id = 0;

            /**
             * AnomalyRequest total.
             * @member {number} total
             * @memberof Service.Intervention.AnomalyRequest
             * @instance
             */
            AnomalyRequest.prototype.total = 0;

            /**
             * AnomalyRequest done.
             * @member {number} done
             * @memberof Service.Intervention.AnomalyRequest
             * @instance
             */
            AnomalyRequest.prototype.done = 0;

            /**
             * AnomalyRequest status.
             * @member {string} status
             * @memberof Service.Intervention.AnomalyRequest
             * @instance
             */
            AnomalyRequest.prototype.status = "";

            /**
             * AnomalyRequest uniqueIdentifier.
             * @member {string} uniqueIdentifier
             * @memberof Service.Intervention.AnomalyRequest
             * @instance
             */
            AnomalyRequest.prototype.uniqueIdentifier = "";

            /**
             * Creates a new AnomalyRequest instance using the specified properties.
             * @function create
             * @memberof Service.Intervention.AnomalyRequest
             * @static
             * @param {Service.Intervention.IAnomalyRequest=} [properties] Properties to set
             * @returns {Service.Intervention.AnomalyRequest} AnomalyRequest instance
             */
            AnomalyRequest.create = function create(properties) {
                return new AnomalyRequest(properties);
            };

            /**
             * Encodes the specified AnomalyRequest message. Does not implicitly {@link Service.Intervention.AnomalyRequest.verify|verify} messages.
             * @function encode
             * @memberof Service.Intervention.AnomalyRequest
             * @static
             * @param {Service.Intervention.IAnomalyRequest} message AnomalyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AnomalyRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.urn);
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.id);
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.total);
                if (message.done != null && Object.hasOwnProperty.call(message, "done"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.done);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.status);
                if (message.uniqueIdentifier != null && Object.hasOwnProperty.call(message, "uniqueIdentifier"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.uniqueIdentifier);
                return writer;
            };

            /**
             * Encodes the specified AnomalyRequest message, length delimited. Does not implicitly {@link Service.Intervention.AnomalyRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Service.Intervention.AnomalyRequest
             * @static
             * @param {Service.Intervention.IAnomalyRequest} message AnomalyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AnomalyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AnomalyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof Service.Intervention.AnomalyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Service.Intervention.AnomalyRequest} AnomalyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AnomalyRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.AnomalyRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.urn = reader.string();
                            break;
                        }
                    case 2: {
                            message.id = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.total = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.done = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.status = reader.string();
                            break;
                        }
                    case 6: {
                            message.uniqueIdentifier = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AnomalyRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Service.Intervention.AnomalyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Service.Intervention.AnomalyRequest} AnomalyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AnomalyRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AnomalyRequest message.
             * @function verify
             * @memberof Service.Intervention.AnomalyRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AnomalyRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.urn != null && message.hasOwnProperty("urn"))
                    if (!$util.isString(message.urn))
                        return "urn: string expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.total != null && message.hasOwnProperty("total"))
                    if (!$util.isInteger(message.total))
                        return "total: integer expected";
                if (message.done != null && message.hasOwnProperty("done"))
                    if (!$util.isInteger(message.done))
                        return "done: integer expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    if (!$util.isString(message.status))
                        return "status: string expected";
                if (message.uniqueIdentifier != null && message.hasOwnProperty("uniqueIdentifier"))
                    if (!$util.isString(message.uniqueIdentifier))
                        return "uniqueIdentifier: string expected";
                return null;
            };

            /**
             * Creates an AnomalyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Service.Intervention.AnomalyRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Service.Intervention.AnomalyRequest} AnomalyRequest
             */
            AnomalyRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.Service.Intervention.AnomalyRequest)
                    return object;
                let message = new $root.Service.Intervention.AnomalyRequest();
                if (object.urn != null)
                    message.urn = String(object.urn);
                if (object.id != null)
                    message.id = object.id >>> 0;
                if (object.total != null)
                    message.total = object.total >>> 0;
                if (object.done != null)
                    message.done = object.done >>> 0;
                if (object.status != null)
                    message.status = String(object.status);
                if (object.uniqueIdentifier != null)
                    message.uniqueIdentifier = String(object.uniqueIdentifier);
                return message;
            };

            /**
             * Creates a plain object from an AnomalyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Service.Intervention.AnomalyRequest
             * @static
             * @param {Service.Intervention.AnomalyRequest} message AnomalyRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AnomalyRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.urn = "";
                    object.id = 0;
                    object.total = 0;
                    object.done = 0;
                    object.status = "";
                    object.uniqueIdentifier = "";
                }
                if (message.urn != null && message.hasOwnProperty("urn"))
                    object.urn = message.urn;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = message.total;
                if (message.done != null && message.hasOwnProperty("done"))
                    object.done = message.done;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = message.status;
                if (message.uniqueIdentifier != null && message.hasOwnProperty("uniqueIdentifier"))
                    object.uniqueIdentifier = message.uniqueIdentifier;
                return object;
            };

            /**
             * Converts this AnomalyRequest to JSON.
             * @function toJSON
             * @memberof Service.Intervention.AnomalyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AnomalyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AnomalyRequest
             * @function getTypeUrl
             * @memberof Service.Intervention.AnomalyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AnomalyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Service.Intervention.AnomalyRequest";
            };

            return AnomalyRequest;
        })();

        return Intervention;
    })();

    return Service;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.seconds = reader.int64();
                            break;
                        }
                    case 2: {
                            message.nanos = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                let message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Timestamp
             * @function getTypeUrl
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Timestamp";
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

export const StoneCode = $root.StoneCode = (() => {

    /**
     * Namespace StoneCode.
     * @exports StoneCode
     * @namespace
     */
    const StoneCode = {};

    StoneCode.PlatformBundle = (function() {

        /**
         * Namespace PlatformBundle.
         * @memberof StoneCode
         * @namespace
         */
        const PlatformBundle = {};

        PlatformBundle.Common = (function() {

            /**
             * Namespace Common.
             * @memberof StoneCode.PlatformBundle
             * @namespace
             */
            const Common = {};

            Common.Entity = (function() {

                /**
                 * Namespace Entity.
                 * @memberof StoneCode.PlatformBundle.Common
                 * @namespace
                 */
                const Entity = {};

                Entity.Urn = (function() {

                    /**
                     * Properties of an Urn.
                     * @memberof StoneCode.PlatformBundle.Common.Entity
                     * @interface IUrn
                     * @property {string|null} [partition] Urn partition
                     * @property {string|null} [service] Urn service
                     * @property {string|null} [resource] Urn resource
                     * @property {string|null} [identifier] Urn identifier
                     */

                    /**
                     * Constructs a new Urn.
                     * @memberof StoneCode.PlatformBundle.Common.Entity
                     * @classdesc Represents an Urn.
                     * @implements IUrn
                     * @constructor
                     * @param {StoneCode.PlatformBundle.Common.Entity.IUrn=} [properties] Properties to set
                     */
                    function Urn(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Urn partition.
                     * @member {string} partition
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @instance
                     */
                    Urn.prototype.partition = "";

                    /**
                     * Urn service.
                     * @member {string} service
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @instance
                     */
                    Urn.prototype.service = "";

                    /**
                     * Urn resource.
                     * @member {string} resource
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @instance
                     */
                    Urn.prototype.resource = "";

                    /**
                     * Urn identifier.
                     * @member {string} identifier
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @instance
                     */
                    Urn.prototype.identifier = "";

                    /**
                     * Creates a new Urn instance using the specified properties.
                     * @function create
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @static
                     * @param {StoneCode.PlatformBundle.Common.Entity.IUrn=} [properties] Properties to set
                     * @returns {StoneCode.PlatformBundle.Common.Entity.Urn} Urn instance
                     */
                    Urn.create = function create(properties) {
                        return new Urn(properties);
                    };

                    /**
                     * Encodes the specified Urn message. Does not implicitly {@link StoneCode.PlatformBundle.Common.Entity.Urn.verify|verify} messages.
                     * @function encode
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @static
                     * @param {StoneCode.PlatformBundle.Common.Entity.IUrn} message Urn message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Urn.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.partition != null && Object.hasOwnProperty.call(message, "partition"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.partition);
                        if (message.service != null && Object.hasOwnProperty.call(message, "service"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.service);
                        if (message.resource != null && Object.hasOwnProperty.call(message, "resource"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.resource);
                        if (message.identifier != null && Object.hasOwnProperty.call(message, "identifier"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.identifier);
                        return writer;
                    };

                    /**
                     * Encodes the specified Urn message, length delimited. Does not implicitly {@link StoneCode.PlatformBundle.Common.Entity.Urn.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @static
                     * @param {StoneCode.PlatformBundle.Common.Entity.IUrn} message Urn message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Urn.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Urn message from the specified reader or buffer.
                     * @function decode
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {StoneCode.PlatformBundle.Common.Entity.Urn} Urn
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Urn.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StoneCode.PlatformBundle.Common.Entity.Urn();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.partition = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.service = reader.string();
                                    break;
                                }
                            case 3: {
                                    message.resource = reader.string();
                                    break;
                                }
                            case 4: {
                                    message.identifier = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Urn message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {StoneCode.PlatformBundle.Common.Entity.Urn} Urn
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Urn.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Urn message.
                     * @function verify
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Urn.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.partition != null && message.hasOwnProperty("partition"))
                            if (!$util.isString(message.partition))
                                return "partition: string expected";
                        if (message.service != null && message.hasOwnProperty("service"))
                            if (!$util.isString(message.service))
                                return "service: string expected";
                        if (message.resource != null && message.hasOwnProperty("resource"))
                            if (!$util.isString(message.resource))
                                return "resource: string expected";
                        if (message.identifier != null && message.hasOwnProperty("identifier"))
                            if (!$util.isString(message.identifier))
                                return "identifier: string expected";
                        return null;
                    };

                    /**
                     * Creates an Urn message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {StoneCode.PlatformBundle.Common.Entity.Urn} Urn
                     */
                    Urn.fromObject = function fromObject(object) {
                        if (object instanceof $root.StoneCode.PlatformBundle.Common.Entity.Urn)
                            return object;
                        let message = new $root.StoneCode.PlatformBundle.Common.Entity.Urn();
                        if (object.partition != null)
                            message.partition = String(object.partition);
                        if (object.service != null)
                            message.service = String(object.service);
                        if (object.resource != null)
                            message.resource = String(object.resource);
                        if (object.identifier != null)
                            message.identifier = String(object.identifier);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Urn message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @static
                     * @param {StoneCode.PlatformBundle.Common.Entity.Urn} message Urn
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Urn.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.partition = "";
                            object.service = "";
                            object.resource = "";
                            object.identifier = "";
                        }
                        if (message.partition != null && message.hasOwnProperty("partition"))
                            object.partition = message.partition;
                        if (message.service != null && message.hasOwnProperty("service"))
                            object.service = message.service;
                        if (message.resource != null && message.hasOwnProperty("resource"))
                            object.resource = message.resource;
                        if (message.identifier != null && message.hasOwnProperty("identifier"))
                            object.identifier = message.identifier;
                        return object;
                    };

                    /**
                     * Converts this Urn to JSON.
                     * @function toJSON
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Urn.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for Urn
                     * @function getTypeUrl
                     * @memberof StoneCode.PlatformBundle.Common.Entity.Urn
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    Urn.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/StoneCode.PlatformBundle.Common.Entity.Urn";
                    };

                    return Urn;
                })();

                return Entity;
            })();

            return Common;
        })();

        return PlatformBundle;
    })();

    StoneCode.ApiExtensionBundle = (function() {

        /**
         * Namespace ApiExtensionBundle.
         * @memberof StoneCode
         * @namespace
         */
        const ApiExtensionBundle = {};

        ApiExtensionBundle.Rpc = (function() {

            /**
             * Namespace Rpc.
             * @memberof StoneCode.ApiExtensionBundle
             * @namespace
             */
            const Rpc = {};

            Rpc.Command = (function() {

                /**
                 * Namespace Command.
                 * @memberof StoneCode.ApiExtensionBundle.Rpc
                 * @namespace
                 */
                const Command = {};

                Command.GetObjectActions = (function() {

                    /**
                     * Properties of a GetObjectActions.
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command
                     * @interface IGetObjectActions
                     * @property {StoneCode.PlatformBundle.Common.Entity.IUrn|null} [userUrn] GetObjectActions userUrn
                     * @property {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions.RefreshCacheOption|null} [refreshCacheOption] GetObjectActions refreshCacheOption
                     */

                    /**
                     * Constructs a new GetObjectActions.
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command
                     * @classdesc Represents a GetObjectActions.
                     * @implements IGetObjectActions
                     * @constructor
                     * @param {StoneCode.ApiExtensionBundle.Rpc.Command.IGetObjectActions=} [properties] Properties to set
                     */
                    function GetObjectActions(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetObjectActions userUrn.
                     * @member {StoneCode.PlatformBundle.Common.Entity.IUrn|null|undefined} userUrn
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @instance
                     */
                    GetObjectActions.prototype.userUrn = null;

                    /**
                     * GetObjectActions refreshCacheOption.
                     * @member {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions.RefreshCacheOption} refreshCacheOption
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @instance
                     */
                    GetObjectActions.prototype.refreshCacheOption = 0;

                    /**
                     * Creates a new GetObjectActions instance using the specified properties.
                     * @function create
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @static
                     * @param {StoneCode.ApiExtensionBundle.Rpc.Command.IGetObjectActions=} [properties] Properties to set
                     * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions} GetObjectActions instance
                     */
                    GetObjectActions.create = function create(properties) {
                        return new GetObjectActions(properties);
                    };

                    /**
                     * Encodes the specified GetObjectActions message. Does not implicitly {@link StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions.verify|verify} messages.
                     * @function encode
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @static
                     * @param {StoneCode.ApiExtensionBundle.Rpc.Command.IGetObjectActions} message GetObjectActions message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetObjectActions.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userUrn != null && Object.hasOwnProperty.call(message, "userUrn"))
                            $root.StoneCode.PlatformBundle.Common.Entity.Urn.encode(message.userUrn, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.refreshCacheOption != null && Object.hasOwnProperty.call(message, "refreshCacheOption"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.refreshCacheOption);
                        return writer;
                    };

                    /**
                     * Encodes the specified GetObjectActions message, length delimited. Does not implicitly {@link StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @static
                     * @param {StoneCode.ApiExtensionBundle.Rpc.Command.IGetObjectActions} message GetObjectActions message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetObjectActions.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetObjectActions message from the specified reader or buffer.
                     * @function decode
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions} GetObjectActions
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetObjectActions.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.userUrn = $root.StoneCode.PlatformBundle.Common.Entity.Urn.decode(reader, reader.uint32());
                                    break;
                                }
                            case 2: {
                                    message.refreshCacheOption = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetObjectActions message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions} GetObjectActions
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetObjectActions.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetObjectActions message.
                     * @function verify
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetObjectActions.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userUrn != null && message.hasOwnProperty("userUrn")) {
                            let error = $root.StoneCode.PlatformBundle.Common.Entity.Urn.verify(message.userUrn);
                            if (error)
                                return "userUrn." + error;
                        }
                        if (message.refreshCacheOption != null && message.hasOwnProperty("refreshCacheOption"))
                            switch (message.refreshCacheOption) {
                            default:
                                return "refreshCacheOption: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a GetObjectActions message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions} GetObjectActions
                     */
                    GetObjectActions.fromObject = function fromObject(object) {
                        if (object instanceof $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions)
                            return object;
                        let message = new $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions();
                        if (object.userUrn != null) {
                            if (typeof object.userUrn !== "object")
                                throw TypeError(".StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions.userUrn: object expected");
                            message.userUrn = $root.StoneCode.PlatformBundle.Common.Entity.Urn.fromObject(object.userUrn);
                        }
                        switch (object.refreshCacheOption) {
                        default:
                            if (typeof object.refreshCacheOption === "number") {
                                message.refreshCacheOption = object.refreshCacheOption;
                                break;
                            }
                            break;
                        case "IF_EXISTS":
                        case 0:
                            message.refreshCacheOption = 0;
                            break;
                        case "FORCE":
                        case 1:
                            message.refreshCacheOption = 1;
                            break;
                        case "NONE":
                        case 2:
                            message.refreshCacheOption = 2;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetObjectActions message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @static
                     * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions} message GetObjectActions
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetObjectActions.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.userUrn = null;
                            object.refreshCacheOption = options.enums === String ? "IF_EXISTS" : 0;
                        }
                        if (message.userUrn != null && message.hasOwnProperty("userUrn"))
                            object.userUrn = $root.StoneCode.PlatformBundle.Common.Entity.Urn.toObject(message.userUrn, options);
                        if (message.refreshCacheOption != null && message.hasOwnProperty("refreshCacheOption"))
                            object.refreshCacheOption = options.enums === String ? $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions.RefreshCacheOption[message.refreshCacheOption] === undefined ? message.refreshCacheOption : $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions.RefreshCacheOption[message.refreshCacheOption] : message.refreshCacheOption;
                        return object;
                    };

                    /**
                     * Converts this GetObjectActions to JSON.
                     * @function toJSON
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetObjectActions.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for GetObjectActions
                     * @function getTypeUrl
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    GetObjectActions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions";
                    };

                    /**
                     * RefreshCacheOption enum.
                     * @name StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActions.RefreshCacheOption
                     * @enum {number}
                     * @property {number} IF_EXISTS=0 IF_EXISTS value
                     * @property {number} FORCE=1 FORCE value
                     * @property {number} NONE=2 NONE value
                     */
                    GetObjectActions.RefreshCacheOption = (function() {
                        const valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "IF_EXISTS"] = 0;
                        values[valuesById[1] = "FORCE"] = 1;
                        values[valuesById[2] = "NONE"] = 2;
                        return values;
                    })();

                    return GetObjectActions;
                })();

                Command.GetObjectActionsResponse = (function() {

                    /**
                     * Properties of a GetObjectActionsResponse.
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command
                     * @interface IGetObjectActionsResponse
                     * @property {StoneCode.PlatformBundle.Common.Entity.IUrn|null} [urn] GetObjectActionsResponse urn
                     * @property {Array.<StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.IAction>|null} [actions] GetObjectActionsResponse actions
                     */

                    /**
                     * Constructs a new GetObjectActionsResponse.
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command
                     * @classdesc Represents a GetObjectActionsResponse.
                     * @implements IGetObjectActionsResponse
                     * @constructor
                     * @param {StoneCode.ApiExtensionBundle.Rpc.Command.IGetObjectActionsResponse=} [properties] Properties to set
                     */
                    function GetObjectActionsResponse(properties) {
                        this.actions = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetObjectActionsResponse urn.
                     * @member {StoneCode.PlatformBundle.Common.Entity.IUrn|null|undefined} urn
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @instance
                     */
                    GetObjectActionsResponse.prototype.urn = null;

                    /**
                     * GetObjectActionsResponse actions.
                     * @member {Array.<StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.IAction>} actions
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @instance
                     */
                    GetObjectActionsResponse.prototype.actions = $util.emptyArray;

                    /**
                     * Creates a new GetObjectActionsResponse instance using the specified properties.
                     * @function create
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @static
                     * @param {StoneCode.ApiExtensionBundle.Rpc.Command.IGetObjectActionsResponse=} [properties] Properties to set
                     * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse} GetObjectActionsResponse instance
                     */
                    GetObjectActionsResponse.create = function create(properties) {
                        return new GetObjectActionsResponse(properties);
                    };

                    /**
                     * Encodes the specified GetObjectActionsResponse message. Does not implicitly {@link StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @static
                     * @param {StoneCode.ApiExtensionBundle.Rpc.Command.IGetObjectActionsResponse} message GetObjectActionsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetObjectActionsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                            $root.StoneCode.PlatformBundle.Common.Entity.Urn.encode(message.urn, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.actions != null && message.actions.length)
                            for (let i = 0; i < message.actions.length; ++i)
                                $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.encode(message.actions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified GetObjectActionsResponse message, length delimited. Does not implicitly {@link StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @static
                     * @param {StoneCode.ApiExtensionBundle.Rpc.Command.IGetObjectActionsResponse} message GetObjectActionsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetObjectActionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetObjectActionsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse} GetObjectActionsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetObjectActionsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.urn = $root.StoneCode.PlatformBundle.Common.Entity.Urn.decode(reader, reader.uint32());
                                    break;
                                }
                            case 2: {
                                    if (!(message.actions && message.actions.length))
                                        message.actions = [];
                                    message.actions.push($root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.decode(reader, reader.uint32()));
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetObjectActionsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse} GetObjectActionsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetObjectActionsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetObjectActionsResponse message.
                     * @function verify
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetObjectActionsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.urn != null && message.hasOwnProperty("urn")) {
                            let error = $root.StoneCode.PlatformBundle.Common.Entity.Urn.verify(message.urn);
                            if (error)
                                return "urn." + error;
                        }
                        if (message.actions != null && message.hasOwnProperty("actions")) {
                            if (!Array.isArray(message.actions))
                                return "actions: array expected";
                            for (let i = 0; i < message.actions.length; ++i) {
                                let error = $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.verify(message.actions[i]);
                                if (error)
                                    return "actions." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a GetObjectActionsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse} GetObjectActionsResponse
                     */
                    GetObjectActionsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse)
                            return object;
                        let message = new $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse();
                        if (object.urn != null) {
                            if (typeof object.urn !== "object")
                                throw TypeError(".StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.urn: object expected");
                            message.urn = $root.StoneCode.PlatformBundle.Common.Entity.Urn.fromObject(object.urn);
                        }
                        if (object.actions) {
                            if (!Array.isArray(object.actions))
                                throw TypeError(".StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.actions: array expected");
                            message.actions = [];
                            for (let i = 0; i < object.actions.length; ++i) {
                                if (typeof object.actions[i] !== "object")
                                    throw TypeError(".StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.actions: object expected");
                                message.actions[i] = $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.fromObject(object.actions[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetObjectActionsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @static
                     * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse} message GetObjectActionsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetObjectActionsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.arrays || options.defaults)
                            object.actions = [];
                        if (options.defaults)
                            object.urn = null;
                        if (message.urn != null && message.hasOwnProperty("urn"))
                            object.urn = $root.StoneCode.PlatformBundle.Common.Entity.Urn.toObject(message.urn, options);
                        if (message.actions && message.actions.length) {
                            object.actions = [];
                            for (let j = 0; j < message.actions.length; ++j)
                                object.actions[j] = $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.toObject(message.actions[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this GetObjectActionsResponse to JSON.
                     * @function toJSON
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetObjectActionsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for GetObjectActionsResponse
                     * @function getTypeUrl
                     * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    GetObjectActionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse";
                    };

                    GetObjectActionsResponse.Action = (function() {

                        /**
                         * Properties of an Action.
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                         * @interface IAction
                         * @property {StoneCode.PlatformBundle.Common.Entity.IUrn|null} [urn] Action urn
                         * @property {Array.<StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.IContext>|null} [contexts] Action contexts
                         */

                        /**
                         * Constructs a new Action.
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse
                         * @classdesc Represents an Action.
                         * @implements IAction
                         * @constructor
                         * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.IAction=} [properties] Properties to set
                         */
                        function Action(properties) {
                            this.contexts = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Action urn.
                         * @member {StoneCode.PlatformBundle.Common.Entity.IUrn|null|undefined} urn
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @instance
                         */
                        Action.prototype.urn = null;

                        /**
                         * Action contexts.
                         * @member {Array.<StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.IContext>} contexts
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @instance
                         */
                        Action.prototype.contexts = $util.emptyArray;

                        /**
                         * Creates a new Action instance using the specified properties.
                         * @function create
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @static
                         * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.IAction=} [properties] Properties to set
                         * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action} Action instance
                         */
                        Action.create = function create(properties) {
                            return new Action(properties);
                        };

                        /**
                         * Encodes the specified Action message. Does not implicitly {@link StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.verify|verify} messages.
                         * @function encode
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @static
                         * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.IAction} message Action message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Action.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                                $root.StoneCode.PlatformBundle.Common.Entity.Urn.encode(message.urn, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.contexts != null && message.contexts.length)
                                for (let i = 0; i < message.contexts.length; ++i)
                                    $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.encode(message.contexts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified Action message, length delimited. Does not implicitly {@link StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @static
                         * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.IAction} message Action message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Action.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an Action message from the specified reader or buffer.
                         * @function decode
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action} Action
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Action.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action();
                            while (reader.pos < end) {
                                let tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.urn = $root.StoneCode.PlatformBundle.Common.Entity.Urn.decode(reader, reader.uint32());
                                        break;
                                    }
                                case 2: {
                                        if (!(message.contexts && message.contexts.length))
                                            message.contexts = [];
                                        message.contexts.push($root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.decode(reader, reader.uint32()));
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an Action message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action} Action
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Action.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an Action message.
                         * @function verify
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Action.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.urn != null && message.hasOwnProperty("urn")) {
                                let error = $root.StoneCode.PlatformBundle.Common.Entity.Urn.verify(message.urn);
                                if (error)
                                    return "urn." + error;
                            }
                            if (message.contexts != null && message.hasOwnProperty("contexts")) {
                                if (!Array.isArray(message.contexts))
                                    return "contexts: array expected";
                                for (let i = 0; i < message.contexts.length; ++i) {
                                    let error = $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.verify(message.contexts[i]);
                                    if (error)
                                        return "contexts." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates an Action message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action} Action
                         */
                        Action.fromObject = function fromObject(object) {
                            if (object instanceof $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action)
                                return object;
                            let message = new $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action();
                            if (object.urn != null) {
                                if (typeof object.urn !== "object")
                                    throw TypeError(".StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.urn: object expected");
                                message.urn = $root.StoneCode.PlatformBundle.Common.Entity.Urn.fromObject(object.urn);
                            }
                            if (object.contexts) {
                                if (!Array.isArray(object.contexts))
                                    throw TypeError(".StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.contexts: array expected");
                                message.contexts = [];
                                for (let i = 0; i < object.contexts.length; ++i) {
                                    if (typeof object.contexts[i] !== "object")
                                        throw TypeError(".StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.contexts: object expected");
                                    message.contexts[i] = $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.fromObject(object.contexts[i]);
                                }
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an Action message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @static
                         * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action} message Action
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Action.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.arrays || options.defaults)
                                object.contexts = [];
                            if (options.defaults)
                                object.urn = null;
                            if (message.urn != null && message.hasOwnProperty("urn"))
                                object.urn = $root.StoneCode.PlatformBundle.Common.Entity.Urn.toObject(message.urn, options);
                            if (message.contexts && message.contexts.length) {
                                object.contexts = [];
                                for (let j = 0; j < message.contexts.length; ++j)
                                    object.contexts[j] = $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.toObject(message.contexts[j], options);
                            }
                            return object;
                        };

                        /**
                         * Converts this Action to JSON.
                         * @function toJSON
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Action.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for Action
                         * @function getTypeUrl
                         * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        Action.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action";
                        };

                        Action.Context = (function() {

                            /**
                             * Properties of a Context.
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                             * @interface IContext
                             * @property {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.IContextType|null} [type] Context type
                             * @property {Array.<string>|null} [values] Context values
                             */

                            /**
                             * Constructs a new Context.
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action
                             * @classdesc Represents a Context.
                             * @implements IContext
                             * @constructor
                             * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.IContext=} [properties] Properties to set
                             */
                            function Context(properties) {
                                this.values = [];
                                if (properties)
                                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                        if (properties[keys[i]] != null)
                                            this[keys[i]] = properties[keys[i]];
                            }

                            /**
                             * Context type.
                             * @member {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.IContextType|null|undefined} type
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @instance
                             */
                            Context.prototype.type = null;

                            /**
                             * Context values.
                             * @member {Array.<string>} values
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @instance
                             */
                            Context.prototype.values = $util.emptyArray;

                            /**
                             * Creates a new Context instance using the specified properties.
                             * @function create
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @static
                             * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.IContext=} [properties] Properties to set
                             * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context} Context instance
                             */
                            Context.create = function create(properties) {
                                return new Context(properties);
                            };

                            /**
                             * Encodes the specified Context message. Does not implicitly {@link StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.verify|verify} messages.
                             * @function encode
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @static
                             * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.IContext} message Context message or plain object to encode
                             * @param {$protobuf.Writer} [writer] Writer to encode to
                             * @returns {$protobuf.Writer} Writer
                             */
                            Context.encode = function encode(message, writer) {
                                if (!writer)
                                    writer = $Writer.create();
                                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                                    $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType.encode(message.type, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                if (message.values != null && message.values.length)
                                    for (let i = 0; i < message.values.length; ++i)
                                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.values[i]);
                                return writer;
                            };

                            /**
                             * Encodes the specified Context message, length delimited. Does not implicitly {@link StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.verify|verify} messages.
                             * @function encodeDelimited
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @static
                             * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.IContext} message Context message or plain object to encode
                             * @param {$protobuf.Writer} [writer] Writer to encode to
                             * @returns {$protobuf.Writer} Writer
                             */
                            Context.encodeDelimited = function encodeDelimited(message, writer) {
                                return this.encode(message, writer).ldelim();
                            };

                            /**
                             * Decodes a Context message from the specified reader or buffer.
                             * @function decode
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @static
                             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                             * @param {number} [length] Message length if known beforehand
                             * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context} Context
                             * @throws {Error} If the payload is not a reader or valid buffer
                             * @throws {$protobuf.util.ProtocolError} If required fields are missing
                             */
                            Context.decode = function decode(reader, length) {
                                if (!(reader instanceof $Reader))
                                    reader = $Reader.create(reader);
                                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context();
                                while (reader.pos < end) {
                                    let tag = reader.uint32();
                                    switch (tag >>> 3) {
                                    case 1: {
                                            message.type = $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType.decode(reader, reader.uint32());
                                            break;
                                        }
                                    case 2: {
                                            if (!(message.values && message.values.length))
                                                message.values = [];
                                            message.values.push(reader.string());
                                            break;
                                        }
                                    default:
                                        reader.skipType(tag & 7);
                                        break;
                                    }
                                }
                                return message;
                            };

                            /**
                             * Decodes a Context message from the specified reader or buffer, length delimited.
                             * @function decodeDelimited
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @static
                             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                             * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context} Context
                             * @throws {Error} If the payload is not a reader or valid buffer
                             * @throws {$protobuf.util.ProtocolError} If required fields are missing
                             */
                            Context.decodeDelimited = function decodeDelimited(reader) {
                                if (!(reader instanceof $Reader))
                                    reader = new $Reader(reader);
                                return this.decode(reader, reader.uint32());
                            };

                            /**
                             * Verifies a Context message.
                             * @function verify
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @static
                             * @param {Object.<string,*>} message Plain object to verify
                             * @returns {string|null} `null` if valid, otherwise the reason why it is not
                             */
                            Context.verify = function verify(message) {
                                if (typeof message !== "object" || message === null)
                                    return "object expected";
                                if (message.type != null && message.hasOwnProperty("type")) {
                                    let error = $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType.verify(message.type);
                                    if (error)
                                        return "type." + error;
                                }
                                if (message.values != null && message.hasOwnProperty("values")) {
                                    if (!Array.isArray(message.values))
                                        return "values: array expected";
                                    for (let i = 0; i < message.values.length; ++i)
                                        if (!$util.isString(message.values[i]))
                                            return "values: string[] expected";
                                }
                                return null;
                            };

                            /**
                             * Creates a Context message from a plain object. Also converts values to their respective internal types.
                             * @function fromObject
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @static
                             * @param {Object.<string,*>} object Plain object
                             * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context} Context
                             */
                            Context.fromObject = function fromObject(object) {
                                if (object instanceof $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context)
                                    return object;
                                let message = new $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context();
                                if (object.type != null) {
                                    if (typeof object.type !== "object")
                                        throw TypeError(".StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.type: object expected");
                                    message.type = $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType.fromObject(object.type);
                                }
                                if (object.values) {
                                    if (!Array.isArray(object.values))
                                        throw TypeError(".StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.values: array expected");
                                    message.values = [];
                                    for (let i = 0; i < object.values.length; ++i)
                                        message.values[i] = String(object.values[i]);
                                }
                                return message;
                            };

                            /**
                             * Creates a plain object from a Context message. Also converts values to other types if specified.
                             * @function toObject
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @static
                             * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context} message Context
                             * @param {$protobuf.IConversionOptions} [options] Conversion options
                             * @returns {Object.<string,*>} Plain object
                             */
                            Context.toObject = function toObject(message, options) {
                                if (!options)
                                    options = {};
                                let object = {};
                                if (options.arrays || options.defaults)
                                    object.values = [];
                                if (options.defaults)
                                    object.type = null;
                                if (message.type != null && message.hasOwnProperty("type"))
                                    object.type = $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType.toObject(message.type, options);
                                if (message.values && message.values.length) {
                                    object.values = [];
                                    for (let j = 0; j < message.values.length; ++j)
                                        object.values[j] = message.values[j];
                                }
                                return object;
                            };

                            /**
                             * Converts this Context to JSON.
                             * @function toJSON
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @instance
                             * @returns {Object.<string,*>} JSON object
                             */
                            Context.prototype.toJSON = function toJSON() {
                                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                            };

                            /**
                             * Gets the default type url for Context
                             * @function getTypeUrl
                             * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                             * @static
                             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                             * @returns {string} The default type url
                             */
                            Context.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                                if (typeUrlPrefix === undefined) {
                                    typeUrlPrefix = "type.googleapis.com";
                                }
                                return typeUrlPrefix + "/StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context";
                            };

                            Context.ContextType = (function() {

                                /**
                                 * Properties of a ContextType.
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                                 * @interface IContextType
                                 * @property {string|null} [reference] ContextType reference
                                 */

                                /**
                                 * Constructs a new ContextType.
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context
                                 * @classdesc Represents a ContextType.
                                 * @implements IContextType
                                 * @constructor
                                 * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.IContextType=} [properties] Properties to set
                                 */
                                function ContextType(properties) {
                                    if (properties)
                                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                            if (properties[keys[i]] != null)
                                                this[keys[i]] = properties[keys[i]];
                                }

                                /**
                                 * ContextType reference.
                                 * @member {string} reference
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType
                                 * @instance
                                 */
                                ContextType.prototype.reference = "";

                                /**
                                 * Creates a new ContextType instance using the specified properties.
                                 * @function create
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType
                                 * @static
                                 * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.IContextType=} [properties] Properties to set
                                 * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType} ContextType instance
                                 */
                                ContextType.create = function create(properties) {
                                    return new ContextType(properties);
                                };

                                /**
                                 * Encodes the specified ContextType message. Does not implicitly {@link StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType.verify|verify} messages.
                                 * @function encode
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType
                                 * @static
                                 * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.IContextType} message ContextType message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ContextType.encode = function encode(message, writer) {
                                    if (!writer)
                                        writer = $Writer.create();
                                    if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.reference);
                                    return writer;
                                };

                                /**
                                 * Encodes the specified ContextType message, length delimited. Does not implicitly {@link StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType.verify|verify} messages.
                                 * @function encodeDelimited
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType
                                 * @static
                                 * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.IContextType} message ContextType message or plain object to encode
                                 * @param {$protobuf.Writer} [writer] Writer to encode to
                                 * @returns {$protobuf.Writer} Writer
                                 */
                                ContextType.encodeDelimited = function encodeDelimited(message, writer) {
                                    return this.encode(message, writer).ldelim();
                                };

                                /**
                                 * Decodes a ContextType message from the specified reader or buffer.
                                 * @function decode
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @param {number} [length] Message length if known beforehand
                                 * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType} ContextType
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ContextType.decode = function decode(reader, length) {
                                    if (!(reader instanceof $Reader))
                                        reader = $Reader.create(reader);
                                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType();
                                    while (reader.pos < end) {
                                        let tag = reader.uint32();
                                        switch (tag >>> 3) {
                                        case 1: {
                                                message.reference = reader.string();
                                                break;
                                            }
                                        default:
                                            reader.skipType(tag & 7);
                                            break;
                                        }
                                    }
                                    return message;
                                };

                                /**
                                 * Decodes a ContextType message from the specified reader or buffer, length delimited.
                                 * @function decodeDelimited
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType
                                 * @static
                                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                                 * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType} ContextType
                                 * @throws {Error} If the payload is not a reader or valid buffer
                                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                                 */
                                ContextType.decodeDelimited = function decodeDelimited(reader) {
                                    if (!(reader instanceof $Reader))
                                        reader = new $Reader(reader);
                                    return this.decode(reader, reader.uint32());
                                };

                                /**
                                 * Verifies a ContextType message.
                                 * @function verify
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType
                                 * @static
                                 * @param {Object.<string,*>} message Plain object to verify
                                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                                 */
                                ContextType.verify = function verify(message) {
                                    if (typeof message !== "object" || message === null)
                                        return "object expected";
                                    if (message.reference != null && message.hasOwnProperty("reference"))
                                        if (!$util.isString(message.reference))
                                            return "reference: string expected";
                                    return null;
                                };

                                /**
                                 * Creates a ContextType message from a plain object. Also converts values to their respective internal types.
                                 * @function fromObject
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType
                                 * @static
                                 * @param {Object.<string,*>} object Plain object
                                 * @returns {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType} ContextType
                                 */
                                ContextType.fromObject = function fromObject(object) {
                                    if (object instanceof $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType)
                                        return object;
                                    let message = new $root.StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType();
                                    if (object.reference != null)
                                        message.reference = String(object.reference);
                                    return message;
                                };

                                /**
                                 * Creates a plain object from a ContextType message. Also converts values to other types if specified.
                                 * @function toObject
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType
                                 * @static
                                 * @param {StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType} message ContextType
                                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                                 * @returns {Object.<string,*>} Plain object
                                 */
                                ContextType.toObject = function toObject(message, options) {
                                    if (!options)
                                        options = {};
                                    let object = {};
                                    if (options.defaults)
                                        object.reference = "";
                                    if (message.reference != null && message.hasOwnProperty("reference"))
                                        object.reference = message.reference;
                                    return object;
                                };

                                /**
                                 * Converts this ContextType to JSON.
                                 * @function toJSON
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType
                                 * @instance
                                 * @returns {Object.<string,*>} JSON object
                                 */
                                ContextType.prototype.toJSON = function toJSON() {
                                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                                };

                                /**
                                 * Gets the default type url for ContextType
                                 * @function getTypeUrl
                                 * @memberof StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType
                                 * @static
                                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                                 * @returns {string} The default type url
                                 */
                                ContextType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                                    if (typeUrlPrefix === undefined) {
                                        typeUrlPrefix = "type.googleapis.com";
                                    }
                                    return typeUrlPrefix + "/StoneCode.ApiExtensionBundle.Rpc.Command.GetObjectActionsResponse.Action.Context.ContextType";
                                };

                                return ContextType;
                            })();

                            return Context;
                        })();

                        return Action;
                    })();

                    return GetObjectActionsResponse;
                })();

                return Command;
            })();

            return Rpc;
        })();

        return ApiExtensionBundle;
    })();

    return StoneCode;
})();

export { $root as default };
