/* eslint-disable @typescript-eslint/no-explicit-any */

import { Model }        from '@mathquis/modelx/';
import { blobToBase64 } from 'tools/blobTools';

type Constructor = new (...args: any[]) => Model;

function withBlob<TBase extends Constructor>(Base: TBase) {
	return class extends Base {

		public _base64: string | ArrayBuffer | null = '';

		private _lockBLobToBase64 = false;

		public get blob(): Blob {
			this._lockBLobToBase64 = false;

			return this.get('blob');
		}

		public get base64(): string | ArrayBuffer | null {
			if (!this._lockBLobToBase64 && this.blob && !this._base64) {
				this._lockBLobToBase64 = true;

				blobToBase64(this.blob, base64 => this.setBase64(base64));
			}

			return this._base64;
		}

		public get src(): string {
			return this.base64?.toString() || '';
		}

		public fetch(options?: Record<string, unknown>): Promise<this> {
			return super.fetch({ responseType: 'blob', ...options });
		}


		public setBase64(base64: string | ArrayBuffer | null) {
			this._base64 = base64;
			this._lockBLobToBase64 = false;
		}

		protected transform(blob: Blob): Record<string, unknown> {
			return { blob };
		}
	};
}

export default withBlob;