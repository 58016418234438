import { resolvable }       from '@mathquis/modelx-resolvables';
import ContractModel        from 'Models/sales/ContractModel';
import { urn }              from 'helpers/ModelDictionary';
import { Blamable }         from 'helpers/traits';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'contract_deposit_rule')
export default class ContractDepositRuleModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	static get path(): string {
		return '/contract_deposit_rules/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'contract': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	public get amount(): number {
		return this.get('amount', 0);
	}

	public get dayOfMonth(): number {
		return this.get('dayOfMonth', 0);
	}

	@resolvable(ContractModel, { attributeName: 'contract' })
	public declare contract: ContractModel;

}