import { urn }           from 'helpers/ModelDictionary';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

@urn('$', 'rh', 'staff_member_photo')
export default class StaffMemberPhotoModel extends RhPrivateApiModel {

	static get path(): string {
		return '/staff_member_photos/{?id}';
	}

	public get contentUrl(): string {
		return this.get('contentUrl', '');
	}

	public get file(): string {
		return this.get('file', '');
	}

	public get fileMimeType(): string {
		return this.get('fileMimeType', '');
	}

	public get fileOriginalName(): string {
		return this.get('fileOriginalName', '');
	}

	public get filePath(): string {
		return this.get('filePath', '');
	}

	public get fileSize(): number {
		return this.get('fileSize', 0);
	}

	public get folder(): string {
		return this.get('folder', '');
	}
}