import { urn }              from 'helpers/ModelDictionary';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export type ContractIterationStatusReference =
	'CONTRACT_ITERATION_STATUS_BEING_CREATED' |
	'CONTRACT_ITERATION_TO_QUOTE' |
	'CONTRACT_ITERATION_STATUS_QUOTED' |
	'CONTRACT_ITERATION_STATUS_CANCELED' |
	'CONTRACT_ITERATION_STATUS_ACCEPTED' |
	'CONTRACT_ITERATION_STATUS_TO_SIGN' |
	'CONTRACT_ITERATION_STATUS_ACTIVATED' |
	'CONTRACT_ITERATION_STATUS_FOR_RECONDUCTION' |
	'CONTRACT_ITERATION_STATUS_COMPLETED';

@urn('$', 'sales', 'contract_iteration_status')
export default class ContractIterationStatusModel extends SalesPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'reference': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'position': string;
	}> = {};

	public static cacheDuration =3600;

	static get path(): string {
		return '/contract_iteration_statuses/{?id}';
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): ContractIterationStatusReference {
		return this.get('reference', '');
	}

	public get color() {
		switch (this.reference) {
			case 'CONTRACT_ITERATION_STATUS_BEING_CREATED' :
				return 'orange';
			case 'CONTRACT_ITERATION_TO_QUOTE' :
				return 'orange';
			case 'CONTRACT_ITERATION_STATUS_QUOTED' :
				return 'yellow';
			case 'CONTRACT_ITERATION_STATUS_CANCELED' :
				return 'black';
			case 'CONTRACT_ITERATION_STATUS_ACCEPTED' :
				return 'blue';
			case 'CONTRACT_ITERATION_STATUS_TO_SIGN' :
				return 'red';
			case 'CONTRACT_ITERATION_STATUS_ACTIVATED' :
				return 'green';
			case 'CONTRACT_ITERATION_STATUS_FOR_RECONDUCTION' :
				return 'purple';
			case 'CONTRACT_ITERATION_STATUS_COMPLETED':
				return 'maroon';
		}

		return 'grey';
	}
}