import { urn }                from 'helpers/ModelDictionary';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type PaymentTypeReference = 'in' | 'out';

@urn('$', 'invoice', 'payment_type')
export default class PaymentTypeModel extends InvoicePrivateApiModel {

	static get path(): string {
		return '/payment_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': PaymentTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	public static cacheDuration = 3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): PaymentTypeReference {
		return this.get('reference', '');
	}
}