import doc                  from 'decorators/doc';
import model                 from 'decorators/model';
import { Blamable }        from 'helpers/traits';
import { Timestampable }   from 'helpers/traits';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

@model.staticLabel('Type')
@model.urn('$', 'time', 'log_type')
@doc.path('/log_types/{?id}')
export default class LogTypeModel extends Blamable(Timestampable(TimePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'label': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	public static cacheDuration = 3600;

	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare reference: LogTypeReference;
}