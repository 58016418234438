import React       from 'react';
import LoginScreen from './components/LoginScreen';

class LoginPage extends React.Component<INavigationProps> {
	render() {
		const { history, location } = this.props;

		return <LoginScreen history={history} location={location} />;
	}
}

export default LoginPage;
