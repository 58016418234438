import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.cacheDuration(3600)
@model.staticLabel('DepositStatus')
@model.urnResource('deposit_status')
@doc.path('/deposit_statuses/{?id}')
export default class DepositStatusModel extends InvoicePrivateApiModel {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: InvoiceDepositStatusReference;

	public get color() {
		switch (this.reference) {
			case 'to_pay':
				return 'red';
			case 'partially_paid':
				return 'orange';
			case 'paid':
				return 'green';
			default:
				return 'grey';
		}
	}
}
