import StaffMemberDocumentReadModel from 'Models/rh/StaffMemberDocumentReadModel';
import {
	Blamable,
	Timestampable,
}                                   from 'helpers/traits';
import { computed }                 from 'mobx';
import RhPrivateApiModel            from '../../abstracts/RhPrivateApiModel';
import withBlob                     from '../../mixins/withBlob';

export default class StaffMemberDocumentModel extends Blamable(Timestampable(RhPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{ 'staffMember': id; }> = {};

	static get path(): string {
		return '/staff_member_documents/{?id}';
	}

	private _readModel = new (withBlob(StaffMemberDocumentReadModel))();

	@computed
	public get fileOriginalName(): string {
		return this.get('fileOriginalName', '');
	}

	@computed
	public get fileMimeType(): string {
		return this.get('fileMimeType', '');
	}

	@computed
	public get staffMemberDocumentTypeId(): number {
		return this.get('staffMemberDocumentType.id', 0);
	}

	public get readModel() {
		this._readModel.setId(this.id);

		return this._readModel;
	}
}
