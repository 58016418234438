import PartitionModel             from 'Models/partition/PartitionModel';
import SelectCollection           from 'components/SelectCollection';
import { ISelectCollectionProps } from 'components/SelectCollection';
import React                      from 'react';
import { appStore }               from 'stores';

export default (props: Partial<ISelectCollectionProps<PartitionModel>>) => (
	<SelectCollection
		allowClear={true}
		collection={appStore.partitionCollection}
		modelClass={PartitionModel}
		placeholder="Agence"
		renderOption={m => m.name}
		sortName={'position'}

		{...props}
	/>
);