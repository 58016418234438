import { WebStorageConnector } from '@mathquis/modelx-webstorage-connector';
import { Connector, Model }    from '@mathquis/modelx';

export const localStorageConnector = new WebStorageConnector({
	storage: localStorage,
});

export default class LocalStorageModel extends Model {
	public static get connector() {
		return localStorageConnector as unknown as Connector;
	}
}
