import OperationModel      from 'Models/intervention/OperationModel';
import AbstractModelXStore from 'stores/AbstractModelXStore';

export default class OperationDashboardStore extends AbstractModelXStore {

	public operation = new OperationModel();

	public async fetchAsync(id: id) {
		this.clear();

		await this.operation.setId(id).fetch();
	}
}