import { resolvable }    from '@mathquis/modelx-resolvables';
import LeaveTypeModel    from 'Models/rh/LeaveTypeModel';
import StaffMemberModel  from 'Models/rh/StaffMemberModel';
import { urn }           from 'helpers/ModelDictionary';
import { Blamable }      from 'helpers/traits';
import { Timestampable } from 'helpers/traits';
import { computed }      from 'mobx';
import moment            from 'moment';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

@urn('$', 'rh', 'staff_member_leave')
export default class StaffMemberLeaveModel extends Blamable(Timestampable(RhPrivateApiModel)) {

	static get path(): string {
		return '/staff_member_leaves/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'staffMember': id;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'endDate': string;
		'id': string;
		'startDate': string;
	}> = {};

	@computed
	public get endDate(): Moment {
		return moment(this.get('endDate', moment()));
	}

	public get nbDaysPaid(): string {
		return this.get('nbDaysPaid', '');
	}

	public get staffMemberId(): id {
		return this.get('staffMember.id', 0);
	}

	public get leaveTypeIri(): string {
		return this.get('leaveType.@id', '');
	}

	@computed
	public get startDate(): Moment {
		return moment(this.get('startDate', moment()));
	}

	@resolvable(LeaveTypeModel, { attributeName: 'leaveType', cache: true })
	public declare leaveType: LeaveTypeModel;

	@resolvable(StaffMemberModel, { attributeName: 'staffMember' })
	public declare staffMember: StaffMemberModel;
}