import { SortWaySet }   from 'Collections/AbstractApiCollection';
import PagedCollection  from 'Collections/PagedCollection';
import ModelDictionary  from 'helpers/ModelDictionary';
import _camelCase       from 'lodash/camelCase';
import _uniqueId        from 'lodash/uniqueId';
import { when }         from 'mobx';
import { observable }   from 'mobx';
import { computed }     from 'mobx';
import { action }       from 'mobx';
import AbstractApiModel from 'modelx/models/abstracts/AbstractApiModel';
import findAsync        from 'tools/findAsync';
import { ucfirst }      from 'tools/jsTools';

export default <T extends AbstractApiModel, D = AbstractApiModel>(p: {
	filterId?: (m: D) => (Promise<boolean> | boolean); // Condition pour l'utilisation de l'id/urn dans la requête
	filters?: ModelFilters<T> | ((models: D[]) => ModelFilters<T>);
	mapping?: (m1: D, m2: T) => Promise<boolean>;
	multipleRequests?: boolean; // Alias inversé de singleRequest
	singleRequest?: boolean; // Default true
	sortName?: ModelSortName<T>,
	sortWay?: SortWaySet,
	urn: string;
	useUrn?: boolean; // Par défaut, on utilise l'id pour filtrer
}) => {
	return function (target, propertyKey: string) {
		const uid = `reverse_resolvable_collection_${_uniqueId()}_${target.modelName}`;
		const privateName = `${propertyKey}_private`;
		const privateNameSet = `${propertyKey}_private_set`;
		const sortName = p.sortName || 'id';
		const sortWay = p.sortWay || 'asc';
		let singleRequest = typeof p.singleRequest === 'undefined' || p.singleRequest;

		if (p.multipleRequests) {
			singleRequest = false;
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [_, service, importName] = p.urn.split(':');
		import(`../modelx/models/private/${service}/${ucfirst(_camelCase(importName))}Model.ts`);

		const getter = function () {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const self = this as typeof D;

			if (!self[privateName]) {
				const modelClass = ModelDictionary.get(p.urn);
				let filterName = _camelCase(self.urn.split(':')[2] || '') as ModelFilterName<never>;
				if (p.useUrn) {
					filterName = filterName.toString() + 'Urn';
				}

				if (singleRequest && self.collection) {
					if (!self.collection[uid]) {
						self.collection[uid] = new PagedCollection(modelClass);

						const superList = self.collection.list.bind(self.collection);
						self.collection.list = (options) => {
							if (self.collection) {
								self.collection[uid] = new PagedCollection(modelClass);
							}
							return superList(options);
						};
					}

					if (!self[privateName]) {
						self[privateName] = new modelClass({}, { collection: self.collection[uid] });
						self[privateName].collection = self.collection[uid];
					}

					setTimeout(async () => {
						let filterIdResult: boolean[] = [];
						if (self.collection && !self.collection[uid].isLoading && !self.collection[uid].isLoaded) {
							filterIdResult = self.collection.map(() => true);

							if (p.filterId) {
								filterIdResult = await Promise.all(
									self.collection.map(m => !!p.filterId && p.filterId(m)),
								);
							}
						}

						if (self.collection && !self.collection[uid].isLoading && !self.collection[uid].isLoaded) {
							const models = self.collection.models as unknown as D[];

							const filterValues = self.collection
								.filter((m, k) => filterIdResult[k])
								.map(m => p.useUrn ? m.urn : m.id);

							await self.collection[uid]
								.setSort(sortName, sortWay)
								.setFilters(typeof p.filters === 'function' ? p.filters(models) : p.filters)
								.setItemsPerPage(self.collection.length)
								.listBy(filterValues, filterName);

							const mappingProperty = p.useUrn ?
								m => m.getUrn(filterName.toString().replace('Urn', '')) :
								m => m.getId(filterName.toString());

							self.collection[uid].forEach(res => {
								(async () => {
									const models = self.collection?.models as unknown as D[];
									const sourceModel = p.mapping ?
										await findAsync(models, async m => !!p.mapping && p.mapping(m, res)) :
										models.find(m => m[p.useUrn ? 'urn' : 'id'] === mappingProperty(res));
									if (sourceModel) {
										console.log(
											`%creverse-resolvable success mapping `
											+ `${sourceModel['urnData']['resource']} "${sourceModel['id']}" `
											+ `with `
											+ `${res['urnData']['resource']} "${res['id']}" `
											+ `from ${p.useUrn ? 'URN' : 'ID'}`,
											'color: green',
										);
										sourceModel[privateNameSet](sourceModel, res);
									} else {
										console.error(`reverse-resolvable mapping error ${privateNameSet}`);
									}
								})();
							});
						}
					});
				} else {
					if (!self[uid]) {
						self[uid] = new PagedCollection(modelClass);
						const superFetch = self.fetch.bind(self);
						self.fetch = (options) => {
							self[uid] = new PagedCollection(modelClass);
							return superFetch(options);
						};
					}

					if (!self[privateName]) {
						self[privateName] = new modelClass({});
					}

					const load = async () => {
						if (self.isLoaded && !self[uid].isLoading && !self[uid].isLoaded) {
							const isIdOk = !p.filterId || (await p.filterId(self));
							const filterValues = isIdOk ? [p.useUrn ? self.urn : self.id] : [];

							await self[uid]
								.setSort(sortName, sortWay)
								.setFilters(typeof p.filters === 'function' ? p.filters([self]) : p.filters)
								.setItemsPerPage(1)
								.listBy(filterValues, filterName);

							const first = self[uid].first();

							if (first) {
								first.collection = null;
								self[privateNameSet](self, self[uid].first());
							}
						}
					};

					when(() => self.isLoaded, load);
				}
			}

			return self[privateName];
		};

		Object.defineProperty(target, privateName, {});
		observable(target, privateName);

		Object.defineProperty(target, propertyKey, { get: getter });
		computed(target, propertyKey);

		Object.defineProperty(target, privateNameSet, {
			configurable: true,
			value: (source, res) => {
				if (res) {
					source[privateName] = res;
					res.setIsLoaded(true);
				}
			},
		});
		action(target, privateNameSet);
	};
};