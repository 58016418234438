import { MenuFoldOutlined } from '@ant-design/icons';
import AntdLayout           from 'antd/lib/layout';
import Spin                 from 'antd/lib/spin';
import { observer }         from 'mobx-react';
import { renderRoutes }     from 'react-router-config';
import { Link }             from 'react-router-dom';
import React                from 'react';
import privateRoutes        from 'routes/privates';
import SelectPartition      from 'selects/SelectPartition';
import { appStore }         from 'stores';
import isSettingsPage       from 'tools/isSettingsPage';
import Breadcrumb           from './Breadcrumb';
import AppUserMenu          from './components/AppUserMenu';
import Navigation           from './components/Navigation';
import './css/AppName.scss';

@observer
export default class Layout extends React.Component<{ route: RouteType } & INavigationProps> {

	public state = { isNotSettings: true, mainMenuCollapsed: true };

	public componentDidMount() {
		this._refreshIsSettings();
	}

	public componentDidUpdate() {
		this._refreshIsSettings();
	}

	public render() {
		const { Content, Header, Sider } = AntdLayout;
		const { isNotSettings, mainMenuCollapsed } = this.state;
		const { history, location } = this.props;

		return (
			<div
				className={`app-container ${appStore.partition?.urn} ${isNotSettings ? '' : 'settings'}`}
				key={appStore.partitionUrn}
			>
				<AntdLayout className="app-main">
					<Sider className="app-side-menu" collapsed={mainMenuCollapsed} collapsedWidth={70} width={300}>
						{!mainMenuCollapsed && (
							<div className="side-menu-focus" onClick={this._toggleMainMenu} />
						)}

						<div className="menu-toggle-trigger" onClick={this._toggleMainMenu}>
							<div className="app-name">
								{appStore.partition?.name}
							</div>

							<MenuFoldOutlined className="Layout__MenuIcon" />
						</div>

						<Navigation location={location} />
					</Sider>

					<div className="app-body">
						<div className="app-header">
							<AntdLayout className="app-header-layout">
								<Header className="app-header-div">
									<Link to="/">
										<div className="app-name">
											{/*<img*/}
											{/*	alt={`logo ${appStore.partition?.name}`}*/}
											{/*	src={getLogo(isNotSettings ?*/}
											{/*		`Placidea-Logo-Fond-Fonce.png` :*/}
											{/*		'Placidea-Logo-Blanc.png',*/}
											{/*	)}*/}
											{/*	style={{ height: 42 }}*/}
											{/*/>*/}
										</div>
									</Link>

									{isNotSettings ? (
										appStore.partitionUrn ? (
											<SelectPartition
												allowClear={false}
												onChange={id => {
													appStore.setPartitionId(id);

													history.push('/');
												}}
												showSearch={false}
												style={{ minWidth: 200 }}
												value={appStore.partition?.id?.toString()}
											/>
										) : null
									) : (
										<span style={{ fontSize: 18, whiteSpace: 'nowrap' }}>
											{`Configuration générale de l'application, toutes agences`}
										</span>
									)}

									<div className="app-right">
										<AppUserMenu />
									</div>
								</Header>
							</AntdLayout>
						</div>

						<AntdLayout className="page-content-wrapper">
							<Content className="page-content">
								{appStore.partitionUrn ? (
									<>
										<Breadcrumb {...this.props} />

										<React.Suspense fallback={null}>
											{renderRoutes(privateRoutes)}
										</React.Suspense>
									</>
								) : (
									<Spin className="app-centered-spin" size="large" />
								)}
							</Content>
						</AntdLayout>
					</div>
				</AntdLayout>
			</div>
		);
	}

	private _refreshIsSettings() {
		const isNotSettings = !isSettingsPage();

		if (isNotSettings !== this.state.isNotSettings) {
			this.setState({ isNotSettings: isNotSettings });
		}
	}

	private _toggleMainMenu = () => this.setState({ mainMenuCollapsed: !this.state.mainMenuCollapsed });
}
