import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type TaskCancellationTypeReference =
	'suspension'
	| 'cancellation_by_period'
	| 'cancellation_by_task'
	| 'closing_notice';

@urn('$', 'intervention', 'task_cancellation_type')
export default class TaskCancellationTypeModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/task_cancellation_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': TaskCancellationTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	public static cacheDuration = 3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get position(): number {
		return this.get('position', 0);
	}

	public get priority(): number {
		return this.get('priority', 0);
	}

	public get reference(): TaskCancellationTypeReference {
		return this.get('reference', '');
	}
}