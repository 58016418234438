/* eslint-disable */
import { DownOutlined } from '@ant-design/icons';
import { UpOutlined }   from '@ant-design/icons';
import Card             from 'antd/lib/card';
import { CardProps }    from 'antd/lib/card';
import Spin             from 'antd/lib/spin';
import View             from 'components/View';
import { matchPath }    from 'react-router-dom';
import React            from 'react';
import routes           from 'routes/privates';
import ReactDomServer   from 'react-dom/server';
import './DashboardBox.scss';

type DashboardBoxProps = {
	cardProps?: CardProps;
	children: React.ReactNode;
	collapsable?: boolean;
	defaultCollapsed?: boolean;
	leftComponent?: React.ReactElement;
	loading?: boolean;
	noHead?: boolean;
	noPadding?: boolean;
	noShadow?: boolean;
	title?: React.ReactNode;
	topRightComponent?: React.ReactElement;
	width?: number | string;
};

export const COLLAPSED_BOXES_STORAGE_KEY = 'COLLAPSED_BOXES';

export default (props: DashboardBoxProps) => {
	const {
		cardProps,
		children,
		defaultCollapsed,
		leftComponent,
		loading,
		noHead,
		noPadding,
		noShadow,
		title,
		topRightComponent,
		width,
	} = props;

	const collapsable = typeof props.collapsable === 'undefined' || props.collapsable;
	const [collapsed, setCollapsed] = React.useState(!!defaultCollapsed);
	const storageKey = React.useRef('');

	React.useEffect(() => {
		const currentRoute = routes.find(route => matchPath(location.pathname, route));
		// @ts-ignore
		storageKey.current = `${currentRoute?.path || location.pathname}_${ReactDomServer.renderToString(title || '')}`;
		const storage = JSON.parse(localStorage.getItem(COLLAPSED_BOXES_STORAGE_KEY) || '{}');
		setCollapsed(!!storage[storageKey.current]);
	}, []);

	const classNames = [
		'DashboardBox',
		collapsed ? 'collapsed' : '',
		(noPadding || collapsed) ? 'no-padding' : '',
		noShadow ? 'no-shadow' : '',
		noHead ? 'no-head' : '',
	];

	const toggleCollapse = () => {
		setCollapsed(!collapsed);

		const storage = JSON.parse(localStorage.getItem(COLLAPSED_BOXES_STORAGE_KEY) || '{}');

		if (collapsed) {
			delete storage[storageKey.current];
		} else {
			storage[storageKey.current] = true;
		}

		localStorage.setItem(COLLAPSED_BOXES_STORAGE_KEY, JSON.stringify(storage));
	};

	return (
		<Card
			className={classNames.join(' ')}
			extra={<div className="DashboardBox__extra">{topRightComponent}</div>}
			style={width ? { width } : {}}
			title={title ? (
				<View
					centerV
					color="rgba(0, 0, 0, .4)"
					gap={10}
					onClick={collapsable ? toggleCollapse.bind(this) : undefined}
					pointer={collapsable}
					row
				>
					{!!leftComponent && leftComponent}

					<div className="DashboardBox__titleTitle">{title}</div>

					{collapsable && <View marginR={10} size={12}>{collapsed ? <DownOutlined /> : <UpOutlined />}</View>}
				</View>
			) : undefined}
			{...cardProps}
		>
			{(collapsed ? undefined : (
				loading ? <Spin style={{ padding: 40, width: '100%' }} /> : <div className="fade-in">{children}</div>
			))}
		</Card>
	);
};