import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type ExportTypeReference =
	'clients_plannings_zip'
	| 'intervention_time_log_csv'
	| 'intervention_version_resource_csv'
	| 'staff_members_plannings_zip';

export default class ExportTypeModel extends InterventionPrivateApiModel {
	static get path(): string {
		return '/export_types/{?id}';
	}

	public static cacheDuration = 3600;

	@computed
	public get reference(): ExportTypeReference {
		return this.get('reference', '');
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}
}
