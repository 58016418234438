import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type InterventionResourceStatusReference = 'in' | 'out';

@urn('$', 'intervention', 'intervention_resource_status')
export default class InterventionResourceStatusModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/intervention_resource_statuses/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': InterventionResourceStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
		'reference': string;
	}> = {};

	public get label(): string {
		return this.get('label', '');
	}

	public get position(): number {
		return this.get('position', 0);
	}

	public get reference(): InterventionResourceStatusReference {
		return this.get('reference', '');
	}
}