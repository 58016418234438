import { resolvable }         from '@mathquis/modelx-resolvables';
import VehicleModel           from 'Models/vehicle/VehicleModel';
import { urn }                from 'helpers/ModelDictionary';
import { computed }           from 'mobx';
import moment                 from 'moment';
import {
	Blamable,
	Timestampable,
} from '../../../../helpers/traits';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@urn('$', 'vehicle', 'vehicleMileage')
export default class VehicleMileageModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'vehicle': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'datetime': string;
		'id': string;
		'updatedAt': string;
		'value': string;
	}> = {};

	static get path(): string {
		return '/vehicle_mileages/{?id}';
	}

	@resolvable(VehicleModel, { attributeName: 'vehicle' })
	public declare vehicle: VehicleModel;

	@computed
	public get datetime(): Moment {
		return moment(this.get('datetime', moment()));
	}

	public get value(): number {
		return this.get('value', 0);
	}

}