import lazyWithRetry from 'tools/lazyWithRetry';

const InterventionVersionListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "interventionVersionListPage" */
	`../../pages/InterventionVersion/InterventionVersionListPage`));

const InterventionVersionDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "interventionVersionDashboardPage" */
	`../../pages/InterventionVersion/InterventionVersionDashboardPage`));

const InterventionVersionStatisticsPage = lazyWithRetry(() => import(
	/* webpackChunkName: "interventionVersionStatisticsPage" */
	'../../pages/InterventionVersion/InterventionVersionStatisticsPage'));

const InterventionVersionTimeLogListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "interventionVersionTimeLogListPage" */
	`../../pages/InterventionVersion/InterventionVersionTimeLogListPage`));

export default [
	{
		component: InterventionVersionListPage,
		exact: true,
		path: '/partitions/:partitionId/intervention_versions',
	},
	{
		component: InterventionVersionStatisticsPage,
		exact: true,
		path: '/partitions/:partitionId/intervention_version_statistics',
	},
	{
		component: InterventionVersionDashboardPage,
		exact: true,
		path: '/partitions/:partitionId/intervention_versions/:interventionVersionId',
	},
	{
		component: InterventionVersionTimeLogListPage,
		exact: true,
		path: '/partitions/:partitionId/intervention_version_time_logs',
	},
];