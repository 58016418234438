import * as Sentry                       from '@sentry/browser';
import { COLLAPSED_BOXES_STORAGE_KEY }   from 'components/DashboardBox/DashboardBox';
import { HIDDEN_COLUMNS_STORAGE_KEY }    from 'components/ListCollection';
import FilterManager                     from 'helpers/FilterManager';
import ModelCache                        from 'helpers/ModelCache';
import { reaction }                      from 'mobx';
import AbstractModelXStore               from 'stores/AbstractModelXStore';
import ActivityDashboardStore            from 'stores/ActivityDashboardStore';
import BillableDashboardStore            from 'stores/BillableDashboardStore';
import BillingPeriodDashboardStore       from 'stores/BillingPeriodDashboardStore';
import ClientDashboardStore              from 'stores/ClientDashboardStore';
import CompanyDashboardStore             from 'stores/CompanyDashboardStore';
import ContactDashboardStore             from 'stores/ContactDashboardStore';
import ContractDashboardStore            from 'stores/ContractDashboardStore';
import ContractIterationDashboardStore   from 'stores/ContractIterationDashboardStore';
import DepositDashboardStore             from 'stores/DepositDashboardStore';
import InterventionVersionDashboardStore from 'stores/InterventionVersionDashboardStore';
import InvoiceDashboardStore             from 'stores/InvoiceDashboardStore';
import OperationDashboardStore           from 'stores/OperationDashboardStore';
import PartitionDashboardStore           from 'stores/PartitionDashboardStore';
import PartitionGroupDashboardStore      from 'stores/PartitionGroupDashboardStore';
import PaymentDashboardStore             from 'stores/PaymentDashboardStore';
import PlanDashboardStore                from 'stores/PlanDashboardStore';
import PlanningClientStore               from 'stores/PlanningClientStore';
import PlanningStaffStore                from 'stores/PlanningStaffStore';
import StaffMemberDashboardStore         from 'stores/StaffMemberDashboardStore';
import StaffMemberUserDashboardStore     from 'stores/StaffMemberUserDashboardStore';
import VehicleDashboardStore             from 'stores/VehicleDashboardStore';
import browserHistory                    from 'tools/browserHistory';
import notificationApiError              from 'tools/notificationApiError';
import AppStore                          from './AppStore';
import AuthenticationStore               from './AuthenticationStore';

export const appStore = new AppStore();
export const planDashboardStore = new PlanDashboardStore();
export const authenticationStore = new AuthenticationStore();
export const clientDashboardStore = new ClientDashboardStore();
export const companyDashboardStore = new CompanyDashboardStore();
export const contactDashboardStore = new ContactDashboardStore();
export const partitionDashboardStore = new PartitionDashboardStore();
export const staffMemberDashboardStore = new StaffMemberDashboardStore();
export const staffMemberUserDashboardStore = new StaffMemberUserDashboardStore();
export const vehicleDashboardStore = new VehicleDashboardStore();
export const billingPeriodDashboardStore = new BillingPeriodDashboardStore();
export const contractDashboardStore = new ContractDashboardStore();
export const depositDashboardStore = new DepositDashboardStore();
export const contractIterationDashboardStore = new ContractIterationDashboardStore();
export const activityDashboardStore = new ActivityDashboardStore();
export const operationDashboardStore = new OperationDashboardStore();
export const planningStaffStore = new PlanningStaffStore();
export const planningClientStore = new PlanningClientStore();
export const interventionVersionDashboardStore = new InterventionVersionDashboardStore();
export const invoiceDashboardStore = new InvoiceDashboardStore();
export const partitionGroupDashboardStore = new PartitionGroupDashboardStore();
export const billableDashboardStore = new BillableDashboardStore();
export const paymentDashboardStore = new PaymentDashboardStore();

const dashboardStores = {
	activityDashboardStore,
	billableDashboardStore,
	billingPeriodDashboardStore,
	clientDashboardStore,
	companyDashboardStore,
	contactDashboardStore,
	contractDashboardStore,
	contractIterationDashboardStore,
	depositDashboardStore,
	interventionVersionDashboardStore,
	invoiceDashboardStore,
	operationDashboardStore,
	partitionDashboardStore,
	partitionGroupDashboardStore,
	paymentDashboardStore,
	planDashboardStore,
	staffMemberDashboardStore,
	staffMemberUserDashboardStore,
	vehicleDashboardStore,
};

const stores = {
	appStore,
	authenticationStore,
	planningClientStore,
	planningStaffStore,

	...dashboardStores,
};

const clearStores = (s: Record<string, AbstractModelXStore> = stores) =>
	Object.values(s).forEach((store) => store.clear());

const onAuthenticatedAsync = async () => {
	try {
		Sentry.setUser({
			id: authenticationStore.session.ownerId?.toString(),
			username: authenticationStore.session.ownerUrn,
		});

		await Promise.all([
			appStore.load(),
		]);
	} catch (err) {
		notificationApiError(err);
	}
};

const onLogout = () => {
	clearStores();
	ModelCache.destroy();
	FilterManager.destroy();
	localStorage.removeItem(HIDDEN_COLUMNS_STORAGE_KEY);
	localStorage.removeItem(COLLAPSED_BOXES_STORAGE_KEY);
	Sentry.setUser({});
};

// Lorsqu'un utilisateur se connecte ou se déconnecte
reaction(
	() => authenticationStore.isAuthenticated,
	() => authenticationStore.isAuthenticated ? onAuthenticatedAsync() : onLogout(),
);

// Initialisation au premier chargement
Promise.all([authenticationStore.session.fetch()])
	.catch(() => null)
	.then(() => authenticationStore.onLoginSuccess())
	.catch(() => null)
	.finally(() => authenticationStore.setIsReady(true));

// Reinitialisation des stores de dashboard lorsque l'url change
let lastLocationPathname = window.location.pathname;
browserHistory.listen(location => {
	if (location.pathname !== lastLocationPathname) {
		lastLocationPathname = location.pathname;
		clearStores(dashboardStores);
	}
});

export default stores;
