import PartitionModel                 from 'Models/partition/PartitionModel';
import LeaveTypeModel                 from 'Models/rh/LeaveTypeModel';
import PartitionGroupModel            from 'Models/rh/PartitionGroupModel';
import StaffMemberAddressModel        from 'Models/rh/StaffMemberAddressModel';
import StaffMemberContractModel       from 'Models/rh/StaffMemberContractModel';
import StaffMemberModel               from 'Models/rh/StaffMemberModel';
import StaffMemberPartitionGroupModel from 'Models/rh/StaffMemberPartitionGroupModel';
import { computed }                   from 'mobx';
import { ApiCollection }              from 'modelx/collections/ApiCollection';
import AbstractModelXStore            from './AbstractModelXStore';

export default class StaffMemberUserDashboardStore extends AbstractModelXStore {

	public leaveTypeCollection = new ApiCollection(LeaveTypeModel);
	public partitionCollection = new ApiCollection(PartitionModel);
	public partitionGroupCollection = new ApiCollection(PartitionGroupModel);
	public staffMember = new StaffMemberModel();
	public staffMemberAddressCollection = new ApiCollection(StaffMemberAddressModel);
	public staffMemberContractCollection = new ApiCollection(StaffMemberContractModel);
	public staffMemberPartitionGroupCollection = new ApiCollection(StaffMemberPartitionGroupModel);

	public async initAsync(staffMemberId: id) {
		this.clear();

		await Promise.all([
			this.leaveTypeCollection.list(),

			this.staffMember
				.clear()
				.setId(staffMemberId)
				.fetch(),
			
			this.staffMemberAddressCollection
				.setFilter('staffMember', staffMemberId)
				.list(),

			this.staffMemberContractCollection
				.setFilter('staffMember', staffMemberId)
				.list(),

			this.listStaffMemberPartitionGroupCollection(staffMemberId),
		]);
	}

	public listStaffMemberPartitionGroupCollection = async (staffMemberId) => {
		await this.staffMemberPartitionGroupCollection
			.setFilter('staffMember', staffMemberId)
			.list()
			.then(async() => {
				await this.partitionGroupCollection.listByFromCollection(this.staffMemberPartitionGroupCollection, 'partitionGroupId', 'id');
				await this.partitionCollection.listByFromCollection(this.staffMemberPartitionGroupCollection, 'partitionId', 'id');
			});
	};

	@computed
	public get staffMemberAddress() {
		return this.staffMemberAddressCollection.first();
	}

	@computed
	public get isOut() {
		return this.staffMember.isOut;
	}
}
