import AntTable, { TableProps } from 'antd/lib/table';
import React          from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ITableProps extends TableProps<any> {
	initialSortName?: string;
	initialSortWay?: 'asc' | 'desc' | null;
}

class PageListTable extends React.Component<ITableProps> {
	public constructor(props) {
		super(props);

		// Suppression de la propriété defaultSortOrder
		props.columns?.forEach(c => delete c.defaultSortOrder);

		if (props.initialSortName && props.initialSortWay) {
			const column = props.columns?.find(c => c.key === props.initialSortName);

			if (column) {
				column.defaultSortOrder = props.initialSortWay === 'asc' ? 'ascend' : 'descend';
			}
		}
	}

	public render() {
		return <AntTable {...this.props} />;
	}
}

export default PageListTable;