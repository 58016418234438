import React                      from 'react';
import { routes as errorsRoutes } from './modules/Errors/routes';
import LoginRequired              from './projectComponents/LoginRequired/LoginRequired';
import privateRoutes              from './routes/privates';
import publicRoutes               from './routes/public';

const routes: IRoute[] = [
	...errorsRoutes,
	...publicRoutes,

	...privateRoutes.map(route => ({
		...route,
		component: props => React.createElement(LoginRequired, props),
	})),
];

export default routes;
