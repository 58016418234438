import lazyWithRetry from 'tools/lazyWithRetry';

const component = lazyWithRetry(() => import(
	/* webpackChunkName: "workHourLogAggregatedDataSpecialDashboardPage" */
	'pages/rh/WorkHourLog/WorkHourLogAggregatedDataSpecialDashboardPage'));

export default [
	{
		component: component,
		exact: true,
		path: '/work_hour_log_aggregated_data_special/:periodId'
	}
];
