import { resolvable }         from '@mathquis/modelx-resolvables';
import VehicleModel           from 'Models/vehicle/VehicleModel';
import { urn }                from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                             from 'helpers/traits';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@urn('$', 'vehicle', 'vehicleComment')
export default class VehicleCommentModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'createdBy': id;
		'vehicle': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	static get path(): string {
		return '/vehicle_comments/{?id}';
	}

	@resolvable(VehicleModel, { attributeName: 'vehicle' })
	public declare vehicle: VehicleModel;

	public get value(): string {
		return this.get('value', '');
	}

}