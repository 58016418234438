import lazyWithRetry from 'tools/lazyWithRetry';

const ActivityGroupListPage = lazyWithRetry(() => import(
	'../../pages/ActivityGroup/ActivityGroupListPage'));

const ActivityGroupDashboardPage = lazyWithRetry(() => import(
	'../../pages/ActivityGroup/ActivityGroupDashboardPage'));

export default [
	{
		component: ActivityGroupListPage,
		exact: true,
		path: '/activity_groups',
	},
	{
		component: ActivityGroupDashboardPage,
		exact: true,
		path: '/activity_groups/:activityGroupId',
	},
];