import lazyWithRetry from 'tools/lazyWithRetry';

const ClientListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "clientListPage" */
	'../../pages/Client/ClientListPage/ClientListPage'));

const ClientDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "clientDashboardPage" */
	'../../pages/Client/ClientDashboardPage/ClientDashboardPage'));

export default [
	{
		component: ClientListPage,
		exact: true,
		path: '/partitions/:partitionId/clients',
	},
	{
		component: ClientDashboardPage,
		exact: true,
		path: '/partitions/:partitionId/clients/:clientId',
	},
];
