import { resolvable }             from '@mathquis/modelx-resolvables';
import TaskZoneModel              from 'Models/intervention/TaskZoneModel';
import ActivityModel              from 'Models/sales/ActivityModel';
import ContractIterationItemModel from 'Models/sales/ContractIterationItemModel';
import { urn }                    from 'helpers/ModelDictionary';
import SalesPrivateApiModel       from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'contract_iteration_item_activity')
export default class ContractIterationItemActivityModel extends SalesPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'contractIterationItem': id;
		'contractIterationItem.contractIteration': id;
		'locationUrn': id;
	}> = {};

	static get path(): string {
		return '/contract_iteration_item_activities/{?id}';
	}

	@resolvable(ActivityModel, { attributeName: 'activity' })
	public declare activity: ActivityModel;

	@resolvable(ContractIterationItemModel, { attributeName: 'contractIterationItem' })
	public declare contractIterationItem: ContractIterationItemModel;

	@resolvable(TaskZoneModel, { attributeName: 'locationUrn' })
	public declare taskZone: TaskZoneModel;

	public get additionalInformations(): string {
		return this.get('additionalInformations', '');
	}

	public get locationUrn(): string {
		return this.get('locationUrn', '');
	}
}