import lazyWithRetry from 'tools/lazyWithRetry';

const PropertyListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "propertyListPage" */
	'../../pages/Property/PropertyListPage'));

export default [
	{
		component: PropertyListPage,
		exact: true,
		path: '/properties',
	},
];