import { urn }                from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                             from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoice_status')
export default class InvoiceStatusModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/invoice_statuses/{?id}';
	}


	public get label(): string {
		return this.get('label', '');
	}

	public get position(): number {
		return this.get('position', 0);
	}

	public get reference(): string {
		return this.get('reference', '');
	}
}