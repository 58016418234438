import lazyWithRetry from 'tools/lazyWithRetry';

const OperationGroupListPage = lazyWithRetry(() => import(
	'../../pages/OperationGroup/OperationGroupListPage'));

const OperationGroupDashboardPage = lazyWithRetry(() => import(
	'../../pages/OperationGroup/OperationGroupDashboardPage'));

export default [
	{
		component: OperationGroupListPage,
		exact: true,
		path: '/operation_groups',
	},
	{
		component: OperationGroupDashboardPage,
		exact: true,
		path: '/operation_groups/:operationGroupId',
	},
];