import AbstractContactStore from 'stores/AbstractContactStore';

export default class ContactDashboardStore extends AbstractContactStore {
	public async fetchAsync(contactId: id) {
		this.clear();

		await this.contact.setId(contactId).fetch();
		await this.fetchRelations(this.contact);
	}
}
