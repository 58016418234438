import { resolvable }                  from '@mathquis/modelx-resolvables';
import InterventionModel               from 'Models/intervention/InterventionModel';
import InterventionResourceStatusModel from 'Models/intervention/InterventionResourceStatusModel';
import ResourceModel                   from 'Models/intervention/ResourceModel';
import { urn }                         from 'helpers/ModelDictionary';
import { Timestampable }               from 'helpers/traits';
import { Blamable }                    from 'helpers/traits';
import { computed }                    from 'mobx';
import moment                          from 'moment';
import InterventionPrivateApiModel     from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'intervention_resource_status_history')
export default class InterventionResourceStatusHistoryModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/intervention_resource_status_histories/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'datetime[after]': string;
		'datetime[before]': string;
		'datetime[strictly_after]': string;
		'datetime[strictly_before]': string;
		'intervention': id;
		'interventionResourceStatus': id;
		'resource': id;
		'resource.resourceType.reference': ResourceTypeReference;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'datetime': string;
		'id': string;
	}> = {};

	@computed
	public get datetime(): Moment {
		return moment(this.get('datetime', moment()));
	}

	public get geoHash(): string {
		return this.get('geoHash', '');
	}

	@resolvable(InterventionModel, { attributeName: 'intervention' })
	public declare intervention: InterventionModel;

	@resolvable(InterventionResourceStatusModel, { attributeName: 'interventionResourceStatus' })
	public declare interventionResourceStatus: InterventionResourceStatusModel;

	@resolvable(ResourceModel, { attributeName: 'resource' })
	public declare resource: ResourceModel;
}
