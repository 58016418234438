import { computed }      from 'mobx';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

export default class StaffMemberDocumentTypeModel extends RhPrivateApiModel {
	public _sorts: ModelSortsExtended<{ 'position': string }> = {};

	public static cacheDuration =3600;

	static get path(): string {
		return '/staff_member_document_types/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}
}
