import PlanConsumptionModel from 'Models/time/PlanConsumptionModel';
import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import TimePrivateApiModel  from '../../abstracts/TimePrivateApiModel';

@model.urn('$', 'time', 'plan_consumption_aggregated_data')
@doc.path('/plan_consumptions/_aggregated_data')
export default class PlanConsumptionAggregatedDataModel extends TimePrivateApiModel {
	public _filters: ModelFilters<PlanConsumptionModel> = {};
	public _sorts: ModelSorts<PlanConsumptionModel> = {};

	@doc.number declare amount: number;
}
