import { resolvable }       from '@mathquis/modelx-resolvables';
import ContractModel        from 'Models/sales/ContractModel';
import ContractStatusModel  from 'Models/sales/ContractStatusModel';
import { urn }              from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                           from 'helpers/traits';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'contract_status_history')
export default class ContractStatusHistoryModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'contract': id;
	}> = {};

	static get path(): string {
		return '/contract_status_histories/{?id}';
	}

	@resolvable(ContractModel, { attributeName: 'contract' })
	public declare contract: ContractModel;

	@resolvable(ContractStatusModel, { attributeName: 'contractStatus', cache: true })
	public declare contractStatus: ContractStatusModel;

	public get comment(): string {
		return this.get('comment', '');
	}
}