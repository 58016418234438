import { urn }              from 'helpers/ModelDictionary';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export type ContractStatusReference =
	'CONTRACT_STATUS_WAITING' |
	'CONTRACT_STATUS_ON_GOING' |
	'CONTRACT_STATUS_SUSPENDED' |
	'CONTRACT_STATUS_NOTICED' |
	'CONTRACT_STATUS_CLOSING' |
	'CONTRACT_STATUS_CLOSED' |
	'CONTRACT_STATUS_CANCELED';

@urn('$', 'sales', 'contractStatus')
export default class ContractStatusModel extends SalesPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'reference': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'position': string;
	}> = {};

	public static cacheDuration =3600;

	static get path(): string {
		return '/contract_statuses/{?id}';
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): ContractStatusReference {
		return this.get('reference', '');
	}

	public get color() {
		switch (this.reference) {
			case 'CONTRACT_STATUS_WAITING' :
				return 'orange';
			case 'CONTRACT_STATUS_ON_GOING' :
				return 'green';
			case 'CONTRACT_STATUS_SUSPENDED' :
				return 'red';
			case 'CONTRACT_STATUS_NOTICED' :
				return 'purple';
			case 'CONTRACT_STATUS_CLOSING' :
				return 'maroon';
			case 'CONTRACT_STATUS_CLOSED' :
				return 'black';
			case 'CONTRACT_STATUS_CANCELED':
				return 'grey';
		}

		return 'grey';
	}
}