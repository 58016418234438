import ClientModel                 from 'Models/directory/ClientModel';
import OwnerModel                  from 'Models/intervention/OwnerModel';
import doc                          from 'decorators/doc';
import model                         from 'decorators/model';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel(`Lieu d'intervention`)
@model.urn('$', 'intervention', 'task_zone')
@doc.path('/task_zones/{?id}')
export default class TaskZoneModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'owner': id;
		'owner.clientUrn': string;
		'owner.contactUrn': string;
		'owner.generalClientUrn': string;
		'owner.partitionUrn': string;
		'taskOperations': id;
		'taskOperations.task': id;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'name': string;
		'updatedAt': string;
		'zipCode': string;
	}> = {};

	@doc.string declare address: string;
	@doc.string declare addressBis: string;
	@doc.string declare city: string;
	@doc.modelUrn(ClientModel) declare client: ClientModel;
	@doc.string declare country: string;
	@doc.string declare geoHash: string;
	@doc.string declare name: string;
	@doc.string declare note: string;
	@doc.model(OwnerModel) declare owner: OwnerModel;
	@doc.string declare phone: string;
	@doc.string declare timeZone: string;
	@doc.string declare zipCode: string;

	public get fullAddress() {
		return [
			this.name ? '(' + this.name + ') ' : '',
			this.address,
			this.addressBis,
			this.zipCode,
			this.city,
		].filter(s => s).join(' ');
	}
}