import lazyWithRetry from 'tools/lazyWithRetry';

const ExportPage = lazyWithRetry(() => import(
	/* webpackChunkName: "exportPage" */
	'../../pages/Export/ExportPage'));

const ExportInterventionListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "exportInterventionListPage" */
	'../../pages/Export/ExportInterventionListPage'));

const ExportInvoiceListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "exportInvoiceListPage" */
	'../../pages/Export/ExportInvoiceListPage'));

const ExportRhListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "exportRhListPage" */
	'../../pages/Export/ExportRhListPage'));

const ExportTimeListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "exportTimeListPage" */
	'../../pages/Export/ExportTimeListPage'));

export default [
	{
		component: ExportInterventionListPage,
		exact: true,
		path: '/intervention_exports',
	},
	{
		component: ExportInvoiceListPage,
		exact: true,
		path: '/invoice_exports',
	},
	{
		component: ExportRhListPage,
		exact: true,
		path: '/rh_exports',
	},
	{
		component: ExportTimeListPage,
		exact: true,
		path: '/time_exports',
	},
	{
		component: ExportPage,
		exact: true,
		path: '/exports/:exportId',
	},
];

