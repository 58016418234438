import { resolvable }    from '@mathquis/modelx-resolvables';
import StaffMemberModel  from 'Models/rh/StaffMemberModel';
import { computed }      from 'mobx';
import moment            from 'moment';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

type StaffMemberContractTypeReference = 'CDD' | 'CDI' | 'INTERIM';

export default class StaffMemberContractModel extends RhPrivateApiModel {
	public _filters: ModelFiltersExtended<{ staffMember: id; 'staffMember.staff': id; }> = {};

	static get path(): string {
		return '/staff_member_contracts/{?id}';
	}

	@resolvable(StaffMemberModel, { attributeName: 'staffMember' })
	public declare staffMember: StaffMemberModel;

	@computed
	public get staffMemberIri(): string {
		return this.get('staffMember.@id', '');
	}

	@computed
	public get type(): StaffMemberContractTypeReference {
		return this.get('type', '');
	}

	@computed
	public get nbMinutes(): number {
		return this.get('nbMinutes', 0);
	}

	@computed
	public get nbMinutesAsHours() {
		return moment.duration(this.nbMinutes, 'minutes').asHours().toFixed(2);
	}

	@computed
	public get hoursPerDay(): number {
		return this.get('hoursPerDay', 0);
	}

	@computed
	public get daysPerWeek(): number {
		return this.get('daysPerWeek', 0);
	}
}
