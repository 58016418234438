import { resolvable }           from '@mathquis/modelx-resolvables';
import { IResourceEntityModel } from 'Models/intervention/OwnerResourceModel';
import EnergyModel              from 'Models/vehicle/EnergyModel';
import VehicleTypeModel         from 'Models/vehicle/VehicleTypeModel';
import model                    from 'decorators/model';
import { urn }                  from 'helpers/ModelDictionary';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import { computed }             from 'mobx';
import moment                   from 'moment';
import { getIdFromUrn }         from 'tools/UrnTools';
import VehiclePrivateApiModel   from '../../abstracts/VehiclePrivateApiModel';

@model.staticLabel('Véhicule')
@urn('$', 'vehicle', 'vehicle')
export default class VehicleModel extends Blamable(Timestampable(VehiclePrivateApiModel))
	implements IResourceEntityModel {

	public _filters: ModelFiltersExtended<{
		'dateOut[after]': string;
		'dateOut[before]': string;
		'dateOut[strictly_after]': string;
		'dateOut[strictly_before]': string;
		'energy': id;
		'licensePlate': string;
		'partitionUrn': string;
		'vehicleType': id;
		'vehicleType.reference': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'dateOut': string;
		'id': string;
		'licensePlate': string;
		'name': string;
		'updatedAt': string;
		'vehicleType.label': string;
		'vehicleType.position': string;
	}> = {};

	static get path(): string {
		return '/vehicles/{?id}';
	}

	@resolvable(EnergyModel, { attributeName: 'energy', cache: true })
	public declare energy: EnergyModel;

	@resolvable(VehicleTypeModel, { attributeName: 'vehicleType', cache: true })
	public declare vehicleType: VehicleTypeModel;

	public get brand(): string {
		return this.get('brand', '');
	}

	@computed
	public get dateOut() {
		return this.get('dateOut') ? moment(this.get('dateOut', moment())) : undefined;
	}

	public get holderUrn(): string {
		return this.get('holderUrn', '');
	}

	@computed
	public get holderId(): id {
		return getIdFromUrn(this.holderUrn);
	}

	public get licensePlate(): string {
		return this.get('licensePlate', '');
	}

	public get model(): string {
		return this.get('model', '');
	}

	public get partitionUrn(): string {
		return this.get('partitionUrn', '');
	}

	public get year(): number {
		return this.get('year', 0);
	}

	public get name(): string {
		return this.get('name', '');
	}

	public get fullName(): string {
		return this.name + ' - ' + this.licensePlate;
	}

	@computed
	public get holderIsPartition() {
		return this.holderUrn.indexOf('$:partition:partition') > -1;
	}
}