import model                       from 'decorators/model';
import { urn }                     from 'helpers/ModelDictionary';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel(`Groupe d'opération`)
@urn('$', 'intervention', 'operation_group')
export default class OperationGroupModel extends InterventionPrivateApiModel {

	static get path(): string {
		return '/operation_groups/{?id}';
	}


	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
	}> = {};

	public get description(): string {
		return this.get('description', '');
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get position(): number {
		return this.get('position', 0);
	}
}