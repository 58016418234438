import { resolvable }         from '@mathquis/modelx-resolvables';
import ClientModel            from 'Models/directory/ClientModel';
import ContactModel           from 'Models/directory/ContactModel';
import GeneralClientModel     from 'Models/directory/GeneralClientModel';
import PartitionModel         from 'Models/partition/PartitionModel';
import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import { getIdFromUrn }       from 'tools/UrnTools';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'owner')
export default class OwnerModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/owners/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'clientUrn': string;
		'contactUrn': string;
		'generalClientUrn': string;
		'partitionUrn': string;
	}> = {};

	@resolvable(ClientModel, { attributeName: 'clientUrn', cache: true })
	public declare client: ClientModel;

	@resolvable(ContactModel, { attributeName: 'contactUrn', cache: true })
	public declare contact: ContactModel;

	@resolvable(GeneralClientModel, { attributeName: 'generalClientUrn', cache: true })
	public declare generalClient: GeneralClientModel;

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	public declare partition: PartitionModel;

	@computed
	public get clientId(): id {
		return getIdFromUrn(this.get('clientUrn'));
	}
}