import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import AbstractApiModel       from './AbstractApiModel';

export const salesLoggedConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('sales', 'api_endpoint'),
});

export default class SalesPrivateApiModel extends AbstractApiModel {
	public static get connector() {
		return salesLoggedConnector;
	}

	public static serviceName: ServiceName = 'sales';
}
