import { urn }              from 'helpers/ModelDictionary';
import GraphPrivateApiModel from '../../abstracts/GraphPrivateApiModel';

@urn('$', 'graph', 'node_type')
export default class NodeTypeModel extends GraphPrivateApiModel {

	static get path(): string {
		return '/node_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'mask': id;
		'reference': string;
	}> = {};


	public get mask(): string {
		return this.get('mask', '');
	}

	public get reference(): string {
		return this.get('reference', '');
	}
}