import doc                     from 'decorators/doc';
import model                    from 'decorators/model';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('BillingPeriodStatus')
@model.urn('$', 'invoice', 'billing_period_status')
@doc.path('/billing_period_statuses/{?id}')
export default class BillingPeriodStatusModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': BillingPeriodStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'reference': string;
	}> = {};

	public static cacheDuration = 3600;

	@doc.string declare label: string;
	@doc.string declare reference: BillingPeriodStatusReference;

	public get color() {
		switch (this.reference) {
			case 'open':
				return 'green';
			case 'closed':
				return 'red';
			default:
				return 'black';
		}
	}
}