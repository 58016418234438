import React from 'react';

const logoSrc = new URL(`/logos/Placidea-Logo.png`, import.meta.url).href;

export default () => (
	<div>
		<img
			alt={`logo`}
			src={logoSrc}
			style={{ height: 70, marginBottom: 20 }}
		/>
	</div>
);