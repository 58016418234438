import { computed }      from 'mobx';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

export default class StaffMemberAddressModel extends RhPrivateApiModel {
	public _filters: ModelFiltersExtended<{ staffMember: id; }> = {};

	static get path(): string {
		return '/staff_member_addresses/{?id}';
	}

	@computed
	public get createdAt(): string {
		return this.get('createdAt', '');
	}

	@computed
	public get updatedAt(): string {
		return this.get('updatedAt', '');
	}

	@computed
	public get address(): string {
		return this.get('address', '');
	}

	@computed
	public get addressBis(): string {
		return this.get('addressBis', '');
	}

	@computed
	public get zipCode(): string {
		return this.get('zipCode', '');
	}

	@computed
	public get city(): string {
		return this.get('city', '');
	}

	@computed
	public get country(): string {
		return this.get('country', '');
	}
}
