import { appStore }   from 'stores';
import browserHistory from 'tools/browserHistory';
import AAM            from '../modelx/models/abstracts/AbstractApiModel';

type P = Record<string, string | number | (string | number)[]> | string;

const partitionPath = (path: string, id = appStore.partition?.id) => `/partitions/${id || ''}/${path}`;
const path = (path: string, params?: P) => {
	const startsWithHttp = path.startsWith('http');

	let p = (startsWithHttp ? '' : '/') + path;

	if (params) {
		if (typeof params === 'string') {
			p += '?' + params;
		} else {
			p += '?' + new URLSearchParams(params as never).toString();
		}
	}

	if (startsWithHttp) {
		return p;
	}

	return p.replace('//', '/');
};

const to = (str: string, redirect?: boolean, params?: P, reload?: boolean) => {
	const url = path(str, params);

	if (redirect) {
		if (reload) {
			window.location.href = url;
		} else {
			browserHistory.push(url);
		}
	}

	return url;
};

const toPartition = (path: string, redirect?: boolean, p?: P, partitionId?: id) => {
	return to(partitionPath(path, partitionId).replace('//', '/'), redirect, p, !!partitionId);
};

export default {
	toActivityGroupList: (r?: boolean, p?: P) => to(`activity_groups`, r, p),
	toActivityList: (r?: boolean, p?: P) => to(`activities`, r, p),
	toAnomalies: (r?: boolean, p?: P) => toPartition(`anomalies`, r, p),
	toBillable: (id: id, r?: boolean) => toPartition(`billables/${id}`, r),
	toBillingPeriodList: (r?: boolean, p?: P) => to(`billing_periods`, r, p),
	toClient: (id: id, r?: boolean, pId?: id) => toPartition(`clients/${id}`, r, {}, pId),
	toClientList: (r?: boolean, p?: P) => toPartition(`clients`, r, p),
	toCompanyList: (r?: boolean, p?: P) => to(`companies`, r, p),
	toContact: (id: id, r?: boolean) => to(`contacts/${id}`, r),
	toContactList: (r?: boolean, p?: P) => to(`contacts`, r, p),
	toContract: (id: id, r?: boolean) => toPartition(`contracts/${id}`, r),
	toContractIteration: (id: id, r?: boolean) => toPartition(`contract_iterations/${id}`, r),
	toContractList: (r?: boolean, p?: P) => toPartition(`contracts`, r, p),
	toDashboard: (m: AAM, r?: boolean, s?: ServiceName) => to((s ? `/${s}` : '') + m.path, r),
	toDeposit: (id: id, r?: boolean) => toPartition(`deposits/${id}`, r),
	toDepositList: (r?: boolean, p?: P) => toPartition(`deposits`, r, p),
	toExportInterventionList: (r?: boolean, p?: P) => to(`intervention_exports`, r, p),
	toExportInvoiceList: (r?: boolean, p?: P) => to(`invoice_exports`, r, p),
	toExportRhList: (r?: boolean, p?: P) => to(`rh_exports`, r, p),
	toExportTimeList: (r?: boolean, p?: P) => to(`time_exports`, r, p),
	toHome: (r?: boolean, p?: P) => to(``, r, p),
	toInterventionVersion: (id: id, r?: boolean) => toPartition(`intervention_versions/${id}`, r),
	toInterventionVersionList: (r?: boolean, p?: P) => toPartition(`intervention_versions`, r, p),
	toInterventionVersionStatisticList: (r?: boolean, p?: P) => toPartition(`intervention_version_statistics`, r, p),
	toInterventionVersionTimeLogList: (r?: boolean, p?: P) => toPartition(`intervention_version_time_logs`, r, p),
	toInvoice: (id: id, r?: boolean) => toPartition(`invoices/${id}`, r),
	toInvoiceList: (r?: boolean, p?: P) => toPartition(`invoices`, r, p),
	toOperationGroupList: (r?: boolean, p?: P) => to(`operation_groups`, r, p),
	toOperationList: (r?: boolean, p?: P) => to(`operations`, r, p),
	toPartitionDashboard: (m: AAM, r?: boolean, s?: ServiceName) => toPartition((s ? `/${s}` : '') + m.path, r),
	toPartitionGroup: (id: id, r?: boolean) => toPartition(`partition_groups/${id}`, r),
	toPartitionGroupList: (r?: boolean, p?: P) => to(`partition_groups`, r, p),
	toPartitionList: (r?: boolean, p?: P) => to(`partitions`, r, p),
	toPayment: (id: id, r?: boolean) => toPartition(`payments/${id}`, r),
	toPaymentList: (r?: boolean, p?: P) => toPartition(`payments`, r, p),
	toPeriodList: (r?: boolean, p?: P) => to(`periods`, r, p),
	toPlan: (id: id, r?: boolean) => toPartition(`plans/${id}`, r),
	toPlanImportList: (r?: boolean, p?: P) => toPartition(`plan_imports`, r, p),
	toPlanList: (r?: boolean, p?: P) => toPartition(`plans`, r, p),
	toPlanningsClient: (r?: boolean, p?: P) => toPartition(`plannings/client`, r, p),
	toPlanningsStaffMember: (r?: boolean, p?: P) => toPartition(`plannings/staff_member`, r, p),
	toPropertyList: (r?: boolean, p?: P) => to(`properties`, r, p),
	toStaffMember: (id: id, r?: boolean) => toPartition(`staff_members/${id}`, r),
	toStaffMemberList: (r?: boolean, p?: P) => toPartition(`staff_members`, r, p),
	toSynthesisAccessRightList: (r?: boolean, p?: P) => to(`synthesis_access_rights`, r, p),
	toTimeLogAggregatedDataSpecial: (periodId: id, r?: boolean, p?: P) => to(`time_log_aggregated_data_special/${periodId}`, r, p),
	toUser: (id: id, r?: boolean) => toPartition(`users/${id}`, r),
	toUserList: (r?: boolean, p?: P) => to(`users`, r, p),
	toVatList: (r?: boolean, p?: P) => to(`vats`, r, p),
	toVehicle: (id: id, r?: boolean) => toPartition(`vehicles/${id}`, r),
	toVehicleList: (r?: boolean, p?: P) => toPartition(`vehicles`, r, p),
	toWorkHourLogAggregatedDataSpecial: (periodId: id, r?: boolean, p?: P) => to(`work_hour_log_aggregated_data_special/${periodId}`, r, p),
};
