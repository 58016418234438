import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import AbstractApiModel       from './AbstractApiModel';

export const rightConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('rights', 'api_endpoint'),
});

export default class RightPrivateApiModel extends AbstractApiModel {
	public static get connector() {
		return rightConnector;
	}
}