import { resolvable }      from '@mathquis/modelx-resolvables';
import TimeGroupModel      from 'Models/time/TimeGroupModel';
import { urn }             from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                          from 'helpers/traits';
import { computed }        from 'mobx';
import moment              from 'moment';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

@urn('$', 'time', 'time_group_price')
export default class TimeGroupPriceModel extends Blamable(Timestampable(TimePrivateApiModel)) {

	static get path(): string {
		return '/time_group_prices/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'timeGroup': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	public get price(): number {
		return this.get('price', 0);
	}

	public get timeGroupId(): id {
		return this.get('timeGroup.id', 0);
	}

	@computed
	public get startDate(): Moment {
		return moment(this.get('startDate', moment()));
	}

	@resolvable(TimeGroupModel, { attributeName: 'timeGroup' })
	public declare timeGroup: TimeGroupModel;
}
