import moment from 'moment';

export const getMondayOfWeekForDate = (d: Date = new Date()): Date => {
	d = new Date(d);
	const day = d.getDay();
	const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

	return new Date(d.setDate(diff));
};

export const getSundayOfWeekForDate = (d: Date = new Date()): Date => {
	const date = getMondayOfWeekForDate(new Date(d));

	date.setDate(date.getDate() + 6);

	return date;
};

export const durationFormatted = (start: Moment, end: Moment, slice?: number[]): string => {
	const duration = moment.duration(end.diff(start));

	let data = [
		{ label: 'an', value: duration.years() },
		{ label: 'jour', value: duration.days() },
		{ label: 'mois', value: duration.months() },
		{ label: 'heure', value: duration.hours() },
		{ label: 'minute', value: duration.minutes() },
		{ label: 'seconde', value: duration.seconds() },
	];

	if (slice) {
		data = data.slice(...slice);
	}

	return data
		.filter(d => d.value)
		.map(d => `${d.value} ${d.label}${d.value > 1 ? 's' : ''}`)
		.join(' ');
};

export const hoursFormatted = (duration: moment.Duration): string => {
	const hours = Math.floor(duration.asHours());
	const minutes = Math.floor(duration.minutes());

	if (!hours) {
		return `${minutes} min`;
	}

	return `${hours}h${minutes > 9 ? minutes : '0' + minutes}`;
};

export const hoursFormattedFromDates = (start: Moment, end: Moment): string => {
	const duration = moment.duration(end.diff(start));
	return hoursFormatted(duration);
};
