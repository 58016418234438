import { resolvable }                     from '@mathquis/modelx-resolvables';
import ContractIterationDocumentTypeModel from 'Models/sales/ContractIterationDocumentTypeModel';
import ContractIterationModel             from 'Models/sales/ContractIterationModel';
import { urn }                            from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                                         from 'helpers/traits';
import { computed }                       from 'mobx';
import ConfigProxy                        from 'tools/ConfigProxy';
import SalesPrivateApiModel               from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'contract_iteration_document')
export default class ContractIterationDocumentModel extends Timestampable(Blamable(SalesPrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'contractIteration': id;
		'contractIterationDocumentType.reference': id;
	}> = {};

	static get path(): string {
		return '/contract_iteration_documents/{?id}';
	}

	@resolvable(ContractIterationModel, { attributeName: 'contractIteration' })
	public declare contractIteration: ContractIterationModel;

	@resolvable(ContractIterationDocumentTypeModel, { attributeName: 'contractIterationDocumentType', cache: true })
	public declare contractIterationDocumentType: ContractIterationDocumentTypeModel;

	public get contentUrl(): string {
		return this.get('contentUrl', '');
	}

	public get file(): string {
		return this.get('file', '');
	}

	public get fileMimeType(): string {
		return this.get('fileMimeType', '');
	}

	public get fileOriginalName(): string {
		return this.get('fileOriginalName', '');
	}

	public get filePath(): string {
		return this.get('filePath', '');
	}

	public get fileSize(): number {
		return this.get('fileSize', 0);
	}

	public get folder(): string {
		return this.get('folder', '');
	}

	public get purchaseOrderNumber(): string {
		return this.get('purchaseOrderNumber', '');
	}

	public get title(): string {
		return this.get('title', '');
	}

	@computed
	public get readUrl(): string {
		return `${ConfigProxy.getServiceConfig('sales', 'api_endpoint')}${this.path}/read`;
	}
}