import { resolvable }       from '@mathquis/modelx-resolvables';
import NodeModel            from 'Models/graph/NodeModel';
import RelationTypeModel    from 'Models/graph/RelationTypeModel';
import { urn }              from 'helpers/ModelDictionary';
import { Timestampable }    from 'helpers/traits';
import { Blamable }         from 'helpers/traits';
import GraphPrivateApiModel from '../../abstracts/GraphPrivateApiModel';

@urn('$', 'graph', 'relation')
export default class RelationModel extends Blamable(Timestampable(GraphPrivateApiModel)) {

	static get path(): string {
		return '/relations/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'inNode': id;
		'inNode.identifier': id;
		'outNode': id;
		'outNode.identifier': id;
		'relationType': id;
		'relationType.reference': string;
	}> = {};


	@resolvable(NodeModel, { attributeName: 'inNode', })
	public declare inNode: NodeModel;

	public get inNodeIdentifier(): string {
		return this.get('inNodeIdentifier', '');
	}

	@resolvable(NodeModel, { attributeName: 'outNode', })
	public declare outNode: NodeModel;

	public get outNodeIdentifier(): string {
		return this.get('outNodeIdentifier', '');
	}

	@resolvable(RelationTypeModel, { attributeName: 'relationType', })
	public declare relationType: RelationTypeModel;

	public get source(): string {
		return this.get('source', '');
	}


}