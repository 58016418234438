import lazyWithRetry from 'tools/lazyWithRetry';

const CompanyAddPage = lazyWithRetry(() => import(
	/* webpackChunkName: "companyAddPage" */
	`../../pages/Company/CompanyAddPage/CompanyAddPage`));

const CompanyEditPage = lazyWithRetry(() => import(
	/* webpackChunkName: "companyEditPage" */
	'../../pages/Company/CompanyEditPage/CompanyEditPage'));

const CompanyDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "companyDashboardPage" */
	`../../pages/Company/CompanyDashboardPage/CompanyDashboardPage`));

const CompanyListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "companyListPage" */
	`../../pages/Company/CompanyListPage/CompanyListPage`));

export default [
	{
		component: CompanyListPage,
		exact: true,
		path: '/companies',
	},
	{
		component: CompanyAddPage,
		exact: true,
		path: '/companies/add',
	},
	{
		component: CompanyEditPage,
		exact: true,
		path: '/companies/edit/:companyId',
	},
	{
		component: CompanyDashboardPage,
		exact: true,
		path: '/companies/:companyId',
	},
];