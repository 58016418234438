import { resolvable }                from '@mathquis/modelx-resolvables';
import ResourceModel                 from 'Models/intervention/ResourceModel';
import { ResourceTypeReference }     from 'Models/intervention/ResourceTypeModel';
import TaskZoneModel                 from 'Models/intervention/TaskZoneModel';
import TaskZoneResourceAffinityModel from 'Models/intervention/TaskZoneResourceAffinityModel';
import { urn }                       from 'helpers/ModelDictionary';
import { Blamable }                  from 'helpers/traits';
import { Timestampable }             from 'helpers/traits';
import InterventionPrivateApiModel   from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'task_zone_resource')
export default class TaskZoneResourceModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/task_zone_resources/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'nbInterventions[between]': string;
		'nbInterventions[gt]': string;
		'nbInterventions[gte]': string;
		'nbInterventions[lt]': string;
		'nbInterventions[lte]': string;
		'resource': id;
		'resource.resourceType.reference': ResourceTypeReference;
		'taskZone': id;
		'taskZone.owner.clientUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'nbInterventions': string;
		'updatedAt': string;
	}> = {};

	public get resourceId(): id {
		return this.get('resource.id', 0);
	}

	public get nbInterventions(): number {
		return this.get('nbInterventions', 0);
	}

	@resolvable(ResourceModel, { attributeName: 'resource' })
	public declare resource: ResourceModel;

	@resolvable(TaskZoneModel, { attributeName: 'taskZone' })
	public declare taskZone: TaskZoneModel;

	@resolvable(TaskZoneResourceAffinityModel, { attributeName: 'taskZoneResourceAffinity' })
	public declare taskZoneResourceAffinity: TaskZoneResourceAffinityModel;
}