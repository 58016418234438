import { urn }                     from 'helpers/ModelDictionary';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'task_zone_resource_affinity')
export default class TaskZoneResourceAffinityModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/task_zone_resource_affinities/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'coefficient[between]': string;
		'coefficient[gt]': string;
		'coefficient[gte]': string;
		'coefficient[lt]': string;
		'coefficient[lte]': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'coefficient': string;
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	public get labelColor(): string {
		switch (this.label) {
			case 'Déconseillé':
				return '#e74c3c';
			case 'Recommandé':
				return '#27ae60';
			case 'Neutre':
			default:
				return 'black';
		}
	}

	public get coefficient(): number {
		return this.get('coefficient', 0);
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): string {
		return this.get('reference', '');
	}
}