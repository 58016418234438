import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import GraphApiModel          from './GraphApiModel';

export const graphLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('graph', 'api_endpoint'),
});

export default class GraphPrivateApiModel extends GraphApiModel {
	public static get connector() {
		return graphLoggedApiConnector;
	}
}
