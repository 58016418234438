import { resolvable }       from '@mathquis/modelx-resolvables';
import OperationModel       from 'Models/intervention/OperationModel';
import ActivityGroupModel   from 'Models/sales/ActivityGroupModel';
import model                from 'decorators/model';
import { urn }              from 'helpers/ModelDictionary';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel(`Type d'activité`)
@urn('$', 'sales', 'activity')
export default class ActivityModel extends SalesPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'activityGroup': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'label': string;
	}> = {};

	static get path(): string {
		return '/activities/{?id}';
	}

	@resolvable(ActivityGroupModel, { attributeName: 'activityGroup', cache: true })
	public declare activityGroup: ActivityGroupModel;

	@resolvable(OperationModel, { attributeName: 'operationUrn' })
	public declare operation: OperationModel;

	public get description(): string {
		return this.get('description', '');
	}

	public get label(): string {
		return this.get('label', '');
	}
}