import { PagedCollection }          from 'Collections/PagedCollection';
import ResourceAvailabilityModel    from 'Models/intervention/ResourceAvailabilityModel';
import ResourceModel                from 'Models/intervention/ResourceModel';
import ResourceTypeModel            from 'Models/intervention/ResourceTypeModel';
import WeekTypeModel                from 'Models/intervention/WeekTypeModel';
import LeaveTypeModel               from 'Models/rh/LeaveTypeModel';
import StaffMemberAddressModel      from 'Models/rh/StaffMemberAddressModel';
import StaffMemberContractModel     from 'Models/rh/StaffMemberContractModel';
import StaffMemberDocumentModel     from 'Models/rh/StaffMemberDocumentModel';
import StaffMemberDocumentTypeModel from 'Models/rh/StaffMemberDocumentTypeModel';
import StaffMemberLeaveModel        from 'Models/rh/StaffMemberLeaveModel';
import StaffMemberModel             from 'Models/rh/StaffMemberModel';
import { ApiCollection }            from 'modelx/collections/ApiCollection';
import { appStore }                 from 'stores';
import AbstractModelXStore          from './AbstractModelXStore';

export default class StaffMemberDashboardStore extends AbstractModelXStore {

	public leaveTypeCollection = new ApiCollection(LeaveTypeModel);
	public resourceAvailabilityCollection = new ApiCollection(ResourceAvailabilityModel);
	public resourceCollection = new ApiCollection(ResourceModel);
	public resourceTypeCollection = new ApiCollection(ResourceTypeModel);
	public staffMember = new StaffMemberModel();
	public staffMemberAddressCollection = new ApiCollection(StaffMemberAddressModel);
	public staffMemberContractCollection = new ApiCollection(StaffMemberContractModel);
	public staffMemberDocumentCollection = new ApiCollection(StaffMemberDocumentModel);
	public staffMemberDocumentTypeCollection = new ApiCollection(StaffMemberDocumentTypeModel);
	public staffMemberLeavePagedCollection = new PagedCollection(StaffMemberLeaveModel);
	public weekTypeCollection = new ApiCollection(WeekTypeModel);

	public async initAsync(staffMemberId: id) {
		this.clear();

		await Promise.all([
			this.staffMember
				.setId(staffMemberId)
				.fetch(),

			this.staffMemberAddressCollection
				.listBy([staffMemberId], 'staffMember'),

			this.staffMemberContractCollection
				.setSort('createdAt')
				.setFilter('staffMember', staffMemberId)
				.list(),

			this.staffMemberDocumentCollection
				.setSort('createdAt')
				.setFilter('staffMember', staffMemberId)
				.list(),

			this.staffMemberLeavePagedCollection
				.listBy([staffMemberId], 'staffMember'),

			this.leaveTypeCollection.list(),

			this.staffMemberDocumentTypeCollection
				.setSort('position')
				.list(),

			this.resourceTypeCollection.list(),
		]);

		await this.resourceCollection
			.setFilters({
				'ownerResource.entityUrn': this.staffMember.urn,
				partitionUrn: appStore.partitionUrn,
				resourceType: this.resourceTypeTechnician?.id,
			})
			.list()
			.then(() => {
				this.resourceAvailabilityCollection.setSorts({
					'day': 'asc',
					'startHour': 'asc',
				});

				if (!this.resource) {
					return;
				}

				this.resourceAvailabilityCollection
					.setFilter('resource', this.resource.id)
					.list();
			});

		this.weekTypeCollection.list({ cache: 3600 });
	}

	public get isTechnician() {
		return this.resourceCollection.length > 0;
	}

	public get staffMemberAddress() {
		return this.staffMemberAddressCollection.first();
	}

	public get staffMemberContract() {
		return this.staffMemberContractCollection.first();
	}

	public get resource() {
		return this.resourceCollection.first();
	}

	public get resourceTypeTechnician() {
		return this.resourceTypeCollection.find(rt => rt.reference === 'technician');
	}
}
