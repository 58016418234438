import { resolvable }           from '@mathquis/modelx-resolvables';
import DebitMandatePreviewModel from 'Models/invoice/DebitMandatePreviewModel';
import DebitMandateStatusModel  from 'Models/invoice/DebitMandateStatusModel';
import InvoicingGroupModel      from 'Models/invoice/InvoicingGroupModel';
import { urn }                  from 'helpers/ModelDictionary';
import { Blamable }             from 'helpers/traits';
import { Timestampable }        from 'helpers/traits';
import { appStore }             from 'stores';
import downloadFile             from 'tools/downloadFile';
import notificationApiError     from 'tools/notificationApiError';
import InvoicePrivateApiModel   from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'debit_mandate')
export default class DebitMandateModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/debit_mandates/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'invoicingGroup': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@resolvable(DebitMandateStatusModel, { attributeName: 'debitMandateStatus', cache: true })
	public declare debitMandateStatus: DebitMandateStatusModel;

	@resolvable(InvoicingGroupModel, { attributeName: 'invoicingGroup' })
	public declare invoicingGroup: InvoicingGroupModel;

	public get bic(): string {
		return this.get('bic', '');
	}

	public get document(): boolean {
		return this.get('document', false);
	}

	public get iban(): string {
		return this.get('iban', '');
	}

	public get rum(): string {
		return this.get('rum', '');
	}

	public async downloadPdfAsync() {
		try {
			const debitMandatePreviewModel = new DebitMandatePreviewModel({ id: this.id });

			await debitMandatePreviewModel.fetch();

			downloadFile(
				debitMandatePreviewModel.blob,
				`${appStore.partition?.name} - Mandat - ${this.rum}.pdf`,
			);
		} catch (err) {
			notificationApiError(err);
		}
	}

	public get accountHolderFirstName(): string {
		return this.get('accountHolderFirstName', '');
	}

	public get accountHolderLastName(): string {
		return this.get('accountHolderLastName', '');
	}

	public get bankAddress(): string {
		return this.get('bankAddress', '');
	}

	public get bankName(): string {
		return this.get('bankName', '');
	}

	public get debtorAddress(): string {
		return this.get('debtorAddress', '');
	}

	public get debtorCity(): string {
		return this.get('debtorCity', '');
	}

	public get debtorCountry(): string {
		return this.get('debtorCountry', '');
	}

	public get debtorFirstName(): string {
		return this.get('debtorFirstName', '');
	}

	public get debtorLastName(): string {
		return this.get('debtorLastName', '');
	}

	public get debtorZipCode(): string {
		return this.get('debtorZipCode', '');
	}
}