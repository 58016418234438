import { urn }                     from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                                  from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type InterventionOperationStatusReference = 'to_do' | 'done' | 'not_done' | 'incomplete';

@urn('$', 'intervention', 'intervention_operation_status')
export default class InterventionOperationStatusModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/intervention_operation_statuses/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': InterventionOperationStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
	}> = {};

	public static cacheDuration =3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get position(): number {
		return this.get('position', 0);
	}

	public get reference(): InterventionOperationStatusReference {
		return this.get('reference', '');
	}
}