import { resolvable }      from '@mathquis/modelx-resolvables';
import ContactModel        from 'Models/directory/ContactModel';
import GeneralClientModel  from 'Models/directory/GeneralClientModel';
import { urn }             from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                          from 'helpers/traits';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

@urn('$', 'time', 'owner')
export default class OwnerModel extends Blamable(Timestampable(TimePrivateApiModel)) {

	static get path(): string {
		return '/owners/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'contactUrn': string;
		'generalClientUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@resolvable(ContactModel, { attributeName: 'contactUrn' })
	public declare contact: ContactModel;

	@resolvable(GeneralClientModel, { attributeName: 'generalClientUrn' })
	public declare generalClient: GeneralClientModel;
}