import lazyWithRetry from 'tools/lazyWithRetry';

const BillableDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "billableDashboardPage" */
	'../../pages/Billable/BillableDashboardPage'));

export default [
	{
		component: BillableDashboardPage,
		path: '/partitions/:partitionId/billables/:billableId',
	},
];