import lazyWithRetry from 'tools/lazyWithRetry';

const PlanListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "planListPage" */
	'../../pages/Plan/PlanListPage'));

const PlanDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "planDashboardPage" */
	'../../pages/Plan/PlanDashboardPage'));

export default [
	{
		component: PlanListPage,
		exact: true,
		path: '/partitions/:partitionId/plans',
	},
	{
		component: PlanDashboardPage,
		path: '/partitions/:partitionId/plans/:planId',
	},
];