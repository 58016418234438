import { urn }                  from 'helpers/ModelDictionary';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@urn('$', 'directory', 'company_group')
export default class CompanyGroupModel extends DirectoryPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'companies': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'name': string;
	}> = {};

	static get path(): string {
		return '/company_groups/{?id}';
	}

	public get companyGroupName(): string {
		return this.get('name', '');
	}
}