import { urn } from 'helpers/ModelDictionary';
import GraphPrivateApiModel from '../../abstracts/GraphPrivateApiModel';

@urn('$', 'graph', 'graph')
export default class GraphModel extends GraphPrivateApiModel {

	static get path(): string {
		return '/graphs/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': string;
	}> = {};


	public get reference(): string {
		return this.get('reference', '');
	}
}