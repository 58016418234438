import model                  from 'decorators/model';
import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel(`Taux de TVA`)
@urn('$', 'invoice', 'vat')
export default class VatModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'label': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	static get path(): string {
		return '/vats/{?id}';
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get fullLabel() {
		return `${this.label} - ${this.rate}%`;
	}

	public get rate(): number {
		return this.get('rate', 0);
	}

	public get defaultVat(): boolean {
		return this.get('defaultVat', false);
	}
}