import { urn }                     from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                                  from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type InterventionTypeReference = 'pause' | 'office' | 'intervention';

@urn('$', 'intervention', 'intervention_type')
export default class InterventionTypeModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/intervention_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': InterventionTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
	}> = {};

	public static cacheDuration = 3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get position(): number {
		return this.get('position', 0);
	}

	public get reference(): InterventionTypeReference {
		return this.get('reference', '');
	}

	public get color() {
		switch (this.reference) {
			case 'intervention' :
				return '#FFA500';
			case 'office' :
				return '#800080';
			case 'pause' :
				return '#FF0000';
		}

		return '#FFA500';
	}
}