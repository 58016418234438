import { ApiCollection }                      from 'Collections/ApiCollection';
import { PagedCollection }                    from 'Collections/PagedCollection';
import RelationModel                          from 'Models/graph/RelationModel';
import TaskZoneModel                          from 'Models/intervention/TaskZoneModel';
import ContractDepositRuleModel               from 'Models/sales/ContractDepositRuleModel';
import ContractIterationAmendmentModel        from 'Models/sales/ContractIterationAmendmentModel';
import ContractIterationDocumentModel         from 'Models/sales/ContractIterationDocumentModel';
import ContractIterationDocumentTypeModel     from 'Models/sales/ContractIterationDocumentTypeModel';
import ContractIterationItemActivityModel     from 'Models/sales/ContractIterationItemActivityModel';
import ContractIterationItemScheduleModel     from 'Models/sales/ContractIterationItemScheduleModel';
import ContractIterationItemScheduleTypeModel from 'Models/sales/ContractIterationItemScheduleTypeModel';
import ContractIterationModel                 from 'Models/sales/ContractIterationModel';
import ContractIterationStatusHistoryModel    from 'Models/sales/ContractIterationStatusHistoryModel';
import ContractIterationStatusModel           from 'Models/sales/ContractIterationStatusModel';
import ContractModel                          from 'Models/sales/ContractModel';
import OwnerModel                             from 'Models/sales/OwnerModel';
import { computed }                           from 'mobx';
import moment                                 from 'moment';
import AbstractModelXStore                    from 'stores/AbstractModelXStore';

export default class ContractIterationDashboardStore extends AbstractModelXStore {

	public collectionContractDepositRule = new ApiCollection(ContractDepositRuleModel);
	public contract = new ContractModel();
	public contractIteration = new ContractIterationModel();
	public contractIterationAmendmentCollection = new ApiCollection(ContractIterationAmendmentModel);
	public contractIterationDocumentCollection = new ApiCollection(ContractIterationDocumentModel);
	public contractIterationDocumentTypeCollection = new ApiCollection(ContractIterationDocumentTypeModel);
	public contractIterationItemActivityResolver = new ApiCollection(ContractIterationItemActivityModel);
	public contractIterationItemScheduleCollection = new ApiCollection(ContractIterationItemScheduleModel);
	public contractIterationItemScheduleTypeCollection = new ApiCollection(ContractIterationItemScheduleTypeModel);
	public contractIterationStatusCollection = new ApiCollection(ContractIterationStatusModel);
	public contractIterationStatusHistoryPagedCollection = new PagedCollection(ContractIterationStatusHistoryModel);
	public owner = new OwnerModel();
	public relationCollection = new ApiCollection(RelationModel);
	public taskZoneCollection = new ApiCollection(TaskZoneModel);

	public async fetchAsync(id: id) {
		this.clear();

		await Promise.all([
			this.contractIteration.setId(id).fetch().then(async () => {
				await Promise.all([
					this.contract
						.setId(this.contractIteration.contract.id)
						.fetch()
						.then(() => {
							this.collectionContractDepositRule.listBy([this.contract.id], 'contract');

							this.owner
								.setId(this.contract.owner.id)
								.fetch()
								.then(() => {
									this.taskZoneCollection
										.setFilter('owner.clientUrn', this.owner.client.urn)
										.list();
								});
						}),

					this.relationCollection
						.setFilters({
							'outNode.identifier': this.contractIteration.urn,
							'relationType.reference': 'iteration_responsible',
						})
						.list(),
				]);
			}),

			this.contractIterationItemScheduleTypeCollection.list(),

			this.contractIterationStatusCollection.list(),

			this.contractIterationDocumentTypeCollection.list(),

			this.contractIterationItemActivityResolver
				.setFilter('contractIterationItem.contractIteration', id).list(),

			this.contractIterationItemScheduleCollection
				.setFilter('contractIterationItem.contractIteration', id).list(),

			this.contractIterationDocumentCollection.setFilter('contractIteration', id).list(),

			this.contractIterationAmendmentCollection.setFilter('contractIterationAmended', id).list(),

			this.contractIterationStatusHistoryPagedCollection
				.setSort('createdAt', false)
				.setFilter('contractIteration', id)
				.list(),
		]);
	}

	@computed
	public get durationByWeek(): moment.Duration {
		return moment.duration(this.durationByMonth.asMilliseconds() / 4.33);
	}

	@computed
	public get durationByMonth(): moment.Duration {
		return this.contractIterationItemScheduleCollection
			.reduce(
				(duration, schedule) => {
					const scheduleType = this.contractIterationItemScheduleTypeCollection.findBy('id', schedule.contractIterationItemScheduleType.id);

					const scheduleTypeReference = scheduleType?.reference;

					switch (scheduleTypeReference) {
						case 'every_weeks':
							duration.add(
								moment.duration(schedule.duration.asMilliseconds() * 4.33)
							);
							break;
						case 'only_weeks':
							duration.add(
								moment.duration(schedule.duration.asMilliseconds() * 4.33 / 2)
							);
							break;
						case 'the_nths':
							duration.add(schedule.duration);
					}

					return duration;
				},
				moment.duration()
			);
	}

	@computed
	public get durationByWeekFormatted() {
		const asHours = this.durationByWeek.asHours();

		return (
			('0' + Math.floor(asHours)).slice(-2) + 'h' +
			('0' + this.durationByWeek.minutes()).slice(-2) +
			' = ' + asHours.toFixed(2) + ' cts'
		);
	}

	@computed
	public get durationByMonthFormatted() {
		const asHours = this.durationByMonth.asHours();

		const hours = Math.floor(asHours);
		const minutes = Math.floor((asHours - hours) * 60);

		return (
			hours + 'h' +
			('0' + minutes).slice(-2) +
			' = ' + asHours.toFixed(2) + ' cts'
		);
	}

	@computed
	public get relation(): RelationModel {
		const relation = this.relationCollection.first();

		if (!relation) {
			return new RelationModel();
		}

		return relation;
	}

	@computed
	public get contractDepositRule() {
		return this.collectionContractDepositRule.first() || new ContractDepositRuleModel();
	}

	@computed
	public get isAmended() {
		return !!this.contractIterationAmendmentCollection.first();
	}
}
