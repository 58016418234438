import { LoggedApiConnector } from 'modelx/connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import AbstractApiModel       from './AbstractApiModel';

export const interventionLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('intervention', 'api_endpoint'),
});

export default class InterventionPrivateApiModel extends AbstractApiModel {
	public static get connector() {
		return interventionLoggedApiConnector;
	}

	public static serviceName: ServiceName = 'intervention';
}