import { resolvable }      from '@mathquis/modelx-resolvables';
import TaskZoneModel       from 'Models/intervention/TaskZoneModel';
import OwnerClientModel    from 'Models/time/OwnerClientModel';
import { urn }             from 'helpers/ModelDictionary';
import { Blamable }        from 'helpers/traits';
import { Timestampable }   from 'helpers/traits';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

@urn('$', 'time', 'owner_client_location')
export default class OwnerClientLocationModel extends Blamable(Timestampable(TimePrivateApiModel)) {

	static get path(): string {
		return '/owner_client_locations/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'locationUrn': string;
		'ownerClient': id;
		'ownerClient.clientUrn': string;
		'ownerClient.partitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@resolvable(TaskZoneModel, { attributeName: 'locationUrn' })
	public declare location: TaskZoneModel;

	@resolvable(OwnerClientModel, { attributeName: 'ownerClient' })
	public declare ownerClient: OwnerClientModel;
}