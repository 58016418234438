import { resolvable }           from '@mathquis/modelx-resolvables';
import BillingGroupModel        from 'Models/invoice/BillingGroupModel';
import PaymentMethodModel       from 'Models/invoice/PaymentMethodModel';
import { PaymentTypeReference } from 'Models/invoice/PaymentTypeModel';
import PaymentTypeModel         from 'Models/invoice/PaymentTypeModel';
import PartitionModel           from 'Models/partition/PartitionModel';
import model                    from 'decorators/model';
import { urn }                  from 'helpers/ModelDictionary';
import { Blamable }             from 'helpers/traits';
import { Timestampable }        from 'helpers/traits';
import { computed }             from 'mobx';
import moment                   from 'moment';
import InvoicePrivateApiModel   from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('Paiement')
@urn('$', 'invoice', 'payment')
export default class PaymentModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/payments/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'amount[between]': string;
		'amount[gt]': string;
		'amount[gte]': string;
		'amount[lt]': string;
		'amount[lte]': string;
		'billingGroup': id;
		'billingGroup.invoicingGroup.owner': id;
		'billingGroup.invoicingGroup.owner.clientUrn': string;
		'billingGroup.invoicingGroup.owner.contactUrn': string;
		'billingGroup.invoicingGroup.owner.generalClientUrn': string;
		'billingGroup.invoicingGroup.owner.partitionUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'date[after]': string | Date;
		'date[before]': string | Date;
		'date[between]': string | Date;
		'date[gt]': string | Date;
		'date[gte]': string | Date;
		'date[lt]': string | Date;
		'date[lte]': string | Date;
		'date[strictly_after]': string | Date;
		'date[strictly_before]': string | Date;
		'paymentType': id;
		'paymentType.reference': PaymentTypeReference;
		'remainingAmountDeposit[between]': string;
		'remainingAmountDeposit[gt]': string;
		'remainingAmountDeposit[gte]': string;
		'remainingAmountDeposit[lt]': string;
		'remainingAmountDeposit[lte]': string;
		'remainingAmount[between]': string;
		'remainingAmount[gt]': string;
		'remainingAmount[gte]': string;
		'remainingAmount[lt]': string;
		'remainingAmount[lte]': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'amount': string;
		'date': string;
		'id': string;
		'remainingAmount': string;
		'remainingAmountDeposit': string;
	}> = {};

	public get amount(): number {
		return this.get('amount', 0);
	}

	@resolvable(BillingGroupModel, { attributeName: 'billingGroup', cache: true })
	public declare billingGroup: BillingGroupModel;

	@computed
	public get date(): Moment {
		return moment(this.get('date', moment()));
	}

	public get depositAmountPayed(): number {
		return this.get('depositAmountPayed', 0);
	}

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	public declare partition: PartitionModel;

	@resolvable(PaymentMethodModel, { attributeName: 'paymentMethod', cache: true })
	public declare paymentMethod: PaymentMethodModel;

	@resolvable(PaymentTypeModel, { attributeName: 'paymentType', cache: true })
	public declare paymentType: PaymentTypeModel;

	@computed
	public get allocatedAmount() {
		return (this.amount - this.remainingAmount) + (this.amount - this.remainingAmountDeposit);
	}

	public get remainingAmount(): number {
		return this.get('remainingAmount', 0);
	}

	public get remainingAmountDeposit(): number {
		return this.get('remainingAmountDeposit', 0);
	}

	@computed
	public get billingGroupUrn(): string {
		return this.get('billingGroup.@urn', '');
	}

	@computed
	public get title() {
		return `N° ${this.id}`;
	}
}