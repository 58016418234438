import { resolvable }    from '@mathquis/modelx-resolvables';
import cacheDuration     from 'decorators/cacheDuration';
import computedModels    from 'decorators/computedModels';
import nestedResolvable  from 'decorators/nestedResolvable';
import resolvableUrn     from 'decorators/resolvableUrn';
import reverseResolvable from 'decorators/reverseResolvable';
import staticLabel       from 'decorators/staticLabel';
import urnResource       from 'decorators/urnResource';
import urn               from 'decorators/urn';

export default {
	cacheDuration,
	computedModels,
	nestedResolvable,
	resolvable,
	resolvableUrn,
	reverseResolvable,
	staticLabel,
	urn,
	urnResource,
};