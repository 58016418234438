import Spin                    from 'antd/lib/spin';
import View                    from 'components/View';
import { observer }            from 'mobx-react';
import LoginPage               from 'pages/LoginPage/LoginPage';
import React                   from 'react';
import { authenticationStore } from 'stores';
import Layout                  from '../Layout/Layout';

const Loading = () => (
	<View bottom centerH heightF padding={40}>
		<Spin className="app-centered-spin" size="large" />

		<a onClick={() => authenticationStore.logout()} style={{ zIndex: 2 }}>
			Déconnexion
		</a>
	</View>
);

export default observer(props => {
	if (!authenticationStore.isReady) {
		return <Loading />;
	}

	if (!authenticationStore.isAuthenticated) {
		return <LoginPage {...props} />;
	}

	return <Layout {...props} />;
});
