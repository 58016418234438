import { computed }             from 'mobx';
import { getCountryName }       from 'tools/Countries';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

export default class ContactAddressModel extends DirectoryPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		contact: id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		position: string;
	}> = {};

	static get path(): string {
		return '/contact_addresses/{?id}';
	}

	@computed
	public get contactIri(): string {
		return this.get('contact.@id', '');
	}

	@computed
	public get contactUrn(): string {
		return this.get('contact.@urn', '');
	}

	@computed
	public get address(): string {
		return this.get('address', '');
	}

	@computed
	public get addressBis(): string {
		return this.get('addressBis', '');
	}

	@computed
	public get city(): string {
		return this.get('city', '');
	}

	@computed
	public get country(): string {
		return this.get('country', '');
	}

	@computed
	public get countryName(): string {
		return getCountryName(this.country);
	}

	@computed
	public get zipCode(): string {
		return this.get('zipCode', '');
	}

	@computed
	public get fullAddress(): string {
		return `${this.address} ${this.addressBis ? `${this.addressBis} ` : ''}${this.zipCode} ${this.city} ${this.countryName}`;
	}

	@computed get name(): string {
		return this.get('name', '');
	}

	@computed get position(): number {
		return this.get('position');
	}
}
