import { ApiCollection }       from 'Collections/ApiCollection';
import CompanyAddressModel     from 'Models/directory/CompanyAddressModel';
import CompanyContactInfoModel from 'Models/directory/CompanyContactInfoModel';
import CompanyModel            from 'Models/directory/CompanyModel';
import CompanyTypeModel        from 'Models/directory/CompanyTypeModel';
import { computed }            from 'mobx';
import AbstractModelXStore     from 'stores/AbstractModelXStore';

export default class CompanyDashboardStore extends AbstractModelXStore {

	public company = new CompanyModel();
	public companyAddressCollection = new ApiCollection(CompanyAddressModel);
	public companyContactInfoCollection = new ApiCollection(CompanyContactInfoModel);
	public companyTypeCollection = new ApiCollection(CompanyTypeModel);

	public async initAsync(companyId: number): Promise<void> {
		this.clear();

		await Promise.all([
			this.company
				.setId(companyId)
				.fetch(),

			this.companyAddressCollection
				.setFilter('company', companyId)
				.setSort('position', true)
				.list(),

			this.companyContactInfoCollection
				.setFilter('company', companyId)
				.list(),

			this.companyTypeCollection
				.list(),
		]);
	}

	@computed
	public get companyAddress(): CompanyAddressModel | null {
		return this.companyAddressCollection.first();
	}

	@computed
	public get companyContactInfo(): CompanyContactInfoModel | null {
		return this.companyContactInfoCollection.first();
	}

	@computed
	public get companyType(): CompanyTypeModel | null {
		return this.companyTypeCollection.find(companyType => companyType.id === this.company.companyType.id);
	}
}
