/* eslint-disable max-classes-per-file,@typescript-eslint/ban-ts-comment */

import { ApiCollection }             from 'Collections/ApiCollection';
import ContactModel                  from 'Models/directory/ContactModel';
import ExportTypeModel               from 'Models/intervention/ExportTypeModel';
import IrtitModel                    from 'Models/intervention/InterventionResourceTypeInterventionTypeModel';
import InterventionStatusModel       from 'Models/intervention/InterventionStatusModel';
import InterventionTypeModel         from 'Models/intervention/InterventionTypeModel';
import InterventionVersionModel      from 'Models/intervention/InterventionVersionModel';
import ProtoInterventionVersionModel from 'Models/intervention/ProtoInterventionVersionModel';
import TaskCancellationTypeModel     from 'Models/intervention/TaskCancellationTypeModel';
import VersionModel                  from 'Models/intervention/VersionModel';
import { VersionTypeReference }      from 'Models/intervention/VersionTypeModel';
import VersionTypeModel              from 'Models/intervention/VersionTypeModel';
import StaffMemberModel              from 'Models/rh/StaffMemberModel';
import VehicleModel                  from 'Models/vehicle/VehicleModel';
import _flatten                      from 'lodash/flatten';
import _uniq                         from 'lodash/uniq';
import { computed }                  from 'mobx';
import { observable }                from 'mobx';
import { action }                    from 'mobx';
import { makeObservable }            from 'mobx';
import moment                        from 'moment';
import Proto                         from 'proto/proto';
import AbstractModelXStore           from 'stores/AbstractModelXStore';
import { appStore }                  from 'stores';
import locationTools                 from 'tools/locationTools';
import notificationApiError          from 'tools/notificationApiError';
import protoFetch                    from 'tools/protoFetch';

const { PlanningInterventionVersionCard } = Proto.Service.Intervention;

export default class PlanningClientStore extends AbstractModelXStore {
	public collectionContact = new ApiCollection(ContactModel);
	public collectionExportType = new ApiCollection(ExportTypeModel);
	public collectionInterventionStatus = new ApiCollection(InterventionStatusModel);
	public collectionInterventionType = new ApiCollection(InterventionTypeModel);
	public collectionIrtit = new ApiCollection(IrtitModel);
	public collectionStaffMember = new ApiCollection(StaffMemberModel);
	public collectionTaskCancellationType = new ApiCollection(TaskCancellationTypeModel);
	public collectionVehicle = new ApiCollection(VehicleModel);
	public collectionVersionTypes = new ApiCollection(VersionTypeModel);

	@observable
	public dateFrom: Moment;

	@observable
	public dateTo: Moment;

	public eventWidth = 120;

	public filterCities = observable<string>([]);
	public filterContactIds = observable<id>([]);
	public filterHours = observable<Moment>([]);
	public filterStaffMemberIds = observable<id>([]);
	public virtualCollectionContact: ApiCollection<ContactModel>;

	private _stores: Record<string, PlanningClientDayStore> = {};

	public constructor() {
		super();

		this.dateFrom = moment();
		this.dateTo = moment();

		this.virtualCollectionContact = this.collectionContact
			.createVirtualCollection(c => this.contactIds.includes(c.id.toString()));

		makeObservable(this);
	}

	public async fetchGlobalAsync() {
		await Promise.all([
			this.collectionVersionTypes.list(),
			this.collectionTaskCancellationType.list(),
			this.collectionInterventionStatus.list(),
			this.collectionInterventionType.list(),
			this.collectionIrtit.list(),
			this.collectionContact
				.setSort('lastName')
				.setFilter('generalClients.clients.partitionUrn', appStore.partitionUrn)
				.list(),
			this.collectionVehicle.setSort('name').setFilter('partitionUrn', appStore.partitionUrn).list(),

			this.collectionStaffMember
				.setSort('lastName')
				// .listBy([appStore.partitionUrn], 'staffMemberPartitionGroups.partitionUrn'),
				.list(),

			this.collectionExportType.list(),
		]);
	}

	public getDayStore(day: Moment) {
		let store = this._stores[day.format('L')];

		if (!store) {
			store = new PlanningClientDayStore(day, this);

			this._stores[day.format('L')] = store;
		}

		return store;
	}

	public async loadAsync() {
		const urlParams = new URLSearchParams(window.location.search);

		if (urlParams.has('from') && urlParams.has('to') && urlParams.has('versions')) {
			this.setDates(moment(urlParams.get('from')), moment(urlParams.get('to')));

			const versions = (urlParams.get('versions') || '').split(',');

			await Promise.all(
				this.currentStores.map((store, idx) => {
					store.setVersionId(versions[idx]);
				}),
			);
		} else {
			const storage = JSON.parse(localStorage.getItem(`planning_client_${appStore.partitionUrn}`) || '{}');

			if (storage) {
				this.setDates(moment(storage.from), moment(storage.to));

				const versions = storage.versions;

				await Promise.all(
					this.currentStores.map((store, idx) => {
						store.setVersionId(versions[idx]);
					}),
				);
			}
		}
	}

	public save() {
		const params = {
			...Object.fromEntries(new URLSearchParams(location.search)),

			from: this.dateFrom.format('YYYY-MM-DD'),
			to: this.dateTo.format('YYYY-MM-DD'),
			versions: this.currentStores.map(store => store.versionId.toString()),
		};

		window.history.replaceState(null, '', locationTools.setValues(window.location.href.split('?')[0], params));

		localStorage.setItem(`planning_client_${appStore.partitionUrn}`, JSON.stringify(params));
	}

	@action
	public setDates(from: Moment, to: Moment) {
		this.dateFrom = from;
		this.dateTo = to;

		this.save();
	}

	@computed
	public get contactIds() {
		let contactIds = _flatten(this.currentStores.map(store => store.contactIds));

		if (this.filterContactIds.length) {
			contactIds = contactIds.filter(id => this.filterContactIds.includes(id));
		}

		if (this.filterCities.length) {
			contactIds = contactIds.filter(id => {
				const cities = this.citiesByContact[id] || [];

				return this.filterCities.some(city => cities.includes(city));
			});
		}

		return contactIds;
	}

	@computed
	public get dates() {
		const dates = [this.dateFrom.clone()];

		const currDate = this.dateFrom.clone();

		while (currDate.diff(this.dateTo, 'days') < 0) {
			dates.push(currDate.add(1, 'days').clone());
		}

		return dates;
	}

	@computed
	public get currentStores() {
		return this.dates.map(date => this.getDayStore(date));
	}

	@computed
	public get cities() {
		const cities: string[] = [];

		this.currentStores.forEach(store => {
			store.virtualCollectionProtoInterventionVersion.forEach(e => cities.push(e.city));
		});

		return _uniq(cities).sort();
	}

	@computed
	public get versionIds() {
		return this.currentStores.map(store => store.versionId);
	}

	@computed
	public get citiesByContact() {
		const res: Record<id, string[]> = {};

		this.collectionContact.forEach(contact => {
			const cities: string[] = [];

			this.currentStores.map(store => {
				(store.interventionVersionByContact[contact.id] || []).forEach(e => cities.push(e.city));
			});

			res[contact.id] = _uniq(cities);
		});

		return res;
	}

	public get isLoading() {
		return (
			this.collectionContact.isLoading
			|| this.currentStores.some(store => store.isLoading)
		);
	}

	@computed
	public get protoInterventionVersions() {
		return _flatten(Object.values(this._stores).map(s => s.collectionProtoInterventionVersion.models));
	}
}

export class PlanningClientDayStore extends AbstractModelXStore {
	public collectionProtoInterventionVersion = new ApiCollection(ProtoInterventionVersionModel);
	public collectionVersion = new ApiCollection(VersionModel);

	public date: Moment;

	public virtualCollectionProtoInterventionVersion: ApiCollection<ProtoInterventionVersionModel>;

	private _parent: PlanningClientStore;

	@observable
	private _versionId: id = '';

	public constructor(date, parent: PlanningClientStore) {
		super();

		this.date = date;
		this._parent = parent;

		this.virtualCollectionProtoInterventionVersion = this.collectionProtoInterventionVersion
			.createVirtualCollection(event => {
				let res = true;

				if (event.statusReference === 'to_delete' || event.statusReference === 'deleted') {
					return false;
				}

				if (res && parent.filterStaffMemberIds.length) {
					res = event.staffMemberIds.some(id => parent.filterStaffMemberIds.includes(id));
				}

				if (res && parent.filterHours.length) {
					const hours = parent.filterHours.map(v => {
						return event.scheduleStartDate.clone().set('hour', v.hours()).set('minute', v.minute());
					});

					res = (
						event.scheduleStartDate.isBetween(hours[0], hours[1], undefined, '[)')
						|| event.scheduleEndDate.isBetween(hours[0], hours[1], undefined, '[)')
						|| hours[0].isBetween(event.scheduleStartDate, event.scheduleStartDate, undefined, '[)')
						|| hours[1].isBetween(event.scheduleEndDate, event.scheduleEndDate, undefined, '[)')
					);
				}

				return res;

			}, { comparator: m => (m as ProtoInterventionVersionModel).scheduleStartDate.valueOf() });

		makeObservable(this);
	}


	@computed
	public get interventionVersionByContact() {
		return this.virtualCollectionProtoInterventionVersion.groupBy('contactId');
	}

	public async fetchAsync() {
		try {
			this.setIsLoading(true);

			const cards = await protoFetch(
				'intervention',
				'/intervention_versions/_planning_cards',
				// @ts-ignore
				PlanningInterventionVersionCard as unknown as PlanningInterventionVersionCard,
				{
					[`order[scheduleStartDate]`]: 'asc',
					'pagination': false,
					'version': this.versionId,

				} as ModelFilters<InterventionVersionModel>,
			);

			this.collectionProtoInterventionVersion.set(cards.map(c => {
				return new ProtoInterventionVersionModel(c.toJSON());
			}));
		} catch (err) {
			notificationApiError(err);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action
	public setVersionId(versionId: id) {
		if (this._versionId != versionId) {
			this._versionId = versionId?.toString();

			this._parent.save();

			if (versionId) {
				return this.fetchAsync();
			}
		}
	}

	public setVersionWithType(reference: Exclude<VersionTypeReference, 'draft'>) {
		const version = this.collectionVersion.find(v => v.versionType.reference === reference);

		if (version) {
			return this.setVersionId(version.id);
		}
	}

	@computed
	public get versionId() {
		return this._versionId || '';
	}

	@computed
	public get version() {
		return this.collectionVersion.getById(this._versionId) || new VersionModel();
	}

	@computed
	public get contactIds() {
		return Object.keys(this.interventionVersionByContact);
	}

	@computed
	public get width() {
		const arr = Object.values(this.interventionVersionByContact).map(v => v.length);
		const maxInter = arr.length ? Math.max(...arr) : 0;

		const gap = 8;
		const minWidth = 270;
		const colWidth = (this._parent.eventWidth * maxInter) + (2 * gap) + (maxInter - 1) * gap;

		if (this._parent.dates.length > 2) {
			return (colWidth < minWidth) ? minWidth : colWidth;
		}

		return undefined;
	}
}
