import { ApiCollection }                   from 'Collections/ApiCollection';
import { PagedCollection }                 from 'Collections/PagedCollection';
import InterventionOperationModel          from 'Models/intervention/InterventionOperationModel';
import IrshModel                           from 'Models/intervention/InterventionResourceStatusHistoryModel';
import IrtitModel                          from 'Models/intervention/InterventionResourceTypeInterventionTypeModel';
import InterventionResourceTypeModel       from 'Models/intervention/InterventionResourceTypeModel';
import InterventionStatusHistoryModel      from 'Models/intervention/InterventionStatusHistoryModel';
import InterventionStatusModel             from 'Models/intervention/InterventionStatusModel';
import InterventionTimeLogModel            from 'Models/intervention/InterventionTimeLogModel';
import InterventionVersionAnomalyFlagModel from 'Models/intervention/InterventionVersionAnomalyFlagModel';
import InterventionVersionModel            from 'Models/intervention/InterventionVersionModel';
import InterventionVersionResourceModel    from 'Models/intervention/InterventionVersionResourceModel';
import TaskCancellationModel               from 'Models/intervention/TaskCancellationModel';
import TaskCancellationTypeModel           from 'Models/intervention/TaskCancellationTypeModel';
import { computed }                        from 'mobx';
import { when }                            from 'mobx';
import AbstractModelXStore                 from 'stores/AbstractModelXStore';

export default class InterventionVersionDashboardStore extends AbstractModelXStore {
	public collectionInterventionOperation = new ApiCollection(InterventionOperationModel);
	public collectionInterventionResourceType = new ApiCollection(InterventionResourceTypeModel);
	public collectionInterventionStatus = new ApiCollection(InterventionStatusModel);
	public collectionInterventionTimeLog = new ApiCollection(InterventionTimeLogModel);
	public collectionInterventionVersionResource = new ApiCollection(InterventionVersionResourceModel);
	public collectionIrsh = new ApiCollection(IrshModel);
	public collectionIrtit = new ApiCollection(IrtitModel);
	public collectionTaskCancellationType = new ApiCollection(TaskCancellationTypeModel);
	public interventionVersion = new InterventionVersionModel();
	public interventionVersionAnomalyFlagCollection = new ApiCollection(InterventionVersionAnomalyFlagModel);
	public pagedCollectionInterventionStatusHistory = new PagedCollection(InterventionStatusHistoryModel);
	public pagedCollectionTaskCancellation = new PagedCollection(TaskCancellationModel);

	public async fetchAsync(id: id) {
		this.clear();

		await Promise.all([
			this.collectionTaskCancellationType.list(),
			this.collectionInterventionResourceType.list(),

			this.interventionVersion.setId(id).fetch().then(async () => {
				await Promise.all([
					this.collectionInterventionTimeLog
						.listBy([this.interventionVersion.intervention.id], 'intervention'),

					this.collectionInterventionOperation
						.listBy([this.interventionVersion.intervention.id], 'intervention'),

					this.interventionVersionAnomalyFlagCollection
						.listBy([this.interventionVersion.id], 'interventionVersion'),

					this.pagedCollectionInterventionStatusHistory
						.setSort('id', false)
						.listBy([this.interventionVersion.intervention.id], 'intervention'),

					this.collectionIrsh
						.setSort('datetime', 'asc')
						.listBy([this.interventionVersion.intervention.id], 'intervention'),

				]);
			}),

			this.collectionInterventionVersionResource.setFilter('interventionVersion', id).list(),

			this.collectionInterventionStatus.list(),
		]);

		when(() => this.interventionVersion.intervention.interventionType.isLoaded, () => {
			this.collectionIrtit
				.setFilter('interventionType', this.interventionVersion.intervention.interventionType.id)
				.list();
		});

		when(() => !!this.task?.isLoaded, () => {
			if (this.task?.id) {
				this.pagedCollectionTaskCancellation.setFilter('taskTaskCancellations.task', this.task.id).list();
			}
		});
	}

	@computed
	public get timeLogInterventionResourceTypes() {
		const interventionResourceTypes = this.collectionIrtit
			.filter(irtit => irtit.interventionResourceType.resourceType.reference === 'technician')
			.map(irtit => irtit.interventionResourceType);

		const transport = this.collectionInterventionResourceType.find(irt => irt.reference === 'transport');

		if (transport) {
			interventionResourceTypes.push(transport);
		}

		return interventionResourceTypes;
	}

	@computed
	public get technicianInterventionVersionResources() {
		return this.collectionInterventionVersionResource
			.filter(ivr => ivr.interventionResourceType.resourceType.reference === 'technician');
	}

	@computed
	public get task() {
		const taskOperation = this.collectionInterventionOperation.first()?.taskOperation;

		return taskOperation?.task;
	}

	@computed
	public get taskZone() {
		const taskOperation = this.collectionInterventionOperation.first()?.taskOperation;

		return taskOperation?.taskZone;
	}

	@computed
	public get interventionStatus() {
		return this.interventionVersion.intervention.interventionStatus;
	}

	@computed
	public get isIntervention() {
		return this.interventionVersion.intervention.interventionType.reference === 'intervention';
	}
}
