import lazyWithRetry from 'tools/lazyWithRetry';

const ContractListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "contractListPage" */
	'../../pages/Contract/ContractListPage'));

const ContractDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "contractDashboardPage" */
	'../../pages/Contract/ContractDashboardPage'));

export default [
	{
		component: ContractListPage,
		exact: true,
		path: '/partitions/:partitionId/contracts',
	},
	{
		component: ContractDashboardPage,
		exact: true,
		path: '/partitions/:partitionId/contracts/:contractId',
	},
];

