// eslint-disable-next-line max-classes-per-file
import { resolvable }       from '@mathquis/modelx-resolvables';
import CompanyModel         from 'Models/directory/CompanyModel';
import ContactModel         from 'Models/directory/ContactModel';
import NodeTypeModel        from 'Models/graph/NodeTypeModel';
import StaffMemberModel     from 'Models/rh/StaffMemberModel';
import { urn }              from 'helpers/ModelDictionary';
import AbstractApiModel     from '../../abstracts/AbstractApiModel';
import GraphPrivateApiModel from '../../abstracts/GraphPrivateApiModel';

export interface IResourceEntityModel extends AbstractApiModel {
	commercialName: string;
	fullName: string;
	urn: Urn;
}

export class IdentifierEntityEmptyModel extends AbstractApiModel implements IResourceEntityModel {
	public get commercialName(): string {
		return '';
	}

	public get fullName(): string {
		return '';
	}

	public get mobileNumber(): string {
		return '';
	}

	public get phoneNumber(): string {
		return '';
	}

	public get email(): string {
		return '';
	}
}

@urn('$', 'graph', 'node')
export default class NodeModel extends GraphPrivateApiModel {

	static get path(): string {
		return '/nodes/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'identifier': id;
		'nodeType': id;
	}> = {};


	// public get identifier(): string {
	// 	return this.get('identifier', '');
	// }

	@resolvable(IdentifierEntityEmptyModel, {
		allow: [ContactModel, StaffMemberModel, CompanyModel],
		attributeName: 'identifier',
		unresolved: IdentifierEntityEmptyModel,
	})
	public declare identifier: IdentifierEntityEmptyModel;

	@resolvable(NodeTypeModel, { attributeName: 'nodeType', cache: true, })
	public declare nodeType: NodeTypeModel;
}
