import { resolvable }         from '@mathquis/modelx-resolvables';
import VehicleModel           from 'Models/vehicle/VehicleModel';
import VehiclePhotoReadModel  from 'Models/vehicle/VehiclePhotoReadModel';
import { urn }                from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                             from 'helpers/traits';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';
import withBlob               from '../../mixins/withBlob';

@urn('$', 'vehicle', 'vehiclePhoto')
export default class VehiclePhotoModel extends Timestampable(Blamable(VehiclePrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
		'vehicle': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	static get path(): string {
		return '/vehicle_photos/{?id}';
	}

	@resolvable(VehicleModel, { attributeName: 'vehicle' })
	public declare vehicle: VehicleModel;

	private _readModel = new (withBlob(VehiclePhotoReadModel))();

	public get contentUrl(): string {
		return this.get('contentUrl', '');
	}

	public get file(): string {
		return this.get('file', '');
	}

	public get fileMimeType(): string {
		return this.get('fileMimeType', '');
	}

	public get fileOriginalName(): string {
		return this.get('fileOriginalName', '');
	}

	public get filePath(): string {
		return this.get('filePath', '');
	}

	public get fileSize(): number {
		return this.get('fileSize', 0);
	}

	public get folder(): string {
		return this.get('folder', '');
	}

	public get readModel() {
		this._readModel.setId(this.id);

		return this._readModel;
	}
}