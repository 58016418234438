import lazyWithRetry from 'tools/lazyWithRetry';

const InvoiceDashboardPage = lazyWithRetry(() => import(
	'../../pages/Invoice/InvoiceDashboardPage/InvoiceDashboardPage'));

const InvoiceListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "invoiceListPage" */
	'../../pages/Invoice/InvoiceListPage/InvoiceListPage'));

export default [
	{
		component: InvoiceDashboardPage,
		exact: true,
		path: '/partitions/:partitionId/invoices/:invoiceId',
	},
	{
		component: InvoiceListPage,
		exact: true,
		path: '/partitions/:partitionId/invoices',
	}
];