import { urn }                from 'helpers/ModelDictionary';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type InvoiceTypeReference = 'invoice' | 'refund' | 'deposit';

@urn('$', 'invoice', 'invoice_type')
export default class InvoiceTypeModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/invoice_types/{?id}';
	}

	public static cacheDuration = 3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): InvoiceTypeReference {
		return this.get('reference', '');
	}
}