import { resolvable }       from '@mathquis/modelx-resolvables';
import RelationModel        from 'Models/graph/RelationModel';
import { urn }              from 'helpers/ModelDictionary';
import GraphPrivateApiModel from '../../abstracts/GraphPrivateApiModel';

export type RelationMetaReference =
	'relation_link' |
	'court_name' |
	'decision_date';

@urn('$', 'graph', 'relation_meta')
export default class RelationMetaModel extends GraphPrivateApiModel {

	static get path(): string {
		return '/relation_metas/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': string;
		'relation': id;
	}> = {};


	public get reference(): RelationMetaReference {
		return this.get('reference', '');
	}

	@resolvable(RelationModel, { attributeName: 'relation' })
	public declare relation: RelationModel;

	public get value(): string {
		return this.get('value', '');
	}
}