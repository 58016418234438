import { resolvable }              from '@mathquis/modelx-resolvables';
import ResourceModel               from 'Models/intervention/ResourceModel';
import { ResourceTypeReference }   from 'Models/intervention/ResourceTypeModel';
import { WeekTypeReference }       from 'Models/intervention/WeekTypeModel';
import WeekTypeModel               from 'Models/intervention/WeekTypeModel';
import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import { computed }                from 'mobx';
import moment                      from 'moment';
import { getWeekdayName }          from 'tools/Weekdays';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'resource_availability')
export default class ResourceAvailabilityModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/resource_availabilities/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'day': number;
		'day[between]': string;
		'day[gt]': string;
		'day[gte]': string;
		'day[lt]': string;
		'day[lte]': string;
		'endHour[after]': string;
		'endHour[before]': string;
		'endHour[strictly_after]': string;
		'endHour[strictly_before]': string;
		'resource': id;
		'resource.partitionUrn': string;
		'resource.ownerResource': id;
		'resource.ownerResource.entityUrn': string;
		'resource.resourceType': id;
		'resource.resourceType.reference': ResourceTypeReference;
		'startHour[after]': string;
		'startHour[before]': string;
		'startHour[strictly_after]': string;
		'startHour[strictly_before]': string;
		'weekType': id;
		'weekType.reference': WeekTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'day': string;
		'endHour': string;
		'id': string;
		'startHour': string;
		'updatedAt': string;
	}> = {};

	public get day(): number {
		return this.get('day', 0);
	}

	public get resourceId(): id {
		return this.get('resource.id', 0);
	}

	@computed
	public get label() {
		return `${getWeekdayName(this.day as never)} de ${this.startHour.format('HH[h]mm')} à ${
			this.endHour.format('HH[h]mm')} (${this.weekType.label})`;
	}

	@computed
	public get endHour(): Moment {
		return moment(this.get('endHour', moment()), 'HH:mm');
	}

	@resolvable(ResourceModel, { attributeName: 'resource' })
	public declare resource: ResourceModel;

	@computed
	public get startHour(): Moment {
		return moment(this.get('startHour', moment()), 'HH:mm');
	}

	@resolvable(WeekTypeModel, { attributeName: 'weekType' })
	public declare weekType: WeekTypeModel;
}