import { resolvable }         from '@mathquis/modelx-resolvables';
import DepositModel           from 'Models/invoice/DepositModel';
import PaymentModel           from 'Models/invoice/PaymentModel';
import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'deposit_payment')
export default class DepositPaymentModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/deposit_payments/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'deposit': id;
		'payment': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'deposit.date': string;
		'id': string;
		'payment.date': string;
	}> = {};

	public get amount(): number {
		return this.get('amount', 0);
	}

	@resolvable(DepositModel, { attributeName: 'deposit' })
	public declare deposit: DepositModel;

	@resolvable(PaymentModel, { attributeName: 'payment' })
	public declare payment: PaymentModel;
}