import { resolvable }       from '@mathquis/modelx-resolvables';
import ClientModel          from 'Models/directory/ClientModel';
import BillingGroupModel    from 'Models/invoice/BillingGroupModel';
import VatModel             from 'Models/invoice/VatModel';
import ContractStatusModel  from 'Models/sales/ContractStatusModel';
import OwnerModel           from 'Models/sales/OwnerModel';
import RecurringModeModel   from 'Models/sales/RecurringModeModel';
import model                from 'decorators/model';
import { urn }              from 'helpers/ModelDictionary';
import { Blamable }         from 'helpers/traits';
import { Timestampable }    from 'helpers/traits';
import { computed }         from 'mobx';
import moment               from 'moment';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Contrat cadre')
@urn('$', 'sales', 'contract')
export default class ContractModel extends Timestampable(Blamable(SalesPrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'archived': string;
		'billingGroupUrn': string;
		'contractStatus': id;
		'contractStatus.reference': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'exists[timeGroupUrn]': boolean;
		'owner.clientUrn': string;
		'owner.contactUrn': string;
		'owner.generalClientUrn': string;
		'owner.partitionUrn': string;
		'recurringMode': id;
		'recurringMode.reference': string;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
		'timeGroupUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'contractStatus.position': string;
		'endDate': string;
		'id': string;
		'number': string;
		'startDate': string;
	}> = {};

	static get path(): string {
		return '/contracts/{?id}';
	}

	@resolvable(BillingGroupModel, { attributeName: 'billingGroupUrn' })
	public declare billingGroup: BillingGroupModel;

	@resolvable(ClientModel, { attributeName: 'clientUrn' })
	public declare client: ClientModel;

	@resolvable(ContractStatusModel, { attributeName: 'contractStatus', cache: true })
	public declare contractStatus: ContractStatusModel;

	@resolvable(OwnerModel, { attributeName: 'owner', cache: true })
	public declare owner: OwnerModel;

	@resolvable(RecurringModeModel, { attributeName: 'recurringMode', cache: true })
	public declare recurringMode: RecurringModeModel;

	@resolvable(VatModel, { attributeName: 'vatUrn', cache: true })
	public declare vat: VatModel;

	public get timeGroupUrn(): string { // Ne pas utiliser de resolvable ici car le contrat est déjà sur le TimeGroup
		return this.get('timeGroupUrn', '');
	}

	public get clientUrn(): string {
		return this.get('clientUrn', '');
	}

	@computed
	public get archived(): boolean {
		return this.get('archived', false);
	}

	@computed
	public get endDate() {
		const endDate = this.get('endDate');
		return endDate ? moment(endDate) : undefined;
	}

	public get number(): string {
		return this.get('number', '');
	}

	@computed
	public get startDate() {
		const startDate = this.get('startDate');
		return startDate ? moment(startDate) : undefined;
	}

	@computed
	public get endDateIsPassed() {
		return !!this.endDate?.isSameOrBefore(moment());
	}

	@computed
	public get duration() {
		if (this.startDate && this.endDate) {
			return moment.duration(this.endDate.diff(this.startDate));
		}

		return undefined;
	}

	@computed
	public get billingGroupUrn(): string {
		return this.get('billingGroupUrn', '');
	}
}