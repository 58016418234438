import { lazy } from 'react';

const LoginPage = lazy(() => import(
	/* webpackChunkName: "LoginPage" */
	'../../pages/LoginPage/LoginPage'));

const ChangePasswordPage = lazy(() => import(
	/* webpackChunkName: "ChangePasswordPage" */
	'../../pages/ChangePasswordPage/ChangePasswordPage'));

const ResetPasswordPage = lazy(() => import(
	/* webpackChunkName: "ResetPasswordPage" */
	'../../pages/ResetPasswordPage/ResetPasswordPage'));

export default [
	{
		component: LoginPage,
		exact: true,
		path: '/login',
	},
	{
		component: ChangePasswordPage,
		exact: true,
		path: '/change_password/:token',
	},
	{
		component: ResetPasswordPage,
		exact: true,
		path: '/reset_password',
	},
];
