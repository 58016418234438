window['global'] ||= window;

import * as Sentry from '@sentry/react';
import App         from 'projectComponents/App/App';
import ReactDOM    from 'react-dom/client';
import React       from 'react';
import ConfigProxy from 'tools/ConfigProxy';

Sentry.init({
	beforeSend(event, hint) {
		console.warn(`SENTRY SEND\n`, hint?.originalException);

		return event;
	},
	dsn: 'https://70c771fae4ff42e08f307ac250108dec@o240489.ingest.sentry.io/6182125',
	environment: ConfigProxy.get('APP_ENV'),
	ignoreErrors: [
		'ResizeObserver loop limit exceeded',
		'ResizeObserver loop completed with undelivered notifications.',
		/-> Network Error/,
		/-> Request failed with status code/,
	],
	integrations: [new Sentry.Replay()],
	// If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	// This sets the sample rate to be 10%. You may want this to be 100% while in development and
	// sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	tracesSampleRate: 0.2,
});

ReactDOM.createRoot(document.getElementById('main') as HTMLElement).render(<App />);

// ViteJS - On force un rafraichissement complet quand il y a une erreur avec le hot reloading
import.meta.hot?.on('vite:invalidate', () => location.reload());
