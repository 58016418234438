import { IResourceEntityModel }  from 'Models/intervention/OwnerResourceModel';
import StaffMemberPhotoModel     from 'Models/rh/StaffMemberPhotoModel';
import StaffMemberPhotoReadModel from 'Models/rh/StaffMemberPhotoReadModel';
import ColorHash                 from 'color-hash';
import doc                        from 'decorators/doc';
import model                       from 'decorators/model';
import { computed }              from 'mobx';
import RhPrivateApiModel         from '../../abstracts/RhPrivateApiModel';
import withBlob                  from '../../mixins/withBlob';

@model.staticLabel('Employé')
@model.urn('$', 'rh', 'staff_member')
@doc.path('/staff_members/{?id}')
export default class StaffMemberModel extends RhPrivateApiModel implements IResourceEntityModel {
	public _filters: ModelFiltersExtended<{
		'dateOut[after]': string;
		'dateOut[before]': string;
		'dateOut[strictly_after]': string;
		'dateOut[strictly_before]': string;
		'enabled': boolean;
		'staffMemberContracts.type': id;
		'staffMemberPartitionGroups.partitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'firstName': string;
		'id': string;
		'lastName': string;
	}> = {};

	@doc.date declare birthdate?: Moment;
	@doc.string declare comment: string;
	@doc.date declare dateIn?: Moment;
	@doc.date declare dateOut?: Moment;
	@doc.string declare driverLicenseNumber: string;
	@doc.string declare email: string;
	@doc.boolean declare enabled: boolean;
	@doc.string declare firstName: string;
	@doc.string declare gender: string;
	@doc.string declare lastName: string;
	@doc.string declare nationality: string;
	@doc.string declare password: string;
	@doc.string declare phoneNumber: string;
	@doc.string declare plainPassword: string;
	@doc.string declare socialSecurityNumber: string;
	@doc.model(StaffMemberPhotoModel) declare staffMemberPhoto: StaffMemberPhotoModel;

	private _photoReadModel = new (withBlob(StaffMemberPhotoReadModel))();

	@computed
	public get photoReadModel() {
		return this._photoReadModel;
	}

	@computed
	public get fullName(): string {
		return this.lastName.toUpperCase() + ' ' + this.firstName;
	}

	@computed
	public get initials(): string {
		return this.firstName.charAt(0) + this.lastName.charAt(0);
	}

	@computed
	public get genderPrefix() {
		return this.gender === 'MALE' ? 'M.' : 'Mme';
	}

	@computed
	public get isOut() {
		return !!(this.dateOut && this.dateOut.isBefore());
	}

	@computed
	public get color(): string {
		return (new ColorHash()).hex(this.id.toString());
	}
}