import { resolvable }         from '@mathquis/modelx-resolvables';
import VehicleModel           from 'Models/vehicle/VehicleModel';
import { urn }                from 'helpers/ModelDictionary';
import { computed }           from 'mobx';
import moment                 from 'moment';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@urn('$', 'vehicle', 'vehicleUnavailability')
export default class VehicleUnavailabilityModel extends VehiclePrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'endDate[after]': string;
		'endDate[before]': string;
		'endDate[strictly_after]': string;
		'endDate[strictly_before]': string;
		'startDate[after]': string;
		'startDate[before]': string;
		'startDate[strictly_after]': string;
		'startDate[strictly_before]': string;
		'vehicle': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'endDate': string;
		'id': string;
		'startDate': string;
		'updatedAt': string;
	}> = {};

	static get path(): string {
		return '/vehicle_unavailabilities/{?id}';
	}

	@resolvable(VehicleModel, { attributeName: 'vehicle' })
	public declare vehicle: VehicleModel;

	public get comment(): string {
		return this.get('comment', '');
	}

	@computed
	public get endDate(): Moment {
		return moment(this.get('endDate', moment()));
	}

	@computed
	public get startDate(): Moment {
		return moment(this.get('startDate', moment()));
	}

}