import model                from 'decorators/model';
import { urn }              from 'helpers/ModelDictionary';
import { Blamable }         from 'helpers/traits';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel(`Groupe d'activité`)
@urn('$', 'sales', 'activity_group')
export default class ActivityGroupModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	static get path(): string {
		return '/activity_groups/{?id}';
	}

	public get name(): string {
		return this.get('name', '');
	}
}