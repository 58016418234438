import { resolvable }       from '@mathquis/modelx-resolvables';
import ContractModel        from 'Models/sales/ContractModel';
import LogTypeModel         from 'Models/time/LogTypeModel';
import { urn }              from 'helpers/ModelDictionary';
import { computed }         from 'mobx';
import moment               from 'moment';
import { Blamable }         from '../../../../helpers/traits';
import { Timestampable }    from '../../../../helpers/traits';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'contract_suspension')
export default class ContractSuspensionModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'contract': id;
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
	}> = {};

	static get path(): string {
		return '/contract_suspensions/{?id}';
	}

	@resolvable(ContractModel, { attributeName: 'contract' })
	public declare contract: ContractModel;

	@resolvable(LogTypeModel, { attributeName: 'logTypeUrn' })
	public declare logType: LogTypeModel;

	public get comment(): string {
		return this.get('comment', '');
	}

	@computed
	public get endDate(): Moment {
		return moment(this.get('endDate', moment()));
	}

	@computed
	public get startDate(): Moment {
		return moment(this.get('startDate', moment()));
	}
}