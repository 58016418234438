import { resolvable }           from '@mathquis/modelx-resolvables';
import DebitMandateDocumentReadModel from 'Models/invoice/DebitMandateDocumentReadModel';
import DebitMandateModel        from 'Models/invoice/DebitMandateModel';
import { urn }                  from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                                    from 'helpers/traits';
import { computed }                  from 'mobx';
import ConfigProxy                   from 'tools/ConfigProxy';
import downloadFile                  from 'tools/downloadFile';
import notificationApiError          from 'tools/notificationApiError';
import InvoicePrivateApiModel        from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'debit_mandate_document')
export default class DebitMandateDocumentModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/debit_mandate_documents/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'debitMandate': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public get contentUrl(): string {
		return this.get('contentUrl', '');
	}

	@resolvable(DebitMandateModel, { attributeName: 'debitMandate' })
	public declare debitMandate: DebitMandateModel;

	public get file(): string {
		return this.get('file', '');
	}

	public get fileMimeType(): string {
		return this.get('fileMimeType', '');
	}

	public get fileOriginalName(): string {
		return this.get('fileOriginalName', '');
	}

	/*public get filePath(): string {
		return this.get('filePath', '');
	}*/

	public get fileSize(): number {
		return this.get('fileSize', 0);
	}

	public get folder(): string {
		return this.get('folder', '');
	}

	@computed
	public get readUrl(): string {
		return `${ConfigProxy.getServiceConfig('invoice', 'api_endpoint')}${this.path}/read`;
	}

	public async downloadPdfAsync() {
		try {
			const debitMandateDocumentReadModel = new DebitMandateDocumentReadModel({ id: this.id });

			await debitMandateDocumentReadModel.fetch();

			downloadFile(
				debitMandateDocumentReadModel.blob,
				`${this.fileOriginalName}`,
			);
		} catch (err) {
			notificationApiError(err);
		}
	}
}