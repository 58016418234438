import { resolvable }           from '@mathquis/modelx-resolvables';
import CompanyGroupModel        from 'Models/directory/CompanyGroupModel';
import CompanyTypeModel         from 'Models/directory/CompanyTypeModel';
import model                    from 'decorators/model';
import { urn }                  from 'helpers/ModelDictionary';
import { Blamable }             from 'helpers/traits';
import { Timestampable }        from 'helpers/traits';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Entité', false)
@urn('$', 'directory', 'company')
export default class CompanyModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'commercialName': string;
		'companyGroup': id;
		'companyType': id;
		'name': string;
		'siretNumber': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'commercialName': string;
		'companyContactInfos.email': string;
		'companyContactInfos.mobileNumber': string;
		'companyContactInfos.phoneNumber': string;
		'companyType.label': string;
		'id': string;
		'name': string;
		'siretNumber': string;
	}> = {};

	static get path(): string {
		return '/companies/{?id}';
	}

	@resolvable(CompanyGroupModel, { attributeName: 'companyGroup', cache: true })
	public declare companyGroup: CompanyGroupModel;

	@resolvable(CompanyTypeModel, { attributeName: 'companyType', cache: true })
	public declare companyType: CompanyTypeModel;

	public get commercialName(): string {
		return this.get('commercialName', '');
	}

	public get intraCommunityVat(): string {
		return this.get('intraCommunityVat', '');
	}

	public get name(): string {
		return this.get('name', '');
	}

	public get siretNumber(): string {
		return this.get('siretNumber', '');
	}
}