import { urn }               from 'helpers/ModelDictionary';
import RightsPrivateApiModel from '../../abstracts/RightsPrivateApiModel';

export type ContextTypeReference = 'global' | 'partition';

@urn('$', 'rights', 'context_type')
export default class ContextTypeModel extends RightsPrivateApiModel {

	static get path(): string {
		return '/context_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'actionGroups': id;
		'reference': ContextTypeReference;
		'rights': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	public get label(): string {
		return this.get('label', '');
	}

	public get mask(): string {
		return this.get('mask', '');
	}

	public get reference(): ContextTypeReference {
		return this.get('reference', '');
	}
}