import lazyWithRetry from 'tools/lazyWithRetry';

const PartitionGroupListPage = lazyWithRetry(() => import(
	'../../pages/PartitionGroup/PartitionGroupListPage'));

const PartitionGroupDashboardPage = lazyWithRetry(() => import(
	'../../pages/PartitionGroup/PartitionGroupDashboardPage'));

const SynthesisAccessRightsPage = lazyWithRetry(() => import(
	'../../pages/SynthesisAccessRights/SynthesisAccessRightPage'));

export default [
	{
		component: PartitionGroupListPage,
		exact: true,
		path: '/partition_groups',
	},
	{
		component: PartitionGroupDashboardPage,
		exact: true,
		path: '/partition_groups/:partitionGroupId',
	},
	{
		component: SynthesisAccessRightsPage,
		exact: true,
		path: '/synthesis_access_rights',
	},
];