import { urn }              from 'helpers/ModelDictionary';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export type ContractIterationItemScheduleTypeReference = 'every_weeks' | 'only_weeks' | 'the_nths';

@urn('$', 'sales', 'contract_iteration_item_schedule_type')
export default class ContractIterationItemScheduleTypeModel extends SalesPrivateApiModel {

	static get path(): string {
		return '/contract_iteration_item_schedule_types/{?id}';
	}

	public static cacheDuration = 3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): ContractIterationItemScheduleTypeReference {
		return this.get('reference', '');
	}
}