import { CloseOutlined } from '@ant-design/icons';

import classNames        from 'classnames';
import View              from 'components/View';
import React             from 'react';
import './SidebarWrapper.scss';

export interface ISidebarWrapperProps {
	absolute?: boolean;
	renderSidebarContent?: () => React.ReactNode;
	renderTitle?: () => React.ReactNode;
	width?: number;
}

export default class SidebarWrapper<T> extends React.PureComponent<React.PropsWithChildren<T & ISidebarWrapperProps>> {
	public state = { collapsed: true };

	public collapse = (value = true): void => {
		this.setState({ collapsed: value });
	};

	public render() {
		const { absolute, children, renderTitle, width } = this.props;

		const { collapsed } = this.state;

		return (
			<div className="SidebarWrapper">
				<div className="SidebarWrapper__content">
					{children}
				</div>

				<div
					className={classNames('SidebarWrapper__sidebar', { absolute, collapsed })}
					style={{ width: width || 300 }}
				>
					<div className="SidebarWrapper__headerClose">
						<CloseOutlined onClick={this.toggleCollapse} />
					</div>

					{!!renderTitle && (
						<div className="SidebarWrapper__headerTitle">
							{renderTitle()}
						</div>
					)}

					{this._renderHead()}

					<View
						marginB={-20}
						marginH={-20}
						marginT={20}
						paddingH={20}
						paddingV={10}
						separatorT
						style={{ overflowY: 'auto' }}
					>
						{this._renderBody()}
					</View>
				</div>
			</div>
		);
	}

	public toggleCollapse = (): void => {
		this.collapse(!this.state.collapsed);
	};

	protected _renderBody = () => {
		const { renderSidebarContent } = this.props;

		return renderSidebarContent ? renderSidebarContent() : null;
	};

	protected _renderHead: () => JSX.Element | null = () => null;
}