import { urn }                from 'helpers/ModelDictionary';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'currency')
export default class CurrencyModel extends InvoicePrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'code': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'code': string;
		'id': string;
		'label': string;
	}> = {};

	static get path(): string {
		return '/currencies/{?id}';
	}

	public get code(): string {
		return this.get('code', '');
	}

	public get label(): string {
		return this.get('label', '');
	}
}