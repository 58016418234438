import { ArgsProps }    from 'antd/lib/notification';
import { notification } from 'antd';

notification.config({
	maxCount: 5,
	placement : 'top',
});

export const notificationSuccess = (options: Partial<ArgsProps> | string) => {
	notification['success']({
		className: 'project-notification success',
		message: `Success`,
		...(typeof options === 'string' ? { message: options } : options),
	});
};

export const notificationError = (options: Partial<ArgsProps> | string) => {
	notification['error']({
		className: 'project-notification error',
		message: `Error`,
		...(typeof options === 'string' ? { message: options } : options),
	});
};