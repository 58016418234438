import { resolvable }        from '@mathquis/modelx-resolvables';
import ContextTypeModel      from 'Models/rights/ContextTypeModel';
import { urn }               from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                            from 'helpers/traits';
import RightsPrivateApiModel from '../../abstracts/RightsPrivateApiModel';

@urn('$', 'rights', 'right')
export default class RightModel extends Blamable(Timestampable(RightsPrivateApiModel)) {

	static get path(): string {
		return '/rights/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'contextType': id;
		'system': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@resolvable(ContextTypeModel, { attributeName: 'contextType' })
	public declare contextType: ContextTypeModel;

	public get label(): string {
		return this.get('label', '');
	}

	public get system(): boolean {
		return this.get('system', false);
	}
}