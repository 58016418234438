import { HomeOutlined }    from '@ant-design/icons';
import Breadcrumb          from 'antd/lib/breadcrumb';
import View                from 'components/View';
import ModelDictionary     from 'helpers/ModelDictionary';
import _sortBy             from 'lodash/sortBy';
import { observer }        from 'mobx-react';
import { Link }            from 'react-router-dom';
import React               from 'react';
import routes              from 'routes';
import { appStore }        from 'stores';
import { getIdFromUrn }    from 'tools/UrnTools';
import { getAllMenuItems } from './components/Navigation';

export default observer((props) => {
	const [items, setItems] = React.useState<{ items: []; name: string; path: string; }[]>([]);
	const [mounted, setMounted] = React.useState(false);

	React.useEffect(() => {
		const crumbs = routes
			.filter(({ path }) => {
				return (
					props.match.path.includes(path)
					|| ('/partitions/:partitionId' + props.match.path).includes(path)
				);
			})
			.map(({ path, ...rest }) => ({
				path: Object.keys(props.match.params).length
					? Object.keys(props.match.params).reduce(
						(path, param) => path.replace(
							`:${param}`, props.match.params[param] || '',
						), path,
					)
					: path,
				...rest,
			}));

		const models = ModelDictionary.models;

		const menuItems = getAllMenuItems();

		setItems(_sortBy(crumbs
			.filter(({ path }) => !['/', '/*'].includes(path))
			.filter(() => props.location.pathname !== '/')
			.filter(({ path }) => path !== '/partitions' || crumbs.length <= 3)
			.map(({ path }) => {
				let name = '';
				const mpath = path.replace(':partitionId', getIdFromUrn(appStore.partitionUrn).toString());
				const mpath2 = path.replace('/partitions/', '');

				const items = getParent(menuItems, mpath) || [];

				if (items.length) {
					name = items.find(i => i.key === mpath)?.label;
				} else {
					const modelDashboard = models.find(m => mpath2.match(new RegExp(m.path.replace('{?id}', '.*'))));
					if (modelDashboard) {
						name = `Fiche ${modelDashboard.staticLabel.toLowerCase()}`;
					} else {
						const modelList = models.find(m => path === m.path.replace('/{?id}', ''));

						if (modelList) {
							name = `Liste des ${modelList.pluralStaticLabel.toLowerCase()}`;
						}
					}
				}

				return { items: items.filter(i => i.key !== path), name, path };
			})
			.filter(c => c.name), c => c.path.replace('/partitions/:partitionId', '')));

	}, [props.match.path, ModelDictionary.size]);

	React.useEffect(() => setMounted(true), []);

	if (!mounted) {
		return <View height={46} paddingH={40} paddingV={8} />;
	}

	if (!items.length) {
		return null;
	}

	return (
		<View paddingH={40} paddingV={8} width="max-content">
			<View bg="rgba(0,0,0,0.08)" br={4} paddingH={10} paddingV={4}>
				<Breadcrumb separator=">">
					<Breadcrumb.Item>
						<Link to={'/'}><HomeOutlined /></Link>
					</Breadcrumb.Item>

					{items.map(({ items, name }, i) => {
						return (
							<Breadcrumb.Item key={i} menu={items.length ? { items } : undefined}>
								{name}
							</Breadcrumb.Item>
						);
					})}
				</Breadcrumb>
			</View>
		</View>
	);
});

const getParent = (data, wantedKey: string) => {
	for (const { children, key } of data) {
		if (key === wantedKey) {
			return data;
		}

		const temp = children && getParent(children, wantedKey);
		if (temp) {
			return temp;
		}
	}
};