import doc                    from 'decorators/doc';
import model                   from 'decorators/model';
import { computed }          from 'mobx';
import Proto                 from 'proto/proto';
import protobuf              from 'protobufjs/minimal';
import RightsPrivateApiModel from '../../abstracts/RightsPrivateApiModel';

const { GetObjectActionsResponse } = Proto.StoneCode.ApiExtensionBundle.Rpc.Command;

@model.staticLabel('ObjectActions')
@model.urn('$', 'rights', 'object_actions')
@doc.path('/object_actions/{?id}')
export default class ObjectActionsModel extends RightsPrivateApiModel {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare contentEncoding: string;
	@doc.string declare contentType: string;
	@doc.string declare data: string;
	@doc.string declare type: string;

	@computed
	public get proto() {
		const buffer = protobuf.util.newBuffer(protobuf.util.base64.length(this.data));
		protobuf.util.base64.decode(this.data, buffer, 0);
		return GetObjectActionsResponse.decode(buffer);
	}

	public hasAction(name: string) {
		return this.hasUrn(`$:rights:action:${name}`);
	}

	public hasUrn(urn: string) {
		return this.proto.actions.some(p => {
			const pUrn = p.urn ? `${p.urn.partition}:${p.urn.service}:${p.urn.resource}:${p.urn.identifier}` : '';
			return pUrn === urn;
		});
	}
}