import { urn }             from 'helpers/ModelDictionary';
import { Timestampable }   from 'helpers/traits';
import { Blamable }        from 'helpers/traits';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

@urn('$', 'time', 'plan_source')
export default class PlanSourceModel extends Blamable(Timestampable(TimePrivateApiModel)) {

	static get path(): string {
		return '/plan_sources/{?id}';
	}

	public _sorts: ModelSortsExtended<{
		'label': string;
	}> = {};

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): string {
		return this.get('reference', '');
	}
}