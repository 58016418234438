import lazyWithRetry from 'tools/lazyWithRetry';

const InterventionVersionAnomalyFlagListPage = lazyWithRetry(() => import(
	'../../pages/InterventionVersionAnomalyFlag/InterventionVersionAnomalyFlagListPage'));

export default [
	{
		component: InterventionVersionAnomalyFlagListPage,
		exact: true,
		path: '/partitions/:partitionId/anomalies',
	},
];
