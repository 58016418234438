import lazyWithRetry from 'tools/lazyWithRetry';

const PlanImportListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "planImportListPage" */
	'../../pages/PlanImport/PlanImportListPage'));

export default [
	{
		component: PlanImportListPage,
		exact: true,
		path: '/partitions/:partitionId/plan_imports'
	},
];