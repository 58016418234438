import lazyWithRetry from 'tools/lazyWithRetry';

const BillingPeriodDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "billingPeriodDashboardPage" */
	'../../pages/BillingPeriod/BillingPeriodDashboardPage'
));

export default [
	{
		component: BillingPeriodDashboardPage,
		exact: true,
		path: '/billing_periods/:billingPeriodId'
	}
];