import 'moment/dist/locale/fr';

import ConfigProvider               from 'antd/lib/config-provider';
import locale                       from 'antd/lib/locale/fr_FR';
import Modal                        from 'antd/lib/modal';
import Spin                         from 'antd/lib/spin';
import { setContextModal }          from 'components/AppForm';
import { Provider as MobxProvider } from 'mobx-react';
import moment                       from 'moment';
import { Router }                   from 'react-router-dom';
import { Route }                    from 'react-router-dom';
import { Switch }                   from 'react-router-dom';
import React                        from 'react';
import routes                       from 'routes';
import mobxStore                    from 'stores';
import browserHistory               from 'tools/browserHistory';

import momentLocaleSpecificationFr from 'tools/momentLocaleSpecificationFr';
import './AntdCustomTheme.less';
import './App.scss';
import './PlacideaAntdCustomTheme.less';

moment.updateLocale('fr', momentLocaleSpecificationFr);

export default () => {
	const [modal, contextHolder] = Modal.useModal();
	React.useEffect(() => {
		setContextModal(modal);
	});
	return (
		<React.Suspense fallback={<Spin className="app-centered-spin" size="large" />}>
			<ConfigProvider locale={locale}>
				<MobxProvider {...mobxStore}>
					<Router history={browserHistory}>
						<Switch>
							{routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)}
						</Switch>

						{contextHolder}
					</Router>
				</MobxProvider>
			</ConfigProvider>
		</React.Suspense>
	);
};

const RouteWithSubRoutes = (route: IRoute) => {
	return <Route path={route.path} render={props => <route.component {...props} route={route} />} />;
};