import { SmileOutlined }     from '@ant-design/icons';
import { FieldTimeOutlined } from '@ant-design/icons';
import { TeamOutlined }      from '@ant-design/icons';
import { HomeOutlined }      from '@ant-design/icons';
import { UserOutlined }      from '@ant-design/icons';
import { CarOutlined }       from '@ant-design/icons';
import { FileTextOutlined }  from '@ant-design/icons';
import { CalendarOutlined }  from '@ant-design/icons';
import { EuroOutlined }      from '@ant-design/icons';
import { ContactsOutlined }  from '@ant-design/icons';
import { BankOutlined }      from '@ant-design/icons';
import { SettingOutlined }   from '@ant-design/icons';
import { DownloadOutlined }  from '@ant-design/icons';
import Divider               from 'antd/lib/divider';
import { MenuItemType }      from 'antd/lib/menu/hooks/useItems';
import { MenuItemGroupType } from 'antd/lib/menu/hooks/useItems';
import { ItemType }          from 'antd/lib/menu/hooks/useItems';
import Menu                  from 'antd/lib/menu';
import IconSvg               from 'components/IconSvg/IconSvg';
import * as H                from 'history';
import orgSvg                from 'icons/organisation.svg';
import _uniqueId             from 'lodash/uniqueId';
import { observer }          from 'mobx-react';
import { NavLink }           from 'react-router-dom';
import { matchPath }         from 'react-router';
import React                 from 'react';
import routes                from 'routes';
import { appStore }          from 'stores';
import navigate              from 'tools/navigate';
import '../css/Navigation.scss';

const getItem = (item: Partial<MenuItemType | MenuItemGroupType>, path?): MenuItemType => ({
	...item,
	key: path || _uniqueId(),
	label: path ? <NavLink to={path}>{item.label}</NavLink> : item.label,
});

const getMenuItems: () => ItemType[] = () => appStore.partitionUrn ? [
	getItem({ icon: <HomeOutlined />, label: 'Accueil' }, navigate.toHome()),
	getItem({ icon: <TeamOutlined />, label: 'Bénéficiaires' }, navigate.toClientList()),
	getItem({ icon: <SmileOutlined />, label: 'Employés' }, navigate.toStaffMemberList()),
	getItem({ icon: <CarOutlined />, label: 'Véhicules' }, navigate.toVehicleList()),
	getItem({ icon: <FileTextOutlined />, label: 'Contrats-cadres' }, navigate.toContractList()),
	getItem({
		children: [
			getItem({ label: `Planning bénéficiaires` }, navigate.toPlanningsClient()),
			getItem({ label: `Planning employés` }, navigate.toPlanningsStaffMember()),
			getItem({ label: 'Suivi des interventions' }, navigate.toInterventionVersionStatisticList()),
			getItem({ label: 'Interventions' }, navigate.toInterventionVersionList()),
			getItem({ label: `Anomalies` }, navigate.toAnomalies()),
		],
		icon: <CalendarOutlined />,
		label: 'Planning des services',
	}),
	getItem({ icon: <FieldTimeOutlined />, label: 'Suivi des heures' }, navigate.toInterventionVersionTimeLogList()),
	getItem({
		children: [
			getItem({ label: `Importer des plans d'aide` }, navigate.toPlanImportList()),
			getItem({ label: `Plans d'aide` }, navigate.toPlanList()),
			getItem({ label: 'Périodes' }, navigate.toPeriodList()),
			getItem({ label: 'Factures' }, navigate.toInvoiceList()),
			getItem({ label: `Acomptes` }, navigate.toDepositList()),
			getItem({ label: `Paiements` }, navigate.toPaymentList()),
		],
		icon: <EuroOutlined />,
		label: 'Facturation',
	}),
] : [];

const getMenuItemsSettings: () => ItemType[] = () => [
	getItem({
		children: [
			getItem({ label: `Documents sur les interventions` }, navigate.toExportInterventionList()),
			getItem({ label: `Documents sur la facturation` }, navigate.toExportInvoiceList()),
			getItem({ label: `Documents sur les ressources humaines` }, navigate.toExportRhList()),
			getItem({ label: `Documents sur les plans d'aide` }, navigate.toExportTimeList()),
		],
		icon: <DownloadOutlined />,
		label: 'Téléchargements',
	}),
	getItem({ icon: <IconSvg color="#0009" size={18} svg={orgSvg} />, label: 'Entités' }, navigate.toCompanyList()),
	getItem({ icon: <ContactsOutlined />, label: 'Contacts' }, navigate.toContactList()),
	getItem({ icon: <UserOutlined />, label: 'Utilisateurs' }, navigate.toUserList()),
	getItem({ icon: <BankOutlined />, label: 'Agences' }, navigate.toPartitionList()),
	getItem({
		children: [
			getItem({ label: `Propriétés` }, navigate.toPropertyList()),
			getItem({ label: `Types d'activité` }, navigate.toActivityList()),
			getItem({ label: `Groupes d'activités` }, navigate.toActivityGroupList()),
			getItem({ label: `Types d'opération` }, navigate.toOperationList()),
			getItem({ label: `Groupes d'opérations` }, navigate.toOperationGroupList()),
			getItem({ label: `Taux de TVA` }, navigate.toVatList()),
			getItem({ label: `Groupes d'utilisateurs` }, navigate.toPartitionGroupList()),
			getItem({ label: `Synthèse des droits d'accès` }, navigate.toSynthesisAccessRightList()),
		],
		icon: <SettingOutlined />,
		label: 'Paramètres',
	}),
];

export const getAllMenuItems = () => [...getMenuItems(), { children: getMenuItemsSettings() }];

export default observer((props: { location: H.Location }) => {
	const selectedKeys = [];
	getCurrentSelectedKeys(props.location, selectedKeys, routes);

	return (
		<div className="Navigation__Container">
			<Menu
				className="Navigation__Menu"
				defaultOpenKeys={['subNav']}
				id="Navigation__Menu"
				items={getMenuItems()}
				mode="inline"
				selectedKeys={selectedKeys}
				theme="light"
			/>

			<Divider style={{ margin: '10px 0' }} />

			<Menu
				className="Navigation__MenuBottom"
				defaultOpenKeys={['subNav']}
				id="Navigation__MenuBottom"
				items={getMenuItemsSettings()}
				mode="inline"
				selectedKeys={selectedKeys}
				theme="light"
			/>
		</div>
	);
});

const getCurrentSelectedKeys = (location: H.Location, matchedRoutes, routes) => {
	for (let i = 0; i < routes.length; i++) {
		const route = routes[i];
		const matchConfig = { exact: route.exact, path: route.path };

		if (route.path === '/') {
			matchConfig.exact = true;
		}

		if (matchPath(location.pathname, matchConfig) && route.path !== '/*') {
			matchedRoutes.push(route.path);
		}

		if (route.routes) {
			getCurrentSelectedKeys(location, matchedRoutes, route.routes);
		}
	}
};
