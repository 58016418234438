import { resolvable }         from '@mathquis/modelx-resolvables';
import InvoiceItemGroupModel  from 'Models/invoice/InvoiceItemGroupModel';
import { urn }                from 'helpers/ModelDictionary';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoice_item')
export default class InvoiceItemModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/invoice_items/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[between]': string;
		'createdAt[gt]': string;
		'createdAt[gte]': string;
		'createdAt[lt]': string;
		'createdAt[lte]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'createdBy[between]': string;
		'createdBy[gt]': string;
		'createdBy[gte]': string;
		'createdBy[lt]': string;
		'createdBy[lte]': string;
		'description[between]': string;
		'description[gt]': string;
		'description[gte]': string;
		'description[lt]': string;
		'description[lte]': string;
		'id[between]': string;
		'id[gt]': string;
		'id[gte]': string;
		'id[lt]': string;
		'id[lte]': string;
		'invoiceItemGroup.invoice': id;
		'label[between]': string;
		'label[gt]': string;
		'label[gte]': string;
		'label[lt]': string;
		'label[lte]': string;
		'locationUrn[between]': string;
		'locationUrn[gt]': string;
		'locationUrn[gte]': string;
		'locationUrn[lt]': string;
		'locationUrn[lte]': string;
		'priceExclTax[between]': string;
		'priceExclTax[gt]': string;
		'priceExclTax[gte]': string;
		'priceExclTax[lt]': string;
		'priceExclTax[lte]': string;
		'priceVat[between]': string;
		'priceVat[gt]': string;
		'priceVat[gte]': string;
		'priceVat[lt]': string;
		'priceVat[lte]': string;
		'quantity[between]': string;
		'quantity[gt]': string;
		'quantity[gte]': string;
		'quantity[lt]': string;
		'quantity[lte]': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[between]': string;
		'updatedAt[gt]': string;
		'updatedAt[gte]': string;
		'updatedAt[lt]': string;
		'updatedAt[lte]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
		'updatedBy[between]': string;
		'updatedBy[gt]': string;
		'updatedBy[gte]': string;
		'updatedBy[lt]': string;
		'updatedBy[lte]': string;
		'vatId[between]': string;
		'vatId[gt]': string;
		'vatId[gte]': string;
		'vatId[lt]': string;
		'vatId[lte]': string;
		'vatRate[between]': string;
		'vatRate[gt]': string;
		'vatRate[gte]': string;
		'vatRate[lt]': string;
		'vatRate[lte]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};


	public get description(): string {
		return this.get('description', '');
	}

	@resolvable(InvoiceItemGroupModel, { attributeName: 'invoiceItemGroup', cache: true })
	public declare invoiceItemGroup: InvoiceItemGroupModel;

	public get label(): string {
		return this.get('label', '');
	}

	public get priceExclTax(): number {
		return this.get('priceExclTax', 0);
	}

	public get priceExclTaxByMin(): number {
		return this.priceExclTax ? this.priceExclTax / 60 : 0;
	}

	public get priceExclTaxByMinRounded(): number {
		return Math.round((this.priceExclTaxByMin + Number.EPSILON) * 100) / 100;
	}

	public get priceInclTax(): number {
		return this.get('priceInclTax', 0);
	}

	public get priceVat(): number {
		return this.get('priceVat', 0);
	}

	public get quantity(): number {
		return this.get('quantity', 0);
	}

	public get totalPriceExclTax(): number {
		return this.get('totalPriceExclTax', 0);
	}

	public get vatId(): string {
		return this.get('vatId', '');
	}

	public get vatRate(): string {
		return this.get('vatRate', '');
	}

	public get invoiceItemGroupId(): id {
		return this.get('invoiceItemGroup.id', 0);
	}
}
