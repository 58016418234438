import { LocaleSpecification } from 'moment';

const localeSpecification: LocaleSpecification = {
	longDateFormat: {
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY HH:mm',
		LLLL: 'dddd D MMMM YYYY HH:mm',
		LT: 'HH[h]mm',
		LTS: 'HH:mm:ss',
	}
};

export default localeSpecification;