import lazyWithRetry from 'tools/lazyWithRetry';

const VatListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "vatListPage" */
	'../../pages/Vat/VatListPage/VatListPage'));

const VatDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "vatDashboardPage" */
	'../../pages/Vat/VatDashboardPage/VatDashboardPage'));

export default [
	{
		component: VatListPage,
		exact: true,
		path: '/vats'
	},
	{
		component: VatDashboardPage,
		exact: true,
		path: '/vats/:vatId',
	}
];