import BillingGroupModel   from 'Models/invoice/BillingGroupModel';
import VatModel            from 'Models/invoice/VatModel';
import ContractModel       from 'Models/sales/ContractModel';
import OwnerClientModel    from 'Models/time/OwnerClientModel';
import doc                 from 'decorators/doc';
import model               from 'decorators/model';
import resolvableUrn       from 'decorators/resolvableUrn';
import { Timestampable }   from 'helpers/traits';
import { Blamable }        from 'helpers/traits';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

@model.staticLabel('TimeGroup')
@model.urn('$', 'time', 'time_group')
@doc.path('/time_groups/{?id}')
export default class TimeGroupModel extends Blamable(Timestampable(TimePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'billingGroupUrn': string;
		'exists[timeGroupPrices]': boolean;
		'ownerClient': id;
		'ownerClient.clientUrn': string;
		'ownerClient.owner': id;
		'ownerClient.owner.contactUrn': string;
		'ownerClient.owner.generalClientUrn': string;
		'ownerClient.partitionUrn': string;
		'sourceUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.modelUrn(BillingGroupModel) declare billingGroup: BillingGroupModel;
	@doc.model(OwnerClientModel) declare ownerClient: OwnerClientModel;
	@doc.modelUrn(VatModel) declare vat: VatModel;

	@resolvableUrn({ attributeName: 'sourceUrn' })
	public declare source: ContractModel;
}