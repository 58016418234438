import { TextAreaProps as TAP } from 'antd/lib/input';
import AntdInput                from 'antd/lib/input';
import { InputProps as AIP }    from 'antd/lib/input';
import _omit                    from 'lodash/omit';
import React                    from 'react';

export interface InputProps extends Omit<AIP, 'placeholder'> {
	placeholder: string;
}

export interface TextAreaProps extends Omit<TAP, 'placeholder'> {
	placeholder: string;
}

export const TextArea = (props: TextAreaProps) => <AntdInput.TextArea size="large" {...props} />;
export const Password = (props: InputProps) => <AntdInput.Password size="large" {...props} />;

export default class Input extends React.Component<InputProps> {
	public static Group = AntdInput.Group;
	public static Password = Password;
	public static TextArea = TextArea;

	public render() {
		const props = _omit(this.props, ['defaultOpen', 'dropdownMatchSelectWidth']);

		return <AntdInput size="large" {...props} />;
	}
}