import { resolvable }              from '@mathquis/modelx-resolvables';
import ClientModel                 from 'Models/directory/ClientModel';
import OwnerModel                  from 'Models/intervention/OwnerModel';
import TaskGroupModel              from 'Models/intervention/TaskGroupModel';
import TaskStatusModel             from 'Models/intervention/TaskStatusModel';
import TimeGroupModel              from 'Models/time/TimeGroupModel';
import model                       from 'decorators/model';
import {
	Blamable,
	Timestampable,
}                                  from 'helpers/traits';
import { computed }                from 'mobx';
import moment                      from 'moment';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Tâche')
@model.urnResource('task')
export default class TaskModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/tasks/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'owner.clientUrn': string;
		'owner.contactUrn': string;
		'owner.generalClientUrn': string;
		'owner.partitionUrn': string;
		'scheduleEndDate[after]': string | Date;
		'scheduleEndDate[before]': string | Date;
		'scheduleEndDate[strictly_after]': string | Date;
		'scheduleEndDate[strictly_before]': string | Date;
		'scheduleStartDate[after]': string | Date;
		'scheduleStartDate[before]': string | Date;
		'scheduleStartDate[strictly_after]': string | Date;
		'scheduleStartDate[strictly_before]': string | Date;
		'sourceUrn': string;
		'taskGroup': id;
		'taskGroup.sourceUrn': string;
		'taskMetas': id;
		'taskMetas.reference': string;
		'taskMetas.value': id;
		'taskOperations': id;
		'taskOperations.operation': id;
		'taskOperations.operation.operationCategory': id;
		'taskOperations.taskZone': id;
		'taskStatus': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'scheduleEndDate': string;
		'scheduleStartDate': string;
		'title': string;
		'updatedAt': string;
	}> = {};

	public get cancelled(): boolean {
		return this.get('cancelled', false);
	}

	@resolvable(ClientModel, { attributeName: 'clientUrn' })
	public declare client: ClientModel;

	public get deleted(): boolean {
		return this.get('deleted', false);
	}

	public get description(): string {
		return this.get('description', '');
	}

	public get note(): string {
		return this.get('note', '');
	}

	@resolvable(OwnerModel, { attributeName: 'owner' })
	public declare owner: OwnerModel;

	@computed
	public get scheduleEndDate(): Moment {
		return moment(this.get('scheduleEndDate', moment()));
	}

	@computed
	public get scheduleStartDate(): Moment {
		return moment(this.get('scheduleStartDate', moment()));
	}

	@resolvable(TaskGroupModel, { attributeName: 'taskGroup' })
	public declare taskGroup: TaskGroupModel;

	@resolvable(TaskStatusModel, { attributeName: 'taskStatus' })
	public declare taskStatus: TaskStatusModel;

	@resolvable(TimeGroupModel, { attributeName: 'timeGroupUrn' })
	public declare timeGroup: TimeGroupModel;

	public get title(): string {
		return this.get('title', '');
	}
}
