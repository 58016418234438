/* eslint-disable @typescript-eslint/no-explicit-any */

import axios                   from 'axios';
import Protobuf                from 'protobufjs/minimal';
import { authenticationStore } from 'stores';
import ConfigProxy             from 'tools/ConfigProxy';

export default async <T>(
	serviceName: string,
	routePath: string,
	Proto: T,
	params = {},
) => {
	const st = (new Date()).getTime();

	const baseURL = ConfigProxy.getServiceConfig(serviceName, 'api_endpoint');

	const instance = axios.create({ baseURL, responseType: 'arraybuffer' });

	instance.interceptors.request.use(async (config) => {
		if (authenticationStore.session.isExpired) {
			await authenticationStore.refreshTokenAsync();
		}

		config.headers['X-Platform-Authorization'] = 'Bearer ' + authenticationStore.session.token;

		return config;
	});

	const { data } = await instance.get(routePath, { params });

	const stp = (new Date()).getTime();
	const reader = Protobuf.Reader.create(new Uint8Array(data));

	const items: T[] = [];

	while (reader.pos < reader.len) {
		items.push((Proto as any).decodeDelimited(reader) as any);
	}

	const et = (new Date()).getTime();
	const totalTime = (et - st);
	const parseTime = (et - stp);

	console.log('Loaded %d protos in %fms (parsing: %fms)', items.length, totalTime, parseTime);

	return items;
};