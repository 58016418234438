import lazyWithRetry from 'tools/lazyWithRetry';

const PlanningStaffPage = lazyWithRetry(() => import(
	/* webpackChunkName: "PlanningStaffPage" */
	'../../pages/Planning/PlanningStaffPage'));

const PlanningClientPage = lazyWithRetry(() => import(
	/* webpackChunkName: "planningClientPage" */
	'../../pages/Planning/PlanningClientPage'));

export default [
	{
		component: PlanningClientPage,
		exact: true,
		path: '/partitions/:partitionId/plannings/client',
	},
	{
		component: PlanningStaffPage,
		exact: true,
		path: '/partitions/:partitionId/plannings/staff_member',
	},
];