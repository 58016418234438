import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import DirectoryApiModel      from './DirectoryApiModel';

export const directoryLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('directory', 'api_endpoint'),
});

export default class DirectoryPrivateApiModel extends DirectoryApiModel {
	public static get connector() {
		return directoryLoggedApiConnector;
	}
}
