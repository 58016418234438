import { urn }                     from 'helpers/ModelDictionary';
import broomSvg                    from 'icons/broom.svg';
import sinkSvg                     from 'icons/sink.svg';
import spraySvg                    from 'icons/spray.svg';
import { computed }                from 'mobx';
import { ReactNode }               from 'react';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'operation_category')
export default class OperationCategoryModel extends InterventionPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
		'updatedAt': string;
	}> = {};

	public static cacheDuration = 3600;

	static get path(): string {
		return '/operation_categories/{?id}';
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get position(): number {
		return this.get('position', 0);
	}

	@computed
	public get icon(): ReactNode {
		switch (this.label) {
			case 'Assainissement':
				return sinkSvg;
			case 'Dégraissage':
				return spraySvg;
			case 'Ramonage':
				return broomSvg;
		}

		return null;
	}
}