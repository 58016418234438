import { resolvable }                from '@mathquis/modelx-resolvables';
import InterventionResourceTypeModel from 'Models/intervention/InterventionResourceTypeModel';
import InterventionVersionModel      from 'Models/intervention/InterventionVersionModel';
import ResourceModel                 from 'Models/intervention/ResourceModel';
import { VersionTypeReference }      from 'Models/intervention/VersionTypeModel';
import nestedResolvable              from 'decorators/nestedResolvable';
import { urn }                       from 'helpers/ModelDictionary';
import { Timestampable }             from 'helpers/traits';
import { Blamable }                  from 'helpers/traits';
import InterventionPrivateApiModel   from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'intervention_version_resource')
export default class InterventionVersionResourceModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/intervention_version_resources/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string | Date;
		'createdAt[before]': string | Date;
		'createdAt[strictly_after]': string | Date;
		'createdAt[strictly_before]': string | Date;
		'interventionResourceType': id;
		'interventionResourceType.reference': InterventionResourceTypeReference;
		'interventionVersion': id;
		'interventionVersion.intervention': id;
		'interventionVersion.version.versionType.reference': VersionTypeReference;
		'interventionVersion.scheduleEndDate[after]': string | Date;
		'interventionVersion.scheduleEndDate[before]': string | Date;
		'interventionVersion.scheduleEndDate[strictly_after]': string | Date;
		'interventionVersion.scheduleEndDate[strictly_before]': string | Date;
		'interventionVersion.scheduleStartDate[after]': string | Date;
		'interventionVersion.scheduleStartDate[before]': string | Date;
		'interventionVersion.scheduleStartDate[strictly_after]': string | Date;
		'interventionVersion.scheduleStartDate[strictly_before]': string | Date;
		'resource': id;
	}> = {};


	public _sorts: ModelSortsExtended<{
		'id': string;
		'createdAt': string;
		'owner.ownerResource.entityUrn': string;
	}> = {};

	public get distance(): number {
		return this.get('distance', 0);
	}

	public get duration(): number {
		return this.get('duration', 0);
	}

	public get interventionVersionId(): id {
		return this.get('interventionVersion.id', 0);
	}

	public get interventionResourceTypeId(): id {
		return this.get('interventionResourceType.id', 0);
	}

	public get interventionResourceTypeIri(): string {
		return this.get('interventionResourceType.@id', '');
	}

	public get resourceId(): id {
		return this.get('resource.id', 0);
	}

	@resolvable(InterventionResourceTypeModel, { attributeName: 'interventionResourceType' })
	public declare interventionResourceType: InterventionResourceTypeModel;

	@resolvable(InterventionVersionModel, { attributeName: 'interventionVersion' })
	public declare interventionVersion: InterventionVersionModel;

	@resolvable(ResourceModel, { attributeName: 'resource' })
	public declare resource: ResourceModel;

	@nestedResolvable()
	public get resourceType() {
		return this.resource.resourceType;
	}

	@nestedResolvable()
	public get ownerResource() {
		return this.resource.ownerResource;
	}

	@nestedResolvable()
	public get entity() {
		return this.ownerResource.entity;
	}
}