import { resolvable }         from '@mathquis/modelx-resolvables';
import InvoicingGroupModel    from 'Models/invoice/InvoicingGroupModel';
import PaymentMethodModel     from 'Models/invoice/PaymentMethodModel';
import { urn }                from 'helpers/ModelDictionary';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'billing_group')
export default class BillingGroupModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	static get path(): string {
		return '/billing_groups/{?id}';
	}

	@resolvable(InvoicingGroupModel, { attributeName: 'invoicingGroup' })
	public declare invoicingGroup: InvoicingGroupModel;

	@resolvable(PaymentMethodModel, { attributeName: 'paymentMethod', cache: true })
	public declare paymentMethod: PaymentMethodModel;

	public get name(): string {
		return this.get('name', '');
	}

	public get balance(): number {
		return this.get('balance', 0);
	}

	public get freeReference(): string {
		return this.get('freeReference', '');
	}
}