import { ApiCollection }              from 'Collections/ApiCollection';
import PartitionGroupModel            from 'Models/rh/PartitionGroupModel';
import StaffMemberPartitionGroupModel from 'Models/rh/StaffMemberPartitionGroupModel';
import RightActionGroupModel          from 'Models/rights/RightActionGroupModel';
import { when }                       from 'mobx';
import AbstractModelXStore            from 'stores/AbstractModelXStore';

export default class PartitionGroupDashboardStore extends AbstractModelXStore {

	public partitionGroup = new PartitionGroupModel();
	public rightActionGroupCollection = new ApiCollection(RightActionGroupModel);
	public staffMemberPartitionGroupCollection = new ApiCollection(StaffMemberPartitionGroupModel);

	public async initAsync(id: id) {
		this.clear();

		this.setIsLoading(true);

		await Promise.all([
			this.partitionGroup.set({ id }).fetch().then(() => {
				when(() => !!this.partitionGroup.right.id, async () => {
					await Promise.all([
						this.rightActionGroupCollection
							.setSort('actionGroup.label')
							.setFilter('right', this.partitionGroup.right.id).list(),
					]);
				});
			}),

			this.staffMemberPartitionGroupCollection.setFilter('partitionGroup', id).list(),
		]);

		this.setIsLoading(false);
	}
}