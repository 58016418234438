import { ApiCollection }   from 'Collections/ApiCollection';
import { PagedCollection } from 'Collections/PagedCollection';
import ContactAddressModel from 'Models/directory/ContactAddressModel';
import RelationMetaModel   from 'Models/graph/RelationMetaModel';
import RelationModel       from 'Models/graph/RelationModel';
import RelationTypeModel   from 'Models/graph/RelationTypeModel';
import { computed }        from 'mobx';
import ContactModel        from 'modelx/models/private/directory/ContactModel';
import AbstractModelXStore from 'stores/AbstractModelXStore';

export default class AbstractContactStore extends AbstractModelXStore {

	public contact = new ContactModel();
	public contactAddressCollection = new ApiCollection(ContactAddressModel);
	public relationMetaCollection = new ApiCollection(RelationMetaModel);
	public relationTypeCollection = new ApiCollection(RelationTypeModel);
	public relationTypeContactPersonCollection = new PagedCollection(RelationModel);
	public relationTypeLegalResponsibleCollection = new PagedCollection(RelationModel);

	public fetchRelations = async (contact: ContactModel) => {

		await Promise.all([
			this.contactAddressCollection
				.setFilter('contact', contact.id)
				.setSort('position', true)
				.list(),
			
			this.relationTypeCollection.list(),

			this.relationTypeLegalResponsibleCollection
				.setFilters({
					'inNode.identifier': contact.urn,
					'relationType.reference': 'legal_responsible',
				})
				.list(),

			this.relationTypeContactPersonCollection
				.setFilters({
					'inNode.identifier': contact.urn,
					'relationType.reference': 'contact_person',
				})
				.list(),

		]);

		const relationsIdsTypeContactPerson = this.relationTypeContactPersonCollection.map(r => r.id);
		const relationsIdsTypeLegalResponsible = this.relationTypeLegalResponsibleCollection.map(r => r.id);

		await this.relationMetaCollection.listBy(
			[ ...relationsIdsTypeContactPerson, ...relationsIdsTypeLegalResponsible, ],
			'relation'
		);
	};

	@computed
	public get contactAddress(): ContactAddressModel | null {
		return this.contactAddressCollection.first();
	}

	@computed
	public get relationTypeContactPerson() {
		const relationType = this.relationTypeCollection.find(rt => rt.reference === 'contact_person');

		if (!relationType) {
			return new RelationTypeModel();
		}

		return relationType;
	}

	@computed
	public get relationTypeLegalResponsible() {
		const relationType = this.relationTypeCollection.find(rt => rt.reference === 'legal_responsible');

		if (!relationType) {
			return new RelationTypeModel();
		}

		return relationType;
	}
}
