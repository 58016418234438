import { ApiCollection }                  from 'Collections/ApiCollection';
import PagedCollection                    from 'Collections/PagedCollection';
import BillingPeriodModel                 from 'Models/invoice/BillingPeriodModel';
import BillingPeriodStatusModel           from 'Models/invoice/BillingPeriodStatusModel';
import InvoiceModel                       from 'Models/invoice/InvoiceModel';
import TimePeriodModel                    from 'Models/time/PeriodModel';
import PlanConsumptionAggregatedDataModel from 'Models/time/PlanConsumptionAggregatedDataModel';
import { makeObservable }                 from 'mobx';
import AbstractModelXStore                from 'stores/AbstractModelXStore';

export default class BillingPeriodDashboardStore extends AbstractModelXStore {

	public billingPeriod = new BillingPeriodModel();
	public collectionBillingPeriodStatus = new ApiCollection(BillingPeriodStatusModel);
	public invoiceInvoiceCollection = new PagedCollection(InvoiceModel);
	public invoiceRefundCollection = new PagedCollection(InvoiceModel);
	public planConsumptionAggregatedData = new PlanConsumptionAggregatedDataModel();
	public timePeriodCollection = new ApiCollection(TimePeriodModel);

	public constructor() {
		super();

		makeObservable(this);
	}

	public async fetchAsync(id: id) {
		this.clear();

		await Promise.all([
			this.billingPeriod.setId(id).fetch().then(() => {
				this.invoiceInvoiceCollection.setItemsPerPage(1);
				this.invoiceInvoiceCollection.setFilters({
					'billingPeriod': id,
					'invoiceType.reference': 'invoice',
				}).list();

				this.invoiceRefundCollection.setItemsPerPage(1);
				this.invoiceRefundCollection.setFilters({
					'billingPeriod': id,
					'invoiceType.reference': 'refund',
				}).list();

				this.timePeriodCollection.setFilters({
					'endDate[before]': this.billingPeriod.endDate.format('YYYY-MM-DD'),
					'startDate[after]': this.billingPeriod.startDate.format('YYYY-MM-DD'),
				});
				this.timePeriodCollection.list().then(() => {
					const firstTimePeriod = this.timePeriodCollection.first();
					if (!firstTimePeriod) {
						return;
					}
					this.planConsumptionAggregatedData.fetch({
						params: {
							period: firstTimePeriod.id,
						},
					});
				});
			}),
			this.collectionBillingPeriodStatus.list(),
		]);
	}
}
