import { resolvable }              from '@mathquis/modelx-resolvables';
import OperationCategoryModel      from 'Models/intervention/OperationCategoryModel';
import OperationGroupModel         from 'Models/intervention/OperationGroupModel';
import model                       from 'decorators/model';
import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel(`Type d'opération`)
@urn('$', 'intervention', 'operation')
export default class OperationModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'operationCategory': id;
		'operationGroup': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'operationGroup.label': string;
		'operationGroup.position': string;
		'updatedAt': string;
	}> = {};

	static get path(): string {
		return '/operations/{?id}';
	}

	@resolvable(OperationCategoryModel, { attributeName: 'operationCategory', cache: true })
	public declare operationCategory: OperationCategoryModel;

	@resolvable(OperationGroupModel, { attributeName: 'operationGroup' })
	public declare operationGroup: OperationGroupModel;

	public get description(): string {
		return this.get('description', '');
	}

	public get duration(): number {
		return this.get('duration', 0);
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get nbPhotos(): number {
		return this.get('nbPhotos', 0);
	}
}