import { resolvable }                 from '@mathquis/modelx-resolvables';
import ContractIterationPdfModel    from 'Models/sales/ContractIterationPdfModel';
import ContractIterationStatusModel from 'Models/sales/ContractIterationStatusModel';
import ContractModel                from 'Models/sales/ContractModel';
import TimeGroupPriceModel          from 'Models/time/TimeGroupPriceModel';
import model                        from 'decorators/model';
import { urn }                      from 'helpers/ModelDictionary';
import { Blamable }                 from 'helpers/traits';
import { Timestampable }            from 'helpers/traits';
import { computed }                 from 'mobx';
import moment                       from 'moment';
import { appStore }                 from 'stores';
import downloadFile                 from 'tools/downloadFile';
import notificationApiError         from 'tools/notificationApiError';
import SalesPrivateApiModel         from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Contrat')
@urn('$', 'sales', 'contract_iteration')
export default class ContractIterationModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'archived': string;
		'contract': id;
		'contract.number': string;
		'contract.owner.clientUrn': string;
		'contract.owner.contactUrn': string;
		'contract.owner.generalClientUrn': string;
		'contract.owner.partitionUrn': string;
		'contract.recurringMode': id;
		'contract.recurringMode.reference': string;
		'contractIterationItems.contractIterationItemActivities.locationUrn': string;
		'contractIterationStatus': id;
		'contractIterationStatus.reference': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'contract.number': string;
		'contractIterationStatus.label': string;
		'hourPrice': string;
		'increment': string;
		'totalPriceExclTax': string;
	}> = {};

	static get path(): string {
		return '/contract_iterations/{?id}';
	}

	@resolvable(ContractModel, { attributeName: 'contract' })
	public declare contract: ContractModel;

	@resolvable(ContractIterationStatusModel, { attributeName: 'contractIterationStatus', cache: true })
	public declare contractIterationStatus: ContractIterationStatusModel;

	@resolvable(TimeGroupPriceModel, { attributeName: 'timeGroupPriceUrn' })
	public declare timeGroupPrice: TimeGroupPriceModel;

	public get address(): string {
		return this.get('address', '');
	}

	public get addressBis(): string {
		return this.get('addressBis', '');
	}

	public get archived(): boolean {
		return this.get('archived', false);
	}

	public get city(): string {
		return this.get('city', '');
	}

	@computed
	public get closedAt() {
		return this.get('closedAt') ? moment(this.get('closedAt')) : undefined;
	}

	public get comment(): string {
		return this.get('comment', '');
	}

	public get country(): string {
		return this.get('country', '');
	}

	@computed
	public get endDate(): Moment {
		return moment(this.get('endDate', moment()));
	}

	public get hourPrice(): number {
		return this.get('hourPrice', 0);
	}

	@computed
	public get startDate(): Moment {
		return moment(this.get('startDate', moment()));
	}

	@computed
	public get suspensionEndDate() {
		return this.get('suspensionEndDate') ? moment(this.get('suspensionEndDate')) : undefined;
	}

	@computed
	public get suspensionStartDate() {
		return this.get('suspensionStartDate') ? moment(this.get('suspensionStartDate')) : undefined;
	}

	public get totalPriceExclTax(): number {
		return this.get('totalPriceExclTax', 0);
	}

	public get zipCode(): string {
		return this.get('zipCode', '');
	}

	public get increment(): number {
		return this.get('increment', 0);
	}

	public get incrementFormatted() {
		return ('00' + this.increment).slice(-3);
	}

	@computed
	public get endDateIsPassed() {
		return this.endDate.isSameOrBefore(moment());
	}

	@computed
	public get duration() {
		return moment.duration(this.endDate.diff(this.startDate));
	}

	public get fullAddress() {
		return `${this.address} ${this.addressBis} ${this.zipCode} ${this.city}`;
	}

	public async downloadPdfAsync() {
		try {
			const contractIterationPdfModel = new ContractIterationPdfModel({ id: this.id });

			await contractIterationPdfModel.fetch();

			downloadFile(
				contractIterationPdfModel.blob,
				`${appStore.partition?.name} - Contrat - cadre - ${this.contract.number} - ${this.incrementFormatted}.pdf`,
			);
		} catch (err) {
			notificationApiError(err);
		}
	}
}