import { ApiCollection }        from 'Collections/ApiCollection';
import BillableItemModel        from 'Models/invoice/BillableItemModel';
import BillableModel            from 'Models/invoice/BillableModel';
import BillableStatusModel      from 'Models/invoice/BillableStatusModel';
import BillableTypeModel        from 'Models/invoice/BillableTypeModel';
import InvoiceDocumentModel     from 'Models/invoice/InvoiceDocumentModel';
import InvoiceItemGroupModel    from 'Models/invoice/InvoiceItemGroupModel';
import InvoiceItemModel         from 'Models/invoice/InvoiceItemModel';
import InvoiceModel             from 'Models/invoice/InvoiceModel';
import InvoiceTypeModel         from 'Models/invoice/InvoiceTypeModel';
import ContractModel            from 'Models/sales/ContractModel';
import { computed }             from 'mobx';
import AbstractModelXStore      from 'stores/AbstractModelXStore';

export default class InvoiceDashboardStore extends AbstractModelXStore {

	public collectionBillableStatus = new ApiCollection(BillableStatusModel);
	public collectionBillableType = new ApiCollection(BillableTypeModel);
	public collectionContract = new ApiCollection(ContractModel);
	public collectionInvoiceDocument = new ApiCollection(InvoiceDocumentModel);
	public collectionInvoiceItem = new ApiCollection(InvoiceItemModel);
	public collectionInvoiceItemGroup = new ApiCollection(InvoiceItemGroupModel);
	public collectionInvoiceType = new ApiCollection(InvoiceTypeModel);
	public collectionRefundedByBillable = new ApiCollection(BillableModel);
	public collectionRefundedByBillableItem = new ApiCollection(BillableItemModel);
	public collectionRefundedByInvoice = new ApiCollection(InvoiceModel);
	public invoice = new InvoiceModel();

	public async fetchAsync(id: id) {
		this.clear();

		await Promise.all([
			this.invoice.setId(id).fetch().then(async () => {
				await this.collectionContract.listBy([this.invoice.billingGroupUrn], 'billingGroupUrn');
			}),
			this.collectionInvoiceType.list(),
			this.collectionBillableStatus.list(),
			this.collectionBillableType.list(),
			this.collectionInvoiceItemGroup.listBy([id], 'invoice'),
			this.collectionInvoiceItem.listBy([id], 'invoiceItemGroup.invoice'),
			this.collectionInvoiceDocument.setFilter('invoice', id).list(),
			this.collectionRefundedByInvoice.listBy([id], 'refundedInvoices.invoiceRefunded'),
			this.reloadRefundedByBillable(),
		]);
	}

	public getTotalPriceExclTax(billable: BillableModel) {
		const billableItems = this.collectionRefundedByBillableItem.filterBy('billableId', billable.id);

		let totalPriceExclTax = 0;

		billableItems.forEach(billableItem => totalPriceExclTax += billableItem.totalPriceExclTax);

		return totalPriceExclTax;
	}

	public async reloadRefundedByBillable() {
		if (this.invoice.id) {
			await this.collectionRefundedByBillable.setFilters({
				'billableStatus.reference': ['in_creation', 'created'],
				'refundedInvoices.invoiceRefunded': this.invoice.id,
			}).list();

			await this.collectionRefundedByBillableItem
				.listByFromCollection(this.collectionRefundedByBillable, 'id', 'billable');
		}
	}

	@computed
	public get lastDocument() {
		return this.collectionInvoiceDocument.last();
	}

	@computed
	public get contract() {
		return this.collectionContract.first();
	}
}
