import { resolvable }      from '@mathquis/modelx-resolvables';
import GeneralClientModel  from 'Models/directory/GeneralClientModel';
import OwnerModel          from 'Models/time/OwnerModel';
import PlanSourceModel     from 'Models/time/PlanSourceModel';
import { urn }             from 'helpers/ModelDictionary';
import { Timestampable }   from 'helpers/traits';
import { Blamable }        from 'helpers/traits';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

@urn('$', 'time', 'owner_plan_source_reference')
export default class OwnerPlanSourceReferenceModel extends Blamable(Timestampable(TimePrivateApiModel)) {

	static get path(): string {
		return '/owner_plan_source_references/{?id}';
	}

	public get reference(): string {
		return this.get('reference', '');
	}

	@resolvable(GeneralClientModel, { attributeName: 'generalClientUrn' })
	public declare generalClient: GeneralClientModel;

	@resolvable(OwnerModel, { attributeName: 'owner' })
	public declare owner: OwnerModel;

	@resolvable(PlanSourceModel, { attributeName: 'planSource' })
	public declare planSource: PlanSourceModel;
}