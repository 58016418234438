import { computed }             from 'mobx';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

export default class CompanyModel extends DirectoryPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		company: id;
	}> = {};

	static get path(): string {
		return '/company_contact_infos/{?id}';
	}

	@computed
	public get email(): string {
		return this.get('email', '');
	}

	@computed
	public get mobileNumber(): string {
		return this.get('mobileNumber', '');
	}

	@computed
	public get phoneNumber(): number {
		return this.get('phoneNumber', '');
	}
}
