import PeriodStatusModel   from 'Models/time/PeriodStatusModel';
import doc                 from 'decorators/doc';
import model               from 'decorators/model';
import { Timestampable }   from 'helpers/traits';
import { Blamable }        from 'helpers/traits';
import { computed }        from 'mobx';
import TimePrivateApiModel from 'modelx/models/abstracts/TimePrivateApiModel';
import { ucFirst }         from 'tools/jsTools';

@model.staticLabel('Période pour les temps facturés', false)
@model.urn('$', 'time', 'period')
@doc.path('/periods/{?id}')
export default class PeriodModel extends Blamable(Timestampable(TimePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'periodStatus': id;
		'startDate': string | Date;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'startDate': string;
		'updatedAt': string;
	}> = {};

	@doc.date declare endDate: Moment;
	@doc.model(PeriodStatusModel) declare periodStatus: PeriodStatusModel;
	@doc.date declare startDate: Moment;

	@computed
	public get name() {
		return ucFirst(this.startDate.format('MMMM YYYY'));
	}
}