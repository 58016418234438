import { urn }             from 'helpers/ModelDictionary';
import { Timestampable }   from 'helpers/traits';
import { Blamable }        from 'helpers/traits';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

@urn('$', 'time', 'plan_status')
export default class PlanStatusModel extends Blamable(Timestampable(TimePrivateApiModel)) {

	static get path(): string {
		return '/plan_statuses/{?id}';
	}

	public static cacheDuration = 3600;

	public get color() {
		switch (this.reference) {
			case 'complete':
				return 'green';
			case 'disabled':
				return 'red';
			case 'waiting_info':
				return 'orange';
			default:
				return 'grey';
		}
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): PlanStatusReference {
		return this.get('reference', '');
	}
}