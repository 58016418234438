import ActivityModel       from 'Models/sales/ActivityModel';
import AbstractModelXStore from 'stores/AbstractModelXStore';

export default class ActivityDashboardStore extends AbstractModelXStore {

	public activity = new ActivityModel();

	public async fetchAsync(id: id) {
		this.clear();

		await this.activity.setId(id).fetch();
	}
}