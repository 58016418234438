import { resolvable }              from '@mathquis/modelx-resolvables';
import { ApiCollection }           from 'Collections/ApiCollection';
import VersionPublicationModel     from 'Models/intervention/VersionPublicationModel';
import VersionTypeModel            from 'Models/intervention/VersionTypeModel';
import PartitionModel              from 'Models/partition/PartitionModel';
import model                       from 'decorators/model';
import { urn }                     from 'helpers/ModelDictionary';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import { computed }                from 'mobx';
import moment                      from 'moment';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Version', false)
@urn('$', 'intervention', 'version')
export default class VersionModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/versions/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'date': string;
		'date[after]': string | Date;
		'date[before]': string | Date;
		'date[strictly_after]': string | Date;
		'date[strictly_before]': string | Date;
		'exists[versionPublications]': string;
		'partitionUrn': string;
		'versionType': id;
		'versionType.position': id;
		'versionType.reference': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'number': string;
		'updatedAt': string;
		'versionType.position': string;
	}> = {};

	public collectionVersionPublication = new ApiCollection(VersionPublicationModel);

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	public declare partition: PartitionModel;

	@resolvable(VersionTypeModel, { attributeName: 'versionType', cache: true })
	public declare versionType: VersionTypeModel;

	private _isLockedPublication = false;

	@computed
	public get date(): Moment {
		return moment(this.get('date', moment()));
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get number(): number {
		return this.get('number', 0);
	}

	public async fetchPublication() {
		if (!this._isLockedPublication && this.versionType.reference === 'real') {
			this._isLockedPublication = true;
			await this.collectionVersionPublication.listBy([this.id], 'version');
			this._isLockedPublication = false;
		}
	}

	@computed
	public get publication() {
		if (
			!this._isLockedPublication
			&& this.versionType.reference === 'real'
			&& !this.collectionVersionPublication.isLoaded
		) {
			setTimeout(() => this.fetchPublication());
		}

		return this.collectionVersionPublication.first();
	}

	@computed
	public get isReal() {
		return this.versionType.reference === 'real';
	}
}