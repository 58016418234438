import { Model }                  from '@mathquis/modelx/';
import ResourceModel              from 'Models/intervention/ResourceModel';
import ResourceTypeModel          from 'Models/intervention/ResourceTypeModel';
import PartitionModel             from 'Models/partition/PartitionModel';
import StaffMemberModel           from 'Models/rh/StaffMemberModel';
import EnergyModel                from 'Models/vehicle/EnergyModel';
import VehicleCommentModel        from 'Models/vehicle/VehicleCommentModel';
import VehicleMileageModel        from 'Models/vehicle/VehicleMileageModel';
import VehicleModel               from 'Models/vehicle/VehicleModel';
import VehiclePhotoModel          from 'Models/vehicle/VehiclePhotoModel';
import VehicleTypeModel           from 'Models/vehicle/VehicleTypeModel';
import VehicleUnavailabilityModel from 'Models/vehicle/VehicleUnavailabilityModel';
import { computed }               from 'mobx';
import { ApiCollection }          from 'modelx/collections/ApiCollection';
import { PagedCollection }        from 'modelx/collections/PagedCollection';
import moment                     from 'moment';
import { appStore }               from 'stores';
import AbstractModelXStore        from './AbstractModelXStore';

export default class VehicleDashboardStore extends AbstractModelXStore {

	public energy = new EnergyModel();
	public lastMileageCollection = new ApiCollection(VehicleMileageModel);
	public mileagePagedCollection = new PagedCollection(VehicleMileageModel);
	public pagedCollectionUnavailabilityToday = new PagedCollection(VehicleUnavailabilityModel);
	public partition = new PartitionModel();
	public resourceCollection = new ApiCollection(ResourceModel);
	public resourceTypeCollection = new ApiCollection(ResourceTypeModel);
	public staffMember = new StaffMemberModel();
	public unavailabilityPagedCollection = new PagedCollection(VehicleUnavailabilityModel);
	public vehicle = new VehicleModel();
	public vehicleCommentPagedCollection = new PagedCollection(VehicleCommentModel);
	public vehiclePhotoCollection = new ApiCollection(VehiclePhotoModel);
	public vehicleType = new VehicleTypeModel();

	public async initAsync(vehicleId: id) {
		this.clear();

		await Promise.all([
			this.listVehiclePhotos(vehicleId),

			this.vehicleCommentPagedCollection
				.setItemsPerPage(5)
				.setFilters({ vehicle: vehicleId })
				.setSort('updatedAt', false)
				.list(),

			this.vehicle
				.set({ id: vehicleId })
				.fetch()
				.then(async () => {
					const promises: Promise<void | Model | EnergyModel>[] = [
						this.vehicleType
							.set({ id: this.vehicle.vehicleType.id })
							.fetch()
							.then(async () => {
								const today = moment();
								await Promise.all([
									this.mileagePagedCollection
										.setFilter('vehicle', vehicleId)
										.setSort('createdAt', false)
										.setItemsPerPage(5)
										.list(),

									this.pagedCollectionUnavailabilityToday
										.setItemsPerPage(0)
										.setFilters({
											'endDate[after]': `${today.format('YYYY-MM-DD')} 00:00:00`,
											'startDate[before]': `${today.format('YYYY-MM-DD')} 23:59:59`,
											'vehicle': vehicleId,
										})
										.list(),

									this.unavailabilityPagedCollection
										.setFilter('vehicle', vehicleId)
										.setItemsPerPage(10)
										.setSort('startDate', false)
										.list(),

									this.lastMileageCollection
										.setFilter('vehicle', vehicleId)
										.setFilter('itemsPerPage', 1)
										.setSort('createdAt', false)
										.list(),
								]);
							}),
					];

					// if (this.vehicle.energy.id) {
					// 	this.energy.set({ id: this.vehicle.energy.id });
					// 	promises.push(this.energy.fetch());
					// }

					if (this.vehicle.holderIsPartition) {
						this.partition.setId(this.vehicle.holderId);
						promises.push(this.partition.fetch());
					} else {
						this.staffMember.setId(this.vehicle.holderId);
						promises.push(this.staffMember.fetch());
					}

					await Promise.all(promises);
				}),
			this.resourceTypeCollection.list(),
		]);

		await this.resourceCollection
			.setFilter('partitionUrn', appStore.partitionUrn)
			.setFilter('resourceType', this.resourceTypeInterVehicle?.id)
			.setFilter('ownerResource.entityUrn', this.vehicle.urn)
			.list();
	}

	public get isInterVehicle() {
		return this.resourceCollection.length > 0;
	}

	public get resourceTypeInterVehicle() {
		return this.resourceTypeCollection.find(rt => rt.reference === 'vehicle');
	}

	public async listVehiclePhotos(vehicleId: id) {
		await this.vehiclePhotoCollection.setFilters({ vehicle: vehicleId }).list();

		await Promise.all(this.vehiclePhotoCollection.map(vp => vp.readModel.fetch()));
	}

	@computed
	public get lastMileage(): VehicleMileageModel | null {
		return this.lastMileageCollection.first();
	}

	@computed
	public get isAvailableToday() {
		return !this.pagedCollectionUnavailabilityToday.length;
	}
}