import { ApiCollection }   from 'Collections/ApiCollection';
import PagedCollection     from 'Collections/PagedCollection';
import DepositPaymentModel from 'Models/invoice/DepositPaymentModel';
import InvoicePaymentModel from 'Models/invoice/InvoicePaymentModel';
import PaymentModel        from 'Models/invoice/PaymentModel';
import ContractModel       from 'Models/sales/ContractModel';
import AbstractModelXStore from 'stores/AbstractModelXStore';

export default class PaymentDashboardStore extends AbstractModelXStore {

	public collectionContract = new ApiCollection(ContractModel);
	public pagedCollectionDepositPayment = new PagedCollection(DepositPaymentModel);
	public pagedCollectionInvoicePayment = new PagedCollection(InvoicePaymentModel);
	public payment = new PaymentModel();

	public async fetchAsync(id: id) {
		this.clear();

		await Promise.all([
			this.payment.setId(id).fetch().then(async () => {
				await this.collectionContract.listBy([this.payment.billingGroupUrn], 'billingGroupUrn');
			}),
		]);
	}

	public get contract() {
		return this.collectionContract.first();
	}
}