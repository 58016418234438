import { computed }             from 'mobx';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

export default class CompanyTypeModel extends DirectoryPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		label: string;
		reference: string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		label: string;
	}> = {};

	public static cacheDuration =3600;

	static get path(): string {
		return '/company_types/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get reference(): string {
		return this.get('reference', '');
	}
}
