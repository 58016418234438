import StaffMemberModel        from 'Models/rh/StaffMemberModel';
import AbstractForm            from 'components/AbstractForm/AbstractForm';
import Form                    from 'components/Form';
import Input                   from 'components/Input';
import React                   from 'react';
import { authenticationStore } from 'stores';
import { notificationSuccess } from 'tools/notification';

export interface IStaffMemberPasswordFormProps {
	onSuccess?: (model: StaffMemberModel) => void;
}

export default class StaffMemberPasswordForm extends AbstractForm<IStaffMemberPasswordFormProps> {

	public render() {
		return (
			<Form onFinish={this._onFinish} ref={this.formRef}>
				<Form.Item label="Nouveau mot de passe" name="plainPassword" required={true}>
					<Input.Password placeholder="Mot de passe" />
				</Form.Item>

				<Form.Item
					label="Confirmer le mot de passe"
					name="confirmPassword"
					rules={[
						{ message: 'Veuillez confirmer le mot de passe', required: true },
						{ validator: this._checkConfirmPlainPassword },
					]}
				>
					<Input.Password placeholder="Confirmer le mot de passe" />
				</Form.Item>
			</Form>
		);
	}

	private _checkConfirmPlainPassword = (rule, value, callback) => {
		if (value && value !== this.formRef.current?.getFieldValue('plainPassword')) {
			callback('Le mot de passe ne correspond pas');
		} else {
			callback();
		}
	};

	private _onFinish = async values => {
		const { onSuccess } = this.props;

		this.setSubmitting(false);

		try {
			const staffMember = new StaffMemberModel({ id: authenticationStore.session.ownerId });

			await staffMember.patch(values);

			if (onSuccess) {
				onSuccess(staffMember);
			}

			notificationSuccess(`Le mot de passe a bien été modifié`);

		} catch (err) {
			this._onError(err);
		} finally {
			this.setSubmitting(false);
		}
	};
}
