import { resolvable }                from '@mathquis/modelx-resolvables';
import InterventionResourceTypeModel from 'Models/intervention/InterventionResourceTypeModel';
import InterventionTypeModel         from 'Models/intervention/InterventionTypeModel';
import nestedResolvable              from 'decorators/nestedResolvable';
import { urn }                       from 'helpers/ModelDictionary';
import InterventionPrivateApiModel   from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'intervention_resource_type_intervention_type')
export default class InterventionResourceTypeInterventionTypeModel extends InterventionPrivateApiModel {

	static get path(): string {
		return '/intervention_resource_type_intervention_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'interventionResourceType': id;
		'interventionType': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'interventionResourceType.position': string;
	}> = {};

	public static cacheDuration = 3600;

	@resolvable(InterventionResourceTypeModel, { attributeName: 'interventionResourceType', cache: true })
	public declare interventionResourceType: InterventionResourceTypeModel;

	@resolvable(InterventionTypeModel, { attributeName: 'interventionType', cache: true })
	public declare interventionType: InterventionTypeModel;

	public get interventionResourceTypeId(): id {
		return this.get('interventionResourceType.id', 0);
	}

	public get interventionResourceTypeIri(): string {
		return this.get('interventionResourceType.@id', '');
	}

	@nestedResolvable()
	public get resourceType() {
		return this.interventionResourceType.resourceType;
	}
}