import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type PaymentMethodReference = 'direct_debit';

@model.cacheDuration()
@model.staticLabel('Mode de paiement')
@model.urn('$', 'invoice', 'payment_method')
@doc.path('/payment_methods/{?id}')
export default class PaymentMethodModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': PaymentMethodReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: PaymentMethodReference;
	@doc.string declare shortLabel: string;
}