import InterventionModel             from 'Models/intervention/InterventionModel';
import InterventionResourceTypeModel from 'Models/intervention/InterventionResourceTypeModel';
import ResourceModel                 from 'Models/intervention/ResourceModel';
import doc                           from 'decorators/doc';
import model                         from 'decorators/model';
import { Blamable }                  from 'helpers/traits';
import { Timestampable }             from 'helpers/traits';
import InterventionPrivateApiModel   from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Pointage intervenant')
@model.urn('$', 'intervention', 'intervention_time_log')
@doc.path('/intervention_time_logs/{?id}')
export default class InterventionTimeLogModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'intervention': id;
		'interventionResourceType': id;
		'interventionResourceType.reference': string;
		'resource': id;
		'resource.resourceType.reference': ResourceTypeReference;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'endDate': string;
		'id': string;
		'startDate': string;
	}> = {};

	public get interventionId() {
		return this.get('intervention.id');
	}

	public get resourceId() {
		return this.get('resource.id');
	}

	@doc.number declare achievedDuration: number;
	@doc.date declare endDate: Moment;
	@doc.number declare expectedDuration: number;
	@doc.model(InterventionModel) declare intervention: InterventionModel;
	@doc.model(InterventionResourceTypeModel) declare interventionResourceType: InterventionResourceTypeModel;
	@doc.model(ResourceModel) declare resource: ResourceModel;
	@doc.date declare startDate: Moment;
}
