import { resolvable }       from '@mathquis/modelx-resolvables';
import GraphModel           from 'Models/graph/GraphModel';
import { urn }              from 'helpers/ModelDictionary';
import { Timestampable }    from 'helpers/traits';
import { Blamable }         from 'helpers/traits';
import GraphPrivateApiModel from '../../abstracts/GraphPrivateApiModel';

type RelationTypeReferene =
	'contract_responsible' |
	'iteration_responsible' |
	'contact_person' |
	'legal_responsible';

@urn('$', 'graph', 'relation_type')
export default class RelationTypeModel extends Blamable(Timestampable(GraphPrivateApiModel)) {

	static get path(): string {
		return '/relation_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': string;
	}> = {};


	@resolvable(GraphModel, { attributeName: 'graph' })
	public declare graph: GraphModel;

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): RelationTypeReferene {
		return this.get('reference', '');
	}
}