const frontPartitionConfigs = [
	{
		configs: {
			MERGED_PLANNINGS: false,
			PARTITION_NAME: 'Placidea',
			PARTITION_URN: '$:partition:partition:1',
		},
		hostnames: ['localhost', /[^./]+-jess-placidea-tech\..+/i, 'jess.placidea.tech'],
	},
];

const getConfig = configs => {
	const config = configs.find(config => {
		return config.hostnames.find(hostname => {
			if (typeof hostname === 'string') {
				return hostname === location.hostname;
			} else if (hostname instanceof RegExp) {
				return hostname.test(location.hostname);
			} else {
				throw `Invalid hostname ${hostname}`;
			}
		});
	});

	if (!config) {
		throw `Config not found for hostname ${location.hostname}`;
	}

	return config.configs;
};

const FrontPartitionConfig = getConfig(frontPartitionConfigs);

export default FrontPartitionConfig;
