/* eslint-disable max-classes-per-file */

import { ApiCollection }             from 'Collections/ApiCollection';
import ContactModel                  from 'Models/directory/ContactModel';
import ExportTypeModel               from 'Models/intervention/ExportTypeModel';
import IrtitModel                    from 'Models/intervention/InterventionResourceTypeInterventionTypeModel';
import InterventionStatusModel       from 'Models/intervention/InterventionStatusModel';
import InterventionTypeModel         from 'Models/intervention/InterventionTypeModel';
import InterventionVersionModel      from 'Models/intervention/InterventionVersionModel';
import OwnerResourceModel            from 'Models/intervention/OwnerResourceModel';
import ProtoInterventionVersionModel from 'Models/intervention/ProtoInterventionVersionModel';
import ResourceAvailabilityModel     from 'Models/intervention/ResourceAvailabilityModel';
import ResourceModel                 from 'Models/intervention/ResourceModel';
import TaskCancellationTypeModel     from 'Models/intervention/TaskCancellationTypeModel';
import VersionModel                  from 'Models/intervention/VersionModel';
import VersionTypeModel              from 'Models/intervention/VersionTypeModel';
import { WeekTypeReference }         from 'Models/intervention/WeekTypeModel';
import StaffMemberLeaveModel         from 'Models/rh/StaffMemberLeaveModel';
import StaffMemberModel              from 'Models/rh/StaffMemberModel';
import VehicleModel                  from 'Models/vehicle/VehicleModel';
import { Buffer }                    from 'buffer';
import _flatten                      from 'lodash/flatten';
import _sortBy                       from 'lodash/sortBy';
import { override }                  from 'mobx';
import { toJS }                      from 'mobx';
import { observable }                from 'mobx';
import { action }                    from 'mobx';
import { computed }                  from 'mobx';
import { makeObservable }            from 'mobx';
import moment                        from 'moment';
import Proto                         from 'proto/proto';
import AbstractModelXStore           from 'stores/AbstractModelXStore';
import { planningStaffStore }        from 'stores';
import { appStore }                  from 'stores';
import MercureServiceIntervention    from 'tools/MercureServiceIntervention';
import { getProto }                  from 'tools/MercureServiceIntervention';
import locationTools                 from 'tools/locationTools';
import notificationApiError          from 'tools/notificationApiError';
import protoFetch                    from 'tools/protoFetch';

const { PlanningInterventionVersionCard } = Proto.Service.Intervention;

const mercure = new MercureServiceIntervention();

const columnMinWidth = 500;

export default class PlanningStaffStore extends AbstractModelXStore {

	public collectionContact = new ApiCollection(ContactModel);
	public collectionExportType = new ApiCollection(ExportTypeModel);
	public collectionInterventionStatus = new ApiCollection(InterventionStatusModel);
	public collectionInterventionType = new ApiCollection(InterventionTypeModel);
	public collectionIrtit = new ApiCollection(IrtitModel);
	public collectionOwnerResource = new ApiCollection(OwnerResourceModel);
	public collectionResource = new ApiCollection(ResourceModel);
	public collectionResourceAvailability = new ApiCollection(ResourceAvailabilityModel);
	public collectionStaffMember = new ApiCollection(StaffMemberModel);
	public collectionStaffMemberLeave = new ApiCollection(StaffMemberLeaveModel);
	public collectionTaskCancellationType = new ApiCollection(TaskCancellationTypeModel);
	public collectionVehicle = new ApiCollection(VehicleModel);
	public collectionVersionTypes = new ApiCollection(VersionTypeModel);

	@observable
	public dateFrom = moment();

	@observable
	public dateTo = moment();

	public filterStaffMemberIds = observable<string>([]);

	public hourFrom = 0;
	public hourTo = 24;

	public stores = observable<PlanningDayStore>([]);

	@observable
	private _distanceVisible = false;

	public constructor() {
		super();

		makeObservable(this);
	}

	public async fetchResourceAvailabilities() {
		this.collectionResourceAvailability.clear();

		await this.collectionResourceAvailability
			.setFilter('resource.ownerResource.entityUrn', this.collectionStaffMember.urns)
			.setFilter('resource.partitionUrn', appStore.partitionUrn)
			.list();

		await Promise.all([
			this.collectionResourceAvailability.whenIsLoaded(ra => ra.weekType),
			this.collectionResourceAvailability.whenIsLoaded(ra => ra.resource.ownerResource.entity),
		]);
	}

	public getVersionUrnStore(versionUrn: string) {
		return this.stores.find(store => store.version.urn === versionUrn);
	}

	public async init() {
		if (this.isLoading) {
			return;
		}

		this.setIsLoading(true);

		await this.collectionResource
			.setFilter('enabled', true)
			.setFilter('resourceType.reference', 'technician')
			.setFilter('partitionUrn', appStore.partitionUrn)
			.list();

		await this.collectionOwnerResource
			.setFilter('id', this.collectionResource.map(r => r.getId('ownerResource')))
			.list();

		await this.collectionStaffMember
			.setFilter('enabled', true)
			.setFilter('id', this.collectionOwnerResource.map(or => or.entityId))
			.setFilter('staffMemberPartitionGroups.partitionUrn', appStore.partitionUrn)
			.setSort('lastName')
			.list();

		const promises: Promise<unknown>[] = [
			this.setDates(),
			this.fetchResourceAvailabilities(),
			this.collectionContact.setSort('lastName').list(),
			this.collectionExportType.list(),
			this.collectionInterventionStatus.list(),
			this.collectionInterventionType.list(),
			this.collectionIrtit.list(),
			this.collectionTaskCancellationType.list(),
			this.collectionVehicle.sortBy('name').listBy([appStore.partitionUrn], 'partitionUrn'),
			this.collectionVersionTypes.list(),

			mercure.start([appStore.partitionUrn]),
		];

		await Promise.all(promises);

		this.setIsLoading(false);
	}

	public save() {
		const versions = this.stores.map(store => (store.version.id || '').toString());

		const params = { from: this.dateFrom.format('YYYY-MM-DD'), to: this.dateTo.format('YYYY-MM-DD') };

		if (versions.length) {
			params['versions'] = versions;
		}

		if (this.filterStaffMemberIds.length) {
			params['staffMembers'] = toJS(this.filterStaffMemberIds);
		}

		window.history.replaceState(null, '', locationTools.setValues(window.location.href.split('?')[0], params));

		localStorage.setItem(`planning_staff_${appStore.partitionUrn}`, JSON.stringify(params));
	}

	@action
	public async setDates(dateFrom?: Moment, dateTo?: Moment) {
		const data = {
			from: dateFrom || moment(),
			staffMembers: [] as string[],
			to: dateTo || moment(),
			versions: [] as string[],
		};

		const urlParams = new URLSearchParams(window.location.search);
		const storage = JSON.parse(localStorage.getItem(`planning_staff_${appStore.partitionUrn}`) || '{}');

		if (urlParams.has('staffMembers')) {
			data.staffMembers = (urlParams.get('staffMembers') || '').split(',').filter(id => id);
		} else if (storage) {
			data.staffMembers = storage.staffMembers || [];
		}

		if (!dateFrom && !dateTo) {
			if (urlParams.has('from') && urlParams.has('to') && urlParams.has('versions')) {
				data.from = moment(urlParams.get('from'));
				data.to = moment(urlParams.get('to'));
				data.versions = (urlParams.get('versions') || '').split(',');
			} else if (storage) {
				data.from = moment(storage.from);
				data.to = moment(storage.to);
				data.versions = storage.versions || [];
			}
		}

		this.dateFrom = data.from.startOf('day');
		this.dateTo = data.to.startOf('day');

		this.stores.replace(this.dates.map((date, idx) => {
			const vId = (dateFrom && dateTo) ? undefined : data.versions[idx];

			return this.stores.find(store => store.date.isSame(date, 'day')) || new PlanningDayStore(date, this, vId);
		}));

		await this.setFilterStaffMember(data.staffMembers || []);
	}

	@action
	public setDistanceVisible(value: boolean) {
		this._distanceVisible = value;
	}

	@action
	public setFilterStaffMember(ids: string[]) {
		this.filterStaffMemberIds.replace(ids);
		this.save();
		this.collectionStaffMemberLeave.setFilter('staffMember', ids);

		const start = this.dateFrom.format('YYYY-MM-DD[T]00:00:00');
		const end = this.dateTo.format('YYYY-MM-DD[T]23:59:59');
		return this.collectionStaffMemberLeave
			.setFilter('startDate[before]', end)
			.setFilter('endDate[after]', start)
			.list();
	}

	@computed
	public get distanceVisible() {
		return this._distanceVisible;
	}

	@override
	public get isLoaded() {
		return this.stores.every(store => store.isLoaded);
	}

	@computed
	public get vehicleCollection() {
		return new ApiCollection(VehicleModel, this.vehicles);
	}

	@computed
	public get dimensions() {
		const hourHeight = 60;

		return {
			fiveMinutesHeight: hourHeight / 12,
			hourColumnWidth: 50,
			hourHeight,
			planningHeaderHeight: 70,
			staffColumnHeight: hourHeight * (this.hourTo - this.hourFrom),
		};
	}

	@computed
	public get dates() {
		const dates = [this.dateFrom.clone()];

		const currDate = this.dateFrom.clone();

		while (currDate.diff(this.dateTo, 'days') < 0) {
			dates.push(currDate.add(1, 'days').clone());
		}

		return dates;
	}

	@computed
	public get staffMembers() {
		if (this.filterStaffMemberIds.length) {
			return this.collectionStaffMember.filter(sm => this.filterStaffMemberIds.includes(sm.id.toString()));
		}

		return this.collectionStaffMember.models;
	}

	@computed
	public get vehicles() {
		return this.collectionVehicle.models;
	}

	@computed
	public get staffMemberIds() {
		return this.staffMembers.map(sm => sm.id);
	}

	@computed
	public get protoInterventionVersions() {
		return _flatten(this.stores.map(s => s.collection.models));
	}

	@computed
	public get versionIds() {
		return this.stores.map(store => store.version.id);
	}
}

export class PlanningDayStore extends AbstractModelXStore {
	public collection = new ApiCollection(ProtoInterventionVersionModel);
	public collectionVersion = new ApiCollection(VersionModel);

	public date: Moment;

	@observable
	public version = new VersionModel();

	private readonly parent: PlanningStaffStore;

	public constructor(date: Moment, parent: PlanningStaffStore, versionId?: id) {
		super();

		this.parent = parent;
		this.date = date.clone();

		if (versionId) {
			this.version.setId(versionId);
		}

		makeObservable(this);
	}

	/**
	 * Disponibilités de tous les employés sur la journée
	 */
	@computed
	public get resourceAvailabilities() {
		const weekTypeRefs: WeekTypeReference[] = [(this.date.isoWeek() % 2) ? 'odd' : 'even', 'all'];

		return this.parent.collectionResourceAvailability
			.filter(ra => ra.day === this.date.isoWeekday() && weekTypeRefs.includes(ra.weekType.reference));
	}

	public fetch = async (version: VersionModel) => {
		try {
			this.setIsLoading(true);

			this.setVersion(version);

			if (version.id) {
				const cards = await protoFetch(
					'intervention',
					'/intervention_versions/_planning_cards',
					PlanningInterventionVersionCard,
					{
						[`order[scheduleStartDate]`]: 'asc',
						'pagination': false,
						'version': this.version.id,
					} as ModelFilters<InterventionVersionModel>,
				);

				// Hack pour alléger le chargement du planning quand il y a beaucoup de données
				const dividePush = (arr, start, end, ms, callback) => {
					callback(arr.slice(start, end));

					if (end < arr.length) {
						const step = end - start;

						setTimeout(() => dividePush(arr, start + step, end + step, ms, callback), ms);
					}
				};

				this.collection.clear();

				// Affichage des interventions 10 par 10 (200 interventions par seconde)
				dividePush(cards, 0, 10, 50, arr => {
					this.collection.set([
						...this.collection.models,
						...arr.map(card => new ProtoInterventionVersionModel(card.toJSON())),
					]);
				});
			} else {
				this.collection.clear();
			}

		} catch (err) {
			notificationApiError(err);
		} finally {
			this.setIsLoading(false);
			this.setIsLoaded(true);
		}
	};

	/**
	 * Indisponibilités pour un employé sur la journée
	 */
	public getUnavailabilitiesForStaffMember(staffMemberId: id) {
		const startDay = moment(this.date.clone().startOf('day').format('HH:mm'), 'HH:mm');
		const endDay = moment(this.date.clone().endOf('day').format('HH:mm'), 'HH:mm');

		const arr: { endHour: Moment; startHour: Moment }[] = [];

		if (
			!this.parent.collectionResourceAvailability.isLoaded
			|| this.parent.collectionResourceAvailability.isLoading
		) {
			return arr;
		}

		let prevResourceAvailability: ResourceAvailabilityModel | null = null;

		const resourceAvailabilities: ResourceAvailabilityModel[] = _sortBy(
			this.resourceAvailabilities.filter(ra => ra.resource.ownerResource.entity.id === staffMemberId),
			ra => ra.startHour.valueOf(),
		);

		resourceAvailabilities.forEach(ra => {
			const startHour = prevResourceAvailability?.endHour || startDay;

			prevResourceAvailability = ra;

			arr.push({ endHour: ra.startHour, startHour });
		});

		if (arr.length) {
			arr.push({
				endHour: endDay,
				startHour: (prevResourceAvailability as unknown as ResourceAvailabilityModel)?.endHour,
			});
		} else {
			arr.push({ endHour: endDay, startHour: startDay });
		}

		return arr;
	}

	@action
	public setVersion(version: VersionModel) {
		this.version = version;

		this.parent.save();
	}

	@computed
	public get staffColumnWidth() {
		if (this.parent.staffMemberIds.length <= 4) {
			return columnMinWidth / (this.parent.staffMemberIds.length || 1);
		}

		return 130;
	}

	@computed
	public get columnWidth() {
		return (this.parent.staffMemberIds.length * this.staffColumnWidth) || this.staffColumnWidth;
	}
}

mercure.setMessageFunction(async eventDataJson => {
	const eventData = JSON.parse(eventDataJson);

	console.log(eventData);

	const protoClass = getProto(eventData.proto);

	const proto = protoClass.decode(Buffer.from(eventData.data, 'base64'));

	switch (eventData.kind) {
		case 'intervention_version_deleted':
			planningStaffStore.stores.forEach(store => store.collection.removeById(proto.id));

			break;

		case 'intervention_version_changed':
			const event = new ProtoInterventionVersionModel(proto.toJSON());

			const store = planningStaffStore.getVersionUrnStore(event.versionUrn);

			if (store) {
				store.collection.replace(event);
			}

			break;

		case 'version_changed':
			planningStaffStore.stores.map(async store => {
				if (store.version.id === proto.id) {
					await store.collectionVersion.list();

					const newVersion = store.collectionVersion.getById(store.version.id);

					if (newVersion) {
						store.setVersion(newVersion);
					}
				}
			});

			console.log('VERSION', proto);
			break;
	}
});