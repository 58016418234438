import PlanImportModel     from 'Models/time/PlanImportModel';
import model               from 'decorators/model';
import { urn }             from 'helpers/ModelDictionary';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

@urn('$', 'time', 'plan_import_plan')
export default class PlanImportPlanModel extends TimePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'plan': id;
	}> = {};

	static get path(): string {
		return '/plan_import_plans/{?id}';
	}

	public get planId() {
		return this.get('plan.id');
	}

	@model.resolvable(PlanImportModel, { attributeName: 'planImport' })
	public declare planImport: PlanImportModel;
}