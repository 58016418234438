import InterventionModel           from 'Models/intervention/InterventionModel';
import TaskZoneModel               from 'Models/intervention/TaskZoneModel';
import VersionModel                from 'Models/intervention/VersionModel';
import doc                          from 'decorators/doc';
import model                         from 'decorators/model';
import nestedResolvable            from 'decorators/nestedResolvable';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import { computed }                from 'mobx';
import moment                      from 'moment/moment';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Intervention', false)
@model.urn('$', 'intervention', 'intervention_version')
@doc.path('/intervention_versions/{?id}')
export default class InterventionVersionModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'exists[version.versionPublications]': boolean;
		'intervention': id;
		'intervention.interventionOperations': id;
		'intervention.interventionOperations.taskOperation': id;
		'intervention.interventionOperations.taskOperation.task': id;
		'intervention.interventionOperations.taskOperation.task.owner': id;
		'intervention.interventionOperations.taskOperation.task.owner.clientUrn': string;
		'intervention.interventionOperations.taskOperation.task.taskGroup': id;
		'intervention.interventionOperations.taskOperation.task.timeGroupUrn': string;
		'intervention.interventionStatus': id;
		'intervention.interventionStatus.reference': InterventionStatusReference;
		'intervention.interventionType.reference': InterventionTypeReference;
		'intervention.partitionUrn': string;
		'interventionVersionResources': id;
		'interventionVersionResources.interventionResourceType': id;
		'interventionVersionResources.resource': id;
		'interventionVersionResources.resource.ownerResource': id;
		'interventionVersionResources.resource.ownerResource.entityUrn': string;
		'interventionVersionResources.resource.resourceType': id;
		'scheduleEndDate[after]': string | Date;
		'scheduleEndDate[before]': string | Date;
		'scheduleEndDate[strictly_after]': string | Date;
		'scheduleEndDate[strictly_before]': string | Date;
		'scheduleStartDate[after]': string | Date;
		'scheduleStartDate[before]': string | Date;
		'scheduleStartDate[strictly_after]': string | Date;
		'scheduleStartDate[strictly_before]': string | Date;
		'version': id;
		'version.date': string;
		'version.date[after]': string;
		'version.date[before]': string;
		'version.date[strictly_after]': string;
		'version.date[strictly_before]': string;
		'version.versionType': id;
		'version.versionType.reference': VersionTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'scheduleEndDate': string;
		'scheduleStartDate': string;
	}> = {};

	@doc.model(InterventionModel) declare intervention: InterventionModel;
	@doc.date declare scheduleEndDate: Moment;
	@doc.date declare scheduleStartDate: Moment;
	@doc.model(VersionModel) declare version: VersionModel;

	@computed
	public get duration() {
		return this.scheduleEndDate.diff(this.scheduleStartDate);
	}

	@computed
	public get durationDate(): Moment {
		return moment.utc(this.duration);
	}

	@nestedResolvable()
	public get location() {
		return this.intervention.location;
	}

	@nestedResolvable()
	public get taskZone() {
		return (this.location instanceof TaskZoneModel) ? this.location as TaskZoneModel : null;
	}

	@nestedResolvable()
	public get owner() {
		return this.taskZone?.owner;
	}

	@nestedResolvable()
	public get contact() {
		return this.owner?.contact;
	}

	@nestedResolvable()
	public get client() {
		return this.owner?.client;
	}

	@nestedResolvable()
	public get interventionStatus() {
		return this.intervention.interventionStatus;
	}

	public get day() {
		return this.scheduleStartDate.day() || 7;
	}

	public get interventionId(): id {
		return this.get('intervention.id', 0);
	}
}
