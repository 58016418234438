import { resolvable }       from '@mathquis/modelx-resolvables';
import ContractModel        from 'Models/sales/ContractModel';
import LogTypeModel         from 'Models/time/LogTypeModel';
import { urn }              from 'helpers/ModelDictionary';
import { computed }         from 'mobx';
import moment               from 'moment';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'contract_notice_period')
export default class ContractNoticePeriodModel extends SalesPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'contract': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'startDate': string;
	}> = {};

	static get path(): string {
		return '/contract_notice_periods/{?id}';
	}

	@resolvable(ContractModel, { attributeName: 'contract' })
	public declare contract: ContractModel;

	@resolvable(LogTypeModel, { attributeName: 'logTypeUrn' })
	public declare logType: LogTypeModel;

	public get days(): number {
		return this.get('days', 0);
	}

	@computed
	public get endDate(): Moment {
		return moment(this.get('endDate', moment()));
	}

	@computed
	public get startDate(): Moment {
		return moment(this.get('startDate', moment()));
	}
}