/* eslint-disable @typescript-eslint/no-explicit-any */

import { AbstractApiCollection } from 'Collections/AbstractApiCollection';
import { when }                  from 'mobx';
import AbstractApiModel          from '../modelx/models/abstracts/AbstractApiModel';

const transformToArray = (data: Record<string, any> | []) => Array.isArray(data) ? data : Object.values(data);

export const isLoading = (data: Record<string, any> | []) => !isAllEqualForProperty('isLoading', data, false);
export const isLoaded = (data: Record<string, any> | []) => isAllEqualForProperty('isLoaded', data, true);
export const isFailed = (data: Record<string, any> | []) => !isAllEqualForProperty('isFailed', data, false);

export const isAllEqualForProperty = (property: string, data: Record<string, any> | [], value: unknown): boolean => {
	return !transformToArray(data).find(obj => {
		if (obj && typeof obj[property] !== 'undefined') {
			return obj[property] !== value;
		}

		return false;
	});
};

export const clear = (data: Record<string, any> | []): void => {
	transformToArray(data).forEach(obj => {
		if (!obj?.isVirtualCollection && obj?.clear) {
			obj.clear();
		}
	});
};

export const getPropertyArray = (data: Record<string, any> | [], property: string): unknown[] => {
	return transformToArray(data).reduce((accumulator: unknown[], item) => {
		if (typeof item[property] !== 'undefined') {
			return item[property];
		} else if (Array.isArray(item['models'])) {
			return accumulator.concat(getPropertyArray(item['models'], property));
		}

		return accumulator;
	}, []);
};


export const asyncModelIs = (
	model: AbstractApiModel,
	property: keyof AbstractApiModel = 'isLoaded',
	value = true,
	timeout = 30000,
) => {
	return asyncIs(() => model[property] === value, timeout);
};

export const asyncCollectionIs = <T extends AbstractApiModel>(
	coll: AbstractApiCollection<T>,
	it?: (m: T) => AbstractApiModel,
	property: keyof AbstractApiModel = 'isLoaded',
	value = true,
	timeout = 30000,
) => {
	return asyncIs(() => (it ? (!coll.length || coll.some(m => it(m)[property])) : coll[property]) === value, timeout);
};

export const asyncIs = (predicate: () => boolean, timeout = 30000) => {
	return new Promise<boolean>((resolve, reject) => {
		const myTimeout = setTimeout(() => reject(`Timeout after ${timeout}s`), timeout);

		when(predicate, () => {
			clearTimeout(myTimeout);
			resolve(true);
		});
	});
};

export const whenAsync = asyncIs;