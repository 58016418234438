import { urn }              from 'helpers/ModelDictionary';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export type RecurringModeReference = 'RECURRING_MODE_NONE' | 'RECURRING_MODE_TACIT_RENEWAL';

@urn('$', 'sales', 'recurring_mode')
export default class RecurringModeModel extends SalesPrivateApiModel {

	static get path(): string {
		return '/recurring_modes/{?id}';
	}

	public static cacheDuration =3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): RecurringModeReference {
		return this.get('reference', '');
	}
}