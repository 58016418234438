import { resolvable }           from '@mathquis/modelx-resolvables';
import ContextTypeModel         from 'Models/rights/ContextTypeModel';
import { ContextTypeReference } from 'Models/rights/ContextTypeModel';
import { urn }                  from 'helpers/ModelDictionary';
import RightsPrivateApiModel    from '../../abstracts/RightsPrivateApiModel';

@urn('$', 'rights', 'action_group')
export default class ActionGroupModel extends RightsPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'contextType': id;
		'contextType.reference': ContextTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	static get path(): string {
		return '/action_groups/{?id}';
	}

	@resolvable(ContextTypeModel, { attributeName: 'contextType' })
	public declare contextType: ContextTypeModel;

	public get description(): string {
		return this.get('description', '') || '';
	}

	public get label(): string {
		return this.get('label', '') || '';
	}
}