import { resolvable }         from '@mathquis/modelx-resolvables';
import InvoiceModel           from 'Models/invoice/InvoiceModel';
import PaymentModel           from 'Models/invoice/PaymentModel';
import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoice_payment')
export default class InvoicePaymentModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/invoice_payments/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'invoice': id;
		'payment': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	public get amount(): number {
		return this.get('amount', 0);
	}

	@resolvable(InvoiceModel, { attributeName: 'invoice' })
	public declare invoice: InvoiceModel;

	@resolvable(PaymentModel, { attributeName: 'payment' })
	public declare payment: PaymentModel;

	public get paymentId(): id {
		return this.get('payment.id', 0);
	}

	public get invoiceId(): id {
		return this.get('invoice.id', 0);
	}
}