import React from 'react';

export interface TextProps {
	align?: 'left' | 'right' | 'center';
	bold?: boolean;
	color?: string;
	italic?: boolean;
	lineHeight?: number | string;
	size?: number;
	uppercase?: boolean;
}

export const styleFromTextProps = (props: TextProps) => {
	const style: React.CSSProperties = {
		color: props.color,
		fontSize: props.size,
		lineHeight: props.lineHeight,
		textAlign: props.align,
	};

	if (props.uppercase) {
		style.textTransform = 'uppercase';
	}

	if (typeof props.bold !== 'undefined') {
		style.fontWeight = props.bold ? 'bold' : 400;
	}

	if (props.italic) {
		style.fontStyle = 'italic';
	}

	return style;
};

export default (props: React.PropsWithChildren<TextProps & { style?: React.CSSProperties; }>) => {
	return (
		<span style={{ ...styleFromTextProps(props), ...props.style }}>
			{props.children}
		</span>
	);
};