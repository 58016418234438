import { resolvable }              from '@mathquis/modelx-resolvables';
import TaskCancellationStatusModel from 'Models/intervention/TaskCancellationStatusModel';
import TaskCancellationTypeModel   from 'Models/intervention/TaskCancellationTypeModel';
import TaskModel                   from 'Models/intervention/TaskModel';
import PartitionModel              from 'Models/partition/PartitionModel';
import LogTypeModel                from 'Models/time/LogTypeModel';
import TimeGroupModel              from 'Models/time/TimeGroupModel';
import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import { computed }                from 'mobx';
import moment                      from 'moment';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'task_cancellation')
export default class TaskCancellationModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/task_cancellations/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'datetime': string;
		'partitionUrn': string;
		'sourceUrn': string;
		'taskCancellationType': id;
		'taskTaskCancellations.task': id;
		'timeGroupUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'datetime': string;
		'id': string;
	}> = {};

	public get closingNotice(): boolean {
		return this.get('closingNotice', false);
	}

	@computed
	public get datetime(): Moment {
		return moment(this.get('datetime', moment()));
	}

	@computed
	public get endDate(): Moment {
		return moment(this.get('endDate', moment()));
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get note(): string {
		return this.get('note', '');
	}

	@computed
	public get startDate(): Moment {
		return moment(this.get('startDate', moment()));
	}

	@computed
	public get invoiced(): boolean {
		return this.get('logTypeUrn', '') === '$:time:log_type:1';
	}

	@resolvable(LogTypeModel, { attributeName: 'logTypeUrn', cache: true })
	public declare logType: LogTypeModel;

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	public declare partition: PartitionModel;

	@resolvable(TaskModel, { attributeName: 'task' })
	public declare task: TaskModel;

	@resolvable(TaskCancellationStatusModel, { attributeName: 'taskCancellationStatus', cache: true })
	public declare taskCancellationStatus: TaskCancellationStatusModel;

	@resolvable(TaskCancellationTypeModel, { attributeName: 'taskCancellationType', cache: true })
	public declare taskCancellationType: TaskCancellationTypeModel;

	@resolvable(TimeGroupModel, { attributeName: 'timeGroupUrn' })
	public declare timeGroup: TimeGroupModel;
}