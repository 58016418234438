import { urn }                from 'helpers/ModelDictionary';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type BillableStatusReference = 'in_creation' | 'created' | 'to_bill' | 'billed';

@urn('$', 'invoice', 'billable_status')
export default class BillableStatusModel extends InvoicePrivateApiModel {

	static get path(): string {
		return '/billable_statuses/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': BillableStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'reference': string;
	}> = {};

	public static cacheDuration = 3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): BillableStatusReference {
		return this.get('reference', '');
	}

	public get color() {
		switch (this.reference) {
			case 'created' :
				return '#3498db';
			case 'to_bill' :
				return '#72A5FA';
			case 'billed' :
				return '#2ecc71';
			case 'in_creation' :
				return '#e67e22';
			default:
				return '#808080';
		}
	}
}