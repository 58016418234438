import { ApiCollection }   from 'Collections/ApiCollection';
import BillableItemModel   from 'Models/invoice/BillableItemModel';
import BillableModel       from 'Models/invoice/BillableModel';
import BillableStatusModel from 'Models/invoice/BillableStatusModel';
import InvoiceModel        from 'Models/invoice/InvoiceModel';
import ContractModel       from 'Models/sales/ContractModel';
import { computed }        from 'mobx';
import AbstractModelXStore from 'stores/AbstractModelXStore';

export default class BillableDashboardStore extends AbstractModelXStore {
	public billable = new BillableModel();
	public collectionBillableItem = new ApiCollection(BillableItemModel);
	public collectionBillableStatus = new ApiCollection(BillableStatusModel);
	public collectionContract = new ApiCollection(ContractModel);
	public collectionInvoiceRefunded = new ApiCollection(InvoiceModel);

	public async fetchAsync(id: id) {
		this.clear();

		await Promise.all([
			this.billable.setId(id).fetch().then(async () => {
				await this.collectionContract.listBy([this.billable.billingGroupUrn], 'billingGroupUrn');
			}),
			this.collectionBillableItem.listBy([id], 'billable'),
			this.collectionInvoiceRefunded.listBy([id], 'refundedByBillables.billable'),
			this.collectionBillableStatus.list(),
		]);
	}

	@computed
	public get totalPriceExclTax() {
		return this.collectionBillableItem.reduce((acc, bi) => acc + bi.totalPriceExclTax, 0);
	}

	@computed
	public get totalPriceVat() {
		return this.collectionBillableItem.reduce((acc, bi) => acc + bi.totalPriceVat, 0);
	}

	@computed
	public get totalPriceInclTax() {
		return this.collectionBillableItem.reduce((acc, bi) => acc + bi.totalPriceInclTax, 0);
	}

	@computed
	public get contract() {
		return this.collectionContract.first();
	}
}