import ApiCollection        from 'Collections/ApiCollection';
import PlanImportPlanModel  from 'Models/time/PlanImportPlanModel';
import PlanModel            from 'Models/time/PlanModel';
import AbstractContactStore from 'stores/AbstractContactStore';

export default class PlanDashboardStore extends AbstractContactStore {

	public plan = new PlanModel();

	public planImportPlanCollection = new ApiCollection(PlanImportPlanModel);

	public async initAsync(planId: id) {
		this.clear();

		await Promise.all([
			this.plan
				.setId(planId)
				.fetch(),

			this.planImportPlanCollection
				.setFilter('plan', planId)
				.list(),
		]);
	}

	public get planImport(){
		return this.planImportPlanCollection.first()?.planImport;
	}
}
