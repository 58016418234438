import moment from 'moment';

export const weekdaysNames = (() => {
	return Array(7).fill(0).map((_, i) => {
		return moment().day(i).format('dddd');
	});
})();

export const getWeekdayName = (day) => {
	if (weekdaysNames[day]) {
		return weekdaysNames[day];
	}

	return '';
};
