import doc                          from 'decorators/doc';
import model                         from 'decorators/model';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Statut')
@model.urn('$', 'intervention', 'intervention_status')
@doc.path('/intervention_statuses/{?id}')
export default class InterventionStatusModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': InterventionStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
		'reference': string;
	}> = {};

	public static cacheDuration = 3600;

	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare reference: InterventionStatusReference;

	public get color() {
		switch (this.reference) {
			case 'scheduled' :
				return '#FFA500';
			case 'in_progress' :
				return '#72A5FA';
			case 'done' :
				return '#1500FF';
			case 'pointed' :
				return '#00FF00';
			case 'cancelled' :
				return '#FF0000';
			case 'to_process' :
				return '#E920EB';
			case 'processed' :
				return '#7E0F7F';
			default:
				return '#808080';
		}
	}
}
