import { ApiCollection }          from 'Collections/ApiCollection';
import { PagedCollection }        from 'Collections/PagedCollection';
import TaskZoneModel              from 'Models/intervention/TaskZoneModel';
import InvoiceTypeModel           from 'Models/invoice/InvoiceTypeModel';
import ContractDepositRuleModel   from 'Models/sales/ContractDepositRuleModel';
import ContractModel              from 'Models/sales/ContractModel';
import ContractNoticePeriodModel  from 'Models/sales/ContractNoticePeriodModel';
import ContractStatusHistoryModel from 'Models/sales/ContractStatusHistoryModel';
import ContractSuspensionModel    from 'Models/sales/ContractSuspensionModel';
import OwnerModel                 from 'Models/sales/OwnerModel';
import { computed }               from 'mobx';
import AbstractModelXStore        from 'stores/AbstractModelXStore';

export default class ContractDashboardStore extends AbstractModelXStore {

	public collectionContractDepositRule = new ApiCollection(ContractDepositRuleModel);
	public collectionInvoiceType = new ApiCollection(InvoiceTypeModel);
	public contract = new ContractModel();
	public contractNoticePeriodPagedCollection = new PagedCollection(ContractNoticePeriodModel);
	public contractStatusHistoryPagedCollection = new PagedCollection(ContractStatusHistoryModel);
	public contractSuspensionPagedCollection = new PagedCollection(ContractSuspensionModel);
	public owner = new OwnerModel();
	public taskZoneCollection = new ApiCollection(TaskZoneModel);

	public async fetchAsync(id: id) {
		this.clear();

		await Promise.all([
			this.contract.setId(id).fetch().then(async () => {
				await Promise.all([
					this.collectionContractDepositRule.listBy([this.contract.id], 'contract'),

					this.owner
						.setId(this.contract.owner.id)
						.fetch()
						.then(() => {
							this.taskZoneCollection
								.setFilter('owner.clientUrn', this.owner.client.urn)
								.list();
						}),
				]);
			}),
			this.contract.setId(id).fetch(),
			this.collectionInvoiceType.list(),
			this.contractStatusHistoryPagedCollection.setSort('createdAt', false).setFilter('contract', id).list(),
			this.contractSuspensionPagedCollection.setSort('createdAt', false).setFilter('contract', id).list(),
			this.contractNoticePeriodPagedCollection
				.setItemsPerPage(1)
				.setFilter('contract', id)
				.setSort('startDate')
				.list(),
		]);
	}

	public get contractNoticePeriod() {
		return this.contractNoticePeriodPagedCollection.first();
	}

	@computed
	public get contractDepositRule() {
		return this.collectionContractDepositRule.first() || new ContractDepositRuleModel();
	}
}