import classNames         from 'classnames';
import GridInfoEmptyValue from 'components/GridInfo/GridInfoEmptyValue';
import React              from 'react';
import './GridInfoArrayValue.scss';

export default ({ hyphen, items }: { hyphen?: boolean, items: React.ReactNode[] }) => {
	return (
		<span className={classNames('GridInfoArrayValue', { hyphen })}>
			{items.filter(v => v).map((value, idx) => (
				value ? <span key={idx}>{value}</span> : <GridInfoEmptyValue key={idx} />
			))}
		</span>
	);
};