import { ApiCollection }     from 'Collections/ApiCollection';
import PartitionAddressModel from 'Models/partition/PartitionAddressModel';
import PartitionModel        from 'Models/partition/PartitionModel';
import { computed }          from 'mobx';
import AbstractModelXStore   from './AbstractModelXStore';

export default class PartitionDashboardStore extends AbstractModelXStore {

	public partition = new PartitionModel();
	public partitionAddressCollection = new ApiCollection(PartitionAddressModel);

	public async initAsync(id: id) {
		this.clear();

		await Promise.all([
			this.partition.set({ id }).fetch(),
			this.partitionAddressCollection.setFilter('partition', id).list(),
		]);
	}

	@computed
	public get partitionAddress() {
		return this.partitionAddressCollection.first();
	}
}
