import { ApiCollection }   from 'Collections/ApiCollection';
import PartitionModel      from 'Models/partition/PartitionModel';
import { makeObservable }  from 'mobx';
import { action }          from 'mobx';
import { computed }        from 'mobx';
import { observable }      from 'mobx';
import AbstractModelXStore from 'stores/AbstractModelXStore';

export default class AppStore extends AbstractModelXStore {

	public partitionCollection = new ApiCollection(PartitionModel);

	@observable
	private _partitionId?: id;

	public constructor() {
		super();

		makeObservable(this);
	}

	public clear(): this {
		this._partitionId = undefined;

		localStorage.removeItem('PartitionId');

		return super.clear();
	}

	public async load() {
		await this.partitionCollection.setSort('position').list();

		const p = window.location.pathname.split('/');

		if (p[1] === 'partitions' && Number.isInteger(parseInt(p[2]))) {
			this.setPartitionId(p[2]);
		} else {
			const storedPartitionId = localStorage.getItem('PartitionId');

			if (storedPartitionId) {
				this.setPartitionId(storedPartitionId);
			} else if (this.partitionCollection.length) {
				this.setPartitionId(this.partitionCollection.models[0].id);
			}
		}
	}

	@computed
	public get partition() {
		if (this._partitionId) {
			return this.partitionCollection.getById(this._partitionId) || new PartitionModel();
		}

		return new PartitionModel();
	}

	@computed
	public get partitionUrn() {
		return this.partition.urn;
	}

	@action
	public setPartitionId = (id: id) => {
			this._partitionId = id;

			localStorage.setItem('PartitionId', id.toString());

			document.title = `Backoffice ${this.partition.name}`;

			return this;
		};
}