import BillingPeriodStatusModel from 'Models/invoice/BillingPeriodStatusModel';
import doc                       from 'decorators/doc';
import model                      from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import { computed }             from 'mobx';
import { ucFirst }              from 'tools/jsTools';
import InvoicePrivateApiModel   from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('Période de facturation', false)
@model.urn('$', 'invoice', 'billing_period')
@doc.path('/billing_periods/{?id}')
export default class BillingPeriodModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'billingPeriodStatus': id;
		'billingPeriodStatus.reference': BillingPeriodStatusReference;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'endDate[after]': string;
		'endDate[before]': string;
		'endDate[strictly_after]': string;
		'endDate[strictly_before]': string;
		'startDate[after]': string;
		'startDate[before]': string;
		'startDate[strictly_after]': string;
		'startDate[strictly_before]': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'billingPeriodStatus.label': string;
		'endDate': string;
		'id': string;
		'invoiceNumber': string;
		'startDate': string;
		'updatedAt': string;
	}> = {};

	@doc.number declare amount: number;
	@doc.number declare amountTaxExcl: number;
	@doc.number declare amountTaxIncl: number;
	@doc.number declare amountVat: number;
	@doc.model(BillingPeriodStatusModel) declare billingPeriodStatus: BillingPeriodStatusModel;
	@doc.date declare endDate: Moment;
	@doc.number declare invoiceNumber: number;
	@doc.number declare remainingAmount: number;
	@doc.date declare startDate: Moment;

	@computed
	public get name() {
		return ucFirst(this.startDate.format('MMMM YYYY'));
	}
}