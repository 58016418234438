import { resolvable }                   from '@mathquis/modelx-resolvables';
import InterventionModel                from 'Models/intervention/InterventionModel';
import InterventionOperationStatusModel from 'Models/intervention/InterventionOperationStatusModel';
import TaskOperationModel               from 'Models/intervention/TaskOperationModel';
import { urn }                          from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                                       from 'helpers/traits';
import InterventionPrivateApiModel      from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'intervention_operation')
export default class InterventionOperationModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/intervention_operations/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'intervention': id;
		'intervention.taskZone.owner.clientUrn': string;
		'interventionOperationStatus': id;
		'taskOperation': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public get cancelled(): boolean {
		return this.get('cancelled', false);
	}

	public get deleted(): boolean {
		return this.get('deleted', false);
	}

	@resolvable(InterventionModel, { attributeName: 'intervention' })
	public declare intervention: InterventionModel;

	@resolvable(InterventionOperationStatusModel, { attributeName: 'interventionOperationStatus', cache: true })
	public declare interventionOperationStatus: InterventionOperationStatusModel;

	public get note(): string {
		return this.get('note', '');
	}

	public get interventionOperationStatusId(): id {
		return this.get('interventionOperationStatus.id', 0);
	}

	@resolvable(TaskOperationModel, { attributeName: 'taskOperation' })
	public declare taskOperation: TaskOperationModel;

	public get value(): number {
		return this.get('value', 0);
	}

	public get interventionId(): id {
		return this.get('intervention.id', 0);
	}

	public get taskOperationId(): id {
		return this.get('taskOperation.id', 0);
	}
}