import ContractIterationModel from 'Models/sales/ContractIterationModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import SalesPrivateApiModel   from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Avenant')
@model.urnResource('contract_iteration_amendment')
@doc.path('/contract_iteration_amendments/{?id}')
export default class ContractIterationAmendmentModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		contractIterationAmendment: id;
		contractIterationAmended: id;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.model(ContractIterationModel) declare contractIterationAmendment: ContractIterationModel;
	@doc.model(ContractIterationModel) declare contractIterationAmended: ContractIterationModel;
}