import { resolvable }           from '@mathquis/modelx-resolvables';
import { ILocationModel }       from 'Models/intervention/InterventionModel';
import PartitionModel           from 'Models/partition/PartitionModel';
import { urn }                  from 'helpers/ModelDictionary';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import PartitionPrivateApiModel from '../../abstracts/PartitionPrivateApiModel';

@urn('$', 'partition', 'partition_address')
export default class PartitionAddressModel
	extends Blamable(Timestampable(PartitionPrivateApiModel)) implements ILocationModel {

	static get path(): string {
		return '/partition_addresses/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'partition': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	public get address(): string {
		return this.get('address', '');
	}

	public get addressBis(): string {
		return this.get('addressBis', '');
	}

	public get city(): string {
		return this.get('city', '');
	}

	public get country(): string {
		return this.get('country', '');
	}

	public get geoHash(): string {
		return this.get('geoHash', '');
	}

	public get zipCode(): string {
		return this.get('zipCode', '');
	}

	public get fullAddress() {
		return `${this.address} ${this.addressBis} ${this.zipCode} ${this.city}`;
	}

	@resolvable(PartitionModel, { attributeName: 'partition', cache: true })
	public declare partition: PartitionModel;
}