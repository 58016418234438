import { DownOutlined }        from '@ant-design/icons/lib';
import Dropdown                from 'antd/lib/dropdown';
import { MenuProps }           from 'antd/lib/menu';
import AppForm                 from 'components/AppForm';
import StaffMemberAvatar       from 'entityComponents/StaffMember/StaffMemberAvatar';
import StaffMemberPasswordForm from 'forms/StaffMemberPasswordForm';
import { observer }            from 'mobx-react';
import React                   from 'react';
import { authenticationStore } from 'stores';
import LogoutLink              from './LogoutLink';
import '../css/AppUserMenu.scss';

interface IAppUserMenuProps {
	style?: React.CSSProperties;
}

@observer
export default class AppUserMenu extends React.Component<IAppUserMenuProps> {
	public render() {
		const { style } = this.props;

		const items: MenuProps['items'] = [
			{
				key: '0',
				label: `Changer mon mot de passe`,
				onClick: () => AppForm.open(StaffMemberPasswordForm, {}, {
					title: `Modifier le mot de passe`,
					width: 450,
				}),
			},
			{
				type: 'divider',
			},
			{
				key: '1',
				label: <LogoutLink />,
			},
		];

		return (
			<div className="AppUserMenu__container" style={style}>
				<Dropdown menu={{ items }} trigger={['click']}>
					<div className="AppUserMenu__title">
						<div className="AppUserMenu__name">
							<StaffMemberAvatar
								mode="fullName"
								staffMember={authenticationStore.session.staffMember}
								tooltipDisabled={true}
							/>
						</div>
						<DownOutlined />
					</div>
				</Dropdown>
			</div>
		);
	}
}
