import { ArgsProps }    from 'antd/lib/notification';
import { notification } from 'antd';
import View             from 'components/View';
import React            from 'react';

export const apiErrorMessage = (err) => {
	const errorCode: string = err?.connectorError?.request?.status
		? ` (code erreur ${err?.connectorError?.request?.status})`
		: '';

	let description: string | undefined = (
		err?.connectorError?.response?.data?.['hydra:description']
		|| err?.connectorError?.response?.data?.message
		|| err?.connectorError?.response?.data?.header?.message
		|| err?.connectorError?.message
		|| err?.response?.data?.header?.message
		|| err.message
	) + errorCode;

	if (description === 'undefined') {
		description = undefined;
	}

	return description;
};

export const apiErrorFullUrl = err => {
	const config = err?.connectorError?.config || err?.config;
	return (config?.baseURL || '') + (config?.url || '');
};

export default (err, notificationOptions?: Partial<ArgsProps & { displayFullUrl: boolean; }>) => {
	const { description, displayFullUrl } = notificationOptions || {};

	const descriptionComp = (
		<View gap={6}>
			<View>{description || apiErrorMessage(err)}</View>
			{displayFullUrl && <View color="rgba(0,0,0,0.6)" size={10}>{apiErrorFullUrl(err)}</View>}
		</View>
	);

	notification.error({ message: `Erreur`, ...notificationOptions, description: descriptionComp });
};
