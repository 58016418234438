import { ApiCollection }              from 'Collections/ApiCollection';
import { PagedCollection }            from 'Collections/PagedCollection';
import ClientModel                    from 'Models/directory/ClientModel';
import TaskZoneModel                  from 'Models/intervention/TaskZoneModel';
import TaskZoneResourceModel          from 'Models/intervention/TaskZoneResourceModel';
import DebitMandateDocumentModel      from 'Models/invoice/DebitMandateDocumentModel';
import DebitMandateModel              from 'Models/invoice/DebitMandateModel';
import DebitMandateStatusHistoryModel from 'Models/invoice/DebitMandateStatusHistoryModel';
import DebitMandateStatusModel        from 'Models/invoice/DebitMandateStatusModel';
import InvoicingGroupModel            from 'Models/invoice/InvoicingGroupModel';
import { when }                       from 'mobx';
import AbstractContactStore           from 'stores/AbstractContactStore';

export default class ClientDashboardStore extends AbstractContactStore {

	public client = new ClientModel();
	public debitMandateCollection = new ApiCollection(DebitMandateModel);
	public debitMandateDocumentCollection = new ApiCollection(DebitMandateDocumentModel);
	public debitMandateStatusCollection = new ApiCollection(DebitMandateStatusModel);
	public debitMandateStatusHistoryCollection = new ApiCollection(DebitMandateStatusHistoryModel);
	public invoicingGroupPagedCollection = new PagedCollection(InvoicingGroupModel);
	public pagedCollectionTaskZoneResource = new PagedCollection(TaskZoneResourceModel);
	public taskZoneCollection = new ApiCollection(TaskZoneModel);
	public taskZonePagedCollection = new PagedCollection(TaskZoneModel);

	public async fetchInvoicingGroup() {
		await this.invoicingGroupPagedCollection
			.listBy([this.client.urn], 'owner.clientUrn').then(() => {
				this.debitMandateCollection
					.listByFromCollection(this.invoicingGroupPagedCollection, 'id', 'invoicingGroup')
					.then(() => {
						this.debitMandateDocumentCollection
							.listByFromCollection(this.debitMandateCollection, 'id', 'debitMandate');
					})
					.then(() => {
						this.debitMandateStatusCollection.list();
					})
					.then(() => {
						this.debitMandateStatusHistoryCollection
							.listByFromCollection(this.debitMandateCollection, 'id', 'debitMandate');
					});
			});
	}

	public async initAsync(clientId: id) {
		this.clear();

		await this.client
			.setId(clientId)
			.fetch()
			.then(() => {
				when(() => this.client.generalClient.contact.isLoaded, () => {
					this.contact.set(this.client.generalClient.contact.attributes);
					this.fetchRelations(this.contact);
				});
			});

		await Promise.all([
			this.taskZoneCollection.listBy([this.client.urn], 'owner.clientUrn'),

			this.taskZonePagedCollection.listBy([this.client.urn], 'owner.clientUrn'),

			this.fetchInvoicingGroup(),
		]);
	}
}
