import lazyWithRetry from 'tools/lazyWithRetry';

const VehicleListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "vehicleListPage" */
	'../../pages/Vehicle/VehicleListPage'));

const VehicleDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "vehicleDashboardPage" */
	'../../pages/Vehicle/VehicleDashboardPage'));

export default [
	{
		component: VehicleListPage,
		exact: true,
		path: '/partitions/:partitionId/vehicles',
	},
	{
		component: VehicleDashboardPage,
		exact: true,
		path: '/partitions/:partitionId/vehicles/:vehicleId',
	},
];