import AntModal                        from 'antd/lib/modal';
import { ModalProps as AntModalProps } from 'antd/lib/modal';
import _omit                           from 'lodash/omit';
import React                           from 'react';

export interface IModalProps extends AntModalProps {
	defaultVisible?: boolean;
}

export default class Modal extends React.Component<IModalProps> {
	public static defaultProps = {
		cancelButtonProps: {},
		cancelText: 'Fermer',
		okButtonProps: {},
		okText: 'Ok',
		width: 750,
	};

	public state = { visible: !!this.props.defaultVisible };

	public hide = () => this.setState({ visible: false });

	public render() {
		return (
			<AntModal
				destroyOnClose={true}
				onCancel={this.hide}
				open={this.state.visible}

				{..._omit(this.props, ['defaultVisible'])}
			>
				{this.props.children}
			</AntModal>
		);
	}

	public show = () => this.setState({ visible: true });
}