import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'version_publication')
export default class VersionPublicationModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/version_publications/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'version': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'version.label': string;
	}> = {};

	public get version() {
		return this.versionId;
	}

	public get versionId(): id {
		return this.get('version.id');
	}
}