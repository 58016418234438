import { urn } from 'helpers/ModelDictionary';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@urn('$', 'vehicle', 'energy')
export default class EnergyModel extends VehiclePrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'label': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'position': string;
		'updatedAt': string;
	}> = {};

	static get path(): string {
		return '/energies/{?id}';
	}
	
	public get label(): string {
		return this.get('label', '');
	}
	
	public get position(): number {
		return this.get('position', 0);
	}
	
	public get reference(): string {
		return this.get('reference', '');
	}
	
}