import { urn }              from 'helpers/ModelDictionary';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export type ContractIterationDocumentTypeReference =
	'CONTRACT_ITERATION_DOCUMENT_TYPE_CONTRACT_PDF' |
	'CONTRACT_ITERATION_DOCUMENT_TYPE_QUOTATION_PDF' |
	'CONTRACT_ITERATION_DOCUMENT_TYPE_OTHER';

@urn('$', 'sales', 'contract_iteration_document_type')
export default class ContractIterationDocumentTypeModel extends SalesPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'reference': id;
	}> = {};

	public static cacheDuration =3600;

	static get path(): string {
		return '/contract_iteration_document_types/{?id}';
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): ContractIterationDocumentTypeReference {
		return this.get('reference', '');
	}

}