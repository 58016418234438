import { resolvable }              from '@mathquis/modelx-resolvables';
import InterventionVersionModel    from 'Models/intervention/InterventionVersionModel';
import TaskZoneModel               from 'Models/intervention/TaskZoneModel';
import nestedResolvable            from 'decorators/nestedResolvable';
import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'intervention_version_anomaly_flag')
export default class InterventionVersionAnomalyFlagModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/intervention_version_anomaly_flags/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'flag': number;
		'flag[gte]': number;
		'interventionVersion': id;
		'interventionVersion.intervention.partitionUrn': string;
	}> = {};

	@resolvable(InterventionVersionModel, { attributeName: 'interventionVersion' })
	public declare interventionVersion: InterventionVersionModel;

	@computed
	public get flag() {
		return this.get('flag', 0);
	}

	@nestedResolvable()
	public get intervention() {
		return this.interventionVersion.intervention;
	}

	@nestedResolvable()
	public get location() {
		return this.intervention.location;
	}

	@nestedResolvable()
	public get owner() {
		const taskZone = (this.location instanceof TaskZoneModel) ? this.location as TaskZoneModel : null;

		return taskZone ? taskZone.owner : null;
	}

	@nestedResolvable()
	public get contact() {
		return this.owner?.contact;
	}
}
