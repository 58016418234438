import { resolvable }      from '@mathquis/modelx-resolvables';
import ClientModel         from 'Models/directory/ClientModel';
import PartitionModel      from 'Models/partition/PartitionModel';
import OwnerModel          from 'Models/time/OwnerModel';
import { urn }             from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                          from 'helpers/traits';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

@urn('$', 'time', 'owner_client')
export default class OwnerClientModel extends Blamable(Timestampable(TimePrivateApiModel)) {

	static get path(): string {
		return '/owner_clients/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'clientUrn': string;
		'owner': id;
		'owner.contactUrn': string;
		'owner.generalClientUrn': string;
		'partitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@resolvable(ClientModel, { attributeName: 'clientUrn' })
	public declare client: ClientModel;

	@resolvable(OwnerModel, { attributeName: 'owner' })
	public declare owner: OwnerModel;

	@resolvable(PartitionModel, { attributeName: 'partitionUrn' })
	public declare partition: PartitionModel;
}