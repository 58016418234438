import { urn }             from 'helpers/ModelDictionary';
import { Timestampable }   from 'helpers/traits';
import { Blamable }        from 'helpers/traits';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

type PlanImportStatusReference = 'waiting_treatment' | 'in_treatment' | 'done_treatment' | 'error_treatment';

@urn('$', 'time', 'plan_import_status')
export default class PlanImportStatusModel extends Blamable(Timestampable(TimePrivateApiModel)) {

	static get path(): string {
		return '/plan_import_statuses/{?id}';
	}

	public static cacheDuration = 3600;

	public get color() {
		switch (this.reference) {
			case 'done_treatment':
				return 'green';
			case 'error_treatment':
				return 'red';
			case 'waiting_treatment':
			case 'in_treatment':
			default:
				return 'grey';
		}
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): PlanImportStatusReference {
		return this.get('reference', '');
	}
}