import { resolvable }    from '@mathquis/modelx-resolvables';
import RightModel        from 'Models/rights/RightModel';
import model             from 'decorators/model';
import { urn }           from 'helpers/ModelDictionary';
import { Blamable }      from 'helpers/traits';
import { Timestampable } from 'helpers/traits';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

@model.staticLabel(`Groupe d'utilisateurs`)
@urn('$', 'rh', 'partition_group')
export default class PartitionGroupModel extends Blamable(Timestampable(RhPrivateApiModel)) {

	static get path(): string {
		return '/partition_groups/{?id}';
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'name': string;
	}> = {};

	public get name(): string {
		return this.get('name', '');
	}

	@resolvable(RightModel, { attributeName: 'rightUrn' })
	public declare right: RightModel;
}