import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type ResourceTypeReference = 'vehicle' | 'technician';

class ResourceTypeModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	public static cacheDuration = 3600;

	static get path(): string {
		return '/resource_types/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get reference(): ResourceTypeReference {
		return this.get('reference', '');
	}

	/*@resolvable(RightModel, { attributeName: 'rightUrn' })
	public declare right: RightModel;*/
}

export default ResourceTypeModel;