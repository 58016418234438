import Space                     from 'antd/lib/space';
import Tooltip, { TooltipProps } from 'antd/lib/tooltip';
import _omit                     from 'lodash/omit';
import React                     from 'react';
import getPrimaryColor           from 'tools/getPrimaryColor';

const interleave = (arr, thing) => [].concat(...arr.map(n => [n, thing])).slice(0, -1);

const getMessage = ({ disabled, messages, operator, title }: IDisabledTooltipProps) => {
	if (disabled && messages) {
		if (Array.isArray(messages)) {

			const operatorLabel = operator === 'or' ? 'OU' : 'ET';

			const messagesFormatted = interleave(messages, (
				<strong style={{ color: getPrimaryColor() }}>{operatorLabel}&nbsp;</strong>
			));

			return (
				<Space align="center" direction="vertical">
					<strong style={{ fontSize: 14, textDecoration: 'underline' }}>
						{title || `Conditions d'activation :`}
					</strong>

					<Space direction="vertical" size={1} style={{ alignItems: 'center', alignSelf: 'center' }}>
						{messagesFormatted.map((message, i) => (
							<div key={i} style={{ fontSize: 12, textAlign: 'center' }}>
								{message}
							</div>
						))}
					</Space>
				</Space>
			);
		}

		return <span style={{ fontSize: 12 }}>{messages}</span>;
	}

	return undefined;
};

export interface IDisabledTooltipProps {
	disabled?: boolean;
	messages?: string | string[];
	operator?: 'and' | 'or';
	title?: string | React.ReactNode;
}

export default (props: IDisabledTooltipProps & Partial<TooltipProps>) => (
	<Tooltip
		{..._omit(props, ['disabled', 'messages'])}
		title={getMessage(props)}
	>
		{props.children}
	</Tooltip>
);