import { resolvable }          from '@mathquis/modelx-resolvables';
import DebitMandateModel       from 'Models/invoice/DebitMandateModel';
import DebitMandateStatusModel from 'Models/invoice/DebitMandateStatusModel';
import { urn }                 from 'helpers/ModelDictionary';
import { Blamable }            from 'helpers/traits';
import { Timestampable }       from 'helpers/traits';
import InvoicePrivateApiModel  from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'debit_mandate_status_history')
export default class DebitMandateStatusHistoryModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/debit_mandate_status_histories/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'debitMandate': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@resolvable(DebitMandateModel, { attributeName: 'debitMandate' })
	public declare debitMandate: DebitMandateModel;

	@resolvable(DebitMandateStatusModel, { attributeName: 'debitMandateStatus' })
	public declare debitMandateStatus: DebitMandateStatusModel;
}