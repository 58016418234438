import { resolvable }              from '@mathquis/modelx-resolvables';
import OperationModel              from 'Models/intervention/OperationModel';
// import SourceModel                 from 'Models/intervention/SourceModel';
import TaskModel                   from 'Models/intervention/TaskModel';
import TaskOperationStatusModel    from 'Models/intervention/TaskOperationStatusModel';
import TaskZoneModel               from 'Models/intervention/TaskZoneModel';
import { urn }                     from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                                  from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'task_operation')
export default class TaskOperationModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/task_operations/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'operation': id;
		'sourceUrn': string;
		'task': id;
		'task.owner.clientUrn': string;
		'task.owner.contactUrn': string;
		'task.owner.generalClientUrn': string;
		'task.owner.partitionUrn': string;
		'task.taskMetas': id;
		'task.taskMetas.reference': string;
		'task.taskMetas.value': id;
		'taskOperationStatus': id;
		'taskZone': id;
		'taskZone.city': string;
		'taskZone.zipCode': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'task.title': string;
		'taskZone.city': string;
		'taskZone.name': string;
		'taskZone.zipCode': string;
		'updatedAt': string;
	}> = {};

	public get cancelled(): boolean {
		return this.get('cancelled', false);
	}

	public get deleted(): boolean {
		return this.get('deleted', false);
	}

	public get note(): string {
		return this.get('note', '');
	}

	@resolvable(OperationModel, { attributeName: 'operation' })
	public declare operation: OperationModel;

	// @resolvable(SourceModel, { attributeName: 'sourceUrn' })
	// public declare source: SourceModel;

	@resolvable(TaskModel, { attributeName: 'task' })
	public declare task: TaskModel;

	@resolvable(TaskOperationStatusModel, { attributeName: 'taskOperationStatus', cache: true })
	public declare taskOperationStatus: TaskOperationStatusModel;

	@resolvable(TaskZoneModel, { attributeName: 'taskZone' })
	public declare taskZone: TaskZoneModel;

	public get taskId(): id {
		return this.get('task.id');
	}

	public get value(): number {
		return this.get('value', 0);
	}
}
