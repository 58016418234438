import { resolvable }              from '@mathquis/modelx-resolvables';
import ClientModel                 from 'Models/directory/ClientModel';
import ContactModel                from 'Models/directory/ContactModel';
import { urn }                     from 'helpers/ModelDictionary';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'owner')
export default class OwnerModel extends InterventionPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'clientUrn': id;
		'contactUrn': id;
		'generalClientUrn': id;
		'partitionUrn': id;
	}> = {};

	static get path(): string {
		return '/owners/{?id}';
	}

	@resolvable(ClientModel, { attributeName: 'clientUrn' })
	public declare client: ClientModel;

	@resolvable(ContactModel, { attributeName: 'contactUrn' })
	public declare contact: ContactModel;

	public get generalClientUrn(): string {
		return this.get('generalClientUrn', '');
	}

	public get partitionUrn(): string {
		return this.get('partitionUrn', '');
	}

}