import { urn }                     from 'helpers/ModelDictionary';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type WeekTypeReference = 'even' | 'odd' | 'all';

@urn('$', 'intervention', 'week_type')
export default class WeekTypeModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/week_types/{?id}';
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	public static cacheDuration = 3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): WeekTypeReference {
		return this.get('reference', '');
	}
}