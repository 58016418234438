import { SearchOutlined } from '@ant-design/icons';
import Form               from 'components/Form';
import Input              from 'components/Input';
import debounce           from 'lodash/debounce';
import React              from 'react';

export default class DefaultSearchComponent extends React.Component<{ fetch: () => void; style?: React.CSSProperties }> {
	public search = debounce(this.props.fetch, 500);

	public render() {
		return (
			<Form.Item name="search" style={this.props.style}>
				<Input
					allowClear={true}
					onChange={this.search}
					onPressEnter={e => e.preventDefault()}
					placeholder="Recherche"
					prefix={<SearchOutlined />}
					size="middle"
				/>
			</Form.Item>
		);
	}
}