import { resolvable }                         from '@mathquis/modelx-resolvables';
import ContractIterationItemScheduleTypeModel from 'Models/sales/ContractIterationItemScheduleTypeModel';
import { urn }                                from 'helpers/ModelDictionary';
import SalesPrivateApiModel                   from '../../abstracts/SalesPrivateApiModel';

export type ContractIterationItemScheduleTypeOptionReference = 'even_weeks' | 'odd_weeks' | 'the_first' | 'the_second' | 'the_third' | 'the_fourth' | 'the_fifth';

@urn('$', 'sales', 'contract_iteration_item_schedule_type_option')
export default class ContractIterationItemScheduleTypeOptionModel extends SalesPrivateApiModel {

	static get path(): string {
		return '/contract_iteration_item_schedule_type_options/{?id}';
	}

	public static cacheDuration =3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): ContractIterationItemScheduleTypeOptionReference {
		return this.get('reference', '');
	}

	@resolvable(ContractIterationItemScheduleTypeModel, { attributeName: 'contractIterationItemScheduleType', cache: true })
	public declare contractIterationItemScheduleType: ContractIterationItemScheduleTypeModel;
}
