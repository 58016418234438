import GeneralClientModel       from 'Models/directory/GeneralClientModel';
import PartitionModel           from 'Models/partition/PartitionModel';
import doc                       from 'decorators/doc';
import model                      from 'decorators/model';
import nestedResolvable         from 'decorators/nestedResolvable';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Bénéficiaire')
@model.urn('$', 'directory', 'client')
@doc.path('/clients/{?id}')
export default class ClientModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'enabled': boolean;
		'generalClient': id;
		'generalClient.contact.enabled': boolean;
		'generalClient.number': number;
		'partitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'generalClient.contact.firstName': string;
		'generalClient.contact.lastName': string;
		'generalClient.number': string;
		'generalClient.reference': string;
		'id': string;
	}> = {};

	@doc.boolean declare enabled: boolean;
	@doc.model(GeneralClientModel) declare generalClient: GeneralClientModel;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;

	@nestedResolvable()
	public get contact() {
		return this.generalClient.contact;
	}

	public get generalClientId() {
		return this.get('generalClient.id');
	}
}
