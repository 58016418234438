import { computed }      from 'mobx';
import RhPrivateApiModel from '../../abstracts/RhPrivateApiModel';

export default class LeaveTypeModel extends RhPrivateApiModel {
	public static cacheDuration = 3600;

	static get path(): string {
		return '/leave_types/{?id}';
	}

	@computed
	public get position(): number {
		return this.get('position', 0);
	}

	@computed
	public get reference(): string {
		return this.get('reference', '');
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get icon(): Record<string, string> {
		return this.get('icon', {});
	}
}
