import { InterventionTypeReference } from 'Models/intervention/InterventionTypeModel';
import _get                          from 'lodash/get';
import { computed }                  from 'mobx';
import moment                        from 'moment';
import { getIdFromUrn }              from 'tools/UrnTools';
import { hoursFormatted }            from 'tools/dateTools';
import { hoursFormattedFromDates }   from 'tools/dateTools';
import InterventionPrivateApiModel   from '../../abstracts/InterventionPrivateApiModel';

export default class ProtoInterventionVersionModel extends InterventionPrivateApiModel {

	public get anomalyFlag(): number {
		return this.get('anomalyFlag', 0);
	}

	public get contactUrn(): string {
		return this.get('owner.contactUrn', '');
	}

	public get interventionTypeId(): id {
		return this.get('intervention.interventionType.id', '');
	}

	public get interventionTypeReference(): InterventionTypeReference {
		return this.get('intervention.interventionType.reference', '');
	}

	public get operationLabels(): string[] {
		return this.get('interventionOperations', []).map(io => io.taskOperation.operation.label);
	}

	public get label(): string {
		return this.get('intervention.label', '');
	}

	public getDistanceForResource(urn: string) {
		const res = this.get('interventionVersionResources', []).find(ivr => _get(ivr, 'resource.resourceUrn') === urn);

		const duration = res.duration as number || 0;
		const distance = res.distance as number || 0;

		const durationFormatted = hoursFormatted(moment.duration(duration, 'minutes'));

		return {
			distance,
			distanceKm: distance / 1000,
			duration,
			durationFormatted,
			durationHours: duration / 60,
		};
	}

	public getInterventionResourceIdForResourceUrn(urn: string): id[] {
		return this.get('interventionVersionResources', []).find(ivr => _get(ivr, 'resource.resourceUrn') === urn)?.id;
	}

	public getInterventionResourceTypeIdForResourceUrn(urn: string): number {
		const interventionVersionResource = this.get('interventionVersionResources', [])
			.find(ivr => _get(ivr, 'resource.resourceUrn') === urn);

		return _get(interventionVersionResource, 'interventionResourceType.id', 0);
	}

	public getResourceUrnsForInterventionResourceType(id: id) {
		return this.get('interventionVersionResources', [])
			.filter(ivr => _get(ivr, 'interventionResourceType.id') === id)
			.map(ivr => _get(ivr, 'resource.resourceUrn', ''));
	}

	@computed
	public get staffMemberUrns(): string[] {
		return this.get('interventionVersionResources', [])
			.filter(ivr => _get(ivr, 'resourceType.reference') === 'technician')
			.map(ivr => _get(ivr, 'resource.resourceUrn', ''));
	}

	@computed
	public get technicianResourceUrns(): string[] {
		return this.get('interventionVersionResources', [])
			.filter(ivr => _get(ivr, 'resourceType.reference') === 'technician')
			.map(ivr => _get(ivr, 'resource.resourceUrn', ''));
	}

	@computed
	public get staffMemberIds(): id[] {
		return this.staffMemberUrns.map(urn => getIdFromUrn(urn));
	}

	@computed
	public get vehicleUrns(): string[] {
		return this.get('interventionVersionResources', [])
			.filter(ivr => _get(ivr, 'resourceType.reference') === 'vehicle')
			.map(ivr => _get(ivr, 'resource.resourceUrn', ''));
	}

	@computed
	public get vehicleIds(): id[] {
		return this.vehicleUrns.map(urn => getIdFromUrn(urn));
	}

	@computed
	public get taskCancellations(): { id: id, note?: string }[] {
		return this.get('task.taskCancellations', []);
	}

	@computed
	public get taskUrn(): string {
		return this.get('task.urn');
	}

	@computed
	public get taskId(): id {
		return getIdFromUrn(this.taskUrn);
	}

	@computed
	public get versionUrn(): string {
		return this.get('version.urn');
	}

	@computed
	public get versionId(): id {
		return getIdFromUrn(this.versionUrn);
	}

	public get city(): string {
		return this.get('location.city', '');
	}

	@computed
	public get contactId(): id {
		return getIdFromUrn(this.contactUrn);
	}

	public get urn(): Urn {
		return this.get('urn', '');
	}

	public get statusId(): id {
		return this.get('intervention.interventionStatus.id', '');
	}

	@computed
	public get statusReference(): InterventionStatusReference {
		return this.get('intervention.interventionStatus.reference', '');
	}

	@computed
	public get isCancelledOrDeleted(): boolean {
		return this.statusReference === 'cancelled' || this.statusReference === 'to_delete' || this.statusReference === 'deleted';
	}

	@computed
	public get scheduleStartDate(): Moment {
		return moment(parseInt(this.get('scheduleStartDate.seconds')) * 1000);
	}

	@computed
	public get scheduleEndDate(): Moment {
		return moment(parseInt(this.get('scheduleEndDate.seconds')) * 1000);
	}

	@computed
	public get duration() {
		return this.scheduleEndDate.diff(this.scheduleStartDate);
	}

	@computed
	public get durationFormatted() {
		return hoursFormattedFromDates(this.scheduleStartDate, this.scheduleEndDate);
	}
}