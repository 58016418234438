import { resolvable } from '@mathquis/modelx-resolvables';
import ActionGroupModel from 'Models/rights/ActionGroupModel';
import RightModel from 'Models/rights/RightModel';
import { urn } from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
} from 'helpers/traits';

import RightsPrivateApiModel from '../../abstracts/RightsPrivateApiModel';

@urn('$', 'rights', 'right_action_group')
export default class RightActionGroupModel extends Blamable(Timestampable(RightsPrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'actionGroup': id;
		'right': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'actionGroup.label': string;
		'id': string;
	}> = {};

	static get path(): string {
		return '/right_action_groups/{?id}';
	}

	@resolvable(ActionGroupModel, { attributeName: 'actionGroup' })
	public declare actionGroup: ActionGroupModel;

	@resolvable(RightModel, { attributeName: 'right' })
	public declare right: RightModel;

	public get actionGroupId(): id {
		return this.get('actionGroup.id', 0);
	}
}