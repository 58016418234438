import model                       from 'decorators/model';
import {
	Blamable,
	Timestampable,
}                                  from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type TaskStatusReference =
	'in_creation'
	| 'to_schedule'
	| 'to_do'
	| 'done'
	| 'done_not_complete'
	| 'blocked'
	| 'cancelled'
	| 'deleted';

@model.staticLabel('Statut')
@model.urnResource('task_status')
export default class TaskStatusModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/task_statuses/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'reference': TaskStatusReference;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	public static cacheDuration =3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): TaskStatusReference {
		return this.get('reference', '');
	}
}
