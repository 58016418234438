import { resolvable }         from '@mathquis/modelx-resolvables';
import ContractIterationModel from 'Models/sales/ContractIterationModel';
import model                  from 'decorators/model';
import SalesPrivateApiModel   from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Item')
@model.urnResource('contract_iteration_item')
export default class ContractIterationItemModel extends SalesPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'contractIteration': id;
	}> = {};

	static get path(): string {
		return '/contract_iteration_items/{?id}';
	}

	@resolvable(ContractIterationModel, { attributeName: 'contractIteration' })
	public declare contractIteration: ContractIterationModel;

}
