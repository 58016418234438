import { resolvable }       from '@mathquis/modelx-resolvables';
import ClientModel          from 'Models/directory/ClientModel';
import ContactModel         from 'Models/directory/ContactModel';
import { urn }              from 'helpers/ModelDictionary';
import { computed }         from 'mobx';
import { getIdFromUrn }     from 'tools/UrnTools';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'owner')
export default class OwnerModel extends SalesPrivateApiModel {

	static get path(): string {
		return '/owners/{?id}';
	}

	@resolvable(ClientModel, { attributeName: 'clientUrn', cache: true })
	public declare client: ClientModel;

	@resolvable(ContactModel, { attributeName: 'contactUrn', cache: true })
	public declare contact: ContactModel;

	@computed
	public get clientId(): id {
		return this.get('clientUrn.id') || getIdFromUrn(this.get('clientUrn', ''));
	}
}