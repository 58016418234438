import { resolvable }              from '@mathquis/modelx-resolvables';
import ResourceTypeModel           from 'Models/intervention/ResourceTypeModel';
import LogTypeModel                from 'Models/time/LogTypeModel';
import { urn }                     from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                                  from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'intervention_resource_type')
export default class InterventionResourceTypeModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/intervention_resource_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
	}> = {};

	public static cacheDuration =3600;

	public get label(): string {
		return this.get('label', '');
	}

	@resolvable(LogTypeModel, { attributeName: 'logTypeUrn' })
	public declare logType: LogTypeModel;

	public get position(): number {
		return this.get('position', 0);
	}

	public get reference(): InterventionResourceTypeReference | undefined {
		return this.get('reference', '');
	}

	@resolvable(ResourceTypeModel, { attributeName: 'resourceType', cache: true })
	public declare resourceType: ResourceTypeModel;
}