import lazyWithRetry from 'tools/lazyWithRetry';

const StaffMemberUserListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "staffMemberListPage" */
	'../../pages/StaffMember/StaffMemberUserListPage'));

const StaffMemberUserDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "staffMemberUserDashboardPage" */
	'../../pages/StaffMember/StaffMemberUserDashboardPage/StaffMemberUserDashboardPage'));

const StaffMemberListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "staffListPage" */
	'../../pages/StaffMember/StaffMemberListPage'));

const StaffMemberDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "staffListPage" */
	'../../pages/StaffMember/StaffMemberDashboardPage/StaffMemberDashboardPage'));

export default [
	{
		component: StaffMemberUserListPage,
		exact: true,
		path: '/users',
	},
	{
		component: StaffMemberUserDashboardPage,
		exact: true,
		path: '/users/:staffMemberId',
	},
	{
		component: StaffMemberDashboardPage,
		exact: true,
		path: '/partitions/:partitionId/staff_members/:staffMemberId',
	},
	{
		component: StaffMemberListPage,
		exact: true,
		path: '/partitions/:partitionId/staff_members',
	},
];
