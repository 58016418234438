import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Blamable }             from 'helpers/traits';
import { Timestampable }        from 'helpers/traits';
import PartitionPrivateApiModel from '../../abstracts/PartitionPrivateApiModel';

@model.staticLabel('Agence', false)
@model.urn('$', 'partition', 'partition')
@doc.path('/partitions/{?id}')
export default class PartitionModel extends Blamable(Timestampable(PartitionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'name': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'name': string;
		'position': string;
	}> = {};

	public static cacheDuration = 3600;

	@doc.string declare name: string;
	@doc.number declare position: number;
	@doc.string declare siret: string;
	@doc.string declare timezone: string;
}