import { ApiConnector } from './ApiConnector';

export const isExpired = exp => (exp || 0) < Math.ceil(new Date().valueOf() / 1000);

export class LoggedApiConnector extends ApiConnector {
	private _exp: number;
	private _onExpired: () => Promise<void>;
	private _token: string;

	constructor(options) {
		super(options);

		this._token = '';
		this._exp = 0;
		this._onExpired = async () => {
			return;
		};

		this.client.interceptors.request.use(async (config) => {
			if (this._token) {
				if (this._onExpired && this._sessionIsExpired()) {
					await this._onExpired();
				}

				// Add the JWT token to the request
				config.headers['X-Platform-Authorization'] = 'Bearer ' + this._token;
			}

			return config;
		});
	}

	public onExpired(callback: () => Promise<void>): this {
		this._onExpired = callback;

		return this;
	}

	public setExpiration(exp: number): this {
		this._exp = exp;

		return this;
	}

	public setToken(token: string): this {
		this._token = token;

		return this;
	}

	private _sessionIsExpired() {
		return isExpired(this._exp);
	}
}
