import { urn }                     from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                                  from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type VersionTypeReference = 'real' | 'internal' | 'draft';

export const getVersionTypeColor = (reference: VersionTypeReference) => {
	switch (reference) {
		case 'real' :
			return 'green';
		case 'draft' :
			return 'grey';
		case 'internal' :
			return 'blue';
	}

	return 'grey';
};

@urn('$', 'intervention', 'version_type')
export default class VersionTypeModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/version_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': VersionTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	public static cacheDuration = 3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get position(): number {
		return this.get('position', 0);
	}

	public get reference(): VersionTypeReference {
		return this.get('reference', '');
	}

	public get color() {
		return getVersionTypeColor(this.reference);
	}
}