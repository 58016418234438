import lazyWithRetry from 'tools/lazyWithRetry';

const DepositListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "depositListPage" */
	'../../pages/Deposit/DepositListPage'));

const DepositDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "depositDashboardPage" */
	'../../pages/Deposit/DepositDashboardPage'));

export default [
	{
		component: DepositListPage,
		exact: true,
		path: '/partitions/:partitionId/deposits',
	},
	{
		component: DepositDashboardPage,
		exact: true,
		path: '/partitions/:partitionId/deposits/:depositId',
	},
];

