import { resolvable }           from '@mathquis/modelx-resolvables';
import ContactModel             from 'Models/directory/ContactModel';
import { urn }                  from 'helpers/ModelDictionary';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@urn('$', 'directory', 'general_client')
export default class GeneralClientModel extends DirectoryPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		'contact': id;
		'number': string;
		'reference': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'contact.lastName': string;
		'id': string;
	}> = {};

	static get path(): string {
		return '/general_clients/{?id}';
	}

	@resolvable(ContactModel, { attributeName: 'contact' })
	public declare contact: ContactModel;

	public get number(): number {
		return this.get('number', 0);
	}

	public get reference(): string {
		return this.get('reference', '');
	}

	public get contactId() {
		return this.get('contact.id');
	}
}
