import lazyWithRetry from 'tools/lazyWithRetry';

const ActivityListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "activityListPage" */
	'../../pages/Activity/ActivityListPage/ActivityListPage'));

const ActivityDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "activityDashboardPage" */
	'../../pages/Activity/ActivityDashboardPage/ActivityDashboardPage'));

export default [
	{
		component: ActivityListPage,
		exact: true,
		path: '/activities',
	},
	{
		component: ActivityDashboardPage,
		exact: true,
		path: '/activities/:activityId',
	},
];