import lazyWithRetry from 'tools/lazyWithRetry';

const PaymentListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "paymentListPage" */
	'../../pages/Payment/PaymentListPage'));

const PaymentDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "paymentDashboardPage" */
	'../../pages/Payment/PaymentDashboardPage'));

export default [
	{
		component: PaymentListPage,
		exact: true,
		path: '/partitions/:partitionId/payments',
	},
	{
		component: PaymentDashboardPage,
		exact: true,
		path: '/partitions/:partitionId/payments/:paymentId',
	},
];

