import OwnerResourceModel          from 'Models/intervention/OwnerResourceModel';
import ResourceTypeModel           from 'Models/intervention/ResourceTypeModel';
import PartitionModel              from 'Models/partition/PartitionModel';
import doc                          from 'decorators/doc';
import model                         from 'decorators/model';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Resource')
@model.urn('$', 'intervention', 'resource')
@doc.path('/resources/{?id}')
export default class ResourceModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'enabled': boolean | string;
		'ownerResource': id;
		'ownerResource.entityUrn': string;
		'partitionUrn': string;
		'resourceAvailabilities.day': number;
		'resourceAvailabilities.weekType.reference': string;
		'resourceType': id;
		'resourceType.reference': ResourceTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'resourceType.label': string;
		'updatedAt': string;
	}> = {};

	@doc.boolean declare enabled: boolean;
	@doc.model(OwnerResourceModel) declare ownerResource: OwnerResourceModel;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.model(ResourceTypeModel) declare resourceType: ResourceTypeModel;
}