import { resolvable }               from '@mathquis/modelx-resolvables';
import ContractIterationModel       from 'Models/sales/ContractIterationModel';
import ContractIterationStatusModel from 'Models/sales/ContractIterationStatusModel';
import { urn }                      from 'helpers/ModelDictionary';
import {
	Blamable,
	Timestampable,
}                                   from 'helpers/traits';
import { computed }                 from 'mobx';
import moment                       from 'moment';
import SalesPrivateApiModel         from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'contract_iteration_status_history')
export default class ContractIterationStatusHistoryModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'contractIteration': id;
	}> = {};

	static get path(): string {
		return '/contract_iteration_status_histories/{?id}';
	}

	@resolvable(ContractIterationModel, { attributeName: 'contractIteration' })
	public declare contractIteration: ContractIterationModel;

	@resolvable(ContractIterationStatusModel, { attributeName: 'contractIterationStatus', cache: true })
	public declare contractIterationStatus: ContractIterationStatusModel;

	public get comment(): string {
		return this.get('comment', '');
	}

	public get rejectionReason(): string {
		return this.get('rejectionReason', '');
	}

	@computed
	public get suspensionEndDate(): Moment {
		return moment(this.get('suspensionEndDate', moment()));
	}

	@computed
	public get suspensionStartDate(): Moment {
		return moment(this.get('suspensionStartDate', moment()));
	}

}