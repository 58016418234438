import { resolvable }          from '@mathquis/modelx-resolvables';
import InvoiceDocumentPdfModel from 'Models/invoice/InvoiceDocumentPdfModel';
import InvoiceModel            from 'Models/invoice/InvoiceModel';
import { urn }                 from 'helpers/ModelDictionary';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import { appStore }            from 'stores/index';
import downloadFile            from 'tools/downloadFile';
import notificationApiError    from 'tools/notificationApiError';
import InvoicePrivateApiModel  from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoice_document')
export default class InvoiceDocumentModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/invoice_documents/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'invoice': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public get contentUrl(): string {
		return this.get('contentUrl', '');
	}

	public get file(): string {
		return this.get('file', '');
	}

	public get fileMimeType(): string {
		return this.get('fileMimeType', '');
	}

	public get fileOriginalName(): string {
		return this.get('fileOriginalName', '');
	}

	public get filePath(): string {
		return this.get('filePath', '');
	}

	public get fileSize(): number {
		return this.get('fileSize', 0);
	}

	public get folder(): string {
		return this.get('folder', '');
	}

	@resolvable(InvoiceModel, { attributeName: 'invoice' })
	public declare invoice: InvoiceModel;

	public async downloadPdfAsync() {
		try {
			const invoiceDocumentPdfModel = new InvoiceDocumentPdfModel({ id: this.id });

			await invoiceDocumentPdfModel.fetch();

			if (!this.invoice.isLoaded) {
				await this.invoice.fetch();
			}

			const fileName =
				invoiceDocumentPdfModel.connectorResultFetch?.res.headers['content-disposition']?.replace('inline; filename=', '')
				|| `${appStore.partition?.name} - Facture - ${this.invoice.reference}.pdf`;

			downloadFile(
				invoiceDocumentPdfModel.blob,
				fileName,
			);
		} catch (err) {
			notificationApiError(err);
		}
	}
}
