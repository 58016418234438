import { EventSourcePolyfill }     from 'event-source-polyfill';
import _get                        from 'lodash/get';
import InterventionEventTokenModel from 'modelx/models/private/intervention/InterventionEventTokenModel';
import moment                      from 'moment';
import Proto                       from 'proto/proto';
import { appStore }                from 'stores';
import ConfigProxy                 from 'tools/ConfigProxy';

export type mercureEvent = { data: string, type: string };

export type onMessageFunction = (data: string, e: mercureEvent) => void;

export default class MercureServiceIntervention {
	private _authTimeout?: ReturnType<typeof setTimeout>;
	private _eventSource: EventSourcePolyfill;

	private _executeMessageFunction?: onMessageFunction;

	private readonly _messageQueue: mercureEvent[];

	private _messageQueueIsPaused: boolean;

	private _token = new InterventionEventTokenModel();

	public constructor(onMessage?: onMessageFunction) {
		this._executeMessageFunction = onMessage;

		this._messageQueue = [];
		this._messageQueueIsPaused = false;
	}

	public pauseMessageQueue(): this {
		this._messageQueueIsPaused = true;

		return this;
	}

	public resumeMessageQueue(): this {
		this._messageQueue.forEach(e => {
			if (this._executeMessageFunction) {
				this._executeMessageFunction(JSON.parse(e.data), e);
			}
		});

		this._messageQueueIsPaused = false;

		return this;
	}

	public setMessageFunction(onMessageFunction: onMessageFunction) {
		this._executeMessageFunction = onMessageFunction;
	}

	public async start(topics: string[] = []): Promise<void> {
		this.stop();

		await this._fetchToken();

		const url = new URL(ConfigProxy.getServiceConfig('mercure', 'api_endpoint'));

		if (topics.length) {
			topics.forEach(topic => url.searchParams.append('topic', topic));
		}

		this._eventSource = new EventSourcePolyfill(url.toString().replace('/?', '?'), {
			headers: { 'Authorization': `Bearer ${this._token.jwt}` },
			// heartbeatTimeout: 120000
		});

		this._eventSource.onmessage = this._onMessage;
	}

	public stop(): void {
		if (this._eventSource) {
			this._eventSource.close();
		}

		if (this._authTimeout) {
			clearTimeout(this._authTimeout);
		}
	}

	private async _fetchToken(): Promise<void> {
		await this._token.clear().patch({ partitionUrn: appStore.partitionUrn });

		const timeRemaining = this._token.expMoment.diff(moment(), 'second');

		this._authTimeout = setTimeout(this._fetchToken.bind(this), timeRemaining * 1000);
	}

	private _onMessage = (e: mercureEvent) => {
		console.info('Mercure event received', e);

		if (this._messageQueueIsPaused) {
			this._messageQueue.push(e);
		}

		if (this._executeMessageFunction) {
			this._executeMessageFunction(e.data, e);
		}
	};
}

export const getProto = (name: string) => _get(Proto, name);