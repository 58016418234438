/* eslint-disable @typescript-eslint/ban-ts-comment */

import AbstractResolvableModel         from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { ResolvableModelClass }        from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { IResolvableDecoratorOptions } from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { resolvable }                  from '@mathquis/modelx-resolvables';
import moment                          from 'moment';

export default {
	boolean: (target, propertyKey: string) => {
		const getter = function () {
			// @ts-ignore
			return this.get(propertyKey, false);
		};

		Object.defineProperty(target, propertyKey, { get: getter, set: () => null });
	},
	date: (target, propertyKey: string) => {
		const getter = function () {
			// @ts-ignore
			const value = this.get(propertyKey);
			// @ts-ignore
			return (!value && this.isLoaded && !this.isLoading) ? undefined : moment(value);
		};

		Object.defineProperty(target, propertyKey, { get: getter, set: () => null });
	},
	model: (
		modelClass: ResolvableModelClass<AbstractResolvableModel>,
		options: IResolvableDecoratorOptions = {},
	) => {
		return function (target: AbstractResolvableModel, key: string) {
			return resolvable(modelClass, { attributeName: key, ...options })(target, key);
		};
	},
	modelUrn: (
		modelClass: ResolvableModelClass<AbstractResolvableModel>,
		options: IResolvableDecoratorOptions = {},
	) => {
		return function (target: AbstractResolvableModel, key: string) {
			return resolvable(modelClass, { attributeName: key + 'Urn', ...options })(target, key);
		};
	},
	number: (target, propertyKey: string) => {
		const getter = function () {
			// @ts-ignore
			return this.get(propertyKey, 0);
		};

		Object.defineProperty(target, propertyKey, { get: getter, set: () => null });
	},
	path: function (value: string) {
		return target => Object.defineProperty(target, 'path', {
			configurable: false,
			enumerable: false,
			value,
			writable: false,
		});
	},
	string: (target, propertyKey: string) => {
		const getter = function () {
			// @ts-ignore
			return this.get(propertyKey, '');
		};

		Object.defineProperty(target, propertyKey, { get: getter, set: () => null });
	},
};