import { resolvable }         from '@mathquis/modelx-resolvables';
import BillableModel          from 'Models/invoice/BillableModel';
import VatModel               from 'Models/invoice/VatModel';
import { urn }                from 'helpers/ModelDictionary';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'billable_item')
export default class BillableItemModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/billable_items/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'billable': id;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'locationUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'description': string;
		'id': string;
		'label': string;
		'quantity': string;
		'updatedAt': string;
	}> = {};

	@resolvable(BillableModel, { attributeName: 'billable' })
	public declare billable: BillableModel;

	public get description(): string {
		return this.get('description', '');
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get priceExclTax(): number {
		return this.get('priceExclTax', 0);
	}

	public get priceInclTax(): number {
		return this.get('priceInclTax', 0);
	}

	public get priceVat(): number {
		return this.get('priceVat', 0);
	}

	public get quantity(): number {
		return this.get('quantity', 0);
	}

	public get totalPriceExclTax(): number {
		return this.get('totalPriceExclTax', 0);
	}

	public get totalPriceInclTax(): number {
		return this.get('totalPriceInclTax', 0);
	}

	public get totalPriceVat(): number {
		return this.get('totalPriceVat', 0);
	}

	public get billableId(): id {
		return this.get('billable.id', 0);
	}

	@resolvable(VatModel, { attributeName: 'vat', cache: true })
	public declare vat: VatModel;
}