import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('Type')
@model.urn('$', 'invoice', 'billable_type')
@doc.path('/billable_types/{?id}')
export default class BillableTypeModel extends InvoicePrivateApiModel {
	public _filters: ModelFiltersExtended<unknown> = {};
	public _sorts: ModelSortsExtended<unknown> = {};

	public static cacheDuration = 3600;

	@doc.string declare label: string;
	@doc.string declare reference: BillableTypeReference;
}